const translationEl = {
  homePage: 'Αρχική',
  contacts: 'Επαφές',
  allContacts: 'όλες οι Επαφές',
  tasks: 'Εργασίες',
  tasksList: 'Εργασίες',
  donations: 'Δωρεές',
  donation: 'Δωρεά',
  newDonation: 'Νέα Δωρεά',
  donationDetails: 'Στοιχεία Δωρεάς',
  editDonation: 'Επεξεργασία Δωρεάς',
  updateDonation: 'Ενημέρωση Δωρεάς',
  warehouse: 'Αποθήκη',
  updateWarehouse: 'Ενημέρωση Αποθήκη',
  createRegistration: 'Νέα Καταχώρηση',
  createDonation: 'Νέα Δωρεά',
  createContact: 'Προσθήκη Επαφής',
  createUser: 'Νέος Χρήστης',
  createTask: 'Νέα Εργασία',
  'Email Address': 'Διεύθυνση Email',
  dataEntryShort: 'Ημ. Εισαγωγής',
  users: 'Χρήστες',
  user: 'Χρήστη',
  addUser: 'Νέος Χρήστης',
  newUser: 'Νέος Χρήστης',
  addNewUser: 'Προσθήκη Χρήστη',
  userDetails: 'Στοιχεία Xρήστη',
  updateUser: 'Eνημέρωση Χρήστη',
  userType: 'Tύπος Xρήστη',
  basicInfo: 'Βασικά Στοιχεία',
  deleteUserMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον χρήστη;',
  deleteContactMessage: 'Είστε σίγουροι ότι θέλετε να διαγράψετε την επάφη;',
  fullName: 'Ονοματεπώνυμο',
  department: 'Τμήμα',
  volunteers: 'Εθελοντών',
  communication: 'Επικοινωνίας',
  programs: 'Προγραμμάτων',
  charity: 'Δωρεών',
  financial: 'Οικονομικών',
  finance: 'Οικονομικών',
  position: 'Θέση',
  lastContacts: 'Τελευταίες εγγραφές',
  latestVolunteerContacts: 'Τελευταίες εγγραφές Εθελοντών',
  person: 'Πρόσωπο',
  contactDetails: 'Eπικοινωνία',
  carrier: 'Φορέας',
  program: 'Πρόγραμμα',
  programEntry: 'Πρόγραμμα',
  registration: 'Καταχώρηση',
  tags: 'Ετικέτες',
  chooseTag: 'Επιλέξτε Ετικέτα',
  chosenTags: 'Επιλεγμένες Ετικέτες',
  noTagsSelected: 'Δεν έχουν επιλεγεί ετικέτες',
  shortDescription: 'Σύντομη Περιγραφή',
  newNoteModal: 'Νέα Σημείωση',
  Username: 'Όνομα Χρήστη',
  username: 'Όνομα Χρήστη',
  Password: 'Κωδικός',
  'Remember me': 'Να με θυμάσαι',
  'Forgot password?': 'Ξεχάσατε τον κωδικό σας;',
  Login: 'Είσοδος',
  Welcome: 'Καλώς ήρθατε',
  settings: 'Ρυθμίσεις',
  logout: 'Αποσύνδεση',
  Email: 'Διεύθυνση Email',
  Phone: 'Τηλέφωνο',
  phoneShort: 'Τηλ.',
  dateShort: 'Ημ.',
  quantity: 'Ποσότητα',
  dateOfRegistration: 'Ημ. Καταχώρησης',
  newWarehouseEntry: 'Νέα καταχώρηση Αποθήκης',
  dateOfRegistrationLong: 'Ημερομηνία Καταχώρησης',
  selectAnOption: 'Επιλέξτε ένα ή περισσότερα',
  createdDate: 'Ημερομηνία Δημιουργίας',
  selectOneOption: 'Επιλέξτε μία επιλογή',
  medicines: 'Φάρμακα',
  consumables: 'Αναλώσιμα',
  medicalEquipment: 'Iατρ. Εξοπλισμός',
  medical_equipment: 'Iατρ. Εξοπλισμός',
  'Medical Equipment': 'Iατρ. Εξοπλισμός',
  it_equipment: 'Eξοπλισμός ΙΤ',
  ITequipment: 'Εξοπλισμός ΙΤ',
  products: 'Προϊοντα',
  medicine: 'Φάρμακα',
  consumable: 'Αναλώσιμα',
  product: 'Προϊοντα',
  lastEdit: 'τελ. Επεξεργασία',
  isDonation: 'Δωρεά',
  mobilePhone: 'Κινητό',
  goods: 'Δωρεά σε Εξοπλισμό',
  goodsDonation: 'Δωρεά σε Εξοπλισμό',
  financialDonation: 'Οικονομική Προσφορά',
  subcategory: 'Υποκατηγορία',
  type: 'Τύπος',
  kind: 'Είδος',
  kindSubcategory: 'Είδος/Υποκατηγορία',
  ACTIVE: 'Ενεργή',
  active: 'Ενεργή/ός',
  notActive: 'Ανενεργή/ός',
  INACTIVE: 'Ανενεργή',
  status: 'Κατάσταση',
  observations: 'Παρατηρήσεις',
  donorName: 'Επωνυμία Δωρητή',
  Cancel: 'Ακύρωση',
  telephone: 'Τηλέφωνο',
  address: 'Διεύθυνση',
  postCode: 'Τ.Κ.',
  isActive: 'Κατάσταση',
  of: 'από',
  page: 'σελίδα',
  pages: 'σελίδες',
  thePageYouAreOn: 'Η σελίδα που βρίσκεστε',
  Admin: 'Διαχειριστής',
  search: 'Αναζήτηση',
  yesIamSure: 'Ναι, είμαι σίγουρος',
  noCancel: 'Όχι, ακύρωση',
  'Confirm password': 'Επιβεβαίωση κωδικού',
  passwordMustBe:
    'Ο κωδικός πρέπει να είναι τουλάχιστον 8 χαρακτήρες και να περιέχει τουλάχιστον ένα κεφαλαίο γράμμα και έναν αριθμό.',
  passwordsMustMatch: 'Οι κωδικοί δεν ταιριάζουν',
  requiredConfirmation: 'Απαιτείται επιβεβαίωση κωδικού',
  state: 'Κατάσταση',
  usage: 'Χρήση',
  Status: 'Κατάσταση',
  notes: 'Σημειώσεις',
  note: 'Σημείωση',
  createNote: 'Νέα Σημείωση',
  addNote: 'Προσθήκη Σημείωσης',
  description: 'Περιγραφή',
  date: 'Ημερομηνία',
  action: 'Ενέργεια',
  image: 'Εικόνα',
  name: 'Όνομα',
  loginToContinue: 'Συνδεθείτε για να συνεχίσετε',
  login: 'Σύνδεση',
  basicDetails: 'Βασικά Στοιχεία',
  editAgent: 'Επεξεργασία Υπαλλήλου',
  back: 'Πίσω',
  zipCode: 'Τ.Κ.',
  attachments: 'Συνημμένα',
  noEntriesFound: 'Δεν βρέθηκαν καταχωρήσεις',
  registrationDetails: 'Στοιχεία καταχώρησης',
  title: 'Τίτλος',
  firstName: 'Όνομα',
  lastName: 'Επώνυμο',
  saveChanges: 'Αποθήκευση Αλλαγών',
  filters: 'Φίλτρα',
  columns: 'Στήλες',
  actions: 'Ενέργειες',
  deleteEditUser: 'Ενέργειες',
  editDelete: 'Επεξεργασία/Διαγραφή',
  deleteTask: 'Διαγραφή Eργασία',
  numberOfOrderByProductType: 'Αριθμός παραγγελιών ανα είδος',
  newPassword: 'Νέος Κωδικός',
  confirmNewPassword: 'Επιβεβαίωση Νέου Κωδικού',
  comments: 'Σχόλια',
  save: 'Αποθήκευση',
  show: 'Εκπομπή',
  clear: 'Καθαρισμός',
  clearFilters: 'Καθαρισμός Φίλτρων',
  selectAll: 'Επιλογή Όλων',
  removeAll: 'Αφαίρεση Όλων',
  newsletters: 'Newsletters',
  newsletterCampaigns: 'Newsletter Καμπάνιες',
  newNewsletter: 'Νέο Newsletter',
  campaignName: 'Όνομα Καμπάνιας',
  emailSubject: 'Θέμα Email',
  newCampaign: 'Νέα Καμπάνια',
  createNewsletter: 'Δημιουργία Newsletter',
  campaignDetails: 'Στοιχεία Καμπάνιας',
  emailDesign: 'Σχεδιασμός Email',
  preview: 'Προεπισκόπηση',
  campaignPreview: 'Προεπισκόπηση Καμπάνιας',
  nextStep: 'Επόμενο Βήμα',
  send: 'Αποστολή',
  mailingLists: 'Λίστα Email',
  sentDates: 'Ημερομηνίες Αποστολής',
  specificContacts: 'Συγκεκριμένοι Επαφές',
  chooseList: 'Επιλέξτε Λίστα',
  chooseEmailList: 'Επιλέξτε λίστα Εmail',
  chooseSomeReceivers: 'Επιλέξτε μεμονωμένους παραλήπτες',
  createNewList: 'Δημιουργία νέας λίστας',
  or: 'ή',
  emailSample: 'Δείγμα Email',
  designYourEmail: 'Σχεδιάστε το Email σας',
  emailPreview: 'Προεπισκόπηση Email',
  passwordChangeWedrning:
    'Παρακαλώ εισάγετε νέο κωδικό μόνο αν επιθυμείτε να αλλάξετε τον κωδικό σας',
  'Campaign name required': 'Απαιτείται όνομα καμπάνιας',
  'Email subject required': 'Απαιτείται θέμα Email',
  'Mailing list required': 'Απαιτείται λίστα Email',
  'Mailing list name required.': 'Απαιτείται όνομα λίστας Email',
  emailList: 'Λίστα Email',
  emailGreeting: 'Χαιρετισμός Email',
  resetPassword: 'Επαναφορά Κωδικού',
  pleaseTypeEmailToResetPassword:
    'Παρακαλώ πληκτρολογείστε το email σας για να ανακτήσετε τον κωδικό σας',
  orderInfo: 'Πληροφορίες Παραγγελίας',
  emailListName: 'Όνομα Λίστας Email',
  insertList: 'Εισάγετε Λίστα',
  supportedMailingListFiles: '(Υποστηριζόμενος τύπος αρχείου: .xlsx)',
  number: 'Αριθμός',
  selectedClients: 'Επιλεγμένοι Πελάτες',
  close: 'Κλείσιμο',
  noClientsSelected: 'Δεν έχετε επιλέξει πελάτες',
  fileAttachments: 'Συνημμένα Αρχεία',
  DRAFT: 'Πρόχειρο',
  email: 'Email',
  SENT: 'Απεσταλμένο',
  uploadingFile: 'Ανέβασμα αρχείου...',
  fromF: 'από τις',
  addOptionalIcon: 'Προσθήκη εικόνας (προαιρετικό)',
  pressHereToAddIcon: 'Πατήστε εδώ για να προσθέσετε μια εικόνα στο newsletter',
  acceptedImage:
    'Επιτρεπτοί τύποι αρχείων: jpg, jpeg, png, Μέγιστο μέγεθος αρχείου: 5MB, Διαστάσεις: 1200 X 400 px (ή μεγαλύτερη)',
  sendEmail: 'Αποστολή Email',
  cancel: 'Ακύρωση',
  activateAccount: 'Ενεργοποίηση Λογαριασμού',
  changePassword: 'Αλλαγή Κωδικού',
  enterNewPasswordToChangePassword:
    'Πληκτρολογήστε ένα νέο κωδικό αν θέλετε να αλλάξετε τον κωδικό πρόσβασης',
  enterPasswordToActivateAccount:
    'Εισάγετε ένα κωδικό πρόσβασης για να ενεργοποιήσετε τον λογαριασμό σας.',
  password: 'Κωδικός Πρόσβασης',
  confirmPassword: 'Επιβεβαίωση Κωδικού Πρόσβασης',
  yourAccountIsActivated: 'Ο λογαριασμός σας ενεργοποιήθηκε.',
  enterNewsPassword: 'Εισάγετε νέο κωδικό πρόσβασης',
  clientsAgentMap: 'Χάρτης Πελατών',
  captchaRequired: 'Απαιτείται Captcha',
  delete: 'Διαγραφή',
  resend: 'Επαναποστολή',
  edit: 'Επεξεργασία',
  task: 'Εργασία',
  noAttachmentsFound: 'Δεν βρέθηκαν συνημμένα',
  files: 'Συνημμένα Αρχεία',
  export: 'Εξαγωγή',
  content: 'Περιεχόμενο',
  yes: 'Ναι',
  no: 'Όχι',
  submit: 'Υποβολή',
  attachingFiles: 'Επισύναψη Αρχείων',
  addFile: 'Προσθήκη αρχείου',
  dragAndDropSelectedFiles:
    'Σύρετε αρχεία για μεταφόρτωση εδώ ή επιλέξτε αρχεία',
  allLocations: 'όλες οι Τοποθεσίες',
  Athens: 'Αθήνα',
  Ukraine: 'Ουκρανία',
  athens: 'Αθήνα',
  ukraine: 'Ουκρανία',
  all: 'Όλες',
  noOptionFound: 'Δεν βρέθηκαν επιλογές',
  newContact: 'Νέα Eπαφή',
  addContact: 'Προσθήκη Επαφής',
  detailsDeptVolunteers: 'Στοιχεία Τμ. Εθελοντών',
  detailsDeptCommunication: 'Στοιχεία Τμ. Επικοινωνίας',
  detailsDeptFinancial: 'Στοιχεία Τμ. Οικονομικών',
  detailsDeptProgram: 'Στοιχεία Τμ. Προγραμμάτων',
  detailsDeptCharity: 'Το τμήμα Δωρεών δεν έχει επιπλέον πεδία',
  ratingComments: 'Αξιολόγηση/Σχόλια',
  lastEditLong: 'Τελευταία Επεξεργασία',
  contactInfo: 'Πληροφορίες Επαφής',
  titleDegree: 'Πρόσωπο | Προσφώνηση',
  dataRegistrationNameOfUser: 'Ημ. Εγγραφής | Ονοματεπώνυμο Χρήστη',
  homePhone: 'Σταθερό Τηλέφωνο',
  country: 'Χώρα έδρας',
  location: 'Τοποθεσία',
  workAddress: 'Διεύθυνση εργασίας',
  supplier: 'Προμηθευτής',
  import: 'Εισαγωγή',
  file: 'Συνημμένα',
  filesAtt: 'Αρχεία',
  updateContact: 'Επεξεργασία Επαφής',
  optionNotFound: 'Η επιλογή δεν βρέθηκε',
  amount: 'Ποσό (€)',
  invoiceNumber: 'Λογαριασμός κατάθεσης',
  bank: 'Τράπεζα',
  invoiceIssueDate: 'Ημερομηνία έκδοσης τιμολογίου',
  depositAccount: 'Λογαριασμός κατάθεσης',
  dateOfDeposit: 'Ημερομηνία κατάθεσης',
  lastContactsPrograms: 'Τελευταίες εγγραφές Προγραμμάτων',
  lastContactsCommunication: 'Τελευταίες εγγραφές Επικοινωνίας',
  lastContactsVolunteers: 'Τελευταίες εγγραφές Εθελοντών',
  lastContactsFinance: 'Τελευταίες εγγραφές Οικονομικών',
  lastContactsCharity: 'Τελευταίες εγγραφές Δωρεών',
  newTask: 'Νέα Εργασία',
  addNewTask: 'Προσθήκη Νέας Εργασίας',
  calender: 'Ημερολόγιο',
  calendars: 'Ημερολόγια',
  allDay: 'Ολόκληρη μέρα',
  assignee: 'Υπεύθυνος',
  January: 'Ιανουάριος',
  February: 'Φεβρουάριος',
  March: 'Μάρτιος',
  April: 'Απρίλιος',
  May: 'Μάιος',
  June: 'Ιούνιος',
  July: 'Ιούλιος',
  August: 'Αύγουστος',
  September: 'Σεπτέμβριος',
  October: 'Οκτώβριος',
  November: 'Νοέμβριος',
  December: 'Δεκέμβριος',
  deadlineShort: 'Καταληκτική Ημ.',
  deadlineLong: 'Καταληκτική Ημερομηνία',
  assosiatedWith: 'Συνδέσεις',
  completed: 'Ολοκληρωμένη',
  inProgress: 'Σε εξέλιξη',
  in_progress: 'Σε εξέλιξη',
  overdue: 'Εκπρόθεσμη',
  associated_with: 'σύνδεση με:',
  departments: 'Τμήμα',
  disposition_to: 'Διάθεση σε',
  dispositionTo: 'Διάθεση σε',
  importDate: 'Ημερομηνία εισαγωγής',
  importQuantity: 'Ποσότητα εισαγωγής',
  registrationDate: 'Ημερομηνία εξαγωγής',
  exportQuantity: 'Ποσότητα εξαγωγής',
  exportDate: 'Ημερομηνία καταγραφής',
  registrationQuantity: 'Ποσότητα καταγραφής',
  addressToPerson: 'Προσφώνηση',
  Greece: 'Ελλάδα',
  agent: 'Πράκτορας',
  administrator: 'Διαχειριστής',
  personal: 'Προσωπικό',
  meetingRoom: 'Αίθουσα συνεδριάσεων',
  allTasks: 'Ολες οι Εργασίες',
  scheduledTasks: 'Προγραμματισμένες',
  expiredTodayTasks: 'Λήγουν Σήμερα',
  expiredTodayTasksShort: 'Λήγ.Σήμερα',
  expiredTasks: 'Εκπρόθεσμες',
  addTime: 'Προσθήκη Ώρας',
  departmentRequired: 'Απαιτείται τμήμα',
  typeOfSponsor: 'Τύπος χορηγού',
  typeOfSupport: 'Τύπος υποστήριξης',
  sizeOfGrant: 'Μέγεθος υποτροφίας',
  institutionalPosition: 'Θέση θεσμού',
  implementationPeriod: 'Περίοδος υλοποίησης',
  currentStatus: 'Τρέχουσα κατάσταση',
  startOfCooperation: 'Έναρξη συνεργασίας',
  endOfCooperation: 'Λήξη συνεργασίας',
  budget: 'Προϋπολογισμός (€)',
  dateOfBirth: 'Ημερομηνία γέννησης',
  studies: 'Σπουδές',
  job: 'Επάγγελμα',
  city: 'Πόλη',
  volunteersAttributes: 'Ιδιότητα εθελόντριας /ντή',
  agencyName: 'Όνομα φορέα',
  typeOfFinancialSupport: 'Τύπος οικονομικής υποστήριξης',
  depositBank: 'Τράπεζα κατάθεσης',
  tv: 'Τηλεόραση',
  newspaper: 'Εφημερίδα',
  NEWSPAPER: 'Εφημερίδα',
  magazine: 'Περιοδικό',
  MAGAZINE: 'Περιοδικό',
  radio: 'Ραδιόφωνο',
  RADIO: 'Ραδιόφωνο',
  mediaCommunication: 'Μέσο',
  media: 'Συνημμένα Αρχεία',
  creationDate: 'Ημερομηνία',
  projectFunding: 'Επιχορήγηση προγράμματος',
  done: 'Ολοκληρώθηκε',
  results: 'Αποτελέσματα',
  result: 'Αποτέλεσμα',
  'ΚΥΡΙΟΣ.': 'Κύριος',
  'ΚΥΡΙΑ.': 'Κυρία',
  'ΔΡ.': 'Δρ.',
  supportedFileFormats: 'Υποστηριζόμενος τύπος Αρχείων : PDF, TXT, DOC, XLS',
  maxSize10MB: 'Μέγιστο μέγεθος Αρχείου : 10MB',
  'GREEK STATE': 'ΕΛΛΗΝΙΚΟ ΚΡΑΤΟΣ',
  'CORPORATE SOCIAL RESPONSIBILITY BODY': 'ΦΟΡΕΑΣ ΕΤΑΙΡΙΚΗΣ ΚΟΙΝΩΝΙΚΗΣ ΕΥΘΥΝΗΣ',
  PRIVATE: 'ΙΔΙΩΤΗΣ',
  private: 'ΙΔΙΩΤΗΣ',
  'EUROPEAN UNION': 'ΕΥΡΩΠΑΪΚΗ ΕΝΩΣΗ',
  'INTERNATIONAL ORGANISATION': 'ΔΙΕΘΝΗΣ ΟΡΓΑΝΙΣΜΟΣ',
  'EUROPEAN ECONOMIC AREA': 'ΕΥΡΩΠΑΪΚΟΣ ΟΙΚΟΝΟΜΙΚΟΣ ΧΩΡΟΣ',
  'ΚΟΙΝΩΦΕΛΕΣ ΙΔΡΥΜΑ': 'CHARITABLE FOUNDATION',
  'CHARITABLE FOUNDATION': 'ΚΟΙΝΩΦΕΛΕΣ ΙΔΡΥΜΑ',
  MUNICIPALITY: 'ΔΗΜΟΣ',
  REGION: 'ΠΕΡΙΦΕΡΕΙΑ',
  'OTHER COUNTRIES': 'ΑΛΛΟ ΚΡΑΤΟΣ',
  DONATION: 'ΔΩΡΕΑ',
  'PROGRAM GRANT': 'ΕΠΙΧΟΡΗΓΗΣΗ ΠΡΟΓΡΑΜΜΑΤΟΣ',
  GRANT: 'ΕΠΙΧΟΡΗΓΗΣΗ ΠΡΟΓΡΑΜΜΑΤΟΣ',
  'DONATION IN KIND': 'ΣΕ ΕΙΔΟΣ',
  'HUMAN RESOURCES': 'ΑΝΘΡΩΠΙΝΟΙ ΠΟΡΟΙ',
  'COMMUNICATION SERVICES': 'ΕΠΙΚΟΙΝΩΝΙΑ',
  'CO-FINANCING': 'ΣΥΓΧΡΗΜΑΤΟΔΟΤΗΣΗ',
  'co-financing': 'ΣΥΓΧΡΗΜΑΤΟΔΟΤΗΣΗ',
  'TOTAL FINANCING': 'ΟΛΙΚΗ ΧΡΗΜΑΤΟΔΟΤΗΣΗ',
  'PARTIAL FINANCING': 'ΜΕΡΙΚΗ ΧΡΗΜΑΤΟΔΟΤΗΣΗ',
  PROPOSAL: 'Δημιουργία Πρότασης',
  proposal: 'Δημιουργία Πρότασης',
  sponsorship: 'Χορηγός',
  SPONSORSHIP: 'Χορηγός',
  partnership: 'Εταίρος',
  PARTNERSHIP: 'Εταίρος',
  'managing authority': 'Διαχειριστική Αρχή',
  'MANAGING AUTHORITY': 'Διαχειριστική Αρχή',
  'external associate': 'Εξωτερικός Συνεργάτης',
  'EXTERNAL ASSOCIATE': 'Εξωτερικός Συνεργάτης',
  'supervisory authority': 'Εποπτική Αρχή',
  'SUPERVISORY AUTHORITY': 'Εποπτική Αρχή',
  subcontactor: 'Υπεργολάβος',
  SUBCONTRACTOR: 'Υπεργολάβος',
  SUBMITTED: 'Έχει υποβληθεί',
  APPROVED: 'Έγκριση',
  REJECTED: 'Απόρριψη',
  IMPLEMENTATION: 'Υλοποίηση',
  COMPLETED: 'Ολοκληρωμένο',
  submitted: 'Έχει υποβληθεί',
  approved: 'Έγκριση',
  rejected: 'Απόρριψη',
  implementation: 'Υλοποίηση',
  volunteerAttributes: 'Ιδιότητα εθελοντή',
  selectedContacts: 'Επιλεγμένες επαφές',
  noContactsSelected: 'Δεν έχουν επιλεγεί επαφές',
  startTime: 'Ωρα έναρξης',
  endTime: 'Ωρα λήξης',
  calendarType: 'Τύπος ημερολογίου',
  next: 'Επόμενος',
  previous: 'Προηγούμενος',
  expand: 'Eπεκτείνομαι',
  collapse: 'Kαταρρέω',
  list: 'Λίστα',
  calendar: 'Ημερολόγιο',
  editList: 'Επεξεργασία λίστας',
  createTag: 'Δημιουργία ετικέτας',
  addTag: 'Δημιουργία ετικέτας',
  deleteTag: 'Διαγραφή ετικέτας',
  removeTagFromChosenTags: 'Aφαίρεση ετικέτας από επιλογή',
  createdBy: 'Δημιουργήθηκε από',
  deleteFile: 'Διαγραφή αρχείου',
  myContacts: 'Οι Επαφές μου',
  taskCreator: 'Δημιουργός Εργασίας',
  myTasks: 'Οι εργασίες μου',

  // validation input fields messages
  usernameRequired: 'Απαιτείται όνομα χρήστη',
  positionRequired: 'Απαιτείται θέση',
  passwordRequired: 'Απαιτείται κωδικός πρόσβασης',
  emailRequired: 'Απαιτείται η διεύθυνση Email',
  campaignNameRequired: 'Απαιτείται όνομα καμπάνιας',
  emailSubjectRequired: 'Απαιτείται θέμα Email',
  mailingListRequired: 'Απαιτείται λίστα αλληλογραφίας',
  mailingListNameRequired: 'Απαιτείται όνομα λίστας αλληλογραφίας',
  titleRequired: 'Απαιτείται τίτλος',
  titleHasAMaximumLimitOf50Characters:
    'Ο τίτλος έχει ένα μέγιστο όριο των 50 χαρακτήρων',
  descriptionRequired: 'Απαιτείται περιγραφή',
  descriptionHasAMaximumLimitOf200Characters:
    'Η περιγραφή έχει ένα μέγιστο όριο των 200 χαρακτήρων',
  donorNameRequired: 'Απαιτείται όνομα δωρητή',
  typeOfDonationRequired: 'Απαιτείται τύπος δωρεάς',
  notesRequired: 'Απαιτούνται σημειώσεις',
  kindOfDonationRequired: 'Απαιτείται είδος δωρεάς',
  subcategoryOfDonationRequired: 'Απαιτείται υποκατηγορία δωρεάς',
  quantityRequired: 'Απαιτείται ποσότητα',
  typeOfSupportRequired: 'Απαιτείται τύπος υποστήριξης ',
  agencyNameRequired: 'Απαιτείται όνομα φορέα',
  dateOfInvoiceIssueRequired: ' Απαιτείται ημερομηνία έκδοσης τιμολογίου',
  bankAccountNumberRequired: 'Απαιτείται αριθμός τραπεζικού λογαριασμού ',
  dateOfDepositRequired: 'Απαιτείται ημερομηνία κατάθεσης ',
  amountRequired: 'Απαιτείται ποσό',
  invoiceNumberRequired: 'Απαιτείται αριθμός τιμολογίου',
  depositAccountRequired: 'Απαιτείται τραπεζικός λογαριασμός κατάθεσης',
  invoiceIssueDateRequired: 'Απαιτείται ημερομηνία έκδοσης τιμολογίου',
  depositDateRequired: 'Απαιτείται ημερομηνία κατάθεσης',
  bankNameRequired: 'Απαιτείται όνομα τράπεζας',
  registrationDateRequired: 'Απαιτείται ημερομηνία καταχώρισης',
  registrationQuantityRequired: 'Απαιτείται ποσότητα καταχώρισης',
  dispositionRequired: 'Απαιτείται αντιμετώπιση',
  usernameIsRequired: 'Απαιτείται όνομα χρήστη',
  userTypeRequired: 'Απαιτείται τύπος χρήστη',
  firstNameRequired: 'Απαιτείται όνομα',
  lastNameRequired: 'Απαιτείται επώνυμο',
  emailAddressRequired: 'Απαιτείται διεύθυνση Email',
  phoneNumberIsRequired: 'Απαιτείται τηλέφωνο',
  supplierIsRequired: 'Απαιτείται προμηθευτής',
  typeRequired: 'Απαιτείται τύπος',
  subcategoryRequired: 'Απαιτείται υποκατηγορία',
  locationRequired: 'Απαιτείται τοποθεσία',
  programRequired: 'Απαιτείται πρόγραμμα',
  aQuantityOfAtLeast1IsRequired: 'Απαιτείται μια ποσότητα τουλάχιστον 1',
  importQuantityOfAtLeast1IsRequired:
    'Απαιτείται ποσότητα εισαγωγής τουλάχιστον 1',
  exportQuantityOfAtLeast1IsRequired:
    'Απαιτείται ποσότητα εξαγωγής τουλάχιστον 1',
  registrationQuantityOfAtLeast1IsRequired:
    'Απαιτείται ποσότητα καταχώρησης τουλάχιστον 1',
  importDateRequired: 'Απαιτείται ημερομηνία εισαγωγής',
  exportDateRequired: 'Απαιτείται ημερομηνία εξαγωγής',
  importQuantityRequired: 'Απαιτείται ποσότητα εισαγωγής',
  exportQuantityRequired: 'Απαιτείται ποσότητα εξαγωγής',
  dateRequired: 'Απαιτείται ημερομηνία',
  messageRequired: 'Απαιτείται μήνυμα',
  recipientRequired: 'Απαιτείται παραλήπτης',
  fileSizeExceedsTheLimitOf10MB:
    'Το μέγεθος του αρχείου υπερβαίνει το όριο των 10MB',
  invalidFileFormat1: 'Μη έγκυρη μορφή αρχείου',
  invalidFileFormat2: 'Έγκυρες μορφές αρχείων',
  onlyUppercaseGreekOrLatinCharactersAreAllowed:
    'Συμπληρώνετε με Ελληνικούς / Λατινικούς πεζοκεφαλαίους χαρακτήρες',
  landlinePhoneNumberRequired: 'Απαιτείται σταθερό τηλέφωνο',
  mobilePhoneNumberRequired: 'Απαιτείται κινητό τηλέφωνο',
  invalidEmailFormat: 'Μη έγκυρη μορφή Email',
  invalidLandlinePhoneNumber: 'Μη έγκυρος αριθμός τηλεφώνου σταθερής γραμμής',
  invalidMobilePhoneNumber: 'Μη έγκυρος αριθμός κινητού τηλεφώνου',
  carrierIsRequired: 'Απαιτείται φορέας',
  programIsRequired: 'Απαιτείται πρόγραμμα',
  departmentIsRequired: 'Απαιτείται τμήμα',
  positionIsRequired: 'Απαιτείται θέση',
  countryIsRequired: 'Απαιτείται χώρα έδρας',
  workAddressIsRequired: 'Απαιτείται η διεύθυνση εργασίας',
  calenderTypeRequired: 'Απαιτείται τύπος ημερολογίου',
  websiteRequired: 'Απαιτείται Website',
  showNameRequired: 'Απαιτείται εκπομπή',
  mediaRequired: 'Απαιτείται μέσο',
  dateOfBirthRequired: 'Απαιτείται ημερομηνία γέννησης',
  studiesRequired: 'Απαιτούνται σπουδές',
  jobPositionRequired: 'Απαιτείται επάγγελμα',
  cityNameRequired: 'Απαιτείται όνομα πόλης',
  volunteerAttributesRequired: 'Απαιτείται ιδιότητα εθελοντή',
  institutionalPositionRequired: 'Απαιτείται Θεσμική Σχέση',
  typeOfSponsorRequired: 'Απαιτείται τύπος χορηγού',
  sizeOfGrantRequired: 'Απαιτείται μέγεθος χορηγίας',
  currentStatusRequired: 'Απαιτείται κατάσταση',
  implementationPeriodRequired: 'Απαιτείται περίοδος υλοποίησης',
  startOfCooperationRequired: 'Απαιτείται ημερομηνία έναρξης συνεργασίας',
  endOfCooperationRequired: 'Απαιτείται ημερομηνία λήξης συνεργασίας',
  budgetRequired: 'Απαιτείται προϋπολογισμός',
  dateOfStartingCooperationRequired: 'Απαιτείται έναρξη συνεργασίας',
  dateOfEndingCooperationRequired: 'Απαιτείται λήξη συνεργασίας',
  startTimeRequired: 'Απαιτείται ώρα',
  endTimeRequired: 'Απαιτείται ώρα',
  exportAll: 'Eξαγωγή (όλα)',
  'How is CRM used': 'Πώς χρησιμοποιείται το CRM',
  tagCannotExceed20Characters: 'Μη μεγαλύτερο από 20 χαρακτήρες',
  'Mobile phone number must be at most 15 characters':
    'Το κινητό πρέπει να έχει έως 15 χαρακτήρες',
  'Home phone number must be at most 15 characters':
    'Το σταθερό πρέπει να έχει έως 15 χαρακτήρες',
  'Phone number must be at most 15 characters':
    'Ο αριθμός πρέπει να έχει έως 15 χαρακτήρες',
  'Password must be at least 8 characters long':
    'Ο κωδικός πρέπει να έχει τουλάχιστον 8 χαρακτήρες',
  'Password must contain at least one lowercase letter':
    'Ο κωδικός πρέπει να περιέχει τουλάχιστον ένα μικρό γράμμα',
  'Password must contain at least one uppercase letter':
    'Ο κωδικός πρέπει να περιέχει τουλάχιστον ένα κεφαλαίο γράμμα',
  'Password must contain at least one special character':
    'Ο κωδικός πρέπει να περιέχει τουλάχιστον ένα ειδικό χαρακτήρα',
  'Password must contain at least one number':
    'Ο κωδικός πρέπει να περιέχει τουλάχιστον έναν αριθμό',
  notesHasAMaximumLimitOf200Characters:
    'Οι Παρατηρήσεις έχουν ένα μέγιστο όριο των 200 χαρακτήρων',
  'Total number of digits should not exceed 8, and decimal part can have at most 2 digits':
    'Συνολικός αριθμός ψηφίων μέχρι 8, δεκαδικό τμήμα μέχρι 2 ψηφία',
  amountMustBePositive: 'Το Ποσό πρέπει να είναι θετικό',
  budgetMustBePositive: 'Το Προϋπολογισμό πρέπει να είναι θετικό',
  // TOAST MESSAGES
  newsletterHasBeenSend: 'Το newsletter απεστάλη επιτυχώς',
  newsletterHasBeenSaved: 'Το newsletter αποθηκεύτηκε επιτυχώς',
  newsletterHasBeenCreated: 'Το newsletter δημιουργήθηκε επιτυχώς',
  newsletterHasBeenUpdated: 'Το newsletter ενημερώθηκε επιτυχώς',
  newsletterHasBeenDeleted: 'Το newsletter διαγράφηκε επιτυχώς',
  mailingListHasBeenCreated: 'Η λίστα Email δημιουργήθηκε επιτυχώς',
  mailingListHasBeenUploaded: 'Η λίστα Email ανέβηκε επιτυχώς',
  mailingListHasBeenUpdated: 'Η λίστα Email ενημερώθηκε επιτυχώς',
  mailingHasBeenDeleted: 'Η λίστα Email διαγράφηκε επιτυχώς',
  deleteNewsletterMessage:
    'Είστε σίγουροι ότι θέλετε να διαγράψετε το newsletter;',
  deleteMailingListMessage:
    'Είστε σίγουροι ότι θέλετε να διαγράψετε τη λίστα Email;',
  deleteComplaintMessage:
    'Είστε σίγουροι ότι θέλετε να διαγράψετε το παράπονο;',
  selectListToDelete: 'Επιλέξτε λίστα για διαγραφή',
  selectListToEdit: 'Επιλέξτε λίστα για επεξεργασία',
  emailHasBeenSent: 'Το Email απεστάλη επιτυχώς',
  emailImageSize5MB: 'Το μέγεθος της εικόνας δεν πρέπει να υπερβαίνει τα 5MB',
  emailWillBeSent:
    'Αν διατηρείτε ενεργό λογαριασμό στο σύστημά μας, σύντομα θα λάβετε ένα νέο email ανάκτησης του κωδικού σας',
  sessionExpired: 'Η σύνδεσή σας έχει λήξει. Παρακαλώ συνδεθείτε ξανά',
  accountActivated: 'Ο λογαριασμός σας ενεργοποιήθηκε επιτυχώς',
  passwordHasBeenReset: 'Ο κωδικός σας έχει επαναφερθεί επιτυχώς',
  'detail: ActivatePasswordRequest with this code does not exist':
    'Ο κωδικός ενεργοποίησης δεν υπάρχει',
  '0: If you maintain an active account in our system, you will soon receive a new password recovery email.':
    'Αν διατηρείτε ενεργό λογαριασμό στο σύστημά μας, σύντομα θα λάβετε ένα νέο email ανάκτησης του κωδικού σας',
  '0: No account with this email registered!':
    'Δεν έχει καταχωρηθεί λογαριασμός με αυτό το email!',
  'detail: No active account found with the given credentials':
    'Δεν βρέθηκε ενεργός λογαριασμός με αυτά τα στοιχεία!',
  "0: {'username': ['User with this Username already exists.'], 'email': ['User with this Email already exists.']}":
    'Υπάρχει ήδη λογαριασμός με αυτό το όνομα χρήστη ή email!',
  "0: {'email': ['User with this Email already exists.']}":
    'Υπάρχει ήδη λογαριασμός με αυτό το email!',
  "0: {'username': ['User with this Username already exists.']}":
    'Υπάρχει ήδη λογαριασμός με αυτό το όνομα χρήστη!',
  'name: mailing list with this name already exists.':
    'Υπάρχει ήδη λίστα email με αυτό το όνομα',
  'name: campaign with this name already exists.':
    'Υπάρχει ήδη καμπάνια με αυτό το όνομα',
  'error_messages: planned_date is required':
    'Η ημερομηνία προγραμματισμού είναι υποχρεωτική',
  'detail: Not found.': 'Δεν βρέθηκε',
  '0: Meeting room is already booked for these times!':
    'Η αίθουσα συναντήσεων έχει ήδη κλειστεί για αυτές τις ώρες! Παρακαλώ επιλέξτε μια άλλη ώρα',
  'Department ID must be provided!': 'Πρέπει να παρέχεται ο κωδικός τμήματος!',
  'Password cannot be blank!': 'Ο κωδικός πρόσβασης δεν μπορεί να είναι κενός',
  "Contact ID's need to be provided!":
    'Πρέπει να παρέχονται τα αναγνωριστικά των Επαφών!',
  'Invalid department chosen!': 'Επιλέχθηκε μη έγκυρο τμήμα!',
  'Invalid Media type!': 'Μη έγκυρος τύπος πολυμέσων!',
  '0: Department ID must be provided!':
    'Πρέπει να παρέχεται ο κωδικός τμήματος!',
  '0: Password cannot be blank!':
    'Ο κωδικός πρόσβασης δεν μπορεί να είναι κενός',
  "0: Contact ID's need to be provided!":
    'Πρέπει να παρέχονται τα αναγνωριστικά των Επαφών!',
  '0: Invalid department chosen!': 'Επιλέχθηκε μη έγκυρο τμήμα!',
  '0: Invalid Media type!': 'Μη έγκυρος τύπος πολυμέσων!',
  'email: contact with this email already exists.':
    'Υπάρχει ήδη επαφή με αυτό το email!',
  "detail: User with email doesn't exist":
    'Ο χρήστης με αυτό το ηλεκτρονικό ταχυδρομείο δεν υπάρχει!',
  fileHasBeenDeleted: 'Το αρχείο έχει διαγραφεί',
  noteHasBeenUpdated: 'Η σημείωση ενημερώθηκε',
  noteHasBeenCreated: 'Η σημείωση δημιουργήθηκε',
  noteSuccessfullyDeleted: 'Η σημείωση διαγράφηκε με επιτυχία',
  taskHasBeenUpdated: 'Η εργασία ενημερώθηκε',
  taskHasBeenCreated: 'Η εργασία δημιουργήθηκε',
  userHasBeenDeleted: 'Ο χρήστης διαγράφηκε',
  userHasBeenUpdated: ' Ο χρήστης ενημερώθηκε',
  userHasBeenCreated: 'Ο χρήστης δημιουργήθηκε',
  contactHasBeenCreated: 'Η επαφή δημιουργήθηκε',
  contactHasBeenUpdated: 'Η επαφή ενημερώθηκε',
  donationHasBeenCreated: 'Η δωρεά δημιουργήθηκε',
  donationHasBeenUpdated: 'Η δωρεά ενημερώθηκε',
  yourDetailsHaveBeenUpdated: 'Τα στοιχεία σας ενημερώθηκαν',
  warehouseHasBeenCreated: 'Το αποθήκευση δημιουργήθηκε',
  contactHasBeenDeleted: 'Διαγράφηκε επαφή',
  taskSuccessfullyDeleted: 'Η εργασία διαγράφηκε με επιτυχία',
  warehouseHasBeenUpdated: 'Η αποθήκη ενημερώθηκε',
  tagHasBeenCreated: 'Η ετικέτα δημιουργήθηκε',
  tagNameRequired: 'Απαιτείται όνομα ετικέτας',
  tagSuccessfullyDeleted: 'Η ετικέτα διαγράφηκε',
  'Something went wrong!': 'Κάτι πήγε στραβά!',
  "0: Information not provided: {'username': ['User with this Username already exists.']}":
    'Ο χρήστης με αυτό το όνομα χρήστη υπάρχει ήδη!',
  "0: Information not provided: {'email': ['User with this Email already exists.']}":
    'Ο χρήστης με αυτό το email υπάρχει ήδη!',
  "0: Information not provided: {'username': ['User with this Username already exists.'], 'email': ['User with this Email already exists.']}":
    'Υπάρχει ήδη λογαριασμός με αυτό το όνομα χρήστη ή email!',
  '0: Captcha Verification Failed': 'Αποτυχία Επαλήθευσης Captcha',
  'detail: ForgotPasswordRequest with this code does not exist':
    'Αυτός ο κωδικός δεν υπάρχει πια',
  '0: Cannot save a tag with the same name!':
    'Η Ετικέτα που προσπαθείτε να δημιουργήσετε υπάρχει ήδη!',
  userGuide: 'Οδηγός χρήστη'
}

export default translationEl
