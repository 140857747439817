function NotesSkeleton() {
  return (
    <div className="w-full h-[17rem] px-5 pt-8 pb-10 bg-gray-100 rounded-regular">
      <div className="flex flex-col items-stretch justify-between px-4 mt-5">
        <div className="bg-gray-200 h-14 mb-7 flex-grow-1 animate-pulse rounded-small" />
        <div className="bg-gray-200 h-14 mb-7 flex-grow-1 animate-pulse rounded-small" />
        <div className="bg-gray-200 h-14 mb-7 flex-grow-1 animate-pulse rounded-small" />
      </div>
    </div>
  );
}

export default NotesSkeleton
