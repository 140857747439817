import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Title from 'src/components/UI/Title'
import NotesPanel from 'src/components/UI/NotesPanel'
import LatestContactsRegistrationPanel from 'src/components/UI/LatestContactsRegistationPanel'
import { startOfToday } from 'date-fns'
import DatePicker from 'src/components/Calender/DatePicker'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import TaskModal from 'src/components/Modals/TaskModal'
import RenderIf from 'src/components/UI/RenderIf'
import DashboardTableSkeleton from 'src/components/Skeletons/DashboardTableSkeleton'
import TaskListTable from '../Common/pages/Tasks/components/TaskListTable'

function AdminDashboard() {
  const { t } = useTranslation()
  const { tasks, isTasksLoading } = useDataProvider()

  useEffect(() => {
    document.title = `MDM CRM - ${t('homePage')}`
  }, [t, tasks])

  return (
    <section>
      <Title title={t('homePage')} add="" />
      <div className="grid grid-cols-1 place-items-center lg:place-items-start lg:grid-cols-[375px_minmax(500px,1fr)] mb-7 gap-9">
        <TaskModal />
        <DatePicker
          tasks={tasks}
        />
        <RenderIf isTrue={isTasksLoading}>
          <DashboardTableSkeleton />
        </RenderIf>
        <RenderIf isTrue={!isTasksLoading}>
          <TaskListTable tasks={tasks} isDashboardTable />
        </RenderIf>
      </div>
      <div className="flex flex-col gap-5 lg:flex-row lg:gap-9">
        <LatestContactsRegistrationPanel />
        <NotesPanel />
      </div>
    </section>
  )
}

export default AdminDashboard
