import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import Select from 'react-select';
import InputErrorMessage from '../../UI/InputErrorMsg';
import RenderIf from '../../UI/RenderIf'
import customStyles from './customStyleForFormMultiSelect';

type Props = {
  name: string;
  errors: boolean;
  errorName: string | undefined;
  inputId?: string | undefined
  disabled?: boolean
  users?: {
    value: string
    label:string
  }[];
  [key: string]: any
};

function FormMultiSelect({
  inputId,
  name,
  errors,
  errorName,
  disabled,
  users,
  ...restProps
}:Props) {
  const { t } = useTranslation();

  return (
    <div className="relative w-full my-2 text-base md:text-lg">
      <label htmlFor={inputId} className="flex flex-col text-sm md:text-regular">
        <span className="mx-0 mb-2 md:mx-4 text-paragraph">{t(name)}</span>
        <Field name={inputId}>
          {() => (
            <Select
              closeMenuOnSelect={false}
              noOptionsMessage={() => t('optionNotFound')}
              styles={customStyles(errors)}
              isMulti
              placeholder={t('selectAnOption')}
              className="font-sans"
              isDisabled={disabled}
              aria-label={t(name)}
              {...restProps}
            />
          )}
        </Field>
      </label>
      <RenderIf isTrue={!!errors}>
        <div className="absolute top-0 w-full md:mx-4">
          <InputErrorMessage text={t(errorName!)} />
        </div>
      </RenderIf>
    </div>
  )
}

export default FormMultiSelect
