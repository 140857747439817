import {
  useRef, useState, useEffect, useCallback
} from 'react'

const useToggleMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const checkClickOutside = useCallback((e: MouseEvent) => {
    if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
      setIsOpen(false)
    }
  }, [isOpen])

  useEffect(() => {
    document.addEventListener('mousedown', checkClickOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkClickOutside)
    }
  }, [isOpen, checkClickOutside])

  return {
    isOpen,
    setIsOpen,
    ref
  }
}

export default useToggleMenu
