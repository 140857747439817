/* eslint-disable max-len */
import * as yup from 'yup'
import 'yup-phone-lite'

const validationSchema = {
  login: yup.object({
    username: yup.string().trim().required('usernameRequired'),
    password: yup.string().trim().required('passwordRequired')
  }),
  forgotPasswordForm: yup.object({
    email: yup.string().trim().email('invalidEmail').required('emailRequired')
  }),
  activatePassword: yup.object({
    password: yup
      .string()
      .trim()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]+/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]+/, 'Password must contain at least one uppercase letter')
      .matches(
        /[@$!%*#?&]+/,
        'Password must contain at least one special character'
      )
      .matches(/\d+/, 'Password must contain at least one number')
      .required('passwordRequired'),
    confirmPassword: yup
      .string()
      .trim()
      .oneOf([yup.ref('password'), null], 'passwordsMustMatch')
      .required('requiredConfirmation')
  }),
  campaignDetailsForm: (specificClients: ClientTypes[]) =>
    yup.object({
      campaignName: yup.string().trim().required('campaignNameRequired'),
      emailSubject: yup.string().trim().required('emailSubjectRequired'),
      mailingList:
        specificClients.length > 0
          ? yup.string().trim().optional()
          : yup.string().trim().required('mailingListRequired')
    }),
  mailingListCreationForm: yup.object({
    mailingListName: yup.string().trim().required('mailingListNameRequired')
  }),
  noteCreationForm: yup.object({
    title: yup.string().trim().trim().required('titleRequired'),
    description: yup.string().trim().required('descriptionRequired')
  }),
  goodsDonationCreationForm: yup.object({
    donor_name: yup.string().trim().required('donorNameRequired'),
    type: yup.string().trim().required('typeOfDonationRequired'),
    notes: yup.string().trim().nullable(),
    kind: yup.string().required('kindOfDonationRequired'),
    subcategory: yup.string().trim().required('subcategoryOfDonationRequired'),
    quantity: yup
      .number()
      .min(1, 'aQuantityOfAtLeast1IsRequired')
      .required('quantityRequired')
      .nullable()
  }),
  financialDonationCreationForm: yup.object({
    donor_name: yup.string().trim().required('donorNameRequired'),
    type: yup.string().required('typeOfDonationRequired'),
    amount: yup
      .number()
      .positive('amountMustBePositive')
      .test(
        'valid-amount',
        'Total number of digits should not exceed 8, and decimal part can have at most 2 digits',
        (value) => {
          if (value === undefined || value === null) {
            return true
          }
          const valueStr = value.toString()
          const parts = valueStr.split('.')
          if (
            parts[0].length + (parts[1] ? parts[1].length : 0) <= 8 &&
            (parts.length !== 2 || parts[1].length <= 2)
          ) {
            return true
          }
          return false
        }
      )
      .nullable()
      .required('amountRequired'),
    invoice_number: yup.string().trim().required('invoiceNumberRequired'),
    deposit_account: yup.string().trim().required('depositAccountRequired'),
    invoice_issue_date: yup
      .string()
      .trim()
      .required('invoiceIssueDateRequired'),
    date_of_deposit: yup.string().trim().required('depositDateRequired'),
    bank: yup.string().trim().required('bankNameRequired'),
    notes: yup.string().trim().nullable()
  }),
  warehouseCreationForm: yup.object({
    supplier: yup.string().trim().required('supplierIsRequired'),
    kind: yup.string().required('typeRequired'),
    subcategory: yup.string().trim().required('subcategoryRequired'),
    location: yup.string().required('locationRequired'),
    program_entry: yup.string().required('programRequired'),
    import_date: yup.string().trim().required('importDateRequired'),
    export_date: yup.string().trim().required('exportDateRequired'),
    import_quantity: yup
      .number()
      .min(1, 'importQuantityOfAtLeast1IsRequired')
      .required('importQuantityRequired')
      .nullable(),
    export_quantity: yup
      .number()
      .min(1, 'exportQuantityOfAtLeast1IsRequired')
      .required('exportQuantityRequired')
      .nullable(),
    registration_date: yup.string().trim().required('registrationDateRequired'),
    registration_quantity: yup
      .number()
      .min(1, 'registrationQuantityOfAtLeast1IsRequired')
      .required('registrationQuantityRequired')
      .nullable(),
    disposition_to: yup.string().trim().required('dispositionRequired'),
    media: yup.array().notRequired(),
    is_donation: yup.boolean().notRequired()
  }),
  userCreationForm: yup.object({
    username: yup.string().trim().required('usernameIsRequired'),
    user_type: yup.string().trim().required('userTypeRequired'),
    first_name: yup.string().trim().required('firstNameRequired'),
    last_name: yup.string().trim().required('lastNameRequired'),
    email: yup
      .string()
      .trim()
      .email('invalidEmailFormat')
      .required('emailAddressRequired'),
    telephone: yup
      .string()
      .trim()
      .max(15, 'Phone number must be at most 15 characters')
      .required('phoneNumberIsRequired'),
    departments: yup.array().when('user_type', {
      is: 'AGENT',
      then: yup.array().min(1, 'departmentRequired'),
      otherwise: yup.array().notRequired()
    }),
    position: yup.string().when('user_type', {
      is: 'AGENT',
      then: yup.string().required('positionRequired'),
      otherwise: yup.string().notRequired()
    })
  }),
  updateUserDetailsForm: (password: string) =>
    yup.object({
      first_name: yup.string().trim().required('firstNameRequired'),
      last_name: yup.string().trim().required('lastNameRequired'),
      email: yup
        .string()
        .trim()
        .email('invalidEmailFormat')
        .required('emailRequired'),
      password: yup
        .string()
        .trim()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /[a-z]+/,
          'Password must contain at least one lowercase letter'
        )
        .matches(
          /[A-Z]+/,
          'Password must contain at least one uppercase letter'
        )
        .matches(
          /[@$!%*#?&]+/,
          'Password must contain at least one special character'
        )
        .matches(/\d+/, 'Password must contain at least one number'),
      confirmPassword:
        password.trim().length === 0
          ? yup.string().optional()
          : yup
              .string()
              .oneOf([yup.ref('password'), null], 'passwordsMustMatch')
    }),
  createContactMainForm: yup.object({
    title: yup.string().required('titleRequired').nullable(),
    degree: yup.string().trim().notRequired(),
    first_name: yup.string().trim().required('firstNameRequired'),
    last_name: yup.string().trim().required('lastNameRequired'),
    email: yup.string().email('invalidEmailFormat').required('emailRequired'),
    home_phone: yup
      .string()
      .trim()
      .max(15, 'Home phone number must be at most 15 characters')
      .nullable(),
    mobile_phone: yup
      .string()
      .trim()
      .max(15, 'Mobile phone number must be at most 15 characters')
      .nullable(),
    carrier: yup.string().trim().required('carrierIsRequired'),
    program: yup.string().trim().notRequired().nullable(),
    department: yup.string().trim().required('departmentIsRequired'),
    position: yup.string().trim().required('positionIsRequired'),
    country: yup.string().trim().required('countryIsRequired'),
    work_address: yup.string().trim().required('workAddressIsRequired'),
    comments: yup.string().trim().nullable(),
    tags: yup.array().notRequired().nullable()
  }),
  createFinancialContactFrom: yup.object({
    type_of_support: yup.string().trim().nullable(),
    agency_name: yup.string().trim().nullable(),
    amount: yup
      .number()
      .positive('amountMustBePositive')
      .test(
        'valid-amount',
        'Total number of digits should not exceed 8, and decimal part can have at most 2 digits',
        (value) => {
          if (value === undefined || value === null) {
            return true
          }
          const valueStr = value.toString()
          const parts = valueStr.split('.')
          if (
            parts &&
            parts[0].length + (parts[1] ? parts[1].length : 0) <= 8 &&
            (parts.length !== 2 || parts[1].length <= 2)
          ) {
            return true
          }
          return false
        }
      )
      .nullable(),
    invoice_issue_date: yup.string().trim().nullable(),
    deposit_bank: yup.string().trim().nullable(),
    deposit_account: yup.string().trim().nullable(),
    date_of_deposit: yup.string().trim().nullable(),
    notes: yup.string().trim().nullable()
  }),
  createCommunicationContactFrom: yup.object({
    website: yup.string().nullable(),
    show: yup.string().trim().nullable(),
    media: yup.string().trim().nullable()
  }),
  createProgramContactFrom: yup.object({
    institutional_position: yup.string().nullable(),
    type_of_sponsor: yup.string().trim().nullable(),
    type_of_support: yup.string().trim().nullable(),
    size_of_grant: yup.string().trim().nullable(),
    current_status: yup.string().trim().nullable(),
    implementation_period: yup.string().trim().nullable(),
    start_of_cooperation: yup.string().trim().nullable(),
    end_of_cooperation: yup.string().trim().nullable(),
    budget: yup.number().nullable()
  }),
  createVolunteerContactFrom: yup.object({
    date_of_birth: yup.string().trim().nullable(),
    studies: yup.string().trim().nullable(),
    job: yup.string().trim().nullable(),
    city: yup.string().trim().nullable(),
    volunteer_attributes: yup.string().trim().nullable(),
    notes: yup.string().trim().nullable(),
    is_active: yup.string().trim().notRequired()
  }),
  createTaskForm: yup.object({
    title: yup.string().required('titleRequired'),
    date: yup.string().required('dateRequired'),
    start_time: yup.string().trim().required('startTimeRequired'),
    end_time: yup.string().trim().required('endTimeRequired'),
    calendar_type: yup.string().trim().required('calenderTypeRequired'),
    department: yup.string().when('calendar_type', {
      is: 'DEPARTMENT',
      then: yup.string().required('departmentIsRequired'),
      otherwise: yup.string().notRequired()
    }),
    assignee: yup.array().notRequired(),
    notes: yup.string().trim().nullable()
  }),
  createTag: yup.object({})
}

export default validationSchema
