import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import PageTitle from 'src/components/UI/PageTitle'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import PageSection from 'src/components/UI/PageSection'
import { startOfToday, format } from 'date-fns'
import { DepartmentOption } from 'src/interfaces/department'
import ContactCreationForm from '../components/ContactCreationForm'

function CreateContact() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [selectedContactType, setSelectedContactType] = useState<
    DepartmentOption | ''
  >('')

  const { mutate: createContactMutation, isLoading } = useMutateData({
    key: 'contacts',
    mutationFn: (values: any) => apiService.createContact(values),
    successMessage: 'contactHasBeenCreated'
  })

  const onSubmit = (values: any) => {
    createContactMutation(values, {
      onSuccess: () => navigate(-1)
    })
  }

  const handleSelectClick = (event: React.MouseEvent<HTMLSelectElement>) => {
    const selectOption = event.currentTarget.value as DepartmentOption
    setSelectedContactType(selectOption)
  }

  useEffect(() => {
    document.title = `MDM CRM - ${t('newContact')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle
        text="newContact"
        subtext="dateOfRegistrationLong"
        date={format(startOfToday(), 'dd/MM/yyyy')}
      />
      <ContactCreationForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        selectedContactType={selectedContactType}
        handleSelectClick={handleSelectClick}
        setSelectedContactType={setSelectedContactType}
      />
    </PageSection>
  )
}
export default CreateContact
