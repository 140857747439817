import { useTranslation } from 'react-i18next'
import { AiOutlineSave } from 'react-icons/ai'
import Spinner from './Spinner'

interface Props {
  type : 'button' | 'submit'
  onClickHandler?: () => void
  isLoading?: boolean
}

function DraftButton({
  type, onClickHandler, isLoading = false
}:Props) {
  const { t } = useTranslation()
  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      className="w-full flex items-center justify-center font-sans gap-3 text-lg leading-6 rounded-[5px] px-[30px] py-[18px] font-medium transition-all duration-200 bg-[#EBF5F9] text-mdm-blue hover:text-mdm-navy-blue"
      onClick={onClickHandler}
    >
      {isLoading ? (
        <Spinner spinnerWidth="w-[8rem]" />
      ) : (
        <>
          <AiOutlineSave size={20} />
          {t('save')}
        </>
      )}
    </button>
  )
}

export default DraftButton
