import ReactDOM from 'react-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import { IoMdClose } from 'react-icons/io'
import FormButtons from 'src/components/Form/FormButtons'
import { useFormik, FormikProvider } from 'formik'
import validationSchema from 'src/utils/validationSchema'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import { HiOutlinePlus } from 'react-icons/hi'
import moment from 'moment/moment'
import userMethods from 'src/utils/userMethods'
import FormInput from '../Form/FormInput'
import FormSelect from '../Form/FormSelect'
import FormTextArea from '../Form/FormTextArea'
import FormCheckbox from '../Form/FormCheckbox'
import FormMultiSelect from '../Form/FormMultiSelect/FormMultiSelect'
import { UserTypes } from '../../interfaces/user'
import RenderIf from '../UI/RenderIf'
import FormTimeInput from '../Form/FormTimeInput';

interface Assignee {
  admin: { id: number } | null;
  agent: { id: number } | null;
}

function TaskModal() {
  const { t } = useTranslation()
  const { selectedTask, showTaskModal, closeTaskModalHandler } = useModalContext()
  const authUser = userMethods.getUser()
  const { users, departments } = useDataProvider()
  const [showTimeFields, setShowTimeFields] = useState(false)
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(false)
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false)
  const [isAllDay, setIsAllDay] = useState(false)

  const handleAddTimeClick = () => {
    setShowTimeFields(true);
  }
  const departmentsOptions = departments?.map(({ id, title }) => ({
    value: id,
    label: title.toLowerCase()
  }));

  const calenders = [
    { value: 'PERSONAL', label: t('personal') },
    { value: 'DEPARTMENT', label: t('department') },
    { value: 'MEETING_ROOM', label: t('meetingRoom') }
  ];

  const overlays = document.getElementById('overlays') as HTMLElement

  const backdropClasses = showTaskModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = showTaskModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'

  // disables edit button and form edit for completed task
  useEffect(() => {
    if (selectedTask?.status === 'COMPLETED') {
      if (authUser?.user.user_type === 'ADMINISTRATOR') {
        setIsFormDisabled(true);
        setIsEditButtonDisabled(true);
      } else if (authUser?.user.user_type === 'AGENT') {
        const isTaskAssigned = selectedTask.assignee.some(
          (assignee: Assignee) => assignee.agent?.id === authUser?.user.agent_id
        );
        setIsFormDisabled(true);
        if (isTaskAssigned) {
          setIsEditButtonDisabled(true);
        } else {
          setIsEditButtonDisabled(true)
          setIsDeleteButtonDisabled(true)
        }
      }
    } else if (authUser?.user.user_type === 'AGENT' && selectedTask && selectedTask.created_by.agent_id !== authUser?.user.agent_id) {
      setIsFormDisabled(true)
      setIsEditButtonDisabled(true);
      setIsDeleteButtonDisabled(true);
    } else {
      setIsFormDisabled(false);
      setIsEditButtonDisabled(false);
      setIsDeleteButtonDisabled(false);
    }
  }, [selectedTask, authUser]);

  const modifyUsersForMultiplySelectInput = users?.map((user) => ({
    label: `${user.first_name} ${user.last_name}`,
    value: user.id ? user.id : null
  }));

  // updates and create task
  const { mutate, isLoading } = useMutateData({
    key: 'tasks',
    mutationFn: selectedTask ? apiService.updateTask : apiService.createTask,
    successMessage: selectedTask
      ? 'taskHasBeenUpdated'
      : 'taskHasBeenCreated'
  })

  const initialValues = {
    id: '',
    title: '',
    date: '',
    start_time: '',
    end_time: '',
    calendar_type: '',
    notes: '',
    assignee: [],
    department:
    authUser?.user.user_type === 'AGENT'
    && Array.isArray(authUser?.user.departments)
    && authUser?.user.departments.length === 1
      ? authUser?.user.departments[0]?.id.toString()
      : '',
    status: '',
    auto_complete_status: true,
    created_by: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.createTaskForm,
    onSubmit: (values: any) => {
      // if (!selectedTask) {
      //   const authUserId = authUser?.user.id;
      //   values.assignee.push({
      //     value: authUserId
      //   });
      // }
      mutate(values, {
        onSuccess: () => {
          closeTaskModalHandler()
          formik.resetForm()
        }
      })
    }
  })

  const closeTaskModalClick = () => {
    setShowTimeFields(false)
    formik.resetForm()
    closeTaskModalHandler()
  }

  const handleMultiSelectChange = (value: any) => {
    const uniqueValues = Array.from(new Set(value));
    formik.setFieldValue('assignee', uniqueValues);
  }

  // all day checkbox
  const handleAllDayCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setIsAllDay(newValue)
    setShowTimeFields(newValue)
    formik.setFieldValue('is_all_day', newValue)
    if (newValue) {
      formik.setFieldValue('start_time', '00:00:00')
      formik.setFieldValue('end_time', '23:59:59')
    } else {
      // Handle unchecking the checkbox
      formik.setFieldValue('start_time', '')
      formik.setFieldValue('end_time', '')
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const hasStartTimeError = !!(formik.errors.start_time && formik.touched.start_time);
    const hasEndTimeError = !!(formik.touched.start_time && formik.errors.end_time);
    if (hasStartTimeError && hasEndTimeError) {
      setShowTimeFields(true);
    } else if (selectedTask?.start_time && selectedTask?.end_time) {
      setShowTimeFields(true);
    } else if (!isAllDay && selectedTask) {
      setShowTimeFields(true);
    }
  }, [formik.errors, formik.touched, selectedTask?.start_time, selectedTask?.end_time, isAllDay]);

  const { setValues, setFieldValue } = formik;
  useEffect(() => {
    if (selectedTask) {
      setValues({
        id: selectedTask.id,
        title: selectedTask.title,
        status: selectedTask.status,
        date: moment(selectedTask.start_time).format('YYYY-MM-DD'),
        start_time: moment(selectedTask.start_time).utc(false).format('HH:mm:ss'),
        end_time: moment(selectedTask.end_time).utc(false).format('HH:mm:ss'),
        calendar_type: selectedTask.calendar_type,
        notes: selectedTask.notes,
        created_by: `${selectedTask.created_by.first_name} ${selectedTask.created_by.last_name}`,
        assignee: selectedTask.assignee?.map((user: { agent: UserTypes; admin: UserTypes }) => {
          if (user.agent) {
            return {
              label: `${user.agent?.user.first_name} ${user.agent?.user.last_name}`,
              value: user.agent?.user.id
            };
          } if (user.admin) {
            return {
              label: `${user.admin?.user.first_name} ${user.admin?.user.last_name}`,
              value: user.admin?.user.id
            };
          }
          return {};
        }) || [],
        // eslint-disable-next-line no-nested-ternary
        department: Array.isArray(selectedTask.department)
          ? selectedTask.department.map((dept: { id:number }) => (dept.id))?.toString()
          : selectedTask.department ? selectedTask.department.id?.toString() : '',
        auto_complete_status: selectedTask.auto_complete_status
      })
      if (moment(selectedTask.start_time).utc(false).format('HH:mm:ss') === '00:00:00'
       && moment(selectedTask.end_time).utc(false).format('HH:mm:ss') === '23:59:59') {
        setFieldValue('is_all_day', true)
        setIsAllDay(true)
      }
    }
    return () => {
      setFieldValue('is_all_day', false)
      setIsAllDay(false)
    }
  }, [setFieldValue, setValues, selectedTask]);

  // delete task
  const { mutate: deleteTaskMutate } = useMutateData({
    key: 'tasks',
    mutationFn: apiService.deleteTask,
    successMessage: 'taskSuccessfullyDeleted'
  });
  const handleDeleteTask = () => {
    deleteTaskMutate({
      id: selectedTask?.id ?? 0
    }, {
      onSuccess: () => {
        closeTaskModalHandler();
        formik.resetForm();
      }
    });
  };

  return ReactDOM.createPortal(
    <>
      { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */ }
      <div className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={closeTaskModalClick} />
      {/* <FocusLock returnFocus autoFocus={false}> */}
      <div className={`absolute bg-white top-8 left-1/2 -translate-x-1/2 z-50  max-w-[90%] md:max-w-[648px] w-full origin-center transition-all duration-300 ${modalClasses} rounded-regular`}>
        <FormikProvider value={formik}>
          <div className="flex items-center justify-between px-3 py-6 text-white rounded-t-2xl md:px-9 bg-mdm-blue">
            <div className="flex flex-col gap-1">
              <h1 className="font-bold text-[1.75rem] md:text-2xl">{t(selectedTask ? 'task' : 'newTask')}</h1>
            </div>
            <IoMdClose size={35} onClick={closeTaskModalClick} className="cursor-pointer" />
          </div>
          <form className="flex flex-col pt-2 pb-3 px-3 md:px-[29px] md:pb-9" onSubmit={formik.handleSubmit}>
            <div className="relative">
              <FormInput
                inputId="title"
                name="title"
                width="w-full"
                getFieldProps={formik.getFieldProps('title')}
                errors={!!(formik.touched.title && formik.errors.title)}
                errorName={formik.errors.title as string}
                disabled={isFormDisabled}
              />
            </div>
            <div className="grid items-center grid-cols-1 md:grid-cols-2">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <FormInput
                    inputId="date"
                    type="date"
                    name="deadlineShort"
                    width="w-full"
                    getFieldProps={formik.getFieldProps('date')}
                    errors={!!(formik.touched.date && formik.errors.date)}
                    errorName={formik.errors.date as string}
                    disabled={isFormDisabled}
                  />
                </div>
                <FormCheckbox
                  inputId="is_all_day"
                  name="allDay"
                  handleAllDayCheckboxChange={handleAllDayCheckboxChange}
                  disabled={isFormDisabled}
                />
              </div>
              {!showTimeFields && !isAllDay && (
                <button type="button" className="flex items-center gap-1 mt-5 md:ml-3 md:mt-2 text-mdm-blue text-regular" onClick={handleAddTimeClick}>
                  <span className="px-2 py-2 rounded-lg bg-fields">
                    <HiOutlinePlus size={18} />
                  </span>
                  <p>{t('addTime')}</p>
                </button>
              )}
              {showTimeFields && !isAllDay && (
                <div className="flex gap-5 mt-1 lg:gap-1">
                  <FormTimeInput
                    inputId="start_time"
                    width="w-full"
                    disabled={isFormDisabled}
                    getFieldProps={formik.getFieldProps('start_time')}
                    errors={!!(formik.touched.start_time && formik.errors.start_time)}
                    errorName={formik.errors.start_time as string}
                  />
                  <FormTimeInput
                    inputId="end_time"
                    width="w-full"
                    disabled={isFormDisabled}
                    getFieldProps={formik.getFieldProps('end_time')}
                    errors={!!(formik.touched.end_time && formik.errors.end_time)}
                    errorName={formik.errors.end_time as string}
                  />
                </div>
              )}
            </div>
            <FormSelect
              inputId="calendar_type"
              name="calender"
              width="lg:w-1/2"
              getFieldProps={formik.getFieldProps('calendar_type')}
              errors={!!(formik.touched.calendar_type && formik.errors.calendar_type)}
              errorName={formik.errors.calendar_type as string}
              disabled={isFormDisabled}
            >
              <option label={t('selectOneOption')} value="" />
              {calenders?.map((calender) => (
                <option key={calender.label} value={calender.value}>
                  {calender.label}
                </option>
              ))}
            </FormSelect>
            <RenderIf isTrue={!!(formik.values.calendar_type === 'DEPARTMENT')}>
              <FormSelect
                inputId="department"
                name="department"
                width="md:w-full"
                getFieldProps={formik.getFieldProps('department')}
                errors={!!(formik.touched.department && formik.errors.department)}
                errorName={formik.errors.department as string}
                disabled={isFormDisabled}
              >
                <RenderIf isTrue={authUser?.user.user_type === 'ADMINISTRATOR'}>
                  <option label={t('selectOneOption')} value="" />
                  {departmentsOptions?.map((department) => (
                    <option key={department.label} value={department?.value?.toString()}>
                      {t(department.label)}
                    </option>
                  ))}
                </RenderIf>
                <RenderIf isTrue={authUser?.user.user_type === 'AGENT' && Array.isArray(authUser?.user.departments) && authUser?.user.departments.length === 1}>
                  {authUser?.user.departments && authUser.user.departments.length === 1 ? (
                    <option
                      key={authUser.user.departments[0]?.id.toString()}
                      value={authUser.user.departments[0]?.id.toString()}
                    >
                      {t(authUser.user.departments[0]?.title?.toLowerCase() ?? '')}
                    </option>
                  ) : null}
                </RenderIf>
                <RenderIf isTrue={authUser?.user.user_type === 'AGENT' && Array.isArray(authUser?.user.departments) && authUser?.user.departments.length > 1}>
                  <option label={t('selectOneOption')} value="" />
                  {authUser?.user.departments?.map((department) => (
                    <option key={department.title} value={department?.id.toString()}>
                      {t(department.title.toLowerCase())}
                    </option>
                  ))}
                </RenderIf>
              </FormSelect>
            </RenderIf>
            <FormMultiSelect
              inputId="assignee"
              name="assignee"
              value={formik.values.assignee}
              errors={!!(formik.touched.assignee && formik.errors.assignee)}
              errorName={formik.errors.assignee as string}
              disabled={isFormDisabled}
              options={modifyUsersForMultiplySelectInput}
              onChange={handleMultiSelectChange}
            />
            <RenderIf isTrue={selectedTask}>
              <FormInput
                inputId="created_by"
                name="taskCreator"
                width="w-full"
                disabled
                getFieldProps={formik.getFieldProps('created_by')}
                errors={!!(formik.touched.created_by && formik.errors.created_by)}
                errorName={formik.errors.created_by as string}
              />
            </RenderIf>

            <div className="mb-5">
              <FormTextArea
                inputId="notes"
                name="notes"
                width="md:w-full"
                getFieldProps={formik.getFieldProps('notes')}
                errors={!!(formik.touched.notes && formik.errors.notes)}
                errorName={formik.errors.notes as string}
                disabled={isFormDisabled}
              />
            </div>
            <div className="hidden md:block w-full h-[1px] bg-lines/50 my-6" />
            <FormButtons
              isLoading={isLoading}
              px="px-10"
              btnText={selectedTask ? 'saveChanges' : 'addNewTask'}
              btnTextSecondary={selectedTask ? 'delete' : 'back'}
              onCancelHandler={closeTaskModalClick}
              spinnerWidth={selectedTask ? 'w-[12rem]' : 'w-[13.8rem]'}
              onClickDeleteHandler={handleDeleteTask}
              isEditButtonDisabled={isEditButtonDisabled}
              isDeleteButtonDisabled={isDeleteButtonDisabled}
            />
          </form>
        </FormikProvider>
      </div>
      {/* </FocusLock> */}
    </>,
    overlays
  );
}

export default TaskModal
