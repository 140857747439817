import axios, { AxiosError } from 'axios'
import exception from 'src/utils/exception'
import { ServerError } from 'src/interfaces/api'
import { ProgramTypes } from 'src/interfaces/program'
import axiosInstance from '../axiosInstance'
import { ProgramServiceInterface } from './programService.interface'

class ProgramService implements ProgramServiceInterface {
  public async getPrograms(): Promise<ProgramTypes[] | null> {
    try {
      const response = await axiosInstance.get('/tasks/program/')
      const programs: ProgramTypes[] = response.data.data
      return programs
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default ProgramService;
