/* eslint-disable react/no-unstable-nested-components */
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { ContactTypes } from 'src/interfaces/contacts'
import RenderIf from 'src/components/UI/RenderIf'
import Table from 'src/components/Table/Table'
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from 'react-icons/io'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import sortingContactTableHeaders from '../../../Contacts/helpers/sortingContactTableHeaders'

interface Props {
  contacts: ContactTypes[]
  selectedContacts: ContactTypes[]
  onSelectHandler: (contact: ContactTypes) => void
  onRemoveHandler: (contact: ContactTypes) => void
  onShowSelectedClientsHandler: () => void
  isHidden: boolean
}

interface OriginalTypes {
  cell: { row: { original: ContactTypes } }
}

function MailingListTable({
  contacts = [], selectedContacts, onSelectHandler,
  onRemoveHandler, onShowSelectedClientsHandler, isHidden
}:Props) {
  const { t } = useTranslation()

  const {
    contactsPaginated, searchTextContact,
    sortTextContact,
    onSortingOrderContactHandler,
    onSortTextContactHandler,
    sortingOrderContact,
    contactsPageParam,
    setContactsPageParam
  } = useDataProvider()

  const columns = useMemo(() => [
    {
      Header: ' ',
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => (
        <div className="flex justify-end w-7 h-7">
          <RenderIf
            isTrue={!selectedContacts
              .find((contact) => contact.id === original.id)}
          >
            <IoMdAddCircleOutline size={25} onClick={() => onSelectHandler(original)} className="transition-colors duration-200 cursor-pointer text-paragraph hover:text-mdm-blue" />
          </RenderIf>
          <RenderIf
            isTrue={!!selectedContacts
              .find((contact) => contact.id === original.id)}
          >
            <IoMdRemoveCircleOutline size={25} onClick={() => onRemoveHandler(original)} className="transition-colors duration-200 cursor-pointer text-paragraph hover:text-red-500" />
          </RenderIf>
        </div>
      )
    },
    {
      Header: t('person'),
      accessor: 'fullname',
      Cell: ({ row }: any) => (
        <p>{`${row.original.first_name || '-'} ${row.original.last_name || '-'}`}</p>
      )
    },
    {
      Header: t('contactDetails'),
      accessor: 'email',
      Cell: ({ value }:{ value:string }) => <p className="whitespace-nowrap font-sans text-[#100899]">{value}</p>
    },
    {
      Header: t('carrier'),
      accessor: 'carrier',
      Cell: ({ value }:{ value:string }) => <p className=" whitespace-nowrap">{value}</p>
    },
    {
      Header: t('emailList'),
      accessor: 'mailing_lists[0].name',
      Cell: ({ value }:{ value:string }) => <p className="whitespace-nowrap">{value || '-'}</p>
    }

  ], [onRemoveHandler, onSelectHandler, selectedContacts, t]);

  return (
    <Table
      columns={columns}
      data={contacts}
      storageKey="mailingListTable"
      isHidden={isHidden}
      rowsPerPage={100}
      searchText={searchTextContact}
      selectedContacts={selectedContacts}
      onShowSelectedClientsHandler={onShowSelectedClientsHandler}
      sortingHeadersNames={sortingContactTableHeaders}
      apiPagination={contactsPaginated}
      dataPageParam={contactsPageParam}
      setDataPageParam={setContactsPageParam}
      onSortingOrderHandler={onSortingOrderContactHandler}
      onSortTextHandler={onSortTextContactHandler}
      sortText={sortTextContact}
      sortingOrder={sortingOrderContact}
    />
  )
}

export default MailingListTable
