import { useTranslation } from 'react-i18next'
import { useMailingList } from 'src/context/MailingListCtx/MailingListProvider'
import RenderIf from 'src/components/UI/RenderIf'
import MailingListFormInput from '../../MailingList/MailingListFormInput'
import CreateNewListBtn from '../../MailingList/CreateNewListBtn'
import FormTitle from '../../FormTitle'
import ViewSelectedClientsBtn from '../../MailingList/ViewSelectedContactsBtn'
import FormInputNewsletter from '../../FormInputNewsletter'

interface Props {
  formik: any
}

function CampaignDetailsStep({ formik }:Props) {
  const {
    mailingList,
    showSpecificContactsListModalHandler,
    getter,
    showSelectedSpecificContactsModalHandler
  } = useMailingList()

  const { t } = useTranslation()

  return (
    <form onSubmit={formik.handleSubmit} className="w-full ">
      <FormTitle text="campaignDetails" />
      <div className="flex flex-col gap-2">
        <FormInputNewsletter
          inputId="campaignName"
          name="campaignName"
          width="md:w-full"
          marginX="mx-0"
          getFieldProps={formik.getFieldProps('campaignName')}
          errors={!!(formik.touched.campaignName && formik.errors.campaignName)}
          errorName={formik.errors.campaignName}
        />
        <FormInputNewsletter
          inputId="emailSubject"
          name="emailSubject"
          width="md:w-full"
          marginX="mx-0"
          getFieldProps={formik.getFieldProps('emailSubject')}
          errors={!!(formik.touched.emailSubject && formik.errors.emailSubject)}
          errorName={formik.errors.emailSubject}
        />
        <MailingListFormInput
          setValue={formik.setFieldValue}
          getFieldProps={formik.getFieldProps('mailingList')}
          errors={!!(formik.touched.mailingList && formik.errors.mailingList)}
          errorName={formik.errors.mailingList}
          mailingList={mailingList}
        />
        <p className="mb-5 text-paragraph">{t('or')}</p>
      </div>
      <CreateNewListBtn text="chooseSomeReceivers" onClickHandler={showSpecificContactsListModalHandler} />
      <RenderIf isTrue={getter.specificMailingListContacts.length > 0}>
        <div className="mt-4">
          <ViewSelectedClientsBtn
            selectedContacts={getter.specificMailingListContacts}
            onClickHandler={showSelectedSpecificContactsModalHandler}
          />
        </div>
      </RenderIf>
    </form>

  )
}

export default CampaignDetailsStep
