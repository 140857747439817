import { useTranslation } from 'react-i18next'
import FormLayout from 'src/components/Form/FormLayout'
import Detail from 'src/components/UI/Detail'
import RenderIf from 'src/components/UI/RenderIf'
import { useState } from 'react'
import formattedDate from 'src/utils/formattedDate'
import underscoreToUpperCase from 'src/utils/underscoreToUpperCase'
import { DepartmentOption } from 'src/interfaces/department'

interface Props {
  contact?: any
}

function ContactDetails({ contact }: Props) {
  const { t } = useTranslation()
  const print = 'print:grid-cols-3'

  const titleDegree = t(contact?.title) || '-'
  const fullName = `${contact?.first_name || '-'} ${contact?.last_name || '-'}`
  const email = contact?.email || '-'
  const homePhone = contact?.home_phone || '-'
  const mobilePhone = contact?.mobile_phone || '-'
  const carrier = contact?.carrier || '-'
  const program = contact?.program || '-'
  const position = contact?.position || '-'
  const country = contact?.country || '-'
  const comments = contact?.comments || '-'
  const workAddress = contact?.work_address || '-'
  const departmentTitle = t(contact?.department?.title.toLowerCase()) || '-'
  const createdDate = formattedDate(contact?.registration_date) || ''
  const firstName = contact?.created_by.first_name || '-'
  const lastName = contact?.created_by.last_name || '-'
  const registrationDetails = `${createdDate}${
    firstName || lastName ? ' | ' : '-'
  }${firstName && lastName ? `${firstName} ${lastName}` : '-'}`

  // program contact information
  const typeOfSponsor = contact?.type_of_sponsor || '-'
  const typeOfSupport = contact?.type_of_support || '-'
  const sizeOfGrant = contact?.size_of_grant || '-'
  const institutionalPosition = contact?.institutional_position || '-'
  const implementationPeriod = contact?.implementation_period || '-'
  const currentStatus = contact?.current_status || '-'
  const startOfCooperation = contact?.start_of_cooperation || '-'
  const endOfCooperation = contact?.end_of_cooperation || '-'
  const budget = contact?.budget || '-'

  // volunteer contact information
  const dateOfBirth = contact?.date_of_birth || '-'
  const studies = contact?.studies || '-'
  const job = contact?.job || '-'
  const city = contact?.city || '-'
  const volunteersAttributes = contact?.volunteer_attributes || '-'
  const note = contact?.notes || '-'
  const status = contact?.is_active ? 'active' : 'notActive'

  // financial contact information
  const agencyName = contact?.agency_name || '-'
  const typeOfFinancialSupport = t(contact?.type_of_support) || '-'
  const amount = contact?.amount || '-'
  const dateOfDeposit = contact?.date_of_deposit || '-'
  const invoiceIssueDate = contact?.invoice_issue_date || '-'
  const depositAccount = contact?.deposit_account || '-'
  const depositBank = contact?.deposit_bank || '-'
  const notes = contact?.notes || '-'

  // communication contact information
  const website = contact?.website || '-'
  const show = contact?.show || '-'
  const media = contact?.media || '-'

  const [expandedForms, setExpandedForms] = useState(new Array(4).fill(true))
  const [, setSelectedContactType] = useState<DepartmentOption | ''>()

  const toggleForm = (index: number) => {
    const updatedExpandedForms = expandedForms.map((value, i) =>
      i === index ? !value : value
    )
    setExpandedForms(updatedExpandedForms)

    if (index === 0) {
      setSelectedContactType('volunteers')
    } else if (index === 1) {
      setSelectedContactType('finance')
    } else if (index === 2) {
      setSelectedContactType('communication')
    } else if (index === 3) {
      setSelectedContactType('programs')
    }
  }

  return (
    <>
      <FormLayout title={t('basicDetails')} isExpandedForm>
        <div
          className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 w-full py-2 md:px-4 md:mt-4 ${print}`}
        >
          <Detail label={t('titleDegree')} text={titleDegree} />
          <Detail label={t('fullName')} text={fullName} />
          <Detail label="email" text={email} />
          <Detail label={t('homePhone')} text={homePhone} />
          <Detail label={t('mobilePhone')} text={mobilePhone} />
          <Detail label={t('carrier')} text={carrier} />
          <Detail label={t('program')} text={program} />
          <Detail label={t('position')} text={position} />
          <Detail label={t('country')} text={country} />
          <Detail label={t('workAddress')} text={workAddress} />
          <Detail label={t('department')} text={departmentTitle} />
          <Detail
            label={t('dataRegistrationNameOfUser')}
            text={registrationDetails}
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-6 px-4 mt-3 md:mt-4">
          <Detail
            label={t('notes')}
            text={comments}
            ellipsis="line-clamp-3 text-ellipsis"
          />
        </div>
      </FormLayout>
      <RenderIf isTrue={!!(contact?.contact_type?.toLowerCase() === 'program')}>
        <FormLayout
          title={t('detailsDeptProgram')}
          bgColor="bg-fields"
          cursor="cursor-pointer"
          shadow="none"
          py="md:py-5"
          toggleForm={() => toggleForm(3)}
          isExpandedForm={expandedForms[3]}
        >
          <div className="grid w-full grid-cols-1 gap-4 py-3 pt-2 mt-1 md:mt-4 md:gap-6 md:px-4 md:grid-cols-2 lg:grid-cols-3">
            <Detail
              label={t('typeOfSponsor')}
              text={t(underscoreToUpperCase(typeOfSponsor))}
            />
            <Detail
              label={t('typeOfSupport')}
              text={t(underscoreToUpperCase(typeOfSupport))}
            />
            <Detail
              label={t('sizeOfGrant')}
              text={t(underscoreToUpperCase(sizeOfGrant))}
            />
            <Detail
              label={t('institutionalPosition')}
              text={t(institutionalPosition.toLowerCase())}
            />
            <Detail
              label={t('implementationPeriod')}
              text={formattedDate(implementationPeriod)}
            />
            <Detail label={t('currentStatus')} text={t(currentStatus)} />
            <Detail
              label={t('startOfCooperation')}
              text={formattedDate(startOfCooperation)}
            />
            <Detail
              label={t('endOfCooperation')}
              text={formattedDate(endOfCooperation)}
            />
            <Detail label={t('budget')} text={budget} />
          </div>
        </FormLayout>
      </RenderIf>
      <RenderIf
        isTrue={!!(contact?.contact_type?.toLowerCase() === 'volunteer')}
      >
        <FormLayout
          title={t('detailsDeptVolunteers')}
          bgColor="bg-fields"
          cursor="cursor-pointer"
          shadow="none"
          py="md:py-5"
          toggleForm={() => toggleForm(0)}
          isExpandedForm={expandedForms[0]}
        >
          <div className="grid w-full grid-cols-1 gap-3 pt-2 mt-1 md:mt-4 md:gap-6 md:px-4 md:grid-cols-2 lg:grid-cols-3">
            <Detail
              label={t('dateOfBirth')}
              text={formattedDate(dateOfBirth)}
            />
            <Detail label={t('studies')} text={studies} />
            <Detail label={t('job')} text={job} />
            <Detail label={t('city')} text={city} />
            <Detail
              label={t('volunteersAttributes')}
              text={volunteersAttributes}
            />
            <Detail label={t('status')} text={t(status)} />
          </div>
          <div className="grid w-full grid-cols-1 gap-6 mt-3 md:mt-4 md:px-4">
            <Detail
              label={t('notes')}
              text={note}
              ellipsis="line-clamp-3 text-ellipsis"
            />
          </div>
        </FormLayout>
      </RenderIf>
      <RenderIf
        isTrue={!!(contact?.contact_type?.toLowerCase() === 'communication')}
      >
        <FormLayout
          title={t('detailsDeptCommunication')}
          bgColor="bg-fields"
          cursor="cursor-pointer"
          shadow="none"
          py="md:py-5"
          toggleForm={() => toggleForm(2)}
          isExpandedForm={expandedForms[2]}
        >
          <div className="grid w-full grid-cols-1 gap-3 pt-2 mt-1 md:mt-4 md:px-4 md:grid-cols-2 lg:grid-cols-3">
            <Detail label="Website" text={website} />
            <Detail label={t('show')} text={t(show)} />
            <Detail label={t('mediaCommunication')} text={t(media)} />
          </div>
        </FormLayout>
      </RenderIf>
      <RenderIf isTrue={!!(contact?.contact_type?.toLowerCase() === 'finance')}>
        <FormLayout
          title={t('detailsDeptFinancial')}
          bgColor="bg-fields"
          cursor="cursor-pointer"
          shadow="none"
          py="md:py-5"
          toggleForm={() => toggleForm(1)}
          isExpandedForm={expandedForms[1]}
        >
          <div className="grid w-full grid-cols-1 gap-3 pt-2 mt-1 md:mt-4 md:gap-6 md:px-4 md:grid-cols-2 lg:grid-cols-3">
            <Detail label={t('agencyName')} text={agencyName} />
            <Detail
              label={t('typeOfFinancialSupport')}
              text={t(typeOfFinancialSupport)}
            />
            <Detail label={t('amount')} text={amount} />
            <Detail
              label={t('dateOfDeposit')}
              text={formattedDate(dateOfDeposit)}
            />
            <Detail
              label={t('invoiceIssueDate')}
              text={formattedDate(invoiceIssueDate)}
            />
            <Detail label={t('depositAccount')} text={depositAccount} />
            <Detail label={t('depositBank')} text={depositBank} />
          </div>
          <div className="grid grid-cols-1 gap-6 mt-3 md:mt-4 md:px-4">
            <Detail
              label={t('notes')}
              text={notes}
              ellipsis="line-clamp-3 text-ellipsis"
            />
          </div>
        </FormLayout>
      </RenderIf>
      <RenderIf isTrue={!!(contact?.contact_type?.toLowerCase() === 'charity')}>
        <FormLayout
          title={t('detailsDeptCharity')}
          bgColor="bg-fields"
          shadow="none"
          py="md:py-5"
          isExpandedForm={false}
        >
          {''}
        </FormLayout>
      </RenderIf>
    </>
  )
}

export default ContactDetails
