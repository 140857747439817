/* eslint-disable react/no-unstable-nested-components */
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Table from 'src/components/Table/Table'
import { DonationTypes, SelectedDonationsTypes } from 'src/interfaces/donations'
import goods from 'src/assets/images/box.png'
import finances from 'src/assets/images/money.png'
import formattedDate from 'src/utils/formattedDate'
import PrimaryToggleButton from 'src/components/UI/PrimaryToggleButton'
import sortByDate from 'src/utils/sortByDate'

interface DonationDetails {
  type: string;
  kind: string;
}

interface Props {
  donations: DonationTypes[]
}

function DonationsTable({ donations = [] }:Props) {
  const { t } = useTranslation()
  // eslint-disable-next-line max-len
  const [selectedDonationsForExport, setSelectedDonationsForExport] = useState<SelectedDonationsTypes>({
    financial_ids: [],
    goods_ids: []
  });

  const handleCheckboxClick = (rowId: string, type: string) => {
    setSelectedDonationsForExport((prevOptions) => {
      if (type === t('financial')) {
        if (prevOptions.financial_ids.includes(rowId)) {
          return {
            ...prevOptions,
            financial_ids: prevOptions.financial_ids.filter((id) => id !== rowId)
          };
        }
        return {
          ...prevOptions,
          financial_ids: [...prevOptions.financial_ids, rowId]
        };
      } if (type === t('goods')) {
        if (prevOptions.goods_ids.includes(rowId)) {
          return {
            ...prevOptions,
            goods_ids: prevOptions.goods_ids.filter((id) => id !== rowId)
          };
        }
        return {
          ...prevOptions,
          goods_ids: [...prevOptions.goods_ids, rowId]
        };
      }
      return prevOptions;
    });
  };

  const columns = useMemo(() => [
    {
      accessor: 'selected',
      Cell: ({ row }: { row: any }) => (
        <input
          type="checkbox"
          value="checkbox"
          className="custom-checkbox"
          onClick={(e) => {
            e.stopPropagation();
            handleCheckboxClick(row.original.id, row.original.type);
          }}
        />
      )
    },
    {
      Header: 'ID',
      accessor: (row: { id: string }) => `#${row.id.toString()}`,
      Cell: ({ value }: { value: string }) => (
        <p title={value}>
          {value}
        </p>
      )
    },
    {
      Header: t('donorName'),
      accessor: 'donor_name',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>,
      sortType: (rowA: any, rowB: any) => {
        const valueA = rowA.original.donor_name.toLowerCase();
        const valueB = rowB.original.donor_name.toLowerCase();
        return valueA.localeCompare(valueB);
      }
    },
    {
      Header: t('type'),
      accessor: (row: DonationDetails) => `${row.type} ${(row.kind)}`,
      Cell: ({ row }: any) => (
        <div className="flex items-center gap-2">
          {row.original.type === t('goods') && (
          <span className="bg-[#EBF5F9] h-[35px] w-[35px] rounded-full flex items-center justify-center flex-shrink-0">
            <img src={goods} className="w-[18px] h-[16px]" alt="goods donation" />
          </span>
          )}
          {row.original.type === t('financial') && (
          <span className="bg-[#EBF5F9] h-[35px] w-[35px] rounded-full flex items-center justify-center flex-shrink-0">
            <img src={finances} className="w-[21px] h-[15px]" alt="financial donation" />
          </span>
          )}
          <p className="whitespace-nowrap">
            {row.original.type === t('goods') ? t(row.original.type) : t('financialDonation') }
            {' '}
            {row.original.type === t('goods') ? '/' : ''}
            {' '}
            {row.original.kind}
          </p>
        </div>
      )
    },
    {
      Header: t('registrationDate'),
      accessor: (row: { registration_date : string }) => (`${formattedDate(row.registration_date)}`),
      Cell: ({ row }: any) => <p>{formattedDate(row.original.registration_date)}</p>,
      // eslint-disable-next-line max-len
      sortType: (a: any, b: any) => sortByDate(a.original.registration_date, b.original.registration_date)
    },
    {
      Header: t('status'),
      accessor: (row: { is_active : boolean }) => (row.is_active ? t('ACTIVE') : t('INACTIVE')),
      Cell: ({ row }: { row: any }) => (
        <div className="min-w-[150px]">
          <PrimaryToggleButton
            isActive={row.original.is_active}
            donationType={row.original.type}
            id={row.original.id}
          />
        </div>

      )
    },
    {
      Header: t('notes'),
      accessor: 'notes',
      Cell: ({ value }:{ value:string }) => <p title={value} className="max-w-[200px] line-clamp-1 text-ellipsis">{value || <span className="text-center md:text-left">-</span>}</p>
    }
  ], [t]);

  return (
    <Table
      columns={columns}
      data={donations}
      storageKey="donationsTable"
      rowsPerPage={7}
      selectedDonationsForExport={selectedDonationsForExport}
    />
  )
}

export default DonationsTable
