import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useModalContext } from '../../context/ModalsCtx/ModalsProvider'

interface Props {
  text: string
  path: string
}

export default function NavLink({ text, path }: Props) {
  const { t } = useTranslation();
  const { setIsOpen } = useModalContext();
  const { pathname } = useLocation();
  const activePath = pathname.split('/')[2];
  const isActiveLink = path.includes(activePath) || (activePath === 'tasks-list' && path.includes('tasks')) || pathname === path;

  return (
    <div className="relative transition-all duration-200">
      <Link
        onClick={() => setIsOpen(false)}
        to={path}
        className={`text-mdm-blue hover:font-bold flex items-center transition-all duration-200 justify-center h-full text-md custom-screen-2:text-mdm-white select-none custom-screen-2:hover:text-mdm-sky-blue ${isActiveLink ? 'font-bold text-[1.38rem] custom-screen-2:text-mdm-sky-blue' : 'font-normal'}`}
      >
        {t(text)}
      </Link>
      <div className={`${isActiveLink ? 'active-link-border -bottom-[4rem] custom-screen-2:-bottom-[5.57rem] absolute -left-7 -right-7 h-[3.75rem] transition-transform duration-200 scale-100' : 'scale-0'}`} />
    </div>
  );
}
