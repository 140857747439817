import { useTranslation } from 'react-i18next';
import { RiDeleteBin6Line } from 'react-icons/ri';

interface Props {
  onClickDeleteHandler?: () => void;
  btnText?: string;
  disabled?: boolean
}

function DeleteButton({ onClickDeleteHandler, btnText = 'delete', disabled }: Props) {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      onClick={onClickDeleteHandler}
      className="text-regular hover:bg-fields text-mdm-blue font-medium rounded-small px-7 py-4 transition-all duration-200 bg-white flex items-center justify-center gap-[10px] md:w-auto w-full disabled:cursor-not-allowed disabled:bg-opacity-70"
      disabled={disabled}
    >
      <RiDeleteBin6Line size={20} className="text-[#E64848]" />
      {t(btnText)}
    </button>
  );
}

export default DeleteButton
