import { useTranslation } from 'react-i18next'

interface Props {
  text: string
}
function MyComponent({ text }:Props) {
  const { t } = useTranslation()
  return (
    <p className="font-sans text-sm font-medium text-center text-paragraph md:text-md">{t(text)}</p>
  )
}

export default MyComponent
