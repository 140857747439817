import { useTranslation } from 'react-i18next'
import {
  useOutletContext, useParams, useLocation
} from 'react-router-dom'
import { DonationTypes } from 'src/interfaces/donations'
import routes from 'src/constants/routes'
import userMethods from 'src/utils/userMethods'
import { TagsTypes } from 'src/interfaces/contacts'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import SecondaryToggleButton from './SecondaryToggleButton'
import RenderIf from './RenderIf'
import DonationBadge from './DonationBadge'
import Badge from './Badge'

interface Props {
  text: string,
  subtext?: string,
  date?: string
  id?: number
  isDonation?: boolean
  isDonationLoading?: boolean
  tags?: TagsTypes[]
}

function PageTitle({
  text, id, isDonation, date, subtext, isDonationLoading, tags
}:Props) {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const donations = useOutletContext<DonationTypes[]>()
  const donation = donations?.find((d) => (d.type === t('goods') || d.type === t('finance')) && d.id.toString() === params.id);
  const authUser = userMethods.getUser()
  const { pathname } = useLocation()

  // render toggle button depending on path
  const dynamicPart = donation?.id
  const pathnameWithoutDynamicPart = dynamicPart ? pathname.replace(`/${dynamicPart}`, '') : pathname
  // eslint-disable-next-line max-len
  const isPath = pathnameWithoutDynamicPart
    === `/${authUser?.user.user_type.toLowerCase()}/donations/${routes.shared.financialDonationInfo}`
  || pathnameWithoutDynamicPart
    === `/${authUser?.user.user_type.toLowerCase()}/donations/${routes.shared.goodsDonationInfo}`
  || pathnameWithoutDynamicPart
    === `/${authUser?.user.user_type.toLowerCase()}/donations/${routes.shared.updateFinancialDonation}`
  || pathnameWithoutDynamicPart
    === `/${authUser?.user.user_type.toLowerCase()}/donations/${routes.shared.updateGoodsDonation}`;

  return (
    <div className="flex flex-col items-center justify-between md:items-start md:flex-row">
      <div className="flex flex-col items-center justify-center md:gap-0 md:items-start md:flex-row">
        <div className="flex items-center gap-3">
          {' '}
          <h2 className="text-white text-center md:text-left font-bold text-[28px] md:text-2xl md:mb-12">{t(text)}</h2>
          <RenderIf isTrue={!!id}>
            <h2 className="text-white font-bold text-[32px] md:text-2xl mb-1 md:mb-12 whitespace-nowrap">
              #
              {id}
            </h2>
          </RenderIf>
        </div>
        <RenderIf isTrue={!!(tags?.length && tags?.length > 0)}>
          <div className="flex flex-wrap gap-2 mt-2.5 mb-1 pl-5 max-h-[100px] overflow-auto">
            {' '}
            {tags?.map((tag) => <Badge key={tag.id} text={tag.title} paddingX="px-2 md:px-5" paddingY="py-1 md:py-[9px]" textSize="text-xxs md:text-sm" cursor="cursor-auto" />)}
          </div>
        </RenderIf>
        <RenderIf isTrue={!!isDonation}>
          <div className="mb-2 md:mt-2 md:mx-4">
            <DonationBadge px="px-7" py="py-2" textSize="text-sm" badgeWidth="w-[120px]" imageHeight="h-[20px]" imageWidth="w-[18px]" borderRadius="rounded-[12px]" />
          </div>
        </RenderIf>
        <RenderIf isTrue={!!isPath}>
          {/* eslint-disable-next-line max-len */}
          <SecondaryToggleButton donationType={donation?.type} isActive={donation?.is_active} isDonationLoading={isDonationLoading} />
        </RenderIf>

      </div>
      <div className="flex flex-col items-center gap-1 mb-3 text-white md:mb-7 md:items-end md:m-0">
        <p className="text-sm md:text-[1.375rem] whitespace-nowrap ml-5">{t(subtext || '')}</p>
        <p className="text-sm md:text-[1.56rem] font-[600]">{t(date || '')}</p>
      </div>
    </div>
  )
}

export default PageTitle
