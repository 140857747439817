import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import useToggleMenu from 'src/hooks/useToggleMenu'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import userMethods from 'src/utils/userMethods'
import Dropdown from '../Dropdown/Dropdown'
import TableFilterButton from './TableFilterButton'

interface Props {
  contactsDepartmentsFilter?: {
    value?: number
    label?: string
  }[]
}

function TableFilterDropdown({ contactsDepartmentsFilter }:Props) {
  const { ref, isOpen, setIsOpen } = useToggleMenu()
  const authUser = userMethods.getUser()
  const authUserId = authUser?.user.id
  const { t } = useTranslation()

  const handleDropdownMenu = () => setIsOpen((prevState) => !prevState)

  const {
    filteredDepartments, setFilteredDepartments, setFilteredMyContacts, filteredMyContacts
  } = useDataProvider()

  const handleDepartmentsCheckboxChange = (value: number | undefined) => {
    if (value !== undefined) {
      if (filteredDepartments.includes(value)) {
        setFilteredDepartments((prevFiltered) => prevFiltered.filter((dep) => dep !== value))
      } else { setFilteredDepartments((prevFiltered) => [...prevFiltered, value]) }
    }
  }

  // sync checkboxes if user opens/closes dropdown menu
  useEffect(() => {
    setFilteredDepartments(filteredDepartments);
  }, []);

  return (
    <div ref={ref} className="relative flex items-center h-full py-2">
      <TableFilterButton onClickHandler={handleDropdownMenu} />
      <Dropdown isOpen={isOpen}>
        {contactsDepartmentsFilter && contactsDepartmentsFilter.length !== 1
       && contactsDepartmentsFilter.map(({ label, value }) => (
         <React.Fragment key={value}>
           <button
             className="flex items-center gap-4 px-4 py-2"
             type="button"
             onClick={() => handleDepartmentsCheckboxChange(value)}
           >
             <input
               type="checkbox"
               className="custom-checkbox"
               defaultChecked={value !== undefined && filteredDepartments.includes(value)}
             />
             <p className="text-left whitespace-nowrap text-mdm-navy-blue">
               {label && t(label)}
             </p>
           </button>

         </React.Fragment>
       ))}
        {contactsDepartmentsFilter && contactsDepartmentsFilter.length !== 1 && <div className="w-full h-[1px] bg-lines/50 mt-2" />}
        <button
          className="flex items-center gap-4 px-4 py-2 my-1"
          type="button"
        >
          <input
            type="checkbox"
            className="custom-checkbox"
            onChange={(e) => {
              if (e.target.checked) {
                if (authUserId) {
                  setFilteredMyContacts(authUserId.toString());
                }
              } else {
                setFilteredMyContacts('');
              }
            }}
            checked={!!filteredMyContacts}
          />
          <p className="text-left whitespace-nowrap text-mdm-navy-blue">{t('myContacts')}</p>
        </button>
      </Dropdown>
    </div>
  )
}

export default TableFilterDropdown
