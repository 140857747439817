import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RenderIf from 'src/components/UI/RenderIf'
import Title from 'src/components/UI/Title'
import routes from 'src/constants/routes'
import TableSkeleton from 'src/components/Skeletons/TableSkeleton'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'
import apiService from 'src/services/api/apiService'
import useGetData from 'src/hooks/useGetData'
import { NewsletterTypes } from 'src/interfaces/newsletters'
import NewslettersProvider from 'src/context/NewsletterCtx/NewslettersProvider'
import MailingListProvider from 'src/context/MailingListCtx/MailingListProvider'
import NewslettersTable from 'src/pages/Common/pages/Newsletters/components/NewslettersTable'

function Newsletters() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.admin.newsletters || pathname === routes.agent.newsletters
  const { data: newsletters, isLoading } = useGetData<NewsletterTypes[]>({
    queryKey: 'newsletters',
    queryFn: apiService.getNewsletters
  })

  const { t } = useTranslation()

  useEffect(() => {
    document.title = `MDM CRM - ${t('newsletters')}`
  }, [t])

  return (
    <NewslettersProvider>
      <MailingListProvider>
        <RenderIf isTrue={isExactPath}>
          <Title title="newsletterCampaigns" add="newCampaign" onClickHandler={() => navigate(routes.shared.createNewsletter)} />
          <RenderIf isTrue={isLoading}>
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isLoading}>
            <NewslettersTable newsletters={newsletters} />
            <ConfirmationModal cacheKey="newsletters" toastSuccessMessage={t('newsletterHasBeenDeleted')} mutationFn={apiService.deleteNewsletter} />
          </RenderIf>
        </RenderIf>
        <RenderIf isTrue={!isExactPath}>
          <Outlet />
        </RenderIf>
      </MailingListProvider>
    </NewslettersProvider>
  )
}

export default Newsletters
