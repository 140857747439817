/* eslint-disable operator-linebreak */
/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ContactPaginateTypes, DeleteContactTypes, TagsTypes } from 'src/interfaces/contacts'
import exception from 'src/utils/exception'
import { ServerError } from 'src/interfaces/api'
import { ContactsServiceInterface } from './contactService.interface'
import axiosInstance from '../axiosInstance'

class ContactsService implements ContactsServiceInterface {
  public async getAllContacts(
    sortTextContact: string,
    sortingOrderContact: string,
    filteredMyContacts: string,
    pageParam = 1
  ): Promise<ContactPaginateTypes | null> {
    try {
      let sortParam = ''
      if (sortTextContact && sortingOrderContact) {
        if (sortingOrderContact === 'desc') {
          sortParam = `&sort_by=-${sortTextContact}`
        } else if (sortingOrderContact === 'asc') {
          sortParam = `&sort_by=${sortTextContact}`
        } else {
          sortParam = ''
        }
      }

      const filterParam = filteredMyContacts
        ? `&created_by=${filteredMyContacts}`
        : ''

      const response = await axiosInstance.get(
        `/contacts/contact/all/?page=${pageParam}${sortParam}${filterParam}`
      )
      return response.data.data as ContactPaginateTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
      return null
    }
  }

  public async getContactsPerDepartment(
    departments: number[],
    sortTextContact: string,
    sortingOrderContact: string,
    filteredMyContacts: string,
    pageParam = 1
  ): Promise<ContactPaginateTypes | null> {
    try {
      let sortParam = ''
      if (sortTextContact && sortingOrderContact) {
        if (sortingOrderContact === 'desc') {
          sortParam = `&sort_by=-${sortTextContact}`
        } else if (sortingOrderContact === 'asc') {
          sortParam = `&sort_by=${sortTextContact}`
        } else {
          sortParam = ''
        }
      }

      const filterParam = filteredMyContacts
        ? `&created_by=${filteredMyContacts}`
        : ''

      const response = await axiosInstance.post(
        `/contacts/contact/by-departments/?page=${pageParam}${sortParam}${filterParam}`,
        {
          departments
        }
      )
      return response.data.data as ContactPaginateTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
      return null
    }
  }

  public async searchContact(
    searchText: string,
    sortTextContact: string,
    sortingOrderContact: string,
    myContacts: string,
    departments: number[] = [],
    pageParam = 1
  ): Promise<ContactPaginateTypes | null> {
    if (!searchText.trim()) {
      return null
    }
    try {
      let sortParam = ''
      if (sortTextContact && sortingOrderContact) {
        if (sortingOrderContact === 'desc') {
          sortParam = `&sort_by=-${sortTextContact}`
        } else if (sortingOrderContact === 'asc') {
          sortParam = `&sort_by=${sortTextContact}`
        } else {
          sortParam = ''
        }
      }

      const filterParam = myContacts ? `&created_by=${myContacts}` : ''

      const response = await axiosInstance.post(
        `/contacts/contact/search/?page=${pageParam}${sortParam}${filterParam}`,
        {
          search_text: searchText,
          ...(departments.length > 0 && { departments })
        }
      )
      return response.data.data as ContactPaginateTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
      return null
    }
  }

  public async createContact(value: any): Promise<AxiosResponse | null> {
    const departments = [
      { id: 5, endpoint: 'charity-contact' },
      { id: 4, endpoint: 'communication-contact' },
      { id: 3, endpoint: 'finance-contact' },
      { id: 2, endpoint: 'program-contact' },
      { id: 1, endpoint: 'volunteer-contact' }
    ]

    // Προσφώνηση field removed for now
    delete value.degree

    Object.keys(value).forEach((key) => {
      if (!value[key]) {
        value[key] = null
      }
    })

    try {
      const contactTypeUrl = departments.find(
        (dep) => dep.id === +value.department
      )?.endpoint

      return await axiosInstance.post(
          `contacts/${contactTypeUrl}/`,
          value
      )
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getContact(
    valueId: any,
    contactType: string
  ): Promise<AxiosResponse | null> {
    try {
      const contact =
        contactType.toLowerCase() === 'programs'
          ? 'program'
          : contactType.toLowerCase()
      const response = await axiosInstance.get(
        `/contacts/${contact}-contact/${valueId}/`
      )

      const mappedData = Object.entries(response.data.data).reduce(
        (acc: any, [key, value]: [string, any]) => {
          if (typeof value === 'string' && value === '-') {
            acc[key] = value.replace(/-/g, '')
          } else {
            acc[key] = value
          }
          return acc
        },
        {}
      )
      return mappedData
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateContact(values: any): Promise<AxiosResponse | null> {
    const departments = [
      { id: 5, endpoint: 'charity-contact' },
      { id: 4, endpoint: 'communication-contact' },
      { id: 3, endpoint: 'finance-contact' },
      { id: 2, endpoint: 'program-contact' },
      { id: 1, endpoint: 'volunteer-contact' }
    ]

    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        values[key] = null
      }
    })

    if (values.is_active) {
      values.is_active = values.is_active === 'active'
    }

    try {
      const contactTypeUrl = departments.find(
        (dep) => dep.id === +values.department
      )?.endpoint
      return await axiosInstance.patch(
          `/contacts/${contactTypeUrl}/${values.id}/`,
          values
      )
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getAllTags(): Promise<TagsTypes[] | null> {
    try {
      const response = await axiosInstance.get('/contacts/tag/')
      const tags = response.data.data
      return tags
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
      return null
    }
  }

  public async createTag(title: string): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.post('contacts/tag/', { title })
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteTag({
    id
  }: {
    id: number | string
  }): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.delete(`/contacts/tag/${id}/`)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  // eslint-disable-next-line max-len
  public async deleteContact({
    id,
    contactDepartmentType
  }: DeleteContactTypes): Promise<AxiosResponse | null> {
    try {
      const contactEndpoint = contactDepartmentType.toLowerCase()
      return await axiosInstance.delete(
        `/contacts/${contactEndpoint}-contact/${id}/`
      )
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async exportAndDownloadContacts(
    selectedContacts: Array<string>
  ): Promise<AxiosResponse> {
    const headers = { 'Content-Type': 'application/json' }

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/contacts/contact/export/',
      responseType: 'arraybuffer',
      headers
    }

    if (selectedContacts.length > 0) {
      config.data = { contacts: selectedContacts }
    }

    try {
      const response = await axiosInstance(config)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
      throw error
    }
  }
}
export default ContactsService
