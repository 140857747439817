import { UserRoleTypes } from 'src/interfaces/user'
import routes from 'src/constants/routes'

export default function navigateTo(userRole: UserRoleTypes['userRole']) {
  switch (userRole) {
    case 'ADMINISTRATOR':
      return routes.admin.home
    case 'AGENT':
      return routes.agent.home
    default:
      return routes.login
  }
}
