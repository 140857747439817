import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiService from 'src/services/api/apiService';
import useMutateData from 'src/hooks/useMutateData';
import { AxiosResponse } from 'axios';

interface Props {
  isActive: boolean;
  donationType: string;
  id: number | string;
}

function PrimaryToggleButton({ isActive = true, donationType, id }: Props) {
  const { t } = useTranslation();
  const [isDonationActive, setIsDonationActive] = useState(isActive);
  const endPoint = donationType === t('goods') ? 'goods-donation' : 'financial-donation';

  const { mutate: updateDonationStatusMutation } = useMutateData<AxiosResponse<any, any> | null>({
    key: ['donations', `donation-${id}`],
    mutationFn: (donationStatus) => apiService.updateDonationStatus(id, endPoint, donationStatus)
  });

  const toggleDonationStatus = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const updatedDonationStatus = !isDonationActive;
    setIsDonationActive(updatedDonationStatus);
    updateDonationStatusMutation({ data: updatedDonationStatus } as AxiosResponse<any, any> | null);
  };

  return (
    <button type="button" className="relative font-sans inline-flex items-center w-14 h-6 rounded-full bg-[#ebf5f9]" onClick={toggleDonationStatus}>
      <div
        className={`${
          isDonationActive ? 'right-[40px] text-[#00AF9C]' : 'right-[10px] text-[#BD1118]'
        } text-regular absolute top-[2px] transition-all duration-300 py-[0.3rem] px-[0.5rem]`}
      />
      <div
        className={`${
          isDonationActive ? 'right-[2px] bg-[#00AF9C]' : 'left-[2px] bg-[#BD1118]'
        } w-[22px] h-[22px] rounded-full absolute top-[1px] transition-all duration-300 `}
      />
      <span className={`font-[600] ${isDonationActive ? 'text-[#00AF9C] right-[-60px]' : 'text-[#BD1118] right-[-80px]'} absolute  text-sm `}>{isDonationActive ? t('ACTIVE') : t('INACTIVE')}</span>
    </button>
  );
}

export default PrimaryToggleButton;
