/* eslint-disable max-len */
import { FieldInputProps } from 'formik'
import { useTranslation } from 'react-i18next'
import React from 'react'
import RenderIf from '../UI/RenderIf'
import InputErrorMessage from '../UI/InputErrorMsg'
import { ChildrenTypes } from '../../interfaces/children'

interface Props extends ChildrenTypes {
  inputId: string
  name: string
  getFieldProps: FieldInputProps<any>
  errors: boolean
  errorName: string | undefined
  width?: 'lg:w-1/3' | 'lg:w-1/2' | 'lg:w-2/3' | 'lg:w-full' | 'md:w-full'
  disabled?: boolean
  handleSelectClick?: (event: React.MouseEvent<HTMLSelectElement>) => void
}

function FormSelect({
  inputId,
  name,
  getFieldProps,
  errors,
  errorName,
  children,
  handleSelectClick,
  width = 'lg:w-1/3',
  disabled = false
}:Props) {
  const { t } = useTranslation()

  return (
    <div className={`relative text-base md:text-lg w-full sm:w-full ${width} my-2`}>
      <label htmlFor={inputId} className="flex flex-col text-sm md:text-regular">
        <span className="mb-2 md:mx-4 text-paragraph">{t(name)}</span>
        <select
          disabled={disabled}
          id={inputId}
          {...getFieldProps}
          value={getFieldProps.value || ''}
          className={`text-black outline-none mx-0 md:mx-4 bg-white border py-1 px-2 rounded-small h-12 appearance-none ${
            disabled ? 'disabled-select text-opacity-70' : ''
          } ${
            errors
              ? 'border-red-700 focus:border-red-700 focus:ring-0'
              : 'border-lines focus:ring-0 focus:border-mdm-blue'
          }`}
          onClick={handleSelectClick}
        >
          {children}
        </select>
      </label>
      <RenderIf isTrue={!!errors}>
        <div className="absolute top-0 w-full md:mx-4">
          <InputErrorMessage text={errorName!} />
        </div>
      </RenderIf>
    </div>
  )
}

export default FormSelect
