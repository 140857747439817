import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import PageTitle from 'src/components/UI/PageTitle'
import RenderIf from 'src/components/UI/RenderIf'
import routes from 'src/constants/routes'
import PageSection from 'src/components/UI/PageSection'

function CreateNewsletter() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  // eslint-disable-next-line max-len
  const isExactPath = pathname === routes.admin.campaignPreview || pathname === routes.agent.campaignPreview

  useEffect(() => {
    document.title = `MDM CRM - ${t('createNewsletter')}`
  }, [t])

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <PageSection>
          <PageTitle text={t('newCampaign')} />
          <Outlet />
        </PageSection>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <section className="w-full mt-5 md:mt-14 ">
          <PageTitle text={t('newCampaign')} />
          <Outlet />
        </section>
      </RenderIf>
    </>
  )
}

export default CreateNewsletter
