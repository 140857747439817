import { TaskTypes, TaskCreationTypes, GetTaskTypes } from 'src/interfaces/tasks'
import axios, { AxiosError, AxiosResponse } from 'axios'
import exception from 'src/utils/exception'
import { ServerError } from 'src/interfaces/api'
import axiosInstance from '../axiosInstance'
import { TasksServiceInterface } from './tasksService.interface'

class TasksService implements TasksServiceInterface {
  public async getTasks(queryParams?:string): Promise<GetTaskTypes[] | null> {
    try {
      let endPoint = '/tasks/task/';
      if (queryParams) {
        endPoint += `?${queryParams}`;
      }
      const response = await axiosInstance.get(endPoint);
      const tasks: GetTaskTypes[] = response.data.data;
      return tasks;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>);
      }
    }
    return null;
  }

  public async createTask(values: TaskCreationTypes): Promise<AxiosResponse | null> {
    try {
      const formData: TaskTypes = {
        title: values.title.trim(),
        start_time: `${values.date} ${values.start_time}`.replace(' ', 'T'),
        calendar_type: values.calendar_type,
        notes: values.notes.trim(),
        auto_complete_status: values.auto_complete_status
      };

      if (values.end_time !== '') {
        formData.end_time = `${values.date} ${values.end_time}`.replace(' ', 'T');
      }

      if (values.assignee.length > 0) {
        formData.assignee = values.assignee.map((assignee:any) => +assignee.value);
      }

      if (values.department === '') {
        delete formData.department;
      } else {
        formData.department = values.department;
      }
      const response = await axiosInstance.post('/tasks/task/', formData);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>);
      }
    }
    return null;
  }

  public async updateTask(values: TaskCreationTypes): Promise<AxiosResponse | null> {
    try {
      const formData: TaskTypes = {
        title: values.title.trim(),
        start_time: `${values.date} ${values.start_time}`.replace(' ', 'T'),
        calendar_type: values.calendar_type,
        notes: values.notes.trim(),
        auto_complete_status: values.auto_complete_status
      };

      if (values.end_time !== '') {
        formData.end_time = `${values.date} ${values.end_time}`.replace(' ', 'T');
      }

      if (values.assignee.length > 0) {
        formData.assignee = values.assignee.map((assignee: any) => +assignee.value);
      }

      if (!values.department || values.department?.length === 0) {
        delete formData.department;
      } else {
        formData.department = values.department;
      }

      const response = await axiosInstance.patch(`/tasks/task/${values.id}/`, formData);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>);
      }
    }
    return null;
  }

  public async deleteTask({ id }: { id: number }): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.delete(`/tasks/task/${id}/`)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}
export default TasksService
