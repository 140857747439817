import { v4 as uuidv4 } from 'uuid';
import SkeletonBlock from './SkeletonBlock';

function ContactSkeleton() {
  return (
    <>
      <div className="py-3 px-3 md:px-8 bg-white rounded-regular shadow-faint w-full flex mx-auto flex-wrap h-[calc(100%-20px)] md:py-10">
        <div className="h-7 mb-8 bg-gray-200 mt-3 w-[15rem] animate-pulse rounded-small md:mx-4" />
        <div className="grid w-full grid-cols-1 gap-6 mb-2 md:px-4 md:grid-cols-2 lg:grid-cols-3">
          {Array(13)
            .fill(null)
            .map(() => (
              <SkeletonBlock key={uuidv4()} />
            ))}
        </div>
      </div>
      <div className="w-full bg-gray-200 h-[65px] animate-pulse rounded-regular mb-3 mt-6" />
      <div className="flex flex-col-reverse justify-between gap-4 mt-4 md:flex-row">
        <div className="px-20 bg-gray-200 py-7 animate-pulse rounded-small" />
        <div className="bg-gray-200 px-28 py-7 animate-pulse rounded-small" />
      </div>
    </>
  );
}

export default ContactSkeleton;
