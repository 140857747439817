import { useState, useRef } from 'react'
import { startOfToday } from 'date-fns'
import FullCalendar from '@fullcalendar/react'
import FullCalender from 'src/components/Calender/FullCalender'
import apiService from 'src/services/api/apiService'
import { GetTaskTypes } from 'src/interfaces/tasks'
import userMethods from 'src/utils/userMethods'
import DatePicker from './DatePicker'
import TaskFilterPanel from './TaskFilterPanel'
import useGetData from '../../hooks/useGetData'

function CalenderTasks() {
  const todayDate = startOfToday()
  const calendarRef = useRef<FullCalendar | null>(null)
  const authUser = userMethods.getUser()
  let initialDepartmentsQuery: number[] = [];

  if (authUser?.user.user_type === 'ADMINISTRATOR') {
    initialDepartmentsQuery = [1, 2, 3, 4, 5];
  } else if (authUser?.user.user_type === 'AGENT') {
    const agentDepartments = authUser.user.departments?.map((department) => department.id) || [];
    initialDepartmentsQuery = [...agentDepartments];
  }
  const [calendarsQuery, setCalendarsQuery] = useState<string[]>(['PERSONAL', 'MEETING_ROOM', 'DEPARTMENT']);
  const [departmentsQuery, setDepartmentsQuery] = useState<number[]>(initialDepartmentsQuery);
  const [selectedDay, setSelectedDay] = useState<Date | null>(todayDate)

  // day navigation in calendar using DatePicker
  const handleDateChange = (date: Date | null) => {
    setSelectedDay(date)
    if (calendarRef.current && date) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView('timeGridDay');
      calendarApi.gotoDate(date)
    }
  }

  const handleCheckboxClick = (value: string, departmentId?: number) => {
    // If value is not DEPARTMENT, add/remove value from calendarsQuery
    if (value !== 'DEPARTMENT') {
      setCalendarsQuery((prevState) => {
        if (prevState.includes(value)) {
          const currentState = [...prevState];
          const index = currentState.indexOf(value);
          currentState.splice(index, 1);
          return currentState;
        }
        return [...prevState, value];
      });
    }
    if (departmentId) {
      setDepartmentsQuery((prevState) => {
        if (prevState.includes(departmentId)) {
          const currentState = [...prevState];
          const index = currentState.indexOf(departmentId);
          currentState.splice(index, 1);
          return currentState;
        }
        return [...prevState, departmentId];
      });
    }
  };

  const { data: filteredTasks } = useGetData<GetTaskTypes[]>({
    queryKey: ['tasks', calendarsQuery, departmentsQuery],
    queryFn: () => {
      // Create query string
      let query = `calendar_type__in=${calendarsQuery.join(',')}`;

      // Remove DEPARTMENT from calendarsQuery if departmentsQuery is empty
      if (departmentsQuery.length === 0) {
        query = `calendar_type__in=${calendarsQuery.join(',').replace('DEPARTMENT', '')}`;
      }

      // Add department__in query if departmentsQuery is not empty
      if (departmentsQuery.length > 0) {
        query += `&department__in=${departmentsQuery.join(',')}`;
      }

      return apiService
        .getTasks(query)
    }
  })

  return (
    <div className="flex flex-col gap-5 md:gap-20 lg:gap-28 xl:gap-8 xl:flex-row">
      <div className="-mt-0 xl:-mt-[6.25rem] flex flex-wrap justify-center items-start xl:flex-nowrap">
        <div className="flex flex-col justify-start gap-8 xl:items-center lg:items-start lg:flex-row xl:flex-col">
          <DatePicker
            handleDateChange={handleDateChange}
            selectedDay={selectedDay}
            tasks={filteredTasks?.length ? filteredTasks : []}
          />
          <TaskFilterPanel handleCheckboxClick={handleCheckboxClick} />
        </div>
      </div>
      <div className="custom-fullcalender-container w-full -mt-0 lg:-mt-[6.8rem] rounded-regular">
        <FullCalender
          tasks={filteredTasks?.length ? filteredTasks : []}
          calendarRef={calendarRef}
        />
      </div>
    </div>
  )
}

export default CalenderTasks
