import { v4 as uuidv4 } from 'uuid';
import SkeletonBlock from './SkeletonBlock';

function GoodsDonationInfoSkeleton() {
  return (
    <>
      <div className="py-3 px-3 md:px-8 md:py-10 mb-6 bg-white rounded-regular shadow-faint w-full flex mx-auto flex-wrap h-[calc(100%-20px)]">
        <div className="h-7 mb-6 bg-gray-200 mt-3 w-[15rem] animate-pulse rounded-small md:mx-4" />
        <div className="grid w-full grid-cols-1 gap-6 mb-2 md:px-4 md:grid-cols-2 lg:grid-cols-3">
          {Array(5)
            .fill(null)
            .map(() => (
              <SkeletonBlock key={uuidv4()} />
            ))}
        </div>
        <div className="grid w-full grid-cols-1 gap-6 mt-3 mb-2 md:px-4 md:grid-cols-2 lg:grid-cols-3">
          <SkeletonBlock />
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between gap-4 md:flex-row">
        <div className="px-20 bg-gray-200 py-7 animate-pulse rounded-small" />
        <div className="bg-gray-200 px-28 py-7 animate-pulse rounded-small" />
      </div>
    </>
  );
}

export default GoodsDonationInfoSkeleton;
