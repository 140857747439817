import { useNavigate } from 'react-router-dom'
import PrimaryActionBtn from '../UI/PrimaryActionBtn'
import SecondaryActionBtn from '../UI/SecondaryActionBtn'
import RenderIf from '../UI/RenderIf'
import DeleteButton from '../UI/DeleteButton'

interface Props {
  isLoading: boolean
  btnText: string
  btnTextSecondary?: string
  onCancelHandler?: () => void
  onClickDeleteHandler?: () => void
  spinnerWidth?: string
  px?:string
  isEditButtonDisabled?: boolean,
  isDeleteButtonDisabled?: boolean
}

function FormButtons({
  isLoading, btnText, onCancelHandler,
  spinnerWidth, px, onClickDeleteHandler,
  btnTextSecondary, isEditButtonDisabled, isDeleteButtonDisabled
}:Props) {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col-reverse justify-end gap-4 md:flex-row">
      <RenderIf isTrue={!!(btnTextSecondary === 'delete')}>
        {/* eslint-disable-next-line max-len */}
        <DeleteButton btnText={btnTextSecondary} onClickDeleteHandler={onClickDeleteHandler} disabled={isDeleteButtonDisabled} />
      </RenderIf>
      <RenderIf isTrue={!(btnTextSecondary === 'delete')}>
        <SecondaryActionBtn btnText="Cancel" onClickHandler={() => (onCancelHandler ? onCancelHandler() : navigate(-1))} />
      </RenderIf>
      <PrimaryActionBtn disabled={isEditButtonDisabled} type="submit" btnText={btnText} isLoading={isLoading} spinnerWidth={spinnerWidth} px={px} />
    </div>
  )
}

export default FormButtons
