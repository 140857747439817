import { GrAttachment } from 'react-icons/gr'
import { useTranslation } from 'react-i18next'
import { NewslettersCtxTypes } from 'src/context/NewsletterCtx/newslettersCtx.interface'
import RenderIf from 'src/components/UI/RenderIf'
import FormTitle from './FormTitle'

interface Props {
  newslettersCtx: NewslettersCtxTypes
}

function EmailAttachmentsPreview({ newslettersCtx }:Props) {
  const { t } = useTranslation()
  return (
    <div>
      <FormTitle text="fileAttachments" />
      <div className="grid gap-2 mb-5 md:grid-cols-2 xl:grid-cols-3">

        <RenderIf isTrue={!!newslettersCtx.getter.newsletterFiles
          && newslettersCtx.getter.newsletterFiles.length > 0}
        >
          {Array.from(newslettersCtx.getter.newsletterFiles ?? [], (file, idx) => (
            <div key={idx} className="flex flex-wrap items-center gap-2 mb-2">
              <GrAttachment size={17} className="text-[#223367]" />
              <p className="whitespace-pre-line font-sans font-medium text-[#100899]">{file.name}</p>
            </div>
          ))}
        </RenderIf>

        <RenderIf isTrue={newslettersCtx.existingFiles.length > 0}>
          {newslettersCtx.existingFiles.map((file) => {
            const fileName = file.file?.split('/').at(-1)
            return (
              <div key={file.id + file.file} className="flex items-center gap-3 mb-2">
                <GrAttachment size={17} className="text-[#223367]" />
                <p className="whitespace-pre-line font-sans font-medium text-[#100899]">{fileName}</p>
              </div>
            )
          })}
        </RenderIf>

        <RenderIf isTrue={
          (!newslettersCtx.getter.newsletterFiles
           || (!!newslettersCtx.getter.newsletterFiles
              && newslettersCtx.getter.newsletterFiles.length === 0)
          )
          && newslettersCtx.existingFiles.length === 0
          }
        >
          <p className="mb-3 text-paragraph">{t('noAttachmentsFound')}</p>
        </RenderIf>
      </div>
    </div>
  )
}

export default EmailAttachmentsPreview
