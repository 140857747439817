import {
  useContext, useState, useMemo, useCallback
} from 'react'
import { NoteTypes } from 'src/interfaces/notes'
import { ChildrenTypes } from 'src/interfaces/children'
import scrollToTop from 'src/utils/scrollToTop'
import { ContactIdTypes, ModalContextTypes, ResourceIdTypes } from './modalsCtx.interface'
import modalsCtx from './modalsCtx'

export const useModalContext = (): ModalContextTypes => useContext(modalsCtx)

function ModalsProvider({ children }:ChildrenTypes) {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [modalText, setModalText] = useState<string | null>(null)
  const [resourceId, setResourceId] = useState<ResourceIdTypes | null>({
    id: null,
    userRole: null
  })
  const [contactId, setContactId] = useState<ContactIdTypes | null>({
    id: null,
    contactDepartmentType: null
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false)
  const [selectedNote, setSelectedNote] = useState<NoteTypes | undefined>(undefined)
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false)
  const [selectedTask, setSelectedTask] = useState<any | undefined>(undefined)

  const closeConfirmationModalHandler = useCallback(() => {
    setShowConfirmationModal(false)
    setModalText(null)
    setResourceId(null)
  }, [])

  const openConfirmationModalHandler = useCallback(
    (text:string, { id, userRole }: ResourceIdTypes) => {
      setModalText(text)
      setResourceId({ id, userRole })
      setShowConfirmationModal(true)
    },
    []
  )

  const openContactConfirmationModalHandler = useCallback(
    (text:string, { id, contactDepartmentType }: ContactIdTypes) => {
      setModalText(text)
      setContactId({ id, contactDepartmentType })
      setShowConfirmationModal(true)
    },
    []
  )

  const closeContactConfirmationModalHandler = useCallback(() => {
    setShowConfirmationModal(false)
    setModalText(null)
    setContactId(null)
  }, [])

  const openNoteModalHandler = useCallback((id?: number, note?: NoteTypes) => {
    scrollToTop()
    if (id !== undefined) {
      setShowNoteModal(true);
      setSelectedNote(note);
    } else {
      setShowNoteModal(true);
      setSelectedNote(undefined);
    }
  }, []);

  const closeNoteModalHandler = useCallback(() => {
    setShowNoteModal(false)
    setSelectedNote(undefined)
  }, [])

  const closeTaskModalHandler = useCallback(() => {
    setShowTaskModal(false)
    setSelectedTask(undefined)
  }, [])

  const openTaskModalHandler = useCallback((id?: number, task?: any) => {
    if (id !== undefined) {
      setShowTaskModal(true);
      setSelectedTask(task);
    } else {
      setShowTaskModal(true);
      setSelectedTask(undefined);
    }
  }, [])

  const ctx = useMemo(() => (
    {
      showConfirmationModal,
      showNoteModal,
      selectedNote,
      modalText,
      resourceId,
      isOpen,
      setIsOpen,
      contactId,
      closeConfirmationModalHandler,
      openConfirmationModalHandler,
      closeContactConfirmationModalHandler,
      openContactConfirmationModalHandler,
      closeNoteModalHandler,
      showTaskModal,
      selectedTask,
      openNoteModalHandler,
      openTaskModalHandler,
      closeTaskModalHandler
    }
  ), [
    showConfirmationModal,
    showNoteModal,
    selectedNote,
    modalText,
    resourceId,
    isOpen,
    setIsOpen,
    closeConfirmationModalHandler,
    openConfirmationModalHandler,
    closeNoteModalHandler,
    openNoteModalHandler,
    showTaskModal,
    selectedTask,
    openTaskModalHandler,
    closeTaskModalHandler,
    contactId,
    closeContactConfirmationModalHandler,
    openContactConfirmationModalHandler
  ]);

  return (
    <modalsCtx.Provider value={ctx}>
      {children}
    </modalsCtx.Provider>
  )
}

export default ModalsProvider
