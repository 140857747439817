import { ChildrenTypes } from 'src/interfaces/children'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import RenderIf from '../UI/RenderIf'

interface Props extends ChildrenTypes {
  marginBottom?: 'mb-6' | 'mb-0',
  marginTop?: 'mt-6' | 'mt-0',
  title?: string
  bgColor?: 'bg-white' | 'bg-fields',
  cursor?: 'cursor-pointer' | 'cursor-default',
  shadow?: 'none' | 'faint',
  px?: 'md:px-8' | 'md:px-10',
  py?: 'md:py-10' | 'md:py-5',
  toggleForm?: () => void,
  isExpandedForm?: boolean
}

function FormLayout({
  marginBottom = 'mb-6', marginTop = 'mt-6', cursor = 'cursor-default', children, title, bgColor = 'bg-white', shadow = 'faint', px = 'md:px-8', py = 'md:py-10', toggleForm, isExpandedForm
}:Props) {
  const { t } = useTranslation()
  return (
    <div className={`py-3 px-3 ${px} ${py} ${bgColor} rounded-regular shadow-${shadow} w-full flex flex-col mx-auto h-[calc(100%-20px)] mt-0 ${marginBottom} md:${marginTop}`}>
      <div role="presentation" className={`flex items-center justify-between ${cursor} group`} onClick={toggleForm}>
        <h2 className={`${title !== t('detailsDeptCharity') ? 'font-bold text-regular md:text-[1.375rem]' : 'text-regular'}  transition-all duration-200  md:mx-4 ${!isExpandedForm && title !== t('detailsDeptCharity') ? 'text-paragraph group-hover:text-mdm-navy-blue' : 'text-mdm-navy-blue'}`}>
          {title}
        </h2>
        <RenderIf isTrue={!!toggleForm}>
          {isExpandedForm
            ? <FiChevronUp size={22} className="mb-3 transition-all duration-200 md:mr-5 text-mdm-navy-blue" /> : <FiChevronDown size={22} className="transition-all duration-200 md:mr-5 group-hover:text-mdm-navy-blue text-paragraph" />}
        </RenderIf>
      </div>
      <RenderIf isTrue={!!isExpandedForm}>
        {children}
      </RenderIf>
    </div>
  )
}

export default FormLayout
