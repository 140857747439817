import ReactDOM from 'react-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import { useMailingList } from 'src/context/MailingListCtx/MailingListProvider'
import MailingListTable from './MailingListTable'

const SpecificClientsListModal = () => {
  const { t } = useTranslation()
  const mailingListCtx = useMailingList()
  const {
    resetFilters, contacts, contactsPaginated, setSearchTextContact
  } = useDataProvider()

  const onCloseModalHandler = () => {
    resetFilters()
    mailingListCtx.closeSpecificContactsListModalHandler()
    mailingListCtx.onClearSpecificContactsHandler()
  }

  useEffect(() => () => { setSearchTextContact('') }, [setSearchTextContact])

  const backdropClasses = mailingListCtx.showSpecificContactsListModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = mailingListCtx.showSpecificContactsListModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
  const overlays = document.getElementById('overlays') as HTMLElement

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={onCloseModalHandler} />
      <div className={` overflow-y-auto bg-white absolute xl:fixed top-8 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 z-50  max-w-[90%] max-h-[95dvh] xl:max-w-[1235px] w-full origin-center transition-all duration-300 ${modalClasses} rounded-2xl`}>
        <div className="flex items-center justify-between bg-mdm-blue text-white rounded-t-2xl px-[35px] py-[25px]">
          <h2 className="text-[1.8rem] md:text-2xl font-bold">{t('chooseSomeReceivers')}</h2>
        </div>
        <div className="flex flex-col gap-2 px-[35px] py-[25px] text-lg text-paragraph">
          <MailingListTable
            contacts={contacts}
            isHidden={contactsPaginated.isFetchingNextPage}
            selectedContacts={mailingListCtx.getter.specificMailingListContacts}
            onSelectHandler={mailingListCtx.onSpecificSelectContactHandler}
            onRemoveHandler={mailingListCtx.onRemoveSpecificContactHandler}
            onShowSelectedClientsHandler={mailingListCtx.showSpecificContactsListModalHandler}
          />
          <div className="w-full h-[1px] bg-[#BEBEBE] mt-4 hidden md:block" />
          <div>
            <div className="flex flex-col justify-end gap-5 mt-4 lg:flex-row">
              <button
                type="button"
                onClick={onCloseModalHandler}
                className="text-lg leading-6 rounded-[5px] px-[30px] py-4 text-mdm-blue text-regular hover:text-mdm-navy-blue hover:border-mdm-navy-blue transition-all duration-200 border border-mdm-blue  flex items-center justify-center gap-[2px]"
              >
                {t('Cancel')}
              </button>
              <button
                type="button"
                className="w-full lg:w-auto text-regular leading-6 rounded-[5px] px-[30px] py-4 hover:bg-mdm-navy-blue transition-all duration-200 text-white bg-mdm-blue "
                onClick={mailingListCtx.closeSpecificContactsListModalHandler}
              >
                {t('save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    overlays
  )
}

export default SpecificClientsListModal
