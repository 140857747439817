import { DonationTypes } from 'src/interfaces/donations'
import { useTranslation } from 'react-i18next'
import FormLayout from 'src/components/Form/FormLayout'
import Detail from 'src/components/UI/Detail'
import formattedDate from 'src/utils/formattedDate'
import RenderIf from 'src/components/UI/RenderIf'

interface Props {
  donation?: DonationTypes
}

function DonationDetails({ donation }: Props) {
  const { t } = useTranslation()
  const print = 'print:grid-cols-3'

  return (
    <FormLayout title={t('donationDetails')} isExpandedForm marginTop="mt-0">
      <RenderIf isTrue={!!(donation?.type === t('financial'))}>
        <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 w-full md:px-4 mt-4 ${print}`}>
          <Detail label="donorName" text={donation?.donor_name || ''} />
          <Detail label="type" text={t(donation?.type === t('financial') ? 'financialDonation' : '')} />
          <Detail label="amount" text={donation?.amount || ''} />
          <Detail label="invoiceNumber" text={donation?.invoice_number || ''} />
          <Detail label="invoiceIssueDate" text={formattedDate(donation?.invoice_issue_date) || ''} />
          <Detail label="depositAccount" text={donation?.deposit_account || ''} />
          <Detail label="dateOfDeposit" text={formattedDate(donation?.date_of_deposit) || ''} />
          <Detail label="bank" text={donation?.bank || ''} />
        </div>
        <div className="grid w-full grid-cols-1 mt-4 md:mx-4">
          <Detail label="observations" text={donation?.notes || '-'} ellipsis="line-clamp-3 text-ellipsis" />
        </div>
      </RenderIf>
      <RenderIf isTrue={!!(donation?.type === t('goods'))}>
        <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full md:px-4 mt-4 ${print}`}>
          <Detail label="donorName" text={donation?.donor_name || ''} />
          <Detail label="type" text={donation?.type || ''} />
          <Detail label="kind" text={donation?.kind || ''} />
          <Detail label="subcategory" text={donation?.subcategory?.toLowerCase() || ''} />
          <Detail label="quantity" text={donation?.quantity?.toString() || ''} />
        </div>
        <div className="grid w-full grid-cols-1 mt-4 md:mx-4">
          <Detail label="observations" text={donation?.notes || '-'} ellipsis="line-clamp-3 text-ellipsis" />
        </div>
      </RenderIf>
    </FormLayout>
  )
}

export default DonationDetails
