import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import PageTitle from 'src/components/UI/PageTitle'
import PageSection from 'src/components/UI/PageSection'
import useGetData from 'src/hooks/useGetData'
import RenderIf from 'src/components/UI/RenderIf'
import DonationSkeleton from 'src/components/Skeletons/DonationSkeleton'
import DonationCreationForm from '../components/DonationCreationForm'

function UpdateGoodsDonation() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const options = [
    { value: 'goods', label: t('goodsDonation') }
  ];

  const { data: donation, isLoading: isDonationLoading } = useGetData<any>({
    queryKey: `donation-${params.id}`,
    queryFn: () => apiService.getGoodsDonation(params.id),
    disabled: !params.id
  })

  const { isLoading, mutate: updateGoodsDonation } = useMutateData(
    {
      key: ['donations', `donation-${params.id}`],
      mutationFn: apiService.updateGoodsDonation,
      successMessage: 'donationHasBeenUpdated'
    }
  )

  const submitFormHandler = (values: any) => {
    updateGoodsDonation(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `MDM CRM - ${t('updateDonation')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('updateDonation')} isDonationLoading={isDonationLoading} />
      <RenderIf isTrue={isDonationLoading}>
        <DonationSkeleton skeletonBlocks={3} />
      </RenderIf>
      <RenderIf isTrue={!isDonationLoading}>
        <DonationCreationForm
          selectedDonationType="goods"
          onSubmit={submitFormHandler}
          donation={donation}
          isLoading={isLoading}
          options={options}
        />
      </RenderIf>
    </PageSection>
  )
}

export default UpdateGoodsDonation
