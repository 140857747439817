import { useFormik, FormikProvider } from 'formik'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import FormLayout from 'src/components/Form/FormLayout'
import FormInput from 'src/components/Form/FormInput'
import UserMethods from 'src/utils/userMethods'
import validationSchema from 'src/utils/validationSchema'
import { UserTypes } from 'src/interfaces/user'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import FormButtons from 'src/components/Form/FormButtons'

function UpdateUserDetailsForm() {
  const { t } = useTranslation()
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const [authUser, setAuthUser] = useState(UserMethods.getUser());

  const togglePasswordVisibility = (name: string) => {
    if (name === 'newPassword') {
      setShowNewPassword(!showNewPassword);
    } else {
      setShowConfirmNewPassword(!showConfirmNewPassword);
    }
  }

  const { mutate: updateMe, isLoading } = useMutateData({
    // ?? 'user-${authUser?.user.admin_id} user-${authUser?.user.user_id}`
    key: ['users'],
    mutationFn: apiService.updateMe,
    successMessage: 'yourDetailsHaveBeenUpdated'
  })

  const formik = useFormik({
    initialValues: {
      id: authUser?.id ?? '',
      first_name: authUser?.user.first_name ?? '',
      last_name: authUser?.user.last_name ?? '',
      email: authUser?.user.email ?? '',
      user_type: authUser?.user.user_type ?? '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: () => validationSchema.updateUserDetailsForm(formik.values.password),
    onSubmit: (values) => {
      if (values.email.trim() === authUser?.user.email
        && values.first_name.trim() === authUser?.user.first_name
        && values.last_name.trim() === authUser?.user.last_name
        && values.user_type.trim() === authUser?.user.user_type
        && values.password.trim().length === 0
      ) {
        return
      }
      updateMe(values, {
        onSuccess: (data) => {
          const user = data as unknown as UserTypes
          UserMethods.saveUser(user)
          formik.values.password = ''
          formik.values.confirmPassword = ''
        }
      })
    }
  })

  useEffect(() => {
    const fetchAuthUser = async () => {
      const user = await UserMethods.getUser();
      setAuthUser(user);
    };
    fetchAuthUser();
  }, []);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <FormLayout isExpandedForm>
          <div className="flex flex-col items-center justify-center w-full">
            <div className="grid w-full grid-cols-1 lg:grid-cols-3 md:mt-[-1.5rem]">
              <FormInput
                width="w-full"
                inputId="first_name"
                name="firstName"
                getFieldProps={formik.getFieldProps('first_name')}
                errors={
            !!(formik.touched.first_name && formik.errors.first_name)
          }
                errorName={formik.errors.first_name}
              />
              <FormInput
                width="w-full"
                inputId="last_name"
                name="lastName"
                getFieldProps={formik.getFieldProps('last_name')}
                errors={
            !!(formik.touched.last_name && formik.errors.last_name)
          }
                errorName={formik.errors.last_name}
              />
              <FormInput
                width="w-full"
                inputId="email"
                name="email"
                getFieldProps={formik.getFieldProps('email')}
                errors={!!(formik.touched.email && formik.errors.email)}
                errorName={formik.errors.email}
              />
            </div>
            <span className="hidden md:block w-[97.5%] mt-5 mb-7 bg-lines h-[1px]" />
            <div className="self-start mb-2 md:mx-4">
              <h2 className="text-[1.375rem] font-bold text-mdm-navy-blue mb-1">
                {' '}
                {t('changePassword')}
              </h2>
              <p className="text-[0.75rem] text-paragraph md:text-regular">
                {t('enterNewPasswordToChangePassword')}
              </p>
            </div>
            <div className="grid w-full grid-cols-1 lg:grid-cols-3">
              <FormInput
                togglePasswordVisibility={togglePasswordVisibility}
                showPassword={showNewPassword}
                type="password"
                width="w-full"
                inputId="password"
                name="newPassword"
                autoComplete="new-password"
                getFieldProps={formik.getFieldProps('password')}
                errors={
              !!(formik.touched.password && formik.errors.password)
            }
                errorName={formik.errors.password}
              />
              <FormInput
                togglePasswordVisibility={togglePasswordVisibility}
                showPassword={showConfirmNewPassword}
                type="password"
                width="w-full"
                inputId="confirmPassword"
                name="confirmNewPassword"
                autoComplete="new-password"
                getFieldProps={formik.getFieldProps('confirmPassword')}
                errors={
              !!(formik.touched.confirmPassword && formik.errors.confirmPassword)
            }
                errorName={formik.errors.confirmPassword}
              />
            </div>
          </div>
        </FormLayout>
        <FormButtons isLoading={isLoading} btnText="save" px="px-10" />
      </form>
    </FormikProvider>

  )
}

export default UpdateUserDetailsForm
