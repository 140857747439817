import axios, { AxiosError, AxiosResponse } from 'axios'
import { NoteCreationTypes, NoteTypes } from 'src/interfaces/notes'
import exception from 'src/utils/exception'
import { ServerError } from 'src/interfaces/api'
import axiosInstance from '../axiosInstance'
import { NotesServiceInterface } from './notesService.interface'

class NotesService implements NotesServiceInterface {
  public async getNotes(): Promise<NoteTypes[] | null> {
    try {
      const response = await axiosInstance.get('/auth/note/')
      const notes: NoteTypes[] = response.data.data

      notes.sort((a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime())

      return notes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }

  public async createNote(values: NoteCreationTypes): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.post('/auth/note/', values)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async editNote(values: NoteCreationTypes): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.patch(`/auth/note/${values.id}/`, {
        title: values.title,
        description: values.description
      })
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteNote({ id }: { id: number }): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.delete(`/auth/note/${id}/`)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default NotesService;
