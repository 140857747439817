import { useTranslation } from 'react-i18next'
import { HiOutlinePlus } from 'react-icons/hi'

interface Props {
  text: string
  onClickHandler: () => void
}

function CreateNewListBtn({ text, onClickHandler }: Props) {
  const { t } = useTranslation();
  return (
    <button onClick={onClickHandler} type="button" className=" text-paragraph text-sm whitespace-nowrap flex items-center gap-[9px] mb-3 mt-3">
      <span className="flex justify-center items-center rounded-lg text-mdm-blue bg-[#F1F2F2] w-[28px] h-[28px] hover:text-mdm-navy-blue transition-colors duration-200">
        <HiOutlinePlus />
      </span>
      {t(text)}
    </button>
  )
}

export default CreateNewListBtn
