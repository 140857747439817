import { UserTypes } from 'src/interfaces/user'

const userMethods = {
  saveUser: (user : UserTypes) => {
    localStorage.setItem('user', JSON.stringify(user))
  },
  getUser: () => {
    const user = localStorage.getItem('user')
    return user ? JSON.parse(user) as UserTypes : null
  },
  removeUser: () => {
    localStorage.removeItem('user')
  }
}

export default userMethods
