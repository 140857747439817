import ReactDOM from 'react-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik, FormikProvider } from 'formik'
import { toast } from 'react-toastify'
import FormButtons from 'src/components/Form/FormButtons'
import FormInput from 'src/components/Form/FormInput'
import { useMailingList } from 'src/context/MailingListCtx/MailingListProvider'
import validationSchema from 'src/utils/validationSchema'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import RenderIf from 'src/components/UI/RenderIf'
import Spinner from 'src/components/UI/Spinner'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import TableSkeleton from 'src/components/Skeletons/TableSkeleton'
import MailingListTable from './MailingListTable'
import UploadMailingList from './UploadMailingList'

interface Props {
  mailingListId: string
}

const MailingListModal = ({ mailingListId }:Props) => {
  const { t } = useTranslation()
  const [listFromFile, setListFromFile] = useState<File | undefined>(undefined)
  const mailingListCtx = useMailingList()
  const {
    contacts, contactsPaginated, setSearchTextContact, isContactsLoading, resetFilters
  } = useDataProvider()

  const { mutate: mutateMailingList, isLoading } = useMutateData({
    key: 'mailingList',
    mutationFn:
      mailingListCtx.getter.isEditMailingList
        ? apiService.updateMailingList
        : apiService.createMailingList,
    successMessage: mailingListCtx.getter.isEditMailingList ? 'mailingListHasBeenUpdated' : 'mailingListHasBeenCreated'
  })

  const { mutate: uploadMailingList, isLoading: isUploading } = useMutateData({
    key: 'mailingList',
    mutationFn: apiService.uploadMailingListFile,
    successMessage: 'mailingListHasBeenUploaded'
  })

  const { closeMailingListModalHandler } = mailingListCtx
  // Send mailing list file
  useEffect(() => {
    if (listFromFile) {
      uploadMailingList(listFromFile, {
        onSuccess: () => {
          setListFromFile(undefined)
          closeMailingListModalHandler()
          setListFromFile(undefined)
        },
        onError: () => {
          setListFromFile(undefined)
        }
      })
    }
  }, [
    listFromFile,
    closeMailingListModalHandler,
    uploadMailingList])

  const formik = useFormik({
    initialValues: {
      mailingListName: mailingListCtx.getter.mailingListName ?? ''
    },
    validationSchema: validationSchema.mailingListCreationForm,
    onSubmit: (values) => {
      if (mailingListCtx.getter.mailingListSelectedContacts.length === 0) {
        toast.warn<string>(t('noContactsSelected'))
        return
      }
      mutateMailingList({
        id: mailingListId,
        name: values.mailingListName,
        contacts: mailingListCtx.getter.mailingListSelectedContacts
      }, {
        onSuccess: () => {
          mailingListCtx.closeMailingListModalHandler()
          mailingListCtx.onClearMailingListSelectedContactsHandler()
          formik.resetForm()
          setListFromFile(undefined)
        }
      })
    }
  })

  const { setFieldValue } = formik
  useEffect(() => {
    if (mailingListCtx.getter.isEditMailingList) {
      setFieldValue('mailingListName', mailingListCtx.getter.mailingListName)
    }
  }, [
    mailingListCtx.getter.mailingListName,
    mailingListCtx.getter.isEditMailingList,
    setFieldValue
  ])

  const backdropClasses = mailingListCtx.showMailingListModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = mailingListCtx.showMailingListModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
  const overlays = document.getElementById('overlays') as HTMLElement

  const onCancelHandler = () => {
    mailingListCtx.closeMailingListModalHandler()
    resetFilters()
  }

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div
        className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`}
        onClick={onCancelHandler}
      />
      <div className={`overflow-y-auto bg-white absolute xl:fixed top-8 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 z-50  max-w-[90%] max-h-[95dvh] xl:max-w-[1235px] w-full origin-center transition-all duration-300 ${modalClasses} rounded-2xl`}>
        <div className="flex items-center justify-between bg-mdm-blue text-white rounded-t-2xl px-[35px] py-[25px]">
          <h2 className="text-[1.75rem] md:text-2xl font-bold">{t(mailingListCtx.getter.isEditMailingList ? 'editList' : 'createNewList')}</h2>
        </div>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6 px-[35px] py-[25px] text-lg text-paragraph">
            <div className="flex flex-col gap-3 sm:items-center sm:flex-row sm:mb-2">
              <FormInput
                inputId="emailListName"
                name="emailListName"
                width="lg:w-1/2"
                marginX="mx-0"
                marginY="my-0"
                getFieldProps={formik.getFieldProps('mailingListName')}
                errors={!!(formik.touched.mailingListName && formik.errors.mailingListName)}
                errorName={formik.errors.mailingListName}
              />
              <RenderIf isTrue={isUploading}>
                <div className="sm:translate-y-[15px] flex items-center gap-3 w-max my-2">
                  <Spinner />
                  <p className="text-sm whitespace-nowrap">{t('uploadingFile')}</p>
                </div>
              </RenderIf>
              <RenderIf isTrue={!isUploading}>
                <div className="flex sm:items-center gap-3 sm:translate-y-[15px]">
                  <p className="hidden text-sm text-[#4D4D4D] sm:block">{t('or')}</p>
                  <UploadMailingList setListFromFile={setListFromFile} />
                </div>
              </RenderIf>
            </div>
            <RenderIf isTrue={isContactsLoading
              || contactsPaginated.isFetchingNextPage
              || contactsPaginated.isFetchingPreviousPage}
            >
              <TableSkeleton />
            </RenderIf>
            <RenderIf isTrue={!isContactsLoading}>
              <MailingListTable
                contacts={contacts}
                isHidden={contactsPaginated.isFetchingNextPage}
                selectedContacts={mailingListCtx.getter.mailingListSelectedContacts}
                onSelectHandler={mailingListCtx.onSelectContactHandler}
                onRemoveHandler={mailingListCtx.onRemoveContactHandler}
                onShowSelectedClientsHandler={mailingListCtx.showSelectedContactsModalHandler}
              />
            </RenderIf>

            <div className="w-full h-[1px] bg-[#BEBEBE] mt-4" />
            <FormButtons
              isLoading={isLoading}
              btnText="save"
              px="px-7"
              onCancelHandler={onCancelHandler}
            />
          </form>
        </FormikProvider>
      </div>
    </>,
    overlays
  )
}

export default MailingListModal
