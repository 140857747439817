const titles = [
  { value: 'MS.', label: 'Ms.' },
  { value: 'MR.', label: 'Mr.' },
  { value: 'DR.', label: 'Dr.' },
  { value: 'MRS.', label: 'Mrs.' },
  { value: 'ΚΥΡΙΟΣ.', label: 'Κύριος' },
  { value: 'ΚΥΡΙΑ.', label: 'Κυρία' },
  { value: 'ΔΡ.', label: 'Δρ.' }
];

const supportTypes = [
  { value: '', label: 'selectOneOption' },
  { value: 'DONATION', label: 'donation' },
  { value: 'GRANT', label: 'projectFunding' }
];

const banks = [
  { value: '', label: 'selectOneOption' },
  { value: 'ETE', label: 'ETE' },
  { value: 'ALPHA', label: 'ALPHA' },
  { value: 'ΠΕΙΡΑΙΩΣ', label: 'ΠΕΙΡΑΙΩΣ' },
  { value: 'EUROBANK', label: 'EUROBANK' }
];

const media = [
  { value: '', label: 'selectOneOption' },
  { value: 'TV', label: 'TV' },
  { value: 'SITE', label: 'Website' },
  { value: 'NEWSPAPER', label: 'newspaper' },
  { value: 'RADIO', label: 'radio' }
];

const institutionalPositions = [
  { value: '', label: 'selectOneOption' },
  { value: 'Donation', label: 'donation' },
  { value: 'Partnership', label: 'partnership' },
  { value: 'Managing Authority', label: 'managing authority' },
  { value: 'Supplier', label: 'supplier' },
  { value: 'External Associate', label: 'external associate' },
  { value: 'Supervisory Authority', label: 'supervisory authority' },
  { value: 'Subcontactor', label: 'subcontactor' }
];

const typesOfSponsor = [
  { value: '', label: 'selectOneOption' },
  { value: 'GREEK_STATE', label: 'GREEK STATE' },
  { value: 'CORPORATE_SOCIAL_RESPONSIBILITY_BODY', label: 'CORPORATE SOCIAL RESPONSIBILITY BODY' },
  { value: 'PRIVATE', label: 'PRIVATE' },
  { value: 'INTERNATIONAL_ORGANISATION', label: 'INTERNATIONAL ORGANISATION' },
  { value: 'EUROPEAN_ECONOMIC_AREA', label: 'EUROPEAN ECONOMIC AREA' },
  { value: 'CHARITABLE_FOUNDATION', label: 'CHARITABLE FOUNDATION' },
  { value: 'MUNICIPALITY', label: 'MUNICIPALITY' },
  { value: 'REGION', label: 'REGION' },
  { value: 'OTHER_COUNTRIES', label: 'OTHER COUNTRIES' }
]

const typesOfSupport = [
  { value: '', label: 'selectOneOption' },
  { value: 'DONATION', label: 'DONATION' },
  { value: 'PROGRAM_GRANT', label: 'PROGRAM GRANT' },
  { value: 'DONATION_IN_KIND', label: 'DONATION IN KIND' },
  { value: 'HUMAN_RESOURCES', label: 'HUMAN RESOURCES' },
  { value: 'COMMUNICATION_SERVICES', label: 'COMMUNICATION SERVICES' }
]

const sizesOfSupport = [
  { value: '', label: 'selectOneOption' },
  { value: 'CO-FINANCING', label: 'CO-FINANCING' },
  { value: 'TOTAL_FINANCING', label: 'TOTAL FINANCING' },
  { value: 'PARTIAL_FINANCING', label: 'PARTIAL FINANCING' }
]

const statusesOfSupport = [
  { value: '', label: 'selectOneOption' },
  { value: 'PROPOSAL', label: 'PROPOSAL' },
  { value: 'SUBMITTED', label: 'SUBMITTED' },
  { value: 'APPROVED', label: 'APPROVED' },
  { value: 'IMPLEMENTATION', label: 'IMPLEMENTATION' },
  { value: 'COMPLETED', label: 'COMPLETED' }
]

const statuses = [
  { value: '', label: 'selectOneOption' },
  { value: 'active', label: 'active' },
  { value: 'notActive', label: 'notActive' }
]

const departments = [
  { value: '', label: 'selectOneOption' },
  { value: 'Financial', label: 'financial' },
  { value: 'Volunteers', label: 'volunteer' },
  { value: 'Programs', label: 'program' },
  { value: 'Communication', label: 'communication' },
  { value: 'Charity', label: 'charity' }
]

export {
  statusesOfSupport,
  sizesOfSupport,
  typesOfSupport,
  typesOfSponsor,
  institutionalPositions,
  media,
  supportTypes,
  banks,
  titles,
  departments,
  statuses
};
