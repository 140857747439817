import { ChildrenTypes } from 'src/interfaces/children'

function TableSkeletonText({ bgColor = 'bg-gray-200', display = 'block' }) {
  return (
    <p className={`h-6 animate-pulse max-w-[150px] w-full ${bgColor} ${display} rounded-2xl`} />
  )
}

function TableSkeletonWrapper({ children }:ChildrenTypes) {
  return (
    <div className="flex justify-between gap-2 p-5 bg-gray-200 rounded-small h-max animate-pulse">
      {children}
    </div>
  )
}

function TableSkeleton() {
  return (
    <div className="w-full p-8 bg-white h-max rounded-2xl shadow-faint">
      <div className="flex flex-wrap items-center justify-between gap-8 p-5 px-4 py-5 mb-12 bg-gray-200 rounded-small animate-pulse md:p-5 md:flex-nowrap md:gap-5">
        <div className="bg-gray-100 min-w-[200px] md:max-w-[60%] lg:max-w-[50%] xl:max-w-[40%] w-full rounded-small h-10 animate-pulse flex-col md:flex" />
        <div className="flex items-center w-full gap-4 md:w-auto">
          <div className="w-24 h-10 bg-gray-100 rounded-small animate-pulse" />
          <div className="w-24 h-10 bg-gray-100 rounded-small animate-pulse" />
        </div>
      </div>
      <div className="w-full">
        <div className="flex justify-between gap-2 px-5 h-max">
          <TableSkeletonText />
          <TableSkeletonText />
          <TableSkeletonText />
          <TableSkeletonText display="hidden lg:block" />
          <TableSkeletonText display="hidden lg:block" />
          <TableSkeletonText display="hidden lg:block" />

        </div>
        <div className="flex flex-col gap-3 mt-5">
          <TableSkeletonWrapper>
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
          </TableSkeletonWrapper>
          <TableSkeletonWrapper>
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
          </TableSkeletonWrapper>
          <TableSkeletonWrapper>
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
          </TableSkeletonWrapper>
          <TableSkeletonWrapper>
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
          </TableSkeletonWrapper>
          <TableSkeletonWrapper>
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
            <TableSkeletonText bgColor="bg-gray-100" display="hidden lg:block" />
          </TableSkeletonWrapper>
        </div>
      </div>
      <div className="flex gap-3 p-6 mt-8 bg-gray-200 rounded-md animate-pulse">
        <TableSkeletonText bgColor="bg-gray-100" />
        <div className="flex justify-end w-full gap-3">
          <TableSkeletonText bgColor="bg-gray-100" />
          <TableSkeletonText bgColor="bg-gray-100" />
        </div>
      </div>
    </div>
  )
}

export default TableSkeleton
