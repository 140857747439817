/* eslint-disable max-len */
import Table from 'src/components/Table/Table'
import { GetTaskTypes } from 'src/interfaces/tasks'
import { useTranslation } from 'react-i18next'
import formattedDate from 'src/utils/formattedDate'
import { useMemo, useState } from 'react'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import TableActionBtns from 'src/components/Table/TableActionBtns'
import apiService from 'src/services/api/apiService'
import useMutateData from 'src/hooks/useMutateData'
import { Column } from 'react-table'

interface Props {
  tasks: GetTaskTypes[]
  isDashboardTable?: boolean
}

interface OriginalTypes {
  cell: { row: { original: { id: number } } }
}

function TaskListTable({ tasks, isDashboardTable }: Props) {
  const { t } = useTranslation()
  const { departments } = useDataProvider()
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const handleCheckboxClick = (rowId: string) => {
    setSelectedRows((prevOptions) => {
      if (prevOptions.includes(rowId)) {
        return prevOptions.filter((option) => option !== rowId)
      }
      return [...prevOptions, rowId]
    })
  }

  const { mutate: deleteTaskMutate } = useMutateData({
    key: 'tasks',
    mutationFn: apiService.deleteTask,
    successMessage: 'taskSuccessfullyDeleted'
  })

  const tasksColumns = useMemo(
    () => [
      {
        accessor: 'selected',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: { row: any }) => (
          <input
            type="checkbox"
            value="checkbox"
            className="custom-checkbox"
            onClick={(e) => {
              e.stopPropagation()
              handleCheckboxClick(row.original.id)
            }}
          />
        )
      },
      {
        Header: t('title'),
        accessor: 'title',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value }: { value: string }) => <p title={value}>{value}</p>
      },
      {
        Header: t('taskCreator'),
        accessor: (row: any) =>
          `${row.created_by.first_name} ${row.created_by.last_name}`,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: any) => (
          <p>{`${row.original.created_by.first_name} ${row.original.created_by.last_name}`}</p>
        )
      },
      {
        Header: t('assignee'),
        accessor: (row: any) =>
          row.assignee.map((user: any) => {
            if (user.admin) {
              return `${user.admin.user.first_name} ${user.admin.user.last_name}`
            }
            if (user.agent) {
              return `${user.agent.user.first_name} ${user.agent.user.last_name}`
            }
            return ''
          }),
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value }: { value: string[] }) => (
          // eslint-disable-next-line max-len
          <div className="flex flex-col gap-3 lg:flex-row">
            {value.map((assignee) => (
              <div className="flex gap-3">
                <span className="flex px-2 py-1 items-center justify-center w-10 h-10 -mt-[6px] rounded-full text-regular bg-mdm-sky-blue text-mdm-blue">
                  {assignee
                    .split(' ')
                    .map((word) => word.charAt(0))
                    .join('')}
                </span>
                <div className="flex gap-1">
                  <p className="whitespace-nowrap">{assignee}</p>
                </div>
              </div>
            ))}
          </div>
        )
      },
      {
        Header: t('status'),
        accessor: (row: any) => `${t(row.status.toLowerCase())}`,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: { row: any }) => (
          <div
            title={t(row.original.status.toLowerCase())}
            className={`p-[11px] max-w-[132px] text-xs text-center rounded-[12px] font-semibold whitespace-nowrap ${
              // eslint-disable-next-line no-nested-ternary
              row.original.status.toLowerCase() === 'in_progress'
                ? 'bg-mdm-sky-blue text-mdm-blue'
                : row.original.status.toLowerCase() === 'completed'
                ? 'bg-[#00AF9C40] text-[#00AF9C]'
                : 'bg-[#bd11173e] text-[#BD1118]'
            }`}
          >
            <p>{t(row.original.status.toLowerCase())}</p>
          </div>
        )
      },
      {
        Header: t('deadlineShort'),
        accessor: (row: any) => `${formattedDate(row.end_time)}`,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: { row: any }) => {
          const currentTime = new Date()
          const endTime = new Date(row.original.end_time)
            .toISOString()
            .split('T')[0]
          const isPassed = endTime < currentTime.toISOString().split('T')[0]
          return (
            <div className="flex items-center gap-1">
              <span
                className={`${
                  isPassed ? 'bg-[#E9511C] h-4 w-4 rounded-full' : ''
                }`}
              />
              <p className={`${isPassed ? 'text-[#E9511C]' : ''}`}>
                {formattedDate(row.original.end_time)}
              </p>
            </div>
          )
        }
      },
      {
        Header: t('notes'),
        accessor: 'notes',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value }: { value: string }) => (
          <p title={value}>{value || '-'}</p>
        )
      },
      {
        Header: t('actions'),
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          cell: {
            row: { original }
          }
        }: OriginalTypes) => (
          <TableActionBtns
            onClickHandlerDelete={(e) => {
              e.stopPropagation()
              deleteTaskMutate({
                id: original.id
              })
            }}
            storageKey="tasksListTable"
            // @ts-ignore
            taskStatus={original.status}
            // @ts-ignore
            taskCreatorId={original.created_by.id}
            // @ts-ignore
            taskAssignees={original.assignee}
          />
        )
      }
    ],
    [t, deleteTaskMutate, departments]
  )

  let columns: Column<any>[] = []
  if (isDashboardTable) {
    columns = tasksColumns.filter((col) => col.Header !== t('status'))
  } else {
    columns = tasksColumns
  }

  return (
    <Table
      columns={columns}
      data={tasks}
      storageKey="tasksListTable"
      rowsPerPage={6}
      selectedRows={selectedRows}
      isDashboardTable={isDashboardTable}
    />
  )
}

export default TaskListTable
