import { useTranslation } from 'react-i18next'
import { BsFilter } from 'react-icons/bs'

interface Props {
  onClickHandler: () => void
}

function TableFilterButton({ onClickHandler }:Props) {
  const { t } = useTranslation()
  return (
    <button
      onClick={onClickHandler}
      type="button"
      className={'flex items-center gap-2 px-4 py-3 text-sm bg-white border border-solid py-small border-lines rounded-small text-paragraph disabled:text-paragraph/70 min-w-[50px] lg:min-w-[125px]'}
      title={t('filters')}
    >
      <BsFilter size={21} className="flex-shrink-0" />
      <span className="hidden lg:block">{t('filters')}</span>
    </button>
  )
}

export default TableFilterButton
