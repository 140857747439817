import Input from 'react-phone-number-input/input'
import PhoneInput from 'react-phone-number-input'
import { useLocation } from 'react-router-dom'
import routes from 'src/constants/routes'
import 'react-phone-number-input/style.css'
import { FieldInputProps, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import RenderIf from '../UI/RenderIf'
import InputErrorMessage from '../UI/InputErrorMsg'

interface PropTypes {
  inputId: string;
  name: string;
  getFieldProps: FieldInputProps<any>;
  errors: boolean;
  errorName: string | undefined;
  width?: 'lg:w-1/3' | 'lg:w-1/2' | 'lg:w-full' | 'md:w-full' | 'w-full';
  marginX?: 'mx-4' | 'mx-2' | 'mx-0';
  marginY?: 'my-4' | 'my-2' | 'my-0';
}

function FormPhoneInput({
  inputId,
  name,
  getFieldProps,
  errors,
  errorName,
  width = 'lg:w-1/3',
  marginX = 'mx-4',
  marginY = 'my-2',
  ...restProps
}: PropTypes) {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext();

  const handlePhoneNumberChange = (value: string | undefined) => {
    setFieldValue(getFieldProps.name, value || '');
  }
  const { pathname } = useLocation()
  const isExactPath = pathname.includes(routes.shared.createContact)
|| pathname.includes(routes.shared.updateContact)

  return (
    <div className={`relative text-sm md:text-regular w-full sm:w-full flex flex-col last:mb-0 ${width} ${marginY}`}>
      <label
        htmlFor={inputId}
        className={`md:${marginX} mb-2 text-paragraph whitespace-nowrap `}
      >
        {t(name)}
      </label>
      <RenderIf isTrue={!!isExactPath}>
        {' '}
        <PhoneInput
          international
          defaultCountry="GR"
          id={inputId}
          value={getFieldProps.value || ''}
          onChange={handlePhoneNumberChange}
          className={`mx-0 md:${marginX} text-black border py-3.5 px-4 rounded-small h-12 ${
            errors
              ? 'border-red-700 focus:border-red-700 focus:ring-0'
              : 'border-lines focus:border-mdm-blue focus:ring-0'
          }`}
        />

      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Input
          id={inputId}
          {...getFieldProps}
          {...restProps}
          value={getFieldProps.value || ''}
          onChange={handlePhoneNumberChange}
          className={`mx-0 md:${marginX} text-black border py-3.5 px-4 rounded-small h-12 ${
            errors
              ? 'border-red-700 focus:border-red-700 focus:ring-0'
              : 'border-lines focus:border-mdm-blue focus:ring-0'
          }`}
        />
      </RenderIf>

      <RenderIf isTrue={!!errors}>
        <div className={`md:${marginX} absolute w-full`}>
          <InputErrorMessage text={t(errorName!)} />
        </div>
      </RenderIf>
    </div>
  );
}

export default FormPhoneInput
