import { NavigationType, useLocation, useNavigationType } from 'react-router-dom'
import { useEffect } from 'react'

const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

const useScrollToTop = () => {
  const { pathname } = useLocation();
  const isPop = useBackButton();
  const scrollToTop = () => window.scrollTo(0, 0);
  useEffect(() => {
    scrollToTop()
  }, [pathname, isPop]);
}

export default useScrollToTop;
