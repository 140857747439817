import { ChildrenTypes } from 'src/interfaces/children'

function CampaignHeaderSteps({ children }: ChildrenTypes) {
  return (
    <header className="flex flex-col justify-between w-full gap-4 pb-3 mb-2 border-b md:pb-5 sm:flex-row sm:flex-wrap sm:items-center border-lines ">
      {children}
    </header>
  )
}

export default CampaignHeaderSteps
