import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import validationSchema from 'src/utils/validationSchema'
import InputErrorMessage from 'src/components/UI/InputErrorMsg'
import Spinner from 'src/components/UI/Spinner'
import RenderIf from 'src/components/UI/RenderIf'
import { ResetPasswordTypes } from 'src/interfaces/resetPassword'
import { FiChevronLeft } from 'react-icons/fi';

const initialValues = {
  email: ''
}
const inputDivClasses = 'relative flex flex-col w-[85%] md:w-3/4 mx-auto mt-8 text-paragraph'

interface Props {
  onSubmit: (values: ResetPasswordTypes) => void,
  isLoading: boolean
}

function ForgotPasswordForm({ onSubmit, isLoading }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.forgotPasswordForm,
    onSubmit
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={`${inputDivClasses} mb-4`}>
        <label htmlFor="email" className="flex flex-col gap-2 mb-2 font-sans text-paragraph">
          Email

          <input
            className={`border text-black py-3.5 px-4 rounded-small h-12 ${formik.errors.email && formik.touched.email ? 'border-red-700 focus:border-red-700 focus:ring-0 ' : 'border-lines focus:border-mdm-blue focus:ring-0 '
            }`}
            id="email"
            type="email"
            {...formik.getFieldProps('email')}
          />
        </label>
        <RenderIf isTrue={!!(formik.errors.email && formik.touched.email)}>
          <div className="ml-0.5 mt-1">
            <InputErrorMessage text={formik.errors.email!} />
          </div>
        </RenderIf>
      </div>
      <div className={inputDivClasses}>
        <button
          className="w-full p-4 mx-auto mt-4 mb-3 text-center font-[500] text-white duration-200 disabled:bg-mdm-navy-blue/80 disabled:cursor-not-allowed text-regular bg-mdm-blue xs:mt-6 rounded-small hover:bg-mdm-navy-blue transition-color"
          type="submit"
        >
          {isLoading ? <Spinner /> : t('resetPassword')}
        </button>
        <button
          className="text-regular font-[500] leading-6 rounded-small mt-3 mb-8 md:mb-14 px-[30px] py-4 bg-white border border-mdm-blue text-mdm-blue hover:border-mdm-navy-blue hover:text-mdm-navy-blue transition-colors duration-200 flex items-center justify-center gap-2"
          type="button"
          onClick={() => navigate(-1)}
        >
          <FiChevronLeft size={20} />
          {t('back')}
        </button>
      </div>
    </form>
  )
}
export default ForgotPasswordForm
