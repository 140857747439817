import { useTranslation } from 'react-i18next'

interface Props {
  text: string
  icon: JSX.Element
  onClickHandler: () => void
}

function DropdownItem({ icon, text, onClickHandler }:Props) {
  const { t } = useTranslation()
  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="flex items-center w-full gap-3 px-4 py-3 font-sans transition-all duration-200 bg-white border-t border-t-lines/50 hover:bg-fields last:rounded-b-md text-paragraph "
    >
      {icon}
      <span className="text-regular">{t(text)}</span>
    </button>
  )
}

export default DropdownItem
