/* eslint-disable jsx-a11y/no-static-element-interactions */
import timeGridPlugin from '@fullcalendar/timegrid'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import elLocale from '@fullcalendar/core/locales/el'
import { useEffect, useState } from 'react'
import moment from 'moment/moment'
import { useModalContext } from '../../context/ModalsCtx/ModalsProvider'
import { Assignee, TaskTypes } from '../../interfaces/tasks'

interface Props {
  tasks: any
  calendarRef?: any
}

interface TaskEvent {
  id: string,
  title: string;
  start: string;
  end: string;
  borderColor: string;
  allDay: boolean;
  backgroundColor: string;
  description: string;
}

function FullCalender({ tasks, calendarRef }:Props) {
  const { openTaskModalHandler } = useModalContext()
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleEventClick = (info:any) => {
    if (calendarRef.current) {
      const task = info.event.extendedProps.task as TaskTypes;
      openTaskModalHandler(task.id, task);
    }
  }

  // for medium and small screens (mobile devices, tablets, laptops)
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1280)
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // modify tasks in fullcalendar events
  const events: TaskEvent[] = tasks?.map((task: any) => {
    let borderColor = '';
    let backgroundColor;
    switch (task.status.toLowerCase()) {
      case 'completed':
        borderColor = '#00AF9C';
        break;
      case 'in_progress':
        borderColor = '#0065BD';
        break;
      case 'overdue':
        borderColor = '#BD1118';
        break;
      default:
        borderColor = 'transparent';
    }

    switch (task.calendar_type) {
      case 'MEETING_ROOM':
        backgroundColor = '#BDDFEC'
        break;
      default:
        backgroundColor = 'white';
    }

    const startTime = moment(task.start_time).utc(false).format('HH:mm:ss');
    const endTime = moment(task.end_time).utc(false).format('HH:mm:ss');
    const isAllDay = startTime === '00:00:00' && endTime === '23:59:59';

    return {
      id: task.id.toString(),
      title: task.title,
      start: moment(task.start_time).utc(false).format('YYYY-MM-DD HH:mm:ss'),
      end: moment(task.end_time).utc(false).format('YYYY-MM-DD HH:mm:ss'),
      description: task.notes,
      allDay: isAllDay,
      assignee: Array.isArray(task.assignee) ? task.assignee.map((a: Assignee) => a) : [],
      task,
      borderColor,
      backgroundColor
    };
  })

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      events={events}
      titleFormat={{
        month: 'long'
      }}
      dayHeaderFormat={{
        weekday: isSmallScreen ? 'short' : 'long'
      }}
      views={{
        timeGridWeek: {
          dayHeaderFormat: {
            weekday: 'short', month: '2-digit', day: '2-digit', year: '2-digit'
          }
        },
        timeGridDay: {
          dayHeaderFormat: {
            weekday: 'long', month: '2-digit', day: '2-digit', year: '2-digit'
          }
        }
      }}
      initialView="dayGridMonth"
      eventClassNames={['event-pointer']}
      headerToolbar={{
        start: `${isSmallScreen ? 'title,prev,next' : 'title,prev,next,dayGridMonth,timeGridWeek'}`,
        right: `${isSmallScreen ? 'dayGridMonth,timeGridWeek' : ''}`
      }}
      eventClick={handleEventClick}
      height="98vh"
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23'
      }}
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23'
      }}
      locales={[elLocale]}
      dayMaxEventRows
      dayMaxEvents
    />
  )
}

export default FullCalender
