import {
  Dispatch,
  SetStateAction,
  useEffect, useState
} from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { InfiniteActionPaginateTypes } from 'src/interfaces/contacts'
import { DebounceInput } from 'react-debounce-input'

interface PaginationProps {
  dataPageParam?: number,
  setDataPageParam?: Dispatch<SetStateAction<number>>,
  pageSize: number,
  pageIndex: number,
  pageLength: number,
  previousPage: () => void,
  canPreviousPage: boolean,
  nextPage: () => void,
  canNextPage: boolean,
  pageOptions: number[]
  gotoPage: (updater: (number | ((pageIndex: number) => number))) => void
  apiPagination?: InfiniteActionPaginateTypes
}

function Pagination({
  pageSize,
  pageIndex,
  pageLength,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageOptions,
  gotoPage,
  apiPagination,
  dataPageParam,
  setDataPageParam
}: PaginationProps) {
  const [pageNum, setPageNum] = useState<number>(pageIndex)
  const { t } = useTranslation()
  const paginatedContactsTotalPages = apiPagination?.totalContactPages;
  const pageNumName = (pageOptions.length
   || (paginatedContactsTotalPages && paginatedContactsTotalPages > 1))
    ? t('pages')
    : t('page');
  const borderClasses = 'border bg-white border-lines focus:border-mdm-blue focus:ring-0 rounded-small w-[30px] h-[30px] flex items-center justify-center hover:opacity-70 transition-opacity duration-200'
  const borderDisableClasses = 'disabled:border-lines/50'
  const fromCurrentPageSize = dataPageParam
    ? pageSize * (dataPageParam - 1) + 1 : pageSize * pageNum + 1
  const toCurrentPageSize = dataPageParam ? pageSize * (dataPageParam - 1) : pageSize * pageNum
  // Keep the page number in sync after state changes
  useEffect(() => {
    gotoPage(pageNum)
  }, [gotoPage, apiPagination, pageNum])

  return (
    <div className="flex text-[0.75rem] items-center md:text-sm flex-wrap justify-between p-3 md:p-5 md:mt-[1.13rem] bg-fields text-lines rounded-small">
      <p className="font-sans">
        {`${fromCurrentPageSize} - ${toCurrentPageSize + pageLength} 
        ${t('of')} ${paginatedContactsTotalPages || pageOptions.length} ${pageNumName}`}
      </p>
      <div className="flex items-center justify-between w-screen md:w-[400px] flex-wrap">
        <div className="flex items-center gap-3">
          <p className="font-sans whitespace-nowrap">{t('thePageYouAreOn')}</p>
          <DebounceInput
            type="number"
            value={dataPageParam ?? pageNum + 1}
            min={1}
            max={paginatedContactsTotalPages || pageOptions.length}
            debounceTimeout={apiPagination ? 500 : 0}
            onChange={(event) => {
              const page = event.target.value ? Number(event.target.value) : 0;

              const lastPage = apiPagination
                ? paginatedContactsTotalPages ?? 1
                : pageOptions.length ?? 1

              if (page > lastPage || page === 0 || page < 0) {
                return;
              }

              if (apiPagination && setDataPageParam) {
                setDataPageParam(page);
              } else {
                setPageNum(page - 1);
              }

              gotoPage(page);
            }}
            className="pagination-input border bg-white border-lines focus:border-mdm-blue focus:ring-0 rounded-small h-[30px] flex items-center justify-center py-1 px-3 w-[70px]"
          />
        </div>
        <div className="hidden md:block h-[30px] w-[1px] bg-[#B8BCBF59] mx-4" />
        <div className="flex gap-2">
          <button
            className={`${borderClasses} ${borderDisableClasses}`}
            type="button"
            onClick={() => {
              if (apiPagination && setDataPageParam) {
                setDataPageParam((prev) => prev - 1)
              } else {
                setPageNum((prev) => prev - 1)
                previousPage()
              }
            }}
            disabled={apiPagination?.hasPreviousPage ? false : !canPreviousPage}
          >
            <BsChevronLeft className={`${!canPreviousPage && !apiPagination?.hasPreviousPage ? 'text-[#E2E2E2]' : 'text-paragraph]'}`} />
          </button>
          <button
            className={`${borderClasses} ${apiPagination?.hasNextPage ? '' : borderDisableClasses}`}
            type="button"
            onClick={() => {
              if (apiPagination && setDataPageParam) {
                setDataPageParam((prev) => prev + 1)
              } else {
                setPageNum((prev) => prev + 1)
                nextPage()
              }
            }}
            disabled={apiPagination?.hasNextPage ? false : !canNextPage}
          >
            <BsChevronRight className={`${!canNextPage && !apiPagination?.hasNextPage ? 'text-[#E2E2E2]' : 'text-paragraph'}`} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Pagination
