/* eslint-disable max-len */
import {
  useMemo, useContext, useState, useCallback, ChangeEvent
} from 'react'
import { ChildrenTypes } from 'src/interfaces/children'
import tokenMethods from 'src/utils/tokenMethods'
import apiService from 'src/services/api/apiService'
import useGetData from 'src/hooks/useGetData'
import { useTranslation } from 'react-i18next'
import { NoteTypes } from 'src/interfaces/notes'
import { DonationTypes } from 'src/interfaces/donations'
import { UserInfoTypes } from 'src/interfaces/user'
import { GetTaskTypes } from 'src/interfaces/tasks'
import { DepartmentTypes } from 'src/interfaces/department'
import { useInfiniteQuery } from 'react-query'
import userMethods from 'src/utils/userMethods'
import useTableSorting from 'src/hooks/useTableSorting'
import dataProviderCtx from './dataProviderCtx'
import { DataProviderCtxTypes } from './dataProvider.interface'

export const useDataProvider = ():DataProviderCtxTypes => useContext(dataProviderCtx)

function DataProvider({ children }: ChildrenTypes) {
  const token = tokenMethods.getAccessToken()
  const [isEnabled, setIsEnabled] = useState<boolean>(!!token)
  const [searchTextContact, setSearchTextContact] = useState<string>('')
  const { t } = useTranslation();
  const authUser = userMethods.getUser();
  const [totalContactPages, setTotalContactPages] = useState<number | null>(null);
  const [totalCountOfContacts, setTotalCountOfContacts] = useState<number | null>(null);
  const [contactsPageParam, setContactsPageParam] = useState<number>(1);
  const [filteredDepartments, setFilteredDepartments] = useState<number[]>([])
  const [filteredMyContacts, setFilteredMyContacts] = useState<string>('')
  const [selectedContactsForExport, setSelectedContactsForExport] = useState<string[]>([]);

  const resetFilters = useCallback(() => {
    setContactsPageParam(1)
    setFilteredDepartments([])
    setFilteredMyContacts('')
    setSearchTextContact('')
    setSelectedContactsForExport([])
  }, [])

  const initialDepartments = useMemo(() => {
    if (authUser?.user.user_type === 'ADMINISTRATOR') {
      return [1, 2, 3, 4, 5];
    } if (authUser?.user.user_type === 'AGENT') {
      const agentDepartments = authUser.user.departments.map((department) => department.id) || [];
      return [...agentDepartments];
    }
    return [];
  }, [authUser?.user.user_type, authUser?.user.departments]);

  const departmentsToUse = filteredDepartments.length > 0 ? filteredDepartments : initialDepartments;

  const {
    sortText: sortTextContact,
    setSortText: setSortTextContact,
    onSortingOrderHandler: onSortingOrderContactHandler,
    onSortTextHandler: onSortTextContactHandler,
    sortingOrder: sortingOrderContact,
    setSortingOrder: setSortingOrderContact
  } = useTableSorting()

  // get notes
  const { data: notes, isLoading: isNotesLoading } = useGetData<NoteTypes[]>({
    queryKey: 'notes',
    queryFn: apiService.getNotes,
    disabled: !isEnabled
  })

  const {
    data,
    isLoading: isContactsLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetching,
    isFetchingPreviousPage,
    isFetchingNextPage
  } = useInfiniteQuery(
    ['contacts', searchTextContact, contactsPageParam, sortTextContact, sortingOrderContact, filteredMyContacts, filteredDepartments],
    () => {
      if (authUser?.user.user_type === 'ADMINISTRATOR') {
        if (searchTextContact) {
          return apiService.searchContact(searchTextContact, sortTextContact, sortingOrderContact, filteredMyContacts, departmentsToUse, contactsPageParam);
        }
        if (filteredDepartments && filteredDepartments.length > 0) {
          return apiService.getContactsPerDepartment(filteredDepartments, sortTextContact, sortingOrderContact, filteredMyContacts, contactsPageParam);
        }
        return apiService.getAllContacts(sortTextContact, sortingOrderContact, filteredMyContacts, contactsPageParam);
      } if (authUser?.user.user_type === 'AGENT') {
        if (searchTextContact) {
          return apiService.searchContact(searchTextContact, sortTextContact, sortingOrderContact, filteredMyContacts, departmentsToUse, contactsPageParam);
        }
        if (filteredDepartments && filteredDepartments.length > 0) {
          return apiService.getContactsPerDepartment(filteredDepartments, sortTextContact, sortingOrderContact, filteredMyContacts, contactsPageParam);
        }
        return apiService.getContactsPerDepartment(initialDepartments!, sortTextContact, sortingOrderContact, filteredMyContacts, contactsPageParam);
      }
      return undefined;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage?.previous?.split('=').at(-1) || undefined,
      getNextPageParam: (lastPage) => lastPage?.next?.split('=').at(-1) || undefined,
      enabled: isEnabled,
      refetchOnWindowFocus: false,
      onSuccess: (contacts) => {
        const lastPage = contacts.pages[contacts.pages.length - 1];
        setTotalContactPages(lastPage?.total_pages || null);
        setTotalCountOfContacts(lastPage?.count || null);
      },
      onError: () => {
        setContactsPageParam(1)
      }
    }
  );

  const contactsPaginated = useMemo(() => ({
    fetchNextPage,
    hasNextPage,
    hasPreviousPage,
    isFetching,
    fetchPreviousPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    totalContactPages,
    totalCountOfContacts
  }), [
    fetchNextPage,
    hasNextPage,
    hasPreviousPage,
    isFetching,
    fetchPreviousPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    totalContactPages,
    totalCountOfContacts
  ])

  const onSearchContactHandler = useCallback((event:ChangeEvent<HTMLInputElement>) => {
    setSearchTextContact(event.target.value.trim())
  }, [])

  const contacts = useMemo(() => {
    if (data && data.pages) {
      return data.pages
        .map((group) => (group?.results ?? []))
        .flat(1)
        .map((contact) => ({
          ...contact,
          department: {
            id: contact.department?.id,
            title: t(contact.department?.title.toLowerCase())
          }
        }));
    }
    return [];
  }, [data, t]);

  // get donations and translate
  const { data: donationsData, isLoading: isDonationsLoading } = useGetData<DonationTypes[]>({
    queryKey: 'donations',
    queryFn: apiService.getAllDonations,
    disabled: !isEnabled
  })

  const donations = useMemo(() => {
    if (donationsData) {
      return donationsData.map((donation) => ({
        ...donation,
        type: t(donation.type),
        kind: t(donation.kind?.toLowerCase() ?? '')
      }));
    }
    return [];
  }, [donationsData, t]);

  // get users
  const { data: users, isLoading: isUsersLoading } = useGetData<UserInfoTypes[]>({
    queryKey: 'users',
    queryFn: apiService.getUsers
  })

  // get tasks
  const { data: tasks, isLoading: isTasksLoading } = useGetData<GetTaskTypes[]>({
    queryKey: 'tasks',
    queryFn: () => apiService.getTasks()
  })

  // get departments
  const { data: departments, isLoading: isDepartmentsLoading } = useGetData<DepartmentTypes[]>({
    queryKey: 'departments',
    queryFn: apiService.getDepartments
  })

  const ctx = useMemo(
    () => ({
      notes,
      isNotesLoading,
      setIsEnabled,
      contacts,
      contactsPageParam,
      isContactsLoading,
      donations,
      isDonationsLoading,
      users,
      isUsersLoading,
      tasks,
      isTasksLoading,
      departments,
      isDepartmentsLoading,
      contactsPaginated,
      searchTextContact,
      setSearchTextContact,
      setContactsPageParam,
      onSearchContactHandler,
      initialDepartments,
      sortTextContact,
      setSortTextContact,
      onSortingOrderContactHandler,
      onSortTextContactHandler,
      sortingOrderContact,
      setSortingOrderContact,
      filteredDepartments,
      setFilteredDepartments,
      filteredMyContacts,
      setFilteredMyContacts,
      resetFilters,
      setSelectedContactsForExport,
      selectedContactsForExport
    }),
    [
      notes,
      isNotesLoading,
      setIsEnabled,
      contacts,
      isContactsLoading,
      contactsPageParam,
      donations,
      isDonationsLoading,
      users,
      isUsersLoading,
      tasks,
      isTasksLoading,
      departments,
      isDepartmentsLoading,
      setContactsPageParam,
      contactsPaginated,
      searchTextContact,
      onSearchContactHandler,
      setSearchTextContact,
      initialDepartments,
      sortTextContact,
      setSortTextContact,
      onSortingOrderContactHandler,
      onSortTextContactHandler,
      sortingOrderContact,
      setSortingOrderContact,
      filteredDepartments,
      setFilteredDepartments,
      filteredMyContacts,
      setFilteredMyContacts,
      resetFilters,
      setSelectedContactsForExport,
      selectedContactsForExport
    ]
  )

  return (
    <dataProviderCtx.Provider value={ctx}>
      {children}
    </dataProviderCtx.Provider>
  )
}

export default DataProvider
