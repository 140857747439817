import { useDropzone } from 'react-dropzone';
import {
  useMemo, CSSProperties, useState, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { GrUploadOption, GrAttachment } from 'react-icons/gr';
import { MdDelete } from 'react-icons/md'
import { FieldInputProps, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import useMutateData from 'src/hooks/useMutateData';
import apiService from 'src/services/api/apiService';
import RenderIf from 'src/components/UI/RenderIf';
import InputErrorMessage from '../UI/InputErrorMsg';

interface PropsTypes {
  inputId: string
  name: string
  getFieldProps: FieldInputProps<any>,
  mediaFiles?: any
}

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '25px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#B8BCBF',
  borderStyle: 'dashed',
  backgroundColor: 'white',
  color: '#929395',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
};

const focusedStyle = {
  borderColor: '#0065BD'
};

const acceptStyle = {
  borderColor: '#00AF9C'
};

const rejectStyle = {
  borderColor: '#BD1118'
};

function DragAndDropZone({
  inputId,
  name,
  mediaFiles
}: PropsTypes) {
  const [media, setMedia] = useState<File[]>(mediaFiles || []);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { id } = useParams();

  const { mutate: deleteWarehouseFileMutation } = useMutateData(
    {
      key: ['warehouses', `warehouse-${id}`, 'media'],
      mutationFn: apiService.deleteMedia,
      successMessage: 'fileHasBeenDeleted'
    }
  )

  useEffect(() => {
    if (media.length > 0) {
      formik.setFieldValue('media', media);
    }
  }, [media])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'text/plain': ['.txt'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.ms-excel': ['.xls']
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    multiple: true,
    onDrop: (acceptedFiles, fileRejections) => {
      const maxSize = 10 * 1024 * 1024; // max limit for file size 10MB
      const validFiles = acceptedFiles.filter((file) => file.size <= maxSize);
      const rejectedFiles = fileRejections.map((fileRejection) => fileRejection.file);

      if (rejectedFiles.length > 0) {
        const invalidFormats = rejectedFiles.map((file) => file.name).join(', ');
        const validFormats = ['.pdf', '.doc', '.txt', '.xls'].join(', ');
        setError(`${t('invalidFileFormat1')}: ${invalidFormats}. ${t('invalidFileFormat2')}: ${validFormats}`);
        setTimeout(() => {
          setError(null);
        }, 5000);
      } else if (validFiles.length === 0) {
        setError(t('fileSizeExceedsTheLimitOf10MB'));
        setTimeout(() => {
          setError(null);
        }, 5000);
      } else {
        setError(null);
        setMedia((prevFiles) => [...prevFiles, ...validFiles])
      }
    }
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const removeFile = (index: number) => {
    setMedia((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      formik.setFieldValue('media', newFiles);
      return newFiles;
    });
  };

  const fileList = media?.map((file: any, index: number) => {
    if (file && file.name) {
      const openFile = () => {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      };

      return (
        <li key={uuidv4()} className="flex items-center gap-2">
          <div className="flex items-center">
            <GrAttachment size={17} className="text-[#223367] mr-[6px]" />
            <button type="button" onClick={openFile} className="font-medium font-sans hover:underline text-[#100899]">
              {file.name}
            </button>
          </div>
          <MdDelete
            className="transition-colors duration-200 text-mdm-navy-blue hover:text-[#BD1118] cursor-pointer"
            onClick={() => removeFile(index)}
            size={21}
            title={t('deleteFile')}
          />
        </li>
      );
    }
    return null;
  });

  const fileListWithLinks = mediaFiles?.map((file: any) => {
    const fileName = file.file?.split('/').at(-1)

    const openFile = () => {
      window.open(file.file.replace('http', 'https'));
    };

    const removeFileFromServer = () => {
      if (file.id) {
        deleteWarehouseFileMutation(file.id);
      }
    };

    if (fileName) {
      return (
        <li key={uuidv4()} className="flex items-center gap-2">
          <button
            type="button"
            className="flex items-center gap-2"
            onClick={openFile}
          >
            <div className="flex items-center">
              <span className="hover:underline text-[#100899] font-medium font-sans">{fileName}</span>
            </div>
          </button>
          <MdDelete className="transition-colors duration-200 text-mdm-navy-blue hover:text-[#BD1118] cursor-pointer" size={21} onClick={removeFileFromServer} title={t('deleteFile')} />
        </li>
      );
    }
    return null;
  });

  return (
    <section className="flex flex-col gap-2 md:mx-4 mt-5 lg:mt-[25px] ">
      <p className="font-bold text-mdm-navy-blue text-[1.375rem] mb-1">{t(name)}</p>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} id={inputId} />
        <div className="flex items-center gap-2 text-xs">
          <div>
            <style>
              {`
                svg path {
                  stroke: #929395
                }
                `}
            </style>
            <div className="flex items-center w-5 h-5"><GrUploadOption color="#929395" size={17} /></div>
          </div>
          {t('dragAndDropSelectedFiles')}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between text-[0.69rem] text-[#CBD0D6]">
        <p>{t('supportedFileFormats')}</p>
        <p>{t('maxSize10MB')}</p>
      </div>

      <RenderIf isTrue={!!error}>
        <div className="absolute w-full mt-8">
          <InputErrorMessage text={error!} />
        </div>
      </RenderIf>
      <aside>
        {media.length > 0 || mediaFiles?.length > 0 ? (
          <>
            <h4 className="mt-5 mb-2 font-bold text-mdm-navy-blue">{t('filesAtt')}</h4>
            <ul className="flex flex-col gap-3">
              {fileList}
              {fileListWithLinks}
            </ul>
          </>
        ) : <p className="mt-3 text-paragraph">{t('noAttachmentsFound')}</p>}
      </aside>
    </section>
  );
}

export default DragAndDropZone;
