import { useEffect, useState } from 'react';
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider';

interface Props {
  isOpen: boolean;
}

interface User {
  user: {
    first_name: string;
    last_name: string;
  }
}

function UserIcon({ isOpen }: Props) {
  const [userInitials, setUserInitials] = useState<string>('')
  const { users } = useDataProvider()
  const storedUser = localStorage.getItem('user')

  useEffect(() => {
    if (storedUser) {
      const parsedUser: User = JSON.parse(storedUser)
      const initials = (parsedUser.user.first_name?.charAt(0)?.toUpperCase() || '')
+ (parsedUser.user.last_name?.charAt(0)?.toUpperCase() || '')
      setUserInitials(initials)
    }
  }, [storedUser, users])

  return (
    <div className={`flex items-center ml-4 justify-center w-10 h-10 border-2 border-mdm-sky-blue rounded-full text-mdm-white ${isOpen ? 'bg-mdm-navy-blue border-mdm-navy-blue' : 'bg-mdm-blue'}`}>
      <p className="text-sm font-semibold">{userInitials}</p>
    </div>
  );
}

export default UserIcon;
