/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useTable,
  useSortBy
} from 'react-table'
import { useNavigate } from 'react-router-dom'
import React, { Dispatch, SetStateAction, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TfiArrowsHorizontal } from 'react-icons/tfi'
import UserMethods from 'src/utils/userMethods'
import { StorageKeyTypes, SortingOrderTypes } from 'src/interfaces/table'
import ViewSelectedClientsBtn from 'src/pages/Common/pages/Newsletters/components/MailingList/ViewSelectedContactsBtn'
import { tableRowNavigation } from 'src/helpers/tableRowNavigation'
import {
  ContactTypes,
  InfiniteActionPaginateTypes
} from 'src/interfaces/contacts'
import underscoreToCamelCase from 'src/utils/underscoreToCamelCase'
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import { BsSortUp, BsSortDown } from 'react-icons/bs'
import { SelectedDonationsTypes } from 'src/interfaces/donations'
import SimpleBar from 'simplebar-react'
import Pagination from './Pagination'
import Search from './Search'
import RenderIf from '../UI/RenderIf'
import NoDataMessage from '../UI/NoDataMessage'
import TableExportBtn from './TableExportButton'
import SubTable from './SubTable'
import expandedRowCols from '../../helpers/expandedRowCols'
import departmentsAttributesMap from '../../helpers/departmentsAttributesMap'
import SearchFromApi from './SearchFromApi'
import TableColumnsConfigurationDropdown from './TableColumnsConfigurationDropdown'
import TableFilterDropdown from './TableFilterDropdown'

interface TableProps {
  columns: any
  subtablesData?: any
  subTableColumns?: any
  data: any
  storageKey: StorageKeyTypes
  rowsPerPage?: number
  selectedRows?: any
  selectedContacts?: ContactTypes[]
  onShowSelectedClientsHandler?: () => void
  isHidden?: boolean
  subTableData?: any
  searchText?: string
  apiPagination?: InfiniteActionPaginateTypes
  sortText?: string
  sortingOrder?: SortingOrderTypes
  onSortTextHandler?: (sortText: string) => void
  onSortingOrderHandler?: () => void
  sortingHeadersNames?: (header: string) => string | null
  dataPageParam?: number
  setDataPageParam?: Dispatch<SetStateAction<number>>
  contactsDepartmentsFilter?: {
    value?: number
    label?: string
  }[]
  selectedDonationsForExport?: SelectedDonationsTypes
  isDashboardTable?: boolean
}

export default function Table({
  data,
  columns,
  selectedRows,
  storageKey,
  rowsPerPage = 5,
  selectedContacts = [],
  onShowSelectedClientsHandler,
  isHidden = false,
  apiPagination,
  sortText,
  sortingOrder,
  onSortTextHandler,
  onSortingOrderHandler,
  sortingHeadersNames,
  dataPageParam,
  setDataPageParam,
  contactsDepartmentsFilter,
  selectedDonationsForExport,
  isDashboardTable
}: TableProps) {
  const authUser = UserMethods.getUser()
  const complaintCreatorColumn =
    authUser?.user.user_type !== 'ADMINISTRATOR' ? 'creator' : ''
  const initialColumns = JSON.parse(localStorage.getItem(storageKey)!) ?? []
  const navigate = useNavigate()
  const tableRef = useRef<HTMLTableElement>(null)
  const { t } = useTranslation()
  const { openTaskModalHandler } = useModalContext()
  const { onSearchContactHandler } = useDataProvider()

  const isContactsTable = storageKey === 'contactsTable'
  const isDonationsTable = storageKey === 'donationsTable'
  const isMailingListTable = storageKey === 'mailingListTable'

  // backend contacts sorting
  const isRemoteSorting = isContactsTable || isMailingListTable

  const withLocalSorting = [
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  ]
  const withRemoteSorting = [useGlobalFilter, useExpanded, usePagination]
  const tablePlugins = isRemoteSorting ? withRemoteSorting : withLocalSorting

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    rows,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    setGlobalFilter,
    allColumns,
    toggleRowExpanded,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: rowsPerPage,
        hiddenColumns: [...initialColumns, complaintCreatorColumn]
      }
    },
    ...tablePlugins
  )
  const disablePrint = 'print:hidden'
  const print = 'print:m-0 print:p-0 print:overflow-x-visible print:text-[10px]'

  // export table in svc format
  // translates table headers in greek and modify some data
  const modifiedTableHeadersForExport = data.map((item: any) => {
    const modifiedItem: { [key: string]: any } = {}
    if (typeof item === 'object' && item !== null) {
      Object.entries(item).forEach(([key, value]) => {
        if (key === 'is_active') {
          modifiedItem[t(underscoreToCamelCase(key))] = value
            ? t('ACTIVE')
            : t('INACTIVE')
        } else if (key === 'is_donation') {
          modifiedItem[t(underscoreToCamelCase(key))] = value
            ? t('yes')
            : t('no')
        } else if (key === 'media' || key === 'files') {
          modifiedItem[t(underscoreToCamelCase(key))] = (value as any)?.length
        } else if (key === 'departments') {
          modifiedItem[t(underscoreToCamelCase(key))] = (value as any)
            ?.map((v: any) => t(v.title?.toString()).toLowerCase())
            .join(',')
        } else if (key === 'department') {
          modifiedItem[t(underscoreToCamelCase(key))] = t(
            (value as any)?.title?.toLowerCase()
          )
        } else if (key === 'contact_type') {
          delete modifiedItem[t(underscoreToCamelCase(key))]
        } else if (key === 'tags') {
          modifiedItem[t(key)] = (value as any)
            ?.map((v: any) => t(v.title?.toString()).toLowerCase())
            .join(',')
        } else if (key === 'created_by') {
          delete modifiedItem[t(underscoreToCamelCase(key))]
        } else if (key === 'mailing_lists') {
          modifiedItem[t(underscoreToCamelCase(key))] = (value as any)
            .map((v: any) => v.name)
            .join(',')
        } else if (key === 'sent_dates') {
          if (Array.isArray(value)) {
            const sentDates = (value as any).map(
              (date: { id: number; sent_date: string }) => date.sent_date
            )
            modifiedItem[t(underscoreToCamelCase(key))] = sentDates.join(',')
          }
        } else if (key === 'specific_contacts') {
          if (Array.isArray(value)) {
            const contactNames = value.map(
              (contact: any) =>
                `${contact.title} ${contact.first_name} ${contact.last_name}`
            )
            modifiedItem[t(underscoreToCamelCase(key))] = contactNames.join(',')
          }
        } else if (key === 'assignee') {
          if (Array.isArray(value)) {
            const names = value.map((assignee: any) => {
              if (assignee && assignee.admin && assignee.admin.user) {
                return `${assignee.admin.user.first_name} ${assignee.admin.user.last_name}`
              }
              if (assignee && assignee.agent && assignee.agent.user) {
                return `${assignee.agent.user.first_name} ${assignee.agent.user.last_name}`
              }
              return ''
            })
            modifiedItem[t(underscoreToCamelCase(key))] = names.join(', ')
          }
        } else if (
          key !== 'agent_id' &&
          key !== 'admin_id' &&
          key !== 'auto_complete_status' &&
          key !== 'email_body'
        ) {
          modifiedItem[t(underscoreToCamelCase(key))] = value
        }
      })
    }
    return modifiedItem
  })

  // export selected rows
  // eslint-disable-next-line max-len
  const selectedRowsForExport = modifiedTableHeadersForExport.filter(
    (row: any) => selectedRows?.includes(row.id)
  )

  let tableDataForExport
  if (selectedRowsForExport.length === 0) {
    tableDataForExport = modifiedTableHeadersForExport
  } else {
    tableDataForExport = selectedRowsForExport
  }

  return (
    <div
      className={`${isHidden ? 'hidden' : 'block'} ${
        storageKey === 'warehousesTable' ||
        (storageKey === 'tasksListTable' && !isDashboardTable)
          ? 'mt-20 rounded-tr-2xl rounded-br-2xl rounded-bl-2xl'
          : 'rounded-2xl mt-3 md:mt-0'
      } bg-white w-full h-max ${
        isMailingListTable ? 'mt-0 shadow-none p-0' : 'shadow-faint p-0 md:p-8'
      } `}
    >
      <RenderIf isTrue={!!isDashboardTable}>
        <p className="font-bold text-mdm-navy-blue text-[1.38rem] px-[10px] my-4 lg:mt-0 lg:mb-5">
          {t('myTasks')}
        </p>
      </RenderIf>
      <div
        className={`${disablePrint} bg-fields flex justify-between items-center ${
          !isDashboardTable
            ? 'px-4 py-5 md:p-5 mb-3 md:mb-12'
            : 'px-[10px] mb-3 md:mb-5'
        } rounded-md  flex-wrap md:flex-nowrap gap-2 md:gap-5`}
      >
        <RenderIf isTrue={isContactsTable || isMailingListTable}>
          <SearchFromApi onChangeHandler={onSearchContactHandler!} />
        </RenderIf>
        <RenderIf isTrue={!isContactsTable && !isMailingListTable}>
          <Search setFilter={setGlobalFilter} />
        </RenderIf>
        <RenderIf isTrue={isMailingListTable}>
          <div className="flex items-center w-full sm:w-[80%] gap-3 sm:gap-5 flex-wrap">
            <RenderIf isTrue={isMailingListTable}>
              <ViewSelectedClientsBtn
                selectedContacts={selectedContacts}
                onClickHandler={onShowSelectedClientsHandler!}
              />
            </RenderIf>
          </div>
        </RenderIf>
        <div className="flex items-center w-full gap-4 md:w-auto">
          <RenderIf isTrue={!isMailingListTable}>
            <TableExportBtn
              tableDataForExport={tableDataForExport}
              isContactsTable={isContactsTable}
              isDonationsTable={isDonationsTable}
              isDashboardTable={isDashboardTable}
              selectedRowsForExport={selectedRows}
              selectedDonationsForExport={selectedDonationsForExport}
            />
          </RenderIf>
          <TableColumnsConfigurationDropdown
            allColumns={allColumns}
            storageKey={storageKey}
            isDashboardTable={isDashboardTable}
          />
          <RenderIf isTrue={isContactsTable}>
            <TableFilterDropdown
              contactsDepartmentsFilter={contactsDepartmentsFilter}
            />
          </RenderIf>
          <TfiArrowsHorizontal
            size={28}
            className="ml-auto text-center md:hidden text-lines"
          />
        </div>
      </div>
      <SimpleBar
        className={isDashboardTable ? 'h-[400px]' : 'max-h-[70vh]'}
        autoHide={false}
      >
        <table
          {...getTableProps()}
          className="w-full mx-2 md:mx-0 border-collapse min-w-[1200px] print:min-w-[400px] print:max-w-[1200px] text-paragraph text-regular"
          ref={tableRef}
        >
          <thead className="sticky top-0 z-10 bg-white">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="top-0">
                {headerGroup.headers.map((column, index) => {
                  const sortingText =
                    sortingHeadersNames?.(column.render('Header') as string) ??
                    null
                  const isSortableColumn = sortingText && isRemoteSorting
                  return (
                    <th
                      onClick={() => {
                        if (
                          isSortableColumn &&
                          onSortTextHandler &&
                          onSortingOrderHandler
                        ) {
                          onSortTextHandler(sortingText)
                          onSortingOrderHandler()
                        }
                      }}
                      className={`${print} text-mdm-navy-blue ${
                        sortingText !== null
                          ? 'cursor-pointer hover:text-mdm-navy-blue/70'
                          : ''
                      }  text-regular font-[500] border-b-2 border-b-lines pb-5 whitespace-nowrap 
                    ${
                      (index === 0 && storageKey !== 'mailingListTable') ||
                      (index === 2 && storageKey === 'contactsTable')
                        ? ''
                        : 'px-2 md:px-7'
                    } 
                    ${
                      (storageKey === 'contactsTable' ||
                        storageKey === 'usersTable' ||
                        storageKey === 'newslettersTable' ||
                        storageKey === 'tasksListTable') &&
                      index === headerGroup.headers.length - 1
                        ? 'text-right'
                        : 'text-left'
                    }`}
                      {...column.getHeaderProps(
                        !isRemoteSorting
                          ? column.getSortByToggleProps()
                          : undefined
                      )}
                    >
                      <span
                        className={`flex items-center gap-2 ${
                          (storageKey === 'newslettersTable' &&
                            index === headerGroup.headers.length - 1) ||
                          (storageKey === 'tasksListTable' &&
                            index === headerGroup.headers.length - 1)
                            ? 'justify-end'
                            : ''
                        }`}
                      >
                        {column.render('Header')}
                        {isRemoteSorting ? (
                          <span className="flex-shrink-0">
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {sortingText === sortText ? (
                              sortingOrder === 'desc' ? (
                                <BsSortDown size={25} />
                              ) : (
                                <BsSortUp size={25} />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        ) : (
                          <span className="flex-shrink-0">
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <BsSortDown size={25} />
                              ) : (
                                <BsSortUp size={25} />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        )}
                      </span>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <RenderIf isTrue={storageKey === 'contactsTable'}>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                // remove toggle from tr for charity contact
                const isCharityContact = row.cells.map(
                  (cell) => cell.value === t('charity')
                )
                const trueIndex = isCharityContact.findIndex(
                  (item) => item === true
                )
                // @ts-ignore
                return (
                  <React.Fragment key={row.getRowProps().key}>
                    <tr
                      className={`${
                        row.isExpanded ? 'bg-[#BDDFEC2E]' : ' hover:bg-fields'
                      } cursor-pointer transition-colors duration-200 border-b border-b-[#E9ECF2]`}
                      {...row.getRowProps()}
                      onClick={() => {
                        if (trueIndex === -1) {
                          toggleRowExpanded([row.id])
                        }
                      }}
                    >
                      {row.cells.map((cell) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                        <td
                          {...cell.getCellProps()}
                          className={`text-regular align-top font-sans text-paragraph py-3 md:py-6 h-[80px] ${
                            trueIndex !== -1 ? 'cursor-pointer' : ''
                          } ${
                            cell.column.id === 'expanded'
                              ? 'py-1 pr-2 flex justify-center text-center w-[50px]'
                              : cell.column.id === 'selected'
                              ? 'max-w-[20px] px-2'
                              : cell.column.id === t('person')
                              ? 'pr-2 md:pr-7'
                              : cell.column.id === t('status')
                              ? 'w-[100px]'
                              : cell.column.id === t('tags')
                              ? 'pl-2 md:pl-7'
                              : 'px-2 md:px-7'
                          }`}
                          onClick={() => {
                            // navigation for charity contact
                            if (trueIndex !== -1) {
                              navigate(
                                tableRowNavigation({
                                  key: storageKey,
                                  rowData: cell.row.original
                                })
                              )
                            }
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                    {row.isExpanded && (
                      <tr>
                        <td colSpan={row.cells.length}>
                          <SubTable
                            columns={expandedRowCols({
                              department: (row.original as any).department
                                .title,
                              t
                            })}
                            data={[
                              {
                                id: (row.original as any).id,
                                contact_type: (row.original as any)
                                  .contact_type,
                                // @ts-ignore
                                // eslint-disable-next-line max-len
                                ...(row.original as any)[
                                  // @ts-ignore
                                  departmentsAttributesMap[
                                    (row.original as any).department.title
                                  ]
                                ]
                              }
                            ]}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                )
              })}
            </tbody>
          </RenderIf>
          <RenderIf isTrue={storageKey !== 'contactsTable'}>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <tr
                    className="transition-colors duration-200 hover:bg-fields font-sans border-b border-b-[#E9ECF2] "
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell, index) => (
                      // eslint-disable-next-line max-len
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                      <td
                        // @ts-ignore
                        onClick={() =>
                          storageKey === 'tasksListTable'
                            ? openTaskModalHandler(
                                // @ts-ignore
                                cell.row.original.id,
                                cell.row.original
                              )
                            : storageKey !== 'newslettersTable'
                            ? navigate(
                                tableRowNavigation({
                                  key: storageKey,
                                  rowData: cell.row.original
                                })
                              )
                            : undefined
                        }
                        {...cell.getCellProps()}
                        className={`text-regular ${
                          storageKey !== 'newslettersTable' &&
                          storageKey !== 'mailingListTable'
                            ? 'cursor-pointer'
                            : ''
                        } align-top text-paragraph py-6 ${print} ${
                          rowsPerPage === 7 || isMailingListTable
                            ? 'h-[50px]'
                            : 'h-[80px]'
                        } ${
                          index === 0 && storageKey !== 'mailingListTable'
                            ? 'w-[10px] pr-[8px]'
                            : 'px-2 md:px-7'
                        }`}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </RenderIf>
        </table>
        <RenderIf isTrue={rows.length === 0}>
          <div
            className={`flex items-center justify-center ${
              isDashboardTable ? 'h-[300px]' : 'h-[30vh]'
            }`}
          >
            <NoDataMessage text="noEntriesFound" />
          </div>
        </RenderIf>
      </SimpleBar>
      <RenderIf isTrue={!isDashboardTable}>
        <Pagination
          pageLength={page.length}
          nextPage={nextPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          pageSize={pageSize}
          gotoPage={gotoPage}
          apiPagination={apiPagination}
          dataPageParam={dataPageParam}
          setDataPageParam={setDataPageParam}
        />
      </RenderIf>
    </div>
  )
}
