import { useLocation, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import routes from 'src/constants/routes'
import RenderIf from 'src/components/UI/RenderIf'
import TableSkeleton from 'src/components/Skeletons/TableSkeleton'
import Title from 'src/components/UI/Title'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'
import apiService from 'src/services/api/apiService'
import ContactsTable from './components/ContactsTable'

function Contacts() {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    contacts,
    isContactsLoading,
    contactsPaginated,
    contactsPageParam,
    setContactsPageParam,
    resetFilters
  } = useDataProvider()
  const isExactPath = pathname === routes.admin.contacts || pathname === routes.agent.contacts

  useEffect(() => {
    document.title = `MDM CRM - ${t('contacts')}`
  }, [t])

  useEffect(
    () => () => {
      resetFilters()
    },
    [resetFilters]
  )

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <section>
          <Title title="contacts" add="createContact" onClickHandler={() => navigate(routes.shared.createContact)} results={contactsPaginated.totalCountOfContacts} />
          <RenderIf isTrue={
            isContactsLoading
              || contactsPaginated.isFetchingNextPage
              || contactsPaginated.isFetchingPreviousPage
            }
          >
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isContactsLoading}>
            <ContactsTable
              contacts={contacts}
              isHidden={contactsPaginated.isFetchingNextPage}
              dataPageParam={contactsPageParam}
              setDataPageParam={setContactsPageParam}
            />
          </RenderIf>
        </section>
        <ConfirmationModal cacheKey={['contacts']} toastSuccessMessage={t('contactHasBeenDeleted')} mutationFn={apiService.deleteContact} />
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Outlet context={contacts} />
      </RenderIf>
    </>
  )
}

export default Contacts
