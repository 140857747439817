import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import PasswordForm from 'src/components/Form/PasswordForm'
import { CreatePasswordFormTypes } from 'src/interfaces/password-form'
import routes from 'src/constants/routes'
import logo from 'src/assets/images/mdm-logo-blue.png'

function ActivateAccount() {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `MDM CRM - ${t('activateAccount')}`
  }, [t])

  const [searchParams] = useSearchParams()
  const tokenParam = searchParams.get('token')
  const navigate = useNavigate()

  const { mutate: activateAccount, isLoading } = useMutateData({
    key: 'activateAccount',
    mutationFn: apiService.activateAccount,
    successMessage: 'accountActivated'
  })

  const onSubmit = (values: CreatePasswordFormTypes) => {
    activateAccount({ token: tokenParam!, password: values.password }, {
      onSuccess: () => {
        navigate(routes.login)
      }
    })
  }

  useEffect(() => {
    if (tokenParam) {
      localStorage.clear()
    }
  }, [tokenParam])

  if (!tokenParam) {
    return <Navigate to={routes.login} />
  }

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-full p-5">
      <img src={logo} className="mx-auto w-[8rem] h-[8rem] md:w-[12.3rem] md:h-[12.3rem]" alt="MdM Greece Logo" />
      <div className="mx-auto bg-white mt-14 max-w-[580px] rounded-regular shadow-faint w-full mb-5">
        <h1 className="w-[85%] mx-auto md:w-3/4 pt-8 md:pt-12 font-bold text-center text-[1.6rem] md:text-[1.875rem] text-mdm-blue">{`${t('activateAccount')}`}</h1>
        <p className="w-[85%] mx-auto md:w-3/4 mt-8 md:mb-10 text-sm text-center md:mx-16 md:text-regular text-paragraph">
          {t('enterNewsPassword')}
        </p>
        <PasswordForm onSubmit={onSubmit} isLoading={isLoading} btnText="activateAccount" />
      </div>
    </div>
  )
}

export default ActivateAccount
