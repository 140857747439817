import { useNavigate } from 'react-router-dom'
import PrimaryActionBtn from './PrimaryActionBtn'
import SecondaryActionBtn from './SecondaryActionBtn'
import RenderIf from './RenderIf'
import DraftButton from './DraftButton'

interface Props {
  type? : 'button' | 'submit'
  btnText?: string
  onClickHandler?: () => void
  onClickHandlerDraft?: () => void
  isPrimaryBtnLoading?: boolean
  isDraftBtnLoading?: boolean
  spinnerWidth?:string
  px?: string
}
function DetailsInfoBtns({
  btnText = '',
  type = 'button',
  onClickHandler,
  onClickHandlerDraft,
  isPrimaryBtnLoading = false,
  isDraftBtnLoading = false,
  spinnerWidth,
  px
}:Props) {
  const navigate = useNavigate()
  const disablePrint = 'print:hidden'

  return (
    <div className={`flex flex-col-reverse md:flex-row justify-between gap-5 mb-5 ${disablePrint}`}>
      <SecondaryActionBtn btnText="back" onClickHandler={() => navigate(-1)} />
      <RenderIf isTrue={!!onClickHandler}>
        <div className="flex flex-col items-center gap-5 md:flex-row">
          <RenderIf isTrue={!!onClickHandlerDraft}>
            <DraftButton
              type={type}
              onClickHandler={onClickHandlerDraft}
              isLoading={isDraftBtnLoading}
            />
          </RenderIf>
          <PrimaryActionBtn
            type={type}
            btnText={btnText!}
            onClickHandler={onClickHandler}
            isLoading={isPrimaryBtnLoading}
            spinnerWidth={spinnerWidth}
            px={px}
          />
        </div>
      </RenderIf>
    </div>
  )
}

export default DetailsInfoBtns
