const customStyles = (errors: boolean) => ({
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: 'white',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    paddingTop: ' 0.25rem',
    paddingBottom: '0.25rem',
    // eslint-disable-next-line no-nested-ternary
    borderColor: errors
      ? '#B91C1C'
      : state.isFocused
        ? '#0065bd'
        : '#B8BCBF',
    '@media (max-width: 768px)': {
      marginLeft: '0',
      marginRight: '0'
    }
  }),
  menu: (base: any) => ({
    ...base,
    width: '94%',
    marginLeft: '1rem',
    boxShadow: '0px 5px 20px #00000014'
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: '#F3F9FC',
    color: 'black'
  }),
  option: (base: any, state: any) => ({
    ...base,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: state.isFocused ? '#F3F9FC' : 'white',
    color: state.isFocused ? '#0065BD' : '#929395',
    cursor: 'pointer',
    fontSize: '1rem'
  }),
  clearIndicator: (base: any) => ({
    ...base,
    color: '#929395',
    cursor: 'pointer',
    ':hover': {
      color: '#0065BD'
    }
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    color: '#929395',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
      color: '#BD1118'
    }
  }),
  placeholder: (base: any, state: any) => ({
    ...base,
    color: state.isDisabled ? '#929395' : 'black',
    fontSize: '1rem'
  })
})

export default customStyles
