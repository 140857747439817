import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider } from 'formik';
import FormLayout from 'src/components/Form/FormLayout';
import FormInput from 'src/components/Form/FormInput';
import FormButtons from 'src/components/Form/FormButtons';
import validationSchema from 'src/utils/validationSchema';
import FormSelect from 'src/components/Form/FormSelect';
import DragAndDropZone from 'src/components/Form/DragAndDropZone';
import FormCheckbox from 'src/components/Form/FormCheckbox';

interface Props {
  onSubmit: (values: any) => void;
  isLoading: boolean;
  warehouse?: any;
}

interface FormValues {
  id: string;
  supplier: string;
  is_donation: boolean;
  kind: string;
  subcategory: string;
  location: string;
  import_date: string;
  import_quantity: number | null;
  export_date: string;
  export_quantity: number | null;
  registration_date: string;
  registration_quantity: number | null;
  disposition_to: string;
  media?: File[];
  program_entry: string;
}

function WarehouseCreationForm({ onSubmit, isLoading, warehouse }: Props) {
  const { t } = useTranslation();

  const initialValues: FormValues = {
    id: warehouse?.id || '',
    supplier: warehouse?.supplier || '',
    is_donation: warehouse?.is_donation || false,
    kind: warehouse?.kind || '',
    subcategory: warehouse?.subcategory || '',
    location: warehouse?.location || '',
    import_date: warehouse?.import_date || '',
    import_quantity: warehouse?.import_quantity || null,
    export_date: warehouse?.export_date || '',
    export_quantity: warehouse?.export_quantity || null,
    registration_date: warehouse?.registration_date || '',
    registration_quantity: warehouse?.registration_quantity || null,
    disposition_to: warehouse?.disposition_to || '',
    media: warehouse?.media || [],
    program_entry: warehouse?.program_entry || ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.warehouseCreationForm,
    onSubmit
  });

  const options = [
    { value: ' ', label: t('selectOneOption') },
    { value: 'MEDICINE', label: t('medicines') },
    { value: 'CONSUMABLE', label: t('consumables') },
    { value: 'MEDICAL_EQUIPMENT', label: t('medicalEquipment') },
    { value: 'IT_EQUIPMENT', label: t('ITequipment') },
    { value: 'PRODUCT', label: t('products') }
  ]

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <FormLayout title={t('registrationDetails')} isExpandedForm marginTop="mt-6">
          <div className="flex flex-col w-full xl:flex-row">
            <div className="flex flex-col w-full">
              <div className="grid w-full grid-cols-1 md:grid-col-2 lg:grid-cols-3">
                <div className="flex flex-col gap-1">
                  <FormInput
                    inputId="supplier"
                    name="supplier"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('supplier')}
                    errors={!!(formik.touched.supplier && formik.errors.supplier)}
                    errorName={formik.errors.supplier as string}
                  />
                  <div className="md:mx-4">
                    <FormCheckbox
                      inputId="is_donation"
                      name="donation"
                    />
                  </div>
                </div>
                <FormSelect
                  inputId="kind"
                  name="kind"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('kind')}
                  errors={!!(formik.touched.kind && formik.errors.kind)}
                  errorName={formik.errors.kind as string}
                >
                  {options?.map((option) => (
                    <option key={option.label} value={option?.value}>
                      {option.label}
                    </option>
                  ))}
                </FormSelect>
                <FormInput
                  inputId="subcategory"
                  name="subcategory"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('subcategory')}
                  errors={!!(formik.touched.subcategory && formik.errors.subcategory)}
                  errorName={formik.errors.subcategory as string}
                />
                <FormSelect
                  inputId="location"
                  name="location"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('location')}
                  errors={!!(formik.touched.location && formik.errors.location)}
                  errorName={formik.errors.location as string}
                >
                  <option value=" ">{t('selectOneOption')}</option>
                  <option value="athens">
                    {t('Greece')}
                    {' '}
                    (
                    {t('Athens')}
                    )
                  </option>
                  <option value="ukraine">
                    {t('Ukraine')}
                  </option>
                </FormSelect>
                <FormInput
                  inputId="program_entry"
                  name="program"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('program_entry')}
                  errors={!!(formik.touched.program_entry && formik.errors.program_entry)}
                  errorName={formik.errors.program_entry as string}
                />
                <FormInput
                  inputId="import_date"
                  type="date"
                  name="importDate"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('import_date')}
                  errors={!!(formik.touched.import_date && formik.errors.import_date)}
                  errorName={formik.errors.import_date as string}
                />
                <FormInput
                  inputId="import_quantity"
                  type="number"
                  name="importQuantity"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('import_quantity')}
                  errors={!!(formik.touched.import_quantity && formik.errors.import_quantity)}
                  errorName={formik.errors.import_quantity as string}
                />
                <FormInput
                  inputId="registration_date"
                  type="date"
                  name="registrationDate"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('registration_date')}
                  errors={!!(formik.touched.registration_date && formik.errors.registration_date)}
                  errorName={formik.errors.registration_date as string}
                />
                <FormInput
                  inputId="export_quantity"
                  type="number"
                  name="exportQuantity"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('export_quantity')}
                  errors={!!(formik.touched.export_quantity && formik.errors.export_quantity)}
                  errorName={formik.errors.export_quantity as string}
                />
                <FormInput
                  inputId="disposition_to"
                  name="disposition_to"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('disposition_to')}
                  errors={!!(formik.touched.disposition_to && formik.errors.disposition_to)}
                  errorName={formik.errors.disposition_to as string}
                />
                <FormInput
                  inputId="export_date"
                  type="date"
                  name="exportDate"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('export_date')}
                  errors={!!(formik.touched.export_date && formik.errors.export_date)}
                  errorName={formik.errors.export_date as string}
                />
                <FormInput
                  inputId="registration_quantity"
                  type="number"
                  name="registrationQuantity"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('registration_quantity')}
// eslint-disable-next-line max-len
                  errors={!!(formik.touched.registration_quantity && formik.errors.registration_quantity)}
                  errorName={formik.errors.registration_quantity as string}
                />
              </div>
              <DragAndDropZone
                mediaFiles={warehouse?.media}
                name="media"
                inputId="media"
                getFieldProps={formik.getFieldProps('media')}
              />
            </div>
          </div>
        </FormLayout>
        <FormButtons
          isLoading={isLoading}
          btnText={warehouse ? t('edit') : t('registration')}
          spinnerWidth="w-[8rem]"
        />
      </form>
    </FormikProvider>
  );
}

export default WarehouseCreationForm;
