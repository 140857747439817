import React from 'react';
import { FieldInputProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import RenderIf from '../UI/RenderIf';
import InputErrorMessage from '../UI/InputErrorMsg';

interface DateInputProps {
  inputId: string;
  getFieldProps: FieldInputProps<any>;
  errors: boolean;
  errorName: string | undefined;
  curDate?: string;
  disabled?: boolean;
  width: string
}

function FormTimeInput({
  inputId,
  getFieldProps,
  errors,
  errorName,
  width,
  disabled = false
}: DateInputProps) {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: nameInput, value } = event.target;
    formik.setFieldValue(nameInput, value);
  };

  return (
    <div className={`relative my-2 text-sm md:text-regular md:mt-5 md:mb-4 ${width}`}>
      <input
        id={inputId}
        type="time"
        {...getFieldProps}
        disabled={disabled}
        onChange={handleInputChange}
        value={getFieldProps.value || ''}
        className={`${disabled ? 'text-paragraph' : 'text-black'} border py-3.5 px-4 w-full rounded-small h-12 ${
          errors ? 'border-red-700 focus:border-red-700 focus:ring-0' : 'border-lines focus:border-mdm-blue focus:ring-0'
        }`}
      />
      <RenderIf isTrue={!!errors}>
        <div className="absolute -top-8 whitespace-nowrap">
          <InputErrorMessage text={t(errorName!)} />
        </div>
      </RenderIf>
    </div>
  );
}

export default FormTimeInput;
