import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import PageTitle from 'src/components/UI/PageTitle'
import PageSection from 'src/components/UI/PageSection'
import useGetData from 'src/hooks/useGetData'
import RenderIf from 'src/components/UI/RenderIf'
import ContactSkeleton from 'src/components/Skeletons/ContactSkeleton'
import ContactCreationForm from '../components/ContactCreationForm'

function UpdateContact() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params
  const [stringPart, numericPart] = id?.split('-') || []

  const { data: contact, isLoading: isContactLoading } = useGetData<any>({
    queryKey: `contact-${numericPart}`,
    queryFn: () => apiService.getContact(numericPart, stringPart),
    disabled: !numericPart || !stringPart
  })

  const { isLoading, mutate: updateContactMutation } = useMutateData({
    key: ['contacts', `contact-${numericPart}`],
    mutationFn: apiService.updateContact,
    successMessage: 'contactHasBeenUpdated'
  })

  const submitFormHandler = (values: any) => {
    updateContactMutation(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `MDM CRM - ${t('updateContact')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('updateContact')} />
      <RenderIf isTrue={isContactLoading}>
        <ContactSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isContactLoading}>
        <ContactCreationForm
          onSubmit={submitFormHandler}
          contact={contact}
          isLoading={isLoading}
        />
      </RenderIf>
    </PageSection>
  )
}

export default UpdateContact
