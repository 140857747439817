import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import validationSchema from 'src/utils/validationSchema'
import RenderIf from '../UI/RenderIf'
import { CreatePasswordFormTypes } from '../../interfaces/password-form'
import InputErrorMessage from '../UI/InputErrorMsg'
import Spinner from '../UI/Spinner'

const inputDivClasses = 'relative flex flex-col w-[85%] md:w-3/4 mx-auto mt-8 text-paragraph'

interface Props {
  onSubmit: (values:CreatePasswordFormTypes) => void,
  btnText: string,
  isLoading: boolean
}

function PasswordForm({ onSubmit, btnText, isLoading }: Props) {
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: validationSchema.activatePassword,
    onSubmit
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={`${inputDivClasses} mb-4`}>
        <label htmlFor="password" className="mb-2">
          {t('password')}
        </label>
        <input
          className={`border text-black py-3.5 px-4 rounded-small h-12 ${formik.errors.password && formik.touched.password ? 'border-red-700 focus:border-red-700 focus:ring-0 ' : 'border-lines focus:border-mdm-blue focus:ring-0 '
          }`}
          id="password"
          type="password"
          {...formik.getFieldProps('password')}
        />
        <RenderIf isTrue={!!(formik.errors.password && formik.touched.password)}>
          <div className="ml-0.5 mt-1">
            <InputErrorMessage text={formik.errors.password!} />
          </div>
        </RenderIf>
      </div>
      <div className={`${inputDivClasses} mb-3`}>
        <label htmlFor="email" className="mb-2">
          {t('confirmPassword')}
        </label>
        <input
          className={`border text-black py-3.5 px-4 rounded-small h-12 ${formik.errors.confirmPassword && formik.touched.confirmPassword ? 'border-red-700 focus:border-red-700 focus:ring-0' : 'border-lines focus:border-mdm-blue focus:ring-0 '
          }`}
          id="email"
          type="password"
          {...formik.getFieldProps('confirmPassword')}
        />
        <RenderIf isTrue={!!(formik.errors.confirmPassword && formik.touched.confirmPassword)}>
          <div className="ml-0.5 mt-1">
            <InputErrorMessage text={formik.errors.confirmPassword!} />
          </div>
        </RenderIf>
      </div>
      <div className={inputDivClasses}>
        <button
          className="w-full p-4 mx-auto mt-4 mb-8 text-center text-white duration-200 md:p-5 md:mb-14 disabled:bg-mdm-navy-blue/80 disabled:cursor-not-allowed text-regular bg-mdm-blue xs:mt-6 rounded-small hover:bg-mdm-navy-blue transition-color"
          type="submit"
        >
          {isLoading ? <Spinner /> : t(btnText)}
        </button>
      </div>
    </form>
  )
}
export default PasswordForm
