import {
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { ChildrenTypes } from '../../interfaces/children'
import { FileTypes, NewsletterGetterDataTypes } from '../../interfaces/newsletters'
import { NewslettersCtxTypes } from './newslettersCtx.interface'
import newslettersCtx from './newslettersCtx'

export const useNewsletters = ():NewslettersCtxTypes => useContext(newslettersCtx)

function NewslettersProvider({ children }: ChildrenTypes) {
  const [isEditNewsletter, setIsEditNewsletter] = useState<NewslettersCtxTypes['isEditNewsletter']>(false)
  const [selectedNewsletterId, setSelectedNewsletterId] = useState<NewsletterGetterDataTypes['selectedNewsletterId']>(null)
  const [campaignName, setCampaignName] = useState<NewsletterGetterDataTypes['campaignName']>(null)
  const [emailSubject, setEmailSubject] = useState<NewsletterGetterDataTypes['emailSubject']>(null)

  const [newsletterFiles, setNewsletterFiles] = useState<NewsletterGetterDataTypes['newsletterFiles']>(null)
  const [existingFiles, setExistingFiles] = useState<NewslettersCtxTypes['existingFiles']>([])

  const [emailTemplate, setEmailTemplate] = useState<NewsletterGetterDataTypes['emailTemplate']>(null)
  const [emailGreeting, setEmailGreeting] = useState<NewslettersCtxTypes['emailGreeting']>(null)
  const [content, setContent] = useState<NewslettersCtxTypes['content']>(null)
  const [searchValue] = useState<string>('')
  const [emailImage, setEmailImage] = useState<undefined | File>(undefined)
  const [emailImageUrl, setEmailImageUrl] = useState<null | FileTypes>(null)

  const setter = useMemo(() => ({
    setCampaignName,
    setEmailSubject,
    setContent,
    setEmailTemplate,
    setEmailGreeting,
    setNewsletterFiles,
    setEmailImage,
    setEmailImageUrl,
    setIsEditNewsletter,
    setExistingFiles,
    setSelectedNewsletterId
  }), []);

  const getter: NewsletterGetterDataTypes = useMemo(() => ({
    campaignName,
    emailSubject,
    emailTemplate,
    newsletterFiles,
    selectedNewsletterId
  }), [
    campaignName,
    emailSubject,
    emailTemplate,
    newsletterFiles,
    selectedNewsletterId
  ])

  // Reset newsletter data
  const onResetNewsletterHandler = useCallback(() => {
    setCampaignName(null)
    setEmailSubject(null)
    setContent(null)
    setEmailTemplate(null)
    setEmailGreeting(null)
    setNewsletterFiles(null)
    setEmailImage(undefined)
    setEmailImageUrl(null)
    setIsEditNewsletter(false)
    setExistingFiles([])
    setSelectedNewsletterId(null)
  }, [])

  const ctx = useMemo(() => ({
    content,
    emailGreeting,
    emailImage,
    emailImageUrl,
    isEditNewsletter,
    existingFiles,
    setter,
    searchValue,
    getter,
    onResetNewsletterHandler
  }), [
    content,
    emailGreeting,
    emailImage,
    emailImageUrl,
    existingFiles,
    isEditNewsletter,
    setter,
    getter,
    searchValue,
    onResetNewsletterHandler
  ])

  return (
    <newslettersCtx.Provider value={ctx}>
      {children}
    </newslettersCtx.Provider>
  )
}

export default NewslettersProvider
