import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import routes from '../constants/routes'

const useLogout = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const logout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('user')
    localStorage.removeItem('mapPins')
    localStorage.removeItem('mapPinsAddedTime')
    queryClient.clear()
    navigate(routes.login)
  }

  return { logout }
}

export default useLogout
