import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PageTitle from 'src/components/UI/PageTitle';
import useMutateData from 'src/hooks/useMutateData';
import apiService from 'src/services/api/apiService';
import PageSection from 'src/components/UI/PageSection'
import { startOfToday, format } from 'date-fns';
import UserCreationForm from '../components/UserCreationForm';

function CreateUser() {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const { mutate: createUserMutation, isLoading } = useMutateData({
    key: 'users',
    mutationFn: apiService.createUser,
    successMessage: 'userHasBeenCreated'
  })

  const onSubmit = (values: any) => {
    createUserMutation(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `MDM CRM - ${t('newUser')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text="newUser" subtext="dateOfRegistrationLong" date={format(startOfToday(), 'dd/MM/yyyy')} />
      <UserCreationForm isLoading={isLoading} onSubmit={onSubmit} />
    </PageSection>
  )
}
export default CreateUser
