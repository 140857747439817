import { v4 as uuidv4 } from 'uuid';
import SkeletonBlock from './SkeletonBlock';

interface Props {
  skeletonBlocks: number
}

function DonationSkeleton({ skeletonBlocks } : Props) {
  const skeletonBlocksArray = Array.from({ length: skeletonBlocks });

  return (
    <>
      <div className="py-3 px-3 md:px-8 md:py-10 bg-white rounded-regular shadow-faint w-full flex mx-auto flex-wrap h-[calc(100%-20px)]">
        <div className="h-7 mb-6 bg-gray-200 mt-3 w-[15rem] animate-pulse rounded-small md:mx-4" />
        <div className="grid w-full grid-cols-1 mb-6 md:px-4 gap-9 md:grid-col-2 lg:grid-cols-3">
          <SkeletonBlock />
          <SkeletonBlock />
        </div>
        <div className="grid w-full grid-cols-1 md:px-4 gap-9 md:grid-col-2 lg:grid-cols-3">
          {skeletonBlocksArray.map(() => (
            <SkeletonBlock key={uuidv4()} />
          ))}
        </div>
        <div className="flex flex-col w-full px-4">
          <div className="h-5 mt-8 mb-2 bg-gray-200 w-[15rem] animate-pulse rounded-small" />
          <div className="flex-grow w-full h-[100px] bg-gray-200 animate-pulse rounded-small" />
        </div>
      </div>
      <div className="flex flex-col-reverse justify-end gap-4 mt-4 md:flex-row">
        <div className="px-20 bg-gray-200 py-7 animate-pulse rounded-small" />
        <div className="px-20 bg-gray-200 py-7 animate-pulse rounded-small" />
      </div>
    </>
  );
}

export default DonationSkeleton;
