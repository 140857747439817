import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { FiPlus } from 'react-icons/fi'
import { ReactNode } from 'react'
import routes from 'src/constants/routes'
import RenderIf from './RenderIf'
import TasksNav from './TasksNav'

interface TitleProps {
  title: string
  add: string
  onClickHandler?: () => void
  onClickOpenModalHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  results?: number | null
  children?: ReactNode
}

export default function Title({
  title, add, onClickHandler, onClickOpenModalHandler, results, children
}: TitleProps): JSX.Element {
  const { t } = useTranslation()
  const disablePrint = 'print:hidden';

  const { pathname } = useLocation()
  // eslint-disable-next-line
	const isExactPath =
  pathname === routes.admin.tasksList
  || pathname === routes.admin.tasks
  || pathname === routes.agent.tasksList
  || pathname === routes.agent.tasks

  return (
    <div className={`${disablePrint} w-full flex flex-col md:flex-row ${title === 'Αρχική' ? 'gap-0' : 'gap-5'} justify-between items-center mt-3 mb-8 md:my-12`}>
      <div className="flex flex-col items-center md:items-start">
        <div className="flex items-center gap-3 sm:gap-4">
          <h2 className="text-white font-bold text-[1.75rem] md:text-2xl leading-[52px] whitespace-nowrap">{t(title)}</h2>
          {children}
        </div>
        <RenderIf isTrue={!!results}>
          <p className="md:mt-2 font-sans font-medium text-mdm-white text-sm md:text-[1.375rem] whitespace-nowrap">
            {`${results} ${results === 1 ? t('result') : t('results')}`}
          </p>
        </RenderIf>
      </div>

      <div className="flex justify-center w-full gap-3 md:justify-end">
        <RenderIf isTrue={isExactPath}>
          {' '}
          <TasksNav />
        </RenderIf>
        <RenderIf isTrue={!!onClickHandler}>
          <button onClick={onClickHandler} type="button" className="flex items-center justify-center w-full gap-2 px-4 py-4 font-bold transition-all duration-200 bg-white border md:w-auto md:px-6 text-regular rounded-small hover:bg-mdm-navy-blue hover:text-white text-mdm-blue border-mdm-blue">
            <FiPlus size={20} />
            {t(add)}
          </button>
        </RenderIf>
        <RenderIf isTrue={!!onClickOpenModalHandler}>
          <button onClick={onClickOpenModalHandler} type="button" className="z-0 flex items-center gap-2 px-4 py-4 font-bold transition-all duration-200 bg-white border md:px-6 text-regular rounded-small hover:bg-mdm-navy-blue hover:text-white text-mdm-blue border-mdm-blue">
            <FiPlus size={20} />
            {t(add)}
          </button>
        </RenderIf>
      </div>

    </div>
  )
}
