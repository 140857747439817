import { IoMdPerson } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { ContactTypes } from 'src/interfaces/contacts'

interface Props {
  selectedContacts: ContactTypes[]
  onClickHandler: () => void
}

function ViewSelectedContactsBtn({ selectedContacts, onClickHandler }:Props) {
  const { t } = useTranslation()

  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="flex items-center gap-3"
    >
      <div className="flex items-center justify-center rounded-full h-[35px] w-[35px] border border-lines">
        <IoMdPerson size={20} className="text-paragraph" />
      </div>
      <p
        className="text-sm text-mdm-blue whitespace-nowrap"
      >
        {`${selectedContacts.length} ${t('selectedContacts')}`}
      </p>
    </button>
  )
}

export default ViewSelectedContactsBtn
