import { useLocation, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import routes from 'src/constants/routes'
import RenderIf from 'src/components/UI/RenderIf'
import TableSkeleton from 'src/components/Skeletons/TableSkeleton'
import Title from 'src/components/UI/Title'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import DonationsTable from './components/DonationsTable'

function Donations() {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.admin.donations || pathname === routes.agent.donations
  const { donations, isDonationsLoading } = useDataProvider()

  useEffect(() => {
    document.title = `MDM CRM - ${t('donations')}`
  }, [t, donations])

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <section>
          <Title title="donations" add="createDonation" onClickHandler={() => navigate(routes.shared.createDonation)} />
          <RenderIf isTrue={isDonationsLoading}>
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isDonationsLoading}>
            <DonationsTable donations={donations} />
          </RenderIf>
        </section>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Outlet context={donations} />
      </RenderIf>
    </>
  )
}

export default Donations
