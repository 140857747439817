import { FieldInputProps } from 'formik'
import { useTranslation } from 'react-i18next'
import RenderIf from '../UI/RenderIf'
import InputErrorMessage from '../UI/InputErrorMsg'

interface PropTypes {
  inputId: string
  name: string
  getFieldProps: FieldInputProps<any>
  errors: boolean
  errorName: string | undefined
  width?: string
  marginX?: 'mx-4' | 'mx-0',
  marginY?: 'my-4' | 'my-0',
  disabled?: boolean
  [key: string]: any
}

function FormTextArea({
  inputId,
  name,
  getFieldProps,
  errors,
  errorName,
  disabled,
  width = 'lg:w-1/3 | w-full',
  marginX = 'mx-4',
  marginY = 'my-4',
  ...restProps
}: PropTypes) {
  const { t } = useTranslation()
  const value = getFieldProps.value !== null ? getFieldProps.value : '';

  return (
    <div className={`relative text-sm md:text-regular w-full sm:w-full flex flex-col md:${marginY} ${width}`}>
      <label
        htmlFor={inputId}
        className={`md:${marginX} my-2 text-paragraph whitespace-nowrap`}
      >
        {t(name)}
      </label>
      <textarea
        id={inputId}
        {...getFieldProps}
        {...restProps}
        value={value}
        disabled={disabled}
        className={`outline-none mx-0 md:${marginX} border py-3.5 px-4 rounded-small h-[100px] resize-none ${errors ? 'border-red-700 focus:border-red-700 focus:ring-0' : 'border-lines focus:border-mdm-blue focus:ring-0'} ${disabled ? 'text-paragraph' : 'text-black'}`}
      />
      <RenderIf isTrue={!!errors}>
        <div className={`${marginX} mt-1 absolute bottom-[5.2rem] w-full`}>
          <InputErrorMessage text={errorName!} />
        </div>
      </RenderIf>
    </div>
  )
}

export default FormTextArea
