type Department = 'Προγραμμάτων' | 'Οικονομικών' | 'Εθελοντών' | 'Επικοινωνίας'
interface Args {
  department: Department
  t: any
}

const expandedRowCols = ({ department, t }:Args) => {
  const communicationSubTableColumns = [
    {
      Header: t('Website'),
      accessor: 'website',
      Cell: ({ value }:{ value:string }) => <p title={value}>{(value)}</p>
    },
    {
      Header: t('show'),
      accessor: 'show',
      Cell: ({ value }:{ value:string }) => <p title={value}>{(value)}</p>
    },
    {
      Header: t('mediaCommunication'),
      accessor: 'media',
      Cell: ({ value }:{ value:string }) => <p title={value}>{(value)}</p>
    }
  ];
  const volunteerSubTableColumns = [
    {
      Header: t('status'),
      accessor: 'is_active',
      Cell: ({ value }: { value: boolean }) => (
        <p>{value}</p>
      )
    },
    {
      Header: t('dateOfBirth'),
      accessor: 'date_of_birth',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('studies'),
      accessor: 'studies',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('job'),
      accessor: 'job',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('city'),
      accessor: 'city',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('volunteerAttributes'),
      accessor: 'volunteer_attributes',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('notes'),
      accessor: 'notes',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    }
  ]

  const programSubTableColumns = [
    {
      Header: t('institutionalPosition'),
      accessor: 'institutional_position',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('typeOfSponsor'),
      accessor: 'type_of_sponsor',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('typeOfSupport'),
      accessor: 'type_of_support',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('sizeOfGrant'),
      accessor: 'size_of_grant',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('currentStatus'),
      accessor: 'current_status',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('implementationPeriod'),
      accessor: 'implementation_period',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('startOfCooperation'),
      accessor: 'start_of_cooperation',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('endOfCooperation'),
      accessor: 'end_of_cooperation',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('budget'),
      accessor: 'budget',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    }
  ];

  const financialSubTableColumns = [
    {
      Header: t('typeOfSupport'),
      accessor: 'type_of_support',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('agencyName'),
      accessor: 'agency_name',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('amount'),
      accessor: 'amount',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('invoiceIssueDate'),
      accessor: 'invoice_issue_date',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('depositBank'),
      accessor: 'deposit_bank',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('depositAccount'),
      accessor: 'deposit_account',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('dateOfDeposit'),
      accessor: 'date_of_deposit',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('notes'),
      accessor: 'notes',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    }
  ];

  // eslint-disable-next-line default-case
  switch (department) {
    case 'Προγραμμάτων':
      return programSubTableColumns;
    case 'Οικονομικών':
      return financialSubTableColumns;
    case 'Εθελοντών':
      return volunteerSubTableColumns;
    case 'Επικοινωνίας':
      return communicationSubTableColumns;
    default:
      return [];
  }
};

export default expandedRowCols;
