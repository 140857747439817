import { useEffect, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageSection from 'src/components/UI/PageSection'
import PageTitle from 'src/components/UI/PageTitle'
import DetailsInfoBtns from 'src/components/UI/DetailsInfoBtns'
import routes from 'src/constants/routes'
import UserMethods from 'src/utils/userMethods'
import RenderIf from 'src/components/UI/RenderIf'
import WarehouseInfoSkeleton from 'src/components/Skeletons/WarehouseInfoSkeleton'
import apiService from 'src/services/api/apiService'
import { WarehouseTypes } from 'src/interfaces/warehouses'
import useGetData from 'src/hooks/useGetData'
import WarehouseDetails from '../components/WarehouseDetails'

function WarehouseInfo() {
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()

  const authUser = UserMethods.getUser();
  const { t } = useTranslation()
  const { data: warehousesData } = useGetData<WarehouseTypes[]>({
    queryKey: 'warehouses',
    queryFn: apiService.getWarehouses
  })

  const warehouses = useMemo(() => {
    if (warehousesData) {
      return warehousesData.map((warehouse:WarehouseTypes) => ({
        ...warehouse,
        location: t(warehouse.location)
      }));
    }
    return [];
  }, [warehousesData, t]);
  const warehouse = warehouses?.find((w) => w.id?.toString() === params.id)

  useEffect(() => {
    document.title = `MDM CRM - ${t('warehouse')}`;
  }, [t])

  return (
    <PageSection>
      <PageTitle text="registration" id={warehouse?.id} isDonation={warehouse?.is_donation} />
      <RenderIf isTrue={!!warehouse}>
        <WarehouseDetails warehouse={warehouse} />
        <DetailsInfoBtns onClickHandler={() => { navigate(`/${authUser?.user.user_type.toLowerCase()}/warehouses/${routes.shared.updateWarehouse}/${warehouse?.id}`) }} btnText="edit" />
      </RenderIf>
      <RenderIf isTrue={!warehouse}>
        <WarehouseInfoSkeleton />
      </RenderIf>
    </PageSection>
  )
}

export default WarehouseInfo
