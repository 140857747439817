import { useTranslation } from 'react-i18next'
import { FiChevronRight } from 'react-icons/fi';
import Spinner from './Spinner'
import RenderIf from './RenderIf';

interface Props {
  type : 'button' | 'submit'
  btnText: string
  onClickHandler?: () => void
  isLoading?: boolean
  spinnerWidth?: string
  disabled?: boolean,
  px?:string
}

function PrimaryActionBtn({
  type, btnText, onClickHandler, isLoading = false, spinnerWidth, disabled, px
}:Props) {
  const { t } = useTranslation()

  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      disabled={disabled}
      className={`${px} font-[500] px-8 min-w-[192px] flex justify-center w-full py-4 text-white transition-colors duration-200 hover:bg-mdm-navy-blue text-regular md:w-auto rounded-small bg-mdm-blue disabled:bg-opacity-70 disabled:cursor-not-allowed`}
      onClick={onClickHandler}
    >
      {isLoading ? (
        <Spinner spinnerWidth={spinnerWidth} />
      ) : (
        <>
          <RenderIf isTrue={!!(btnText === 'nextStep')}>
            <div className="flex items-center justify-center gap-3">
              <p className="pl-2">
                {' '}
                {t(btnText)}
              </p>
              <FiChevronRight size={20} />
            </div>
          </RenderIf>
          <RenderIf isTrue={!(btnText === 'nextStep')}>{t(btnText)}</RenderIf>
        </>
      )}
    </button>
  )
}

export default PrimaryActionBtn
