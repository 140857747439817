import axios, { AxiosError, AxiosResponse } from 'axios'
import exception from 'src/utils/exception'
import { ServerError } from 'src/interfaces/api'
import { WarehouseTypes } from 'src/interfaces/warehouses'
import axiosInstance from '../axiosInstance'
import { WarehouseServiceInterface } from './warehousesService.interface'

class WarehousesService implements WarehouseServiceInterface {
  public async getWarehouses(): Promise<WarehouseTypes[] | null> {
    try {
      const response = await axiosInstance.get('/warehouses/warehouse-entry/')
      const warehouses: WarehouseTypes[] = response.data.data
      return warehouses
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }

  public async createWarehouse(body: any): Promise<AxiosResponse | null> {
    try {
      const formData = new FormData();
      if (body.media.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const file of body.media) {
          formData.append('media', file)
        }
      }
      // eslint-disable-next-line no-param-reassign
      delete body.media
      Object.entries(body).forEach(([key, value]: [string, any]) => {
        formData.append(key, value as any);
      });
      const response = await axiosInstance.post('/warehouses/warehouse-entry/', formData);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>);
      }
    }
    return null;
  }

  public async getWarehouse(valueId: any): Promise<WarehouseTypes | null> {
    try {
      const response = await axiosInstance.get(`/warehouses/warehouse-entry/${valueId}/`)
      return response.data.data as WarehouseTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateWarehouse(values: any): Promise<AxiosResponse<any, any> | null> {
    try {
      const formData = new FormData();
      Object.entries(values).forEach((entry: any) => {
        const [key, value] = entry;
        if (key === 'media' && Array.isArray(value)) {
          value.forEach((mediaItem: any, index: number) => {
            if (mediaItem instanceof File) {
              formData.append('media', mediaItem);
            } else {
              formData.append(`media[${index}][id]`, mediaItem.id);
              formData.append(`media[${index}][file]`, mediaItem.file);
              formData.append(`media[${index}][warehouse]`, mediaItem.warehouse);
            }
          });
        } else {
          formData.append(key, value);
        }
      });

      const response = await axiosInstance.patch(`/warehouses/warehouse-entry/${values.id}/`, formData);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>);
      }
    }
    return null;
  }
}

export default WarehousesService;
