import { Dispatch, SetStateAction } from 'react'

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

function HamburgerMenu({ isOpen, setIsOpen }:Props) {
  const hamburgerLine = 'h-1 w-7 my-1 rounded-full bg-white transition ease transform duration-300'
  return (
    <button
      type="button"
      aria-label="Open/Close"
      className="flex flex-col items-center justify-center h-12 w-13 custom-screen-2:hidden group"
      onClick={() => setIsOpen((prevState) => !prevState)}
    >
      <div className={`${hamburgerLine} ${isOpen && 'rotate-45 translate-y-3'}`} />
      <div className={`${hamburgerLine} ${isOpen && 'opacity-0'}`} />
      <div className={`${hamburgerLine} ${isOpen && '-rotate-45 -translate-y-3'}`} />
    </button>
  )
}

export default HamburgerMenu
