/* eslint-disable max-len */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageSection from 'src/components/UI/PageSection'
import PageTitle from 'src/components/UI/PageTitle'
import SimpleBar from 'simplebar-react'
import userGuidePDF from 'src/assets/docs/userGuidePDF.pdf'
import UserGuideSection from './components/UserGuideSection'
import UserGuideContent from './components/UserGuideContent'

function UserGuide() {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `MDM CRM - ${t('userGuide')}`
  }, [t])

  return (
    <PageSection width="w-full">
      <PageTitle text="userGuide" />
      <article className="flex flex-col w-full gap-6">
        <UserGuideSection title="How is CRM used">
          <p className="text-sm md:text-regular">
            Παρακάτω μπορείτε να βρείτε μια περιγραφή λειτουργίας του MdM CRM.
            Αν θέλετε να κατεβάσετε το αρχείο σε μορφή PDF, κάνετε κλικ&nbsp;
            <a
              className="underline text-mdm-blue hover:text-mdm-secondary-blue"
              href={userGuidePDF}
              download="MdM_CRM_Οδηγίες_Χρήσης.pdf"
            >
              εδώ
            </a>
            .
          </p>
        </UserGuideSection>
        <UserGuideSection title="userGuide">
          <SimpleBar autoHide={false} className="h-[530px] pr-5">
            <UserGuideContent />
          </SimpleBar>
        </UserGuideSection>
      </article>
    </PageSection>
  )
}

export default UserGuide
