import axios from 'axios'
import { toast } from 'react-toastify'
import tokenMethods from 'src/utils/tokenMethods'

interface ResponseInterceptorTypes {
  logout: () => void
  toastMsg: string
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

axiosInstance.interceptors.request.use(
  (request) => {
    if (!request.url?.includes('/login')) {
      const token = tokenMethods.getAccessToken()
      if (token && request.headers) request.headers.Authorization = `Bearer ${token}`
    }
    return request
  },
  (error) => Promise.reject(error)
)

export default axiosInstance

export const responseInterceptor = ({ logout, toastMsg }: ResponseInterceptorTypes) => {
  axiosInstance.interceptors.response.use(
    (response) => response,

    (error) => {
      const originalConfig = error.config
      if (originalConfig.url !== '/login/' && error.response) {
        // eslint-disable-next-line no-underscore-dangle
        if (error.response.status === 401 && !originalConfig._retry) {
          // eslint-disable-next-line no-underscore-dangle
          originalConfig._retry = true
          logout()
          toast.error(toastMsg, {
            toastId: 'logoutId'
          })
        }
      }
      return Promise.reject(error)
    }
  )
}
