import { Navigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import tokenMethods from 'src/utils/tokenMethods'
import userMethods from 'src/utils/userMethods'
import routes from 'src/constants/routes'
import { responseInterceptor } from 'src/services/axiosInstance'
import useLogout from 'src/hooks/useLogout'
import { UserRoleTypes } from 'src/interfaces/user'

interface Props {
  userRole: UserRoleTypes['userRole']
}

function ProtectedRoutes({ userRole }:Props) {
  const { t } = useTranslation()
  const { logout } = useLogout()
  const token = tokenMethods.getAccessToken()
  const user = userMethods.getUser()

  responseInterceptor({ logout, toastMsg: t('sessionExpired') })

  return (
    token && user?.user.user_type === userRole ? <Outlet /> : <Navigate to={routes.login} />
  )
}

export default ProtectedRoutes
