import axios, { AxiosError, AxiosResponse } from 'axios'
import exception from 'src/utils/exception'
import { ServerError } from 'src/interfaces/api'
import {
  DeleteUserTypes, UpdateMeTypes, UpdateUserTypes, UserCreationTypes, UserTypes
} from 'src/interfaces/user'
import axiosInstance from '../axiosInstance'
import { UsersServiceInterface } from './usersService.interface';

interface UserFormDataTypes {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  user_type: string;
  telephone: string;
  password?: string;
  position?: string;
  departments?: any;
}

class UsersService implements UsersServiceInterface {
  public async getUsers(): Promise<UserTypes[] | null> {
    try {
      const response = await axiosInstance.get('/auth/user/all/')
      const users: UserTypes[] = response.data.data
      return users
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }

      return null
    }
  }

  public async getUser(id: number, user_type: string): Promise<UserTypes | null> {
    try {
      const response = await axiosInstance.get(`/auth/${user_type}/${id}`);
      return response.data.data as UserTypes;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>);
      }
    }
    return null;
  }

  public async createUser(values: UserCreationTypes): Promise<AxiosResponse | null> {
    try {
      const formData: UserFormDataTypes = {
        username: values.username.trim(),
        email: values.email.trim(),
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        telephone: values.telephone.trim(),
        position: values?.position?.trim(),
        password: values.password.trim(),
        user_type: values.user_type.trim(),
        departments: values?.departments
          ?.map((department: any) => department.value[0].toUpperCase() + department.value.slice(1))
      }
      const userEndpoint = values.user_type.toLowerCase()
      const response = await axiosInstance.post(`/auth/${userEndpoint}/`, formData)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateUser({ id, values }:UpdateUserTypes): Promise<AxiosResponse | null> {
    const formData: UserFormDataTypes = {
      username: values.username.trim(),
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      email: values.email.trim(),
      telephone: values.telephone.trim(),
      user_type: values.user_type.trim(),
      position: values?.position?.trim(),
      departments: values.departments
        ?.map((department: any) => department.title || department.value)
    }
    const userEndpoint = values.user_type.toLowerCase()
    try {
      const response = await axiosInstance.patch(`/auth/${userEndpoint}/${id}/`, formData)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateMe(values: UpdateMeTypes): Promise<AxiosResponse | null> {
    const formData = {
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      email: values.email.trim(),
      password: values.password?.trim()
    }

    if (values.password?.length === 0) {
      delete formData.password
    }

    try {
      const response = await axiosInstance.patch(`/auth/${values.user_type.toLowerCase()}/${values.id}/`, formData)
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }

  public async deleteUser({ id, userRole }: DeleteUserTypes): Promise<AxiosResponse | null> {
    try {
      const userEndpoint = userRole.toLowerCase()
      return await axiosInstance.delete(`/auth/${userEndpoint}/${id}/`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default UsersService;
