import goods from 'src/assets/images/goods.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  px?: 'px-3' | 'px-7'
  py?: 'py-2' | 'py-1'
  badgeWidth?: 'w-[86px]' | 'w-[120px]'
  textSize?: 'text-sm' | 'text-[0.75rem]'
  imageWidth?: 'w-[12px]' | 'w-[18px]'
  imageHeight?: 'h-[11px]' | 'h-[20px]'
  borderRadius?: 'rounded-[12px]' | 'rounded-full'
}

function DonationBadge({
  px = 'px-3',
  py = 'py-1',
  badgeWidth = 'w-[86px]',
  textSize = 'text-sm',
  imageHeight = 'h-[11px]',
  imageWidth = 'w-[12px]',
  borderRadius = 'rounded-full'
} : Props) {
  const { t } = useTranslation()
  return (
    <div className={`${px} ${py} bg-mdm-navy-blue ${borderRadius} flex gap-2 items-center justify-center mt-3 md:mt-0 ${badgeWidth}`}>
      <img src={goods} alt="donation" className={`${imageHeight} ${imageWidth}`} />
      <p className={`font-semibold text-mdm-sky-blue ${textSize}`}>{t('donation')}</p>
    </div>
  )
}

export default DonationBadge
