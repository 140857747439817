import { createContext } from 'react'
import { DataProviderCtxTypes } from './dataProvider.interface'

const dataProviderCtx = createContext<DataProviderCtxTypes>({
  notes: [],
  isNotesLoading: false,
  setIsEnabled: () => {},
  contacts: [],
  isContactsLoading: false,
  donations: [],
  isDonationsLoading: false,
  users: [],
  isUsersLoading: false,
  tasks: [],
  isTasksLoading: false,
  departments: [],
  isDepartmentsLoading: false,
  contactsPageParam: 1,
  setContactsPageParam: () => {},
  contactsPaginated: {
    fetchPreviousPage: async () => {},
    fetchNextPage: async () => {},
    hasNextPage: false,
    hasPreviousPage: false,
    isFetching: false,
    isFetchingPreviousPage: false,
    isFetchingNextPage: false,
    totalContactPages: null,
    totalCountOfContacts: null
  },
  searchTextContact: '',
  onSearchContactHandler: () => {},
  setSearchTextContact: () => {},
  initialDepartments: [],
  sortTextContact: '',
  setSortTextContact: () => {},
  onSortingOrderContactHandler: () => {},
  onSortTextContactHandler: () => {},
  sortingOrderContact: 'initial',
  setSortingOrderContact: () => {},
  filteredDepartments: [],
  setFilteredDepartments: () => {},
  filteredMyContacts: '',
  setFilteredMyContacts: () => {},
  resetFilters: () => {},
  setSelectedContactsForExport: () => {},
  selectedContactsForExport: []
})

export default dataProviderCtx
