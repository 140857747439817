import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Table from 'src/components/Table/Table'
import { WarehouseTypes } from 'src/interfaces/warehouses'
import formattedDate from 'src/utils/formattedDate'
import { AiOutlinePaperClip } from 'react-icons/ai'
import medicine from 'src/assets/images/medicine.svg'
import products from 'src/assets/images/products.svg'
import consumables from 'src/assets/images/consumables.svg'
import medicalEq from 'src/assets/images/medical_equipment.svg'
import itEq from 'src/assets/images/it_equipment.svg'
import DonationBadge from 'src/components/UI/DonationBadge'

import sortByDate from 'src/utils/sortByDate'

interface Props {
  warehouses: WarehouseTypes[]
}

interface DonationTypeDetails {
  kind: string,
  subcategory: string,
}

interface ImportDetails {
  import_date: string,
  import_quantity: string,
}

interface ExportDetails {
  export_date: string,
  export_quantity: string,
}

function WarehousesTable({ warehouses = [] }:Props) {
  const { t } = useTranslation()
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const handleCheckboxClick = (rowId: string) => {
    setSelectedRows((prevOptions) => {
      if (prevOptions.includes(rowId)) {
        return prevOptions.filter((option) => option !== rowId);
      }
      return [...prevOptions, rowId];
    });
  };

  const columns = useMemo(() => [
    {
      accessor: 'selected',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: { row: any }) => (
        <input
          type="checkbox"
          value="checkbox"
          className="custom-checkbox"
          onClick={(e) => {
            e.stopPropagation();
            handleCheckboxClick(row.original.id);
          }}
        />
      )
    },
    {
      Header: 'ID',
      accessor: (row: { id: string }) => `#${row.id.toString()}`,
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p title={value}>
          {value}
        </p>
      )
    },
    {
      Header: t('supplier'),
      accessor: 'supplier',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: any) => (
        <div className="flex flex-col gap-2">
          <p>{row.original.supplier}</p>
          {row.original.is_donation && (
          <DonationBadge px="px-3" py="py-1" textSize="text-[0.75rem]" />
          )}
        </div>
      )
    },
    {
      Header: t('kindSubcategory'),
      accessor: (row: DonationTypeDetails) => `${(row.kind)} ${row.subcategory}`,
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: any) => (
        <div className="flex flex-col gap-1">
          <div className="flex gap-1">
            <div className="flex items-center gap-2 ">
              {row.original.kind === t('product') && <img className="w-[22px] h-[22px]" src={products} alt="Products" />}
              {row.original.kind === t('consumable') && <img className="w-[22px] h-[22px]" src={consumables} alt="Consumables" />}
              {row.original.kind === t('medical_equipment') && <img className="w-[22px] h-[22px]" src={medicalEq} alt="Medical Equipment" />}
              {row.original.kind === t('it_equipment') && <img className="w-[22px] h-[15px]" src={itEq} alt="IT Equipment" />}
              {row.original.kind === t('medicine') && <img className="w-[22px] h-[24px]" src={medicine} alt="Medicine" />}
              <p>{t(row.original.kind)}</p>
            </div>
          </div>
          <p className=" text-lines">{row.original.subcategory}</p>
        </div>
      )
    },
    {
      Header: t('location'),
      accessor: 'location',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p title={value}>
          {(value)}
        </p>
      )
    },
    {
      Header: t('programEntry'),
      accessor: 'program_entry',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: any) => (
        <p className="whitespace-nowrap">
          {row.original.program_entry}
        </p>
      )
    },
    {
      Header: t('file'),
      accessor: 'media',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: any) => (
        <div className="flex items-center gap-1">
          <AiOutlinePaperClip />
          {row.original?.media?.length === 0 ? '-' : row?.original?.media.length}
        </div>
      )
    },
    {
      Header: t('import'),
      accessor: (row: ImportDetails) => `${formattedDate(row.import_date)} ${row.import_quantity}`,
      sortType: (a: any, b: any) => sortByDate(a.original.import_date, b.original.import_date),
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: any) => (
        <div className="flex flex-col w-full gap-1 whitespace-nowrap">
          <div className="flex gap-1">
            {t('dataEntryShort')}
            :
            {' '}
            <p className="">
              {' '}
              {formattedDate(row.original.import_date)}
            </p>
          </div>
          <div className="text-lines">
            {t('quantity')}
            :
            {' '}
            {row.original.import_quantity}
          </div>
        </div>
      )
    },
    {
      Header: t('export'),
      accessor: (row: ExportDetails) => `${formattedDate(row.export_date)} ${row.export_quantity}`,
      sortType: (a: any, b: any) => sortByDate(a.original.export_date, b.original.export_date),
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: any) => (
        <div className="flex flex-col gap-1 whitespace-nowrap">
          <div className="flex gap-1">
            {t('dataEntryShort')}
            :
            {' '}
            <p className="">
              {' '}
              {formattedDate(row.original.export_date)}
            </p>
          </div>
          <div className="text-lines">
            {t('quantity')}
            :
            {' '}
            {row.original.export_quantity}
          </div>
        </div>
      )
    }
  ], [t]);

  return (
    <Table
      columns={columns}
      data={warehouses}
      storageKey="warehousesTable"
      rowsPerPage={5}
      selectedRows={selectedRows}
    />
  )
}

export default WarehousesTable
