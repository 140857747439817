import { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import { NoteCreationTypes } from 'src/interfaces/notes';

type CopyToClipboardHandler = (text: string | number | NoteCreationTypes) => void;

function useCopyToClipboard(resetInterval?: number): [boolean, CopyToClipboardHandler] {
  const [isCopied, setCopied] = useState(false);

  const handleCopy: CopyToClipboardHandler = (text) => {
    if (typeof text === 'string' || typeof text === 'number') {
      copy(text.toString());
      setCopied(true);
    } else if (typeof text === 'object' && text !== null) {
      const { title, description } = text as NoteCreationTypes;
      const copiedText = `${title} ${description}`;
      copy(copiedText);
      setCopied(true);
    } else {
      setCopied(false);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isCopied && resetInterval) {
      timeout = setTimeout(() => setCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied, resetInterval]);

  return [isCopied, handleCopy];
}

export default useCopyToClipboard;
