import { UserRoleTypes } from 'src/interfaces/user'
import AdminNavMobile from './UserRoleNav/AdminNav/AdminNavMobile'
import RenderIf from '../UI/RenderIf'
import AgentNavMobile from './UserRoleNav/AgentNav/AgentNavMobile'

interface Props {
  userRole : UserRoleTypes['userRole']
  isOpen: boolean
}

function HamburgerNav({ isOpen, userRole }:Props) {
  const isOpenClass = isOpen ? 'translate-x-0' : 'translate-x-full'
  return (
    <div
      className={`${isOpenClass} fixed z-[300] right-0 left-0 bottom-0 top-[5.62rem] bg-white custom-screen-2:hidden transition-transform flex flex-col items-center justify-center`}
    >
      <nav className="flex flex-col items-center w-full m-10 gap-9 lg:gap-12">
        <RenderIf isTrue={userRole === 'ADMINISTRATOR'}>
          <AdminNavMobile />
        </RenderIf>
        <RenderIf isTrue={userRole === 'AGENT'}>
          <AgentNavMobile />
        </RenderIf>
      </nav>
    </div>
  )
}

export default HamburgerNav
