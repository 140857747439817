import { Outlet } from 'react-router-dom'
import Header from 'src/components/Header/Header'
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import useScrollToTop from 'src/hooks/useScrollToTop'
import { UserRoleTypes } from '../interfaces/user'

function Layout({ userRole }: UserRoleTypes) {
  const { isOpen, setIsOpen } = useModalContext()
  useScrollToTop()
  return (
    <>
      <main className={`flex relative flex-col z-10 min-h-screen pb-5 px-5 md:px-5 lg:px-10 xl:px-20 ${isOpen ? 'overflow-y-hidden h-screen' : 'overflow-y-auto lg:min-h-screen'}`}>
        <Header userRole={userRole} isOpen={isOpen} setIsOpen={setIsOpen} />
        <Outlet />
      </main>
      <div className="absolute bg-mdm-blue top-0 w-full left-0 h-[22.3rem]" />
    </>
  )
}

export default Layout
