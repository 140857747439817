import { useTranslation } from 'react-i18next'
import { FiChevronLeft } from 'react-icons/fi';
import RenderIf from './RenderIf'

interface Props {
  onClickHandler: () => void
  btnText: 'back' | 'Cancel'
}

function SecondaryActionBtn({ onClickHandler, btnText }:Props) {
  const { t } = useTranslation()
  return (
    <button
      type="button"
      onClick={onClickHandler}
      className="text-regular font-[500] text-mdm-blue rounded-small px-7 py-3.5 transition-all duration-200 hover:border-mdm-navy-blue hover:text-mdm-navy-blue bg-white border border-mdm-blue flex items-center justify-center gap-[2px] md:w-auto w-full"
    >
      <RenderIf isTrue={btnText === 'back'}>
        <FiChevronLeft size={20} className="-translate-x-2" />
      </RenderIf>
      {t(btnText)}
    </button>
  )
}

export default SecondaryActionBtn
