import { Dispatch, SetStateAction } from 'react'
import Logo from 'src/components/UI/Logo'
import Navigation from 'src/components/Navigation/Navigation'
import OptionsDropdown from 'src/components/Dropdown/OptionsDropdown'
import HamburgerMenu from 'src/components/UI/HamburgerMenu'
import HamburgerNav from 'src/components/Navigation/HamburgerNav'
import { UserRoleTypes } from 'src/interfaces/user'
import routes from 'src/constants/routes'
import AdminUsersNav from '../Navigation/UserRoleNav/AdminNav/AdminUsersNav'
import RenderIf from '../UI/RenderIf'

interface Props {
  userRole : UserRoleTypes['userRole']
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

function Header({ isOpen, setIsOpen, userRole }:Props) {
  const disablePrint = 'print:hidden';

  return (
    <header className={`${disablePrint} ${isOpen ? 'touch-none' : 'touch-auto'} flex items-center justify-between min-h-[6rem] border-b-[2px] border-solid border-mdm-sky-blue`}>
      <Logo />
      <Navigation userRole={userRole} />
      <HamburgerNav isOpen={isOpen} userRole={userRole} />
      <div className="flex items-center">
        <RenderIf isTrue={userRole === 'ADMINISTRATOR'}>
          <AdminUsersNav path={routes.admin.users} />
          <span className="h-10 hidden custom-screen-2:flex w-[1px] bg-mdm-sky-blue" />
        </RenderIf>
        <div className="flex items-center gap-5 md:gap-7">
          <OptionsDropdown />
          <HamburgerMenu isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
    </header>
  )
}

export default Header
