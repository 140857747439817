import { FiEdit } from 'react-icons/fi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdSend } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import userMethods from 'src/utils/userMethods'
import { Assignee } from 'src/interfaces/tasks'
import RenderIf from '../UI/RenderIf'

interface Props {
  onClickHandlerEdit?: (event: React.MouseEvent) => void;
  onClickHandlerDelete?: (event: React.MouseEvent) => void;
  onClickHandlerOrder?: () => void;
  onClickHandlerSend?: () => void;
  taskStatus?: string
  taskAssignees?: Assignee[]
  taskCreatorId?: string
  storageKey?: string
}

export default function TableActionBtns({
  onClickHandlerEdit,
  onClickHandlerDelete,
  onClickHandlerOrder,
  onClickHandlerSend,
  taskStatus,
  taskAssignees,
  storageKey,
  taskCreatorId
}: Props) {
  const { t } = useTranslation();
  const iconClasses = 'transition-colors duration-200';
  const authUser = userMethods.getUser();
  let isDeleteButtonDisabled = false;

  // for tasks list table actions disable delete button for tasks table for certain conditions
  const isAdministrator = authUser?.user.user_type === 'ADMINISTRATOR';
  const isTaskCompleted = taskStatus === 'COMPLETED';
  const isTaskCreator = authUser?.user.agent_id === taskCreatorId;
  const isTaskAssigned = taskAssignees
    ?.some((taskAssignee) => taskAssignee.agent?.user.id === authUser?.user.agent_id);
  const isTaskListTable = storageKey === 'taskListTable';

  if (isTaskListTable) {
    if (!isAdministrator && isTaskCompleted) {
      isDeleteButtonDisabled = true;
    }
    if (!isTaskCreator || !isTaskAssigned) {
      isDeleteButtonDisabled = true;
    }
  }

  return (
    <div className="flex items-center justify-end gap-4 min-w-[80px]">
      <RenderIf isTrue={!!onClickHandlerSend}>
        <MdSend
          title={t('resend')}
          onClick={onClickHandlerSend}
          size={21}
          aria-label="Send"
          className={`${iconClasses} hover:text-[#00af9c] cursor-pointer`}
        />
      </RenderIf>
      <RenderIf isTrue={!!onClickHandlerEdit}>
        <FiEdit
          title={t('edit')}
          size={21}
          aria-label="Edit"
          onClick={onClickHandlerEdit}
          className={`${iconClasses} hover:text-mdm-blue transition-colors duration-200 cursor-pointer`}
        />
      </RenderIf>
      <RenderIf isTrue={!!onClickHandlerDelete}>
        <RiDeleteBin6Line
          title={t('delete')}
          onClick={isDeleteButtonDisabled ? undefined : onClickHandlerDelete}
          aria-label="Delete"
          size={21}
          className={`${iconClasses} ${isDeleteButtonDisabled ? 'text-[#BD1118]/50 cursor-not-allowed' : 'text-[#BD1118] cursor-pointer hover:text-[#BD1118]/70'}  transition-colors duration-200 `}
        />
      </RenderIf>
      <RenderIf isTrue={!!onClickHandlerOrder}>
        <AiOutlineShoppingCart
          title={t('order')}
          onClick={onClickHandlerOrder}
          size={21}
          cursor="pointer"
          className={`${iconClasses} hover:text-mdm-blue`}
        />
      </RenderIf>
    </div>
  );
}
