/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useTranslation } from 'react-i18next'
import { BsChevronRight } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import routes from 'src/constants/routes'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import userMethods from 'src/utils/userMethods'
import NoDataMessage from 'src/components/UI/NoDataMessage'
import { v4 as uuidv4 } from 'uuid'
import { ContactTypes } from 'src/interfaces/contacts'
import formattedDate from 'src/utils/formattedDate'
import SubTitle from './SubTitle'
import RenderIf from './RenderIf'
import LastContactsSkeleton from '../Skeletons/LastContactsSkeleton'

function LatestContactsRegistrationPanel() {
  const { t } = useTranslation()
  const { contacts, isContactsLoading } = useDataProvider()
  const authUser = userMethods.getUser()
  const navigate = useNavigate()

  const renderContacts = (contactList: ContactTypes[]) => (
    <ul className="flex flex-col gap-4">
      {contactList?.slice(0, 3).map((contact: ContactTypes) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
          className="flex flex-col font-sans md:flex-row p-3 md:p-5 border text-lines transition-all duration-200 hover:border-paragraph hover:text-paragraph border-lines rounded-[0.75rem] justify-between cursor-pointer md:items-center"
          key={contact.id}
          onClick={() =>
            navigate(
              `/${authUser?.user.user_type.toLowerCase()}/contacts/${
                routes.shared.contactInfo
              }/${contact?.contact_type.toLowerCase()}-${contact?.id}`
            )
          }
        >
          <span className="flex text-sm">
            <p className=" text-paragraph whitespace-nowrap">
              {contact.first_name} {contact.last_name}
            </p>
          </span>
          <div className="relative flex items-center justify-between gap-6 font-sans text-xs font-normal md:static md:justify-end whitespace-nowrap">
            <p className="text-mdm-navy-blue">
              {formattedDate(contact.registration_date)}
            </p>
            <BsChevronRight
              size={16}
              className="absolute flex-shrink-0 md:static bottom-3 md:bottom-0 right-2 md:right-0"
            />
          </div>
        </li>
      ))}
      {contactList &&
        contactList.length > 0 &&
        contactList.length < 4 &&
        Array.from({ length: 4 - contactList.length }).map(() => (
          <div
            className="flex p-5 h-16 bg-fields rounded-[0.75rem]"
            key={uuidv4()}
          />
        ))}
    </ul>
  )

  return (
    <div className="w-full lg:w-1/2">
      <RenderIf isTrue={authUser?.user.user_type === 'ADMINISTRATOR'}>
        <SubTitle title="lastContacts" />
        <div className="h-[17rem] flex flex-col gap-3">
          <RenderIf isTrue={!!isContactsLoading}>
            <LastContactsSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isContactsLoading}>
            <RenderIf isTrue={contacts?.length === 0}>
              <div className="rounded-[0.75rem] bg-fields p-9 h-[19rem] flex items-center justify-center">
                <NoDataMessage text="noEntriesFound" />
              </div>
            </RenderIf>
            {renderContacts(contacts)}
          </RenderIf>
          <RenderIf isTrue={!isContactsLoading}>
            <div className="flex justify-end mt-1">
              <Link
                className="text-sm underline transition-colors duration-150 cursor-pointer text-mdm-blue/60 hover:text-mdm-blue"
                to={`/${authUser?.user.user_type.toLowerCase()}/contacts`}
              >
                {t('allContacts')}
              </Link>
            </div>
          </RenderIf>
        </div>
      </RenderIf>
      <RenderIf isTrue={authUser?.user.user_type === 'AGENT'}>
        <SubTitle
          title={
            authUser?.user?.departments?.length === 1
              ? `lastContacts${authUser?.user?.departments[0]?.title}`
              : 'lastContacts'
          }
        />
        <div className="h-[17rem] flex flex-col justify-between">
          <RenderIf isTrue={!!isContactsLoading}>
            <LastContactsSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isContactsLoading}>
            <RenderIf isTrue={contacts?.length === 0}>
              <div className="rounded-[0.75rem] bg-fields p-9 h-[21rem] flex items-center justify-center">
                <NoDataMessage text="noEntriesFound" />
              </div>
            </RenderIf>
            {renderContacts(contacts)}
          </RenderIf>
          <div className="flex justify-end mt-1">
            <Link
              className="text-sm underline transition-colors duration-150 text-mdm-blue/60 hover:text-mdm-blue"
              to={`/${authUser?.user.user_type.toLowerCase()}/contacts`}
            >
              {t('allContacts')}
            </Link>
          </div>
        </div>
      </RenderIf>
    </div>
  )
}

export default LatestContactsRegistrationPanel
