const headerNames: any = {
  Πρόσωπο: 'first_name',
  Eπικοινωνία: 'email',
  Φορέας: 'carrier',
  Τμήμα: 'department',
  Πρόγραμμα: 'program',
  Καταχώρηση: 'created_by',
  Ετικέτες: 'tags'
}

function sortingContactTableHeaders(header:string): string | null {
  return headerNames?.[header] ?? null
}

export default sortingContactTableHeaders
