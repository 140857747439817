import routes from 'src/constants/routes'
import NavLink from '../../NavLink'

function AgentNav() {
  return (
    <>
      <NavLink text="homePage" path={routes.agent.home} />
      <NavLink text="contacts" path={routes.agent.contacts} />
      <NavLink text="tasks" path={routes.agent.tasks} />
      <NavLink text="donations" path={routes.agent.donations} />
      <NavLink text="warehouse" path={routes.agent.warehouses} />
      <NavLink text="newsletters" path={routes.agent.newsletters} />
    </>
  )
}

export default AgentNav
