import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageSection from 'src/components/UI/PageSection'
import PageTitle from 'src/components/UI/PageTitle'
import UpdateUserDetailsForm from './components/UpdateUserDetails'

function Settings() {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `MDM CRM - ${t('settings')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text="settings" />
      <UpdateUserDetailsForm />
    </PageSection>
  )
}

export default Settings
