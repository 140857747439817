function underscoreToCamelCase(value: string) {
  if (value.includes('_')) {
    return value
      .split('_')
      .map((word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join('');
  }
  return value.toLowerCase();
}

export default underscoreToCamelCase;
