import axios, { AxiosError } from 'axios';
import exception from 'src/utils/exception';
import { ServerError } from 'src/interfaces/api';
import { DepartmentTypes } from 'src/interfaces/department';
import axiosInstance from '../axiosInstance';
import { DepartmentsServiceInterface } from './departmentService.interface';

class DepartmentsService implements DepartmentsServiceInterface {
  public async getDepartments(): Promise<DepartmentTypes[] | null> {
    try {
      const response = await axiosInstance.get('/auth/department/')
      const departments: DepartmentTypes[] = response.data.data
      return departments
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default DepartmentsService
