import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  setListFromFile: Dispatch<SetStateAction<File | undefined>>
}

function UploadMailingList({ setListFromFile }:Props) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-3">
      <label htmlFor="uploadList" className="text-sm underline cursor-pointer text-mdm-blue">
        {t('insertList')}
        <input
          className="hidden"
          id="uploadList"
          type="file"
          accept=".xlsx"
          onChange={(e) => setListFromFile(e.target.files?.[0])}
        />
      </label>
      <p className="text-[0.75rem] text-lines">{t('supportedMailingListFiles')}</p>
    </div>
  )
}

export default UploadMailingList
