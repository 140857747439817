import { v4 as uuidv4 } from 'uuid';
import SkeletonBlock from './SkeletonBlock';

function WarehouseSkeleton() {
  return (
    <>
      <div className="py-3 px-3 md:px-8 md:py-10 mb-6 bg-white rounded-regular shadow-faint w-full flex mx-auto flex-wrap h-[calc(100%-20px)]">
        <div className="h-7 mb-6 mt-3 bg-gray-200 w-[15rem] animate-pulse rounded-small mx-4" />
        <div className="grid w-full grid-cols-1 gap-6 px-4 md:grid-col-2 lg:grid-cols-3">
          {Array(12)
            .fill(null)
            .map(() => (
              <SkeletonBlock key={uuidv4()} />
            ))}
        </div>
        <div className="flex flex-col w-full px-4">
          <div className="h-7 mt-8 mb-4 bg-gray-200 w-[15rem] animate-pulse rounded-small" />
          <div className="flex-grow w-full h-[100px] bg-gray-200 animate-pulse rounded-small" />
        </div>
      </div>
      <div className="flex flex-col-reverse justify-end gap-4 mt-4 md:flex-row">
        <div className="px-20 bg-gray-200 py-7 animate-pulse rounded-small" />
        <div className="px-20 bg-gray-200 py-7 animate-pulse rounded-small" />
      </div>
    </>
  )
}

export default WarehouseSkeleton
