import { useTranslation } from 'react-i18next'
import { parseISO, format } from 'date-fns';
import { AiOutlinePlus } from 'react-icons/ai';
import SimpleBarReact from 'simplebar-react';
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider';
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider';
import RenderIf from 'src/components/UI/RenderIf';
import { NoteTypes } from 'src/interfaces/notes';
import NoDataMessage from 'src/components/UI/NoDataMessage';
import userMethods from 'src/utils/userMethods';
import NotesSkeleton from '../Skeletons/NotesSkeleton';
import NoteModal from '../Modals/NoteModal';

export default function NotesPanel() {
  const { t } = useTranslation()
  const { openNoteModalHandler } = useModalContext()
  const { notes, isNotesLoading } = useDataProvider()
  const authUser = userMethods.getUser()

  const formattedDate = (dateString: string) => format(parseISO(dateString), 'dd/MM/yyyy');

  const openNoteEditModalHandler = (note: NoteTypes) => {
    openNoteModalHandler(note.id, note);
  }

  const personalNotes = notes
    ?.filter((note) => note.user === authUser?.user.id)

  return (
    <>
      <NoteModal />
      <div className="w-full lg:w-1/2">
        <div className="flex items-center justify-between py-4 ">
          <h2 className="font-bold text-mdm-navy-blue text-[1.38rem]">{t('notes')}</h2>
          <button className="flex items-center gap-1 text-xs font-bold transition-all duration-150 text-mdm-blue hover:text-mdm-navy-blue" type="button" onClick={() => openNoteModalHandler()} disabled={isNotesLoading}>
            <span className="p-1 bg-fields rounded-[8px]"><AiOutlinePlus size={20} /></span>
            {t('createNote')}
          </button>
        </div>
        <RenderIf isTrue={!!isNotesLoading}>
          <NotesSkeleton />
        </RenderIf>
        <RenderIf isTrue={!isNotesLoading}>
          <RenderIf isTrue={personalNotes?.length === 0}>
            <div className="rounded-[0.75rem] bg-fields p-9 h-[17rem] flex items-center justify-center">
              <NoDataMessage text="noEntriesFound" />
            </div>
          </RenderIf>
          <RenderIf isTrue={personalNotes?.length > 0}>
            <div className="h-[17rem] overflow-y-auto rounded-[0.75rem] py-3 pl-3  md:py-7 md:pl-9 md:pr-4 bg-fields">
              <SimpleBarReact forceVisible="y" style={{ maxHeight: '100%', height: '100%' }}>
                <ul className="flex flex-col gap-6 pr-3 md:pr-5">
                  {personalNotes?.map(({
                    title, description, created_date, id
                  }: NoteTypes) => (
                    <li
                      key={id}
                      className="flex flex-col border-b cursor-pointer border-b-paragraph/50"
                      onClick={() => openNoteEditModalHandler({
                        title, description, created_date, id
                      })}
                      role="presentation"
                    >
                      <span className="flex items-center justify-between">
                        <h3 className="text-sm font-bold text-mdm-navy-blue">{title}</h3>
                        <p className="text-xs text-mdm-blue/70">{formattedDate(created_date)}</p>
                      </span>
                      <p className="w-full my-3 text-xs line-clamp-2 text-ellipsis text-paragraph">{description}</p>
                    </li>
                  ))}
                </ul>
                {' '}
              </SimpleBarReact>
            </div>

          </RenderIf>
        </RenderIf>
      </div>
    </>
  )
}
