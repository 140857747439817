function EmailPreviewFooter() {
  return (
    <div style={{
      boxSizing: 'border-box',
      margin: '30px auto 20px auto',
      maxWidth: '600px',
      width: '100%',
      borderRadius: '10px',
      height: 'auto',
      backgroundColor: '#1D1A55',
      textAlign: 'center',
      marginTop: '16px'
    }}
    >

      <div style={{
        textAlign: 'center', paddingTop: '10px', marginBottom: '10px'
      }}
      >
        <a
          href="https://mdmgreece.gr/"
          target="_blank"
          rel="noreferrer"
          style={{
            color: 'white'
          }}
        >
          mdmgreece.gr
        </a>
      </div>
      <div style={{
        textAlign: 'center'
      }}
      >
        <a href="https://www.facebook.com/mdmgreece.gr/" target="_blank" rel="noreferrer" style={{ display: 'inline-block', marginLeft: '10px', marginRight: '10px' }}>
          <img src="https://mdm-back-dev.konnekt-able.com/static/facebook-2.png" alt="facebook" />
        </a>
        <a href="https://www.instagram.com/mdm_greece/" target="_blank" rel="noreferrer" style={{ display: 'inline-block', marginRight: '10px' }}>
          <img src="https://mdm-back-dev.konnekt-able.com/static/instagram-2.png" alt="instagram" />
        </a>
        <a href="https://twitter.com/mdm_greece/" target="_blank" rel="noreferrer" style={{ display: 'inline-block', marginRight: '10px' }}>
          <img src="https://mdm-back-dev.konnekt-able.com/static/twitterx.png" alt="twitter" />
        </a>
        <a href="https://www.youtube.com/channel/UC3lNHxObcK6dWkRX8bV7ecg/" target="_blank" rel="noreferrer" style={{ display: 'inline-block', marginRight: '10px' }}>
          <img src="https://mdm-back-dev.konnekt-able.com/static/youtube.png" alt="youtube" />
        </a>
        <a href="https://www.linkedin.com/company/mdmgreece/" target="_blank" rel="noreferrer" style={{ display: 'inline-block', marginRight: '10px' }}>
          <img src="https://mdm-back-dev.konnekt-able.com/static/linkedin.png" alt="linkedin" />
        </a>
      </div>
      <div style={{
        textAlign: 'center', wordBreak: 'break-word', paddingBottom: '10px'
      }}
      >
        <small id="clientEmail" style={{ color: 'white', fontSize: '75%' }}>Το παρόν email εστάλη στο name@email.com</small>
      </div>

    </div>
  )
}

export default EmailPreviewFooter
