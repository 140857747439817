/* eslint-disable no-nested-ternary */
import { FieldInputProps, useFormikContext } from 'formik'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import routes from 'src/constants/routes'
import RenderIf from '../UI/RenderIf'
import InputErrorMessage from '../UI/InputErrorMsg'

interface PropTypes {
  inputId: string
  name: string
  getFieldProps: FieldInputProps<any>
  errors: boolean
  errorName: string | undefined
  type?: string
  width?: 'lg:w-1/3' | 'lg:w-1/2' | 'lg:w-full' | 'md:w-full' | 'w-full'
  marginX?: 'mx-4' | 'mx-2' | 'mx-0',
  marginY?: 'my-4' | 'my-0',
  marginR?: 'mr-4'
  options?: Array<{ value: string; label: string }>
  [key: string]: any
  min?: number | string,
  step?: string
  togglePasswordVisibility?: (name:string) => void,
  showPassword?: boolean,
  tags?: Array<{ title: string }>,
  addTag?: () => void,
  startTime?:string,
  endTime?:string,
  curDate?:string
  disabled?: boolean
}

function FormInput({
  inputId,
  name,
  inputMode,
  getFieldProps,
  errors,
  errorName,
  type = 'text',
  min,
  width = 'lg:w-1/3',
  marginX = 'mx-4',
  marginY = 'my-4',
  togglePasswordVisibility,
  showPassword,
  options,
  tags,
  step,
  addTag,
  marginR,
  startTime,
  endTime,
  curDate,
  disabled = false,
  ...restProps
}: PropTypes) {
  const { t } = useTranslation()
  const formik = useFormikContext();
  const { pathname } = useLocation();
  // eslint-disable-next-line max-len
  const isExactPath = pathname.includes(routes.shared.createContact) || pathname.includes(routes.shared.updateContact);
  const { selectedTask } = useModalContext();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: nameInput, value } = event.target;
    if ((nameInput === 'first_name' && isExactPath) || (nameInput === 'last_name' && isExactPath)) {
      // formik.setFieldValue(nameInput, value.toUpperCase());
      formik.setFieldValue(nameInput, value);
    } else {
      formik.setFieldValue(nameInput, value);
    }
  }
  let statusCSSClass;
  switch ((selectedTask?.status.toLowerCase())) {
    case ('completed'):
      statusCSSClass = 'text-[#00AF9C] bg-[#bfebe6]';
      break;
    case ('in_progress'):
      statusCSSClass = 'text-mdm-blue bg-mdm-sky-blue';
      break;
    default:
      statusCSSClass = '';
      break;
  }

  let statusCSSClassSpan;
  switch ((selectedTask?.status.toLowerCase())) {
    case ('completed'):
      statusCSSClassSpan = 'bg-[#00AF9C]';
      break;
    case ('in_progress'):
      statusCSSClassSpan = 'bg-mdm-blue';
      break;
    default:
      statusCSSClassSpan = '';
      break;
  }

  return (
    <div className={`relative text-sm md:text-regular w-full sm:w-full flex flex-col ${width} my-2 md:${marginY}`}>
      <div className="flex items-center justify-between">
        <label
          htmlFor={inputId}
          className={`md:${marginX} mb-2 text-paragraph whitespace-nowrap ${name === 'Email' ? 'font-sans' : ''}`}
        >
          {t(name)}
        </label>
        <RenderIf isTrue={!!(selectedTask && inputId === 'date')}>
          <div className={`${statusCSSClass} ${disabled ? 'opacity-70' : ''} flex items-center px-2 py-1 justify-center rounded-[12px] mb-2 gap-[5px] min-w-[115px] md:mr-4`}>
            <span className={`${statusCSSClassSpan} w-3 h-3 rounded-full flex-shrink-0`} />
            <p className="text-[0.75rem] font-bold">{t(selectedTask?.status.toLowerCase())}</p>
          </div>
        </RenderIf>
      </div>
      <RenderIf isTrue={!(name === 'tags')}>
        <input
          id={inputId}
          type={showPassword ? 'text' : type}
          inputMode={inputMode}
          {...getFieldProps}
          {...restProps}
          disabled={disabled}
          onChange={handleInputChange}
          value={
          inputId === 'date'
            ? getFieldProps.value || curDate || ''
            : getFieldProps.value || ''
           }
          className={`mx-0 md:${marginX} ${type === 'date' || disabled ? 'text-paragraph' : 'text-black'} border py-3.5 px-4 rounded-small h-12 ${errors ? 'border-red-700 focus:border-red-700 focus:ring-0' : 'border-lines focus:border-mdm-blue focus:ring-0'}`}
        />
      </RenderIf>
      <RenderIf isTrue={type === 'password'}>
        <button
          type="button"
          onClick={() => {
            if (togglePasswordVisibility) {
              togglePasswordVisibility(name);
            }
          }}
          className="absolute right-3 md:right-8 top-12 text-paragraph focus:outline-none"
        >
          {showPassword ? <BsEyeSlash size={21} /> : <BsEye size={21} />}
        </button>
      </RenderIf>
      <RenderIf isTrue={!!errors}>
        <div className={`md:${marginX} absolute w-full`}>
          <InputErrorMessage text={t(errorName!)} />
        </div>
      </RenderIf>
    </div>
  )
}

export default FormInput
