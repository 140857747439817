import formattedDate from './formattedDate';

function underscoreToUpperCase(value:string) {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;

  if (value.includes('_')) {
    return value
      .split('_')
      .map((word) => word)
      .join(' ');
  }

  if (datePattern.test(value)) {
    return formattedDate(value);
  }

  return value
}

export default underscoreToUpperCase
