const routes = {
  login: '/',
  forgotPassword: '/forgot-password',
  activateAccount: '/activate-account',
  resetPassword: '/reset-password',
  admin: {
    home: '/administrator',
    contacts: '/administrator/contacts',
    tasks: '/administrator/tasks',
    tasksList: '/administrator/tasks-list',
    donations: '/administrator/donations',
    warehouses: '/administrator/warehouses',
    users: '/administrator/users',
    settings: '/administrator/settings',
    newsletters: '/administrator/newsletters',
    campaignPreview: '/administrator/newsletters/create-newsletter/campaign-preview',
    designEmail: '/administrator/newsletters/create-newsletter/design-email',
    userGuide: '/admin/user-guide'
  },
  agent: {
    home: '/agent',
    contacts: '/agent/contacts',
    tasks: '/agent/tasks',
    tasksList: '/agent/tasks-list',
    donations: '/agent/donations',
    warehouses: '/agent/warehouses',
    users: '/agent/users',
    settings: '/agent/settings',
    newsletters: '/agent/newsletters',
    campaignPreview: '/agent/newsletters/create-newsletter/campaign-preview',
    designEmail: '/agent/newsletters/create-newsletter/design-email',
    userGuide: '/agent/user-guide'
  },
  shared: {
    createContact: 'create-contact',
    contactInfo: 'contact-info',
    updateContact: 'update-contact',
    createDonation: 'create-donation',
    goodsDonationInfo: 'goods-donation-info',
    financialDonationInfo: 'financial-donation-info',
    updateGoodsDonation: 'update-goods-donation',
    updateFinancialDonation: 'update-financial-donation',
    createWarehouse: 'create-warehouse',
    warehouseInfo: 'warehouse-info',
    updateWarehouse: 'update-warehouse',
    createNewsletter: 'create-newsletter',
    campaignDetails: 'campaign-details',
    userInfo: 'user-info',
    createUser: 'create-user',
    userUpdate: 'update-user'
  }
}

export default routes
