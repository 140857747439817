/* eslint-disable max-len */
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import routes from 'src/constants/routes'
import RenderIf from 'src/components/UI/RenderIf'
import Title from 'src/components/UI/Title'
import TaskModal from 'src/components/Modals/TaskModal'
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import TableSkeleton from 'src/components/Skeletons/TableSkeleton'
import {
  Tab, Tabs, TabList, TabPanel
} from 'react-tabs'
import { GetTaskTypes } from 'src/interfaces/tasks'
import apiService from 'src/services/api/apiService'
import moment from 'moment'
import userMethods from 'src/utils/userMethods'
import useGetData from 'src/hooks/useGetData'
import TaskListTable from '../components/TaskListTable'

function TasksList() {
  const [t] = useTranslation()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.admin.tasksList || routes.agent.tasksList
  const { openTaskModalHandler } = useModalContext()
  const [selected, setSelected] = useState(0);
  const [tasks, setTasks] = useState<GetTaskTypes[]>([])
  const [queryParams, setQueryParams] = useState<string | undefined>(undefined)
  const todayDate = moment().format('YYYY-MM-DD');
  const authUser = userMethods.getUser()

  const { data: tasksData, isLoading: isTasksLoading } = useGetData<GetTaskTypes[]>({
    queryKey: ['tasks', queryParams],
    queryFn: () => apiService
      .getTasks(queryParams)
  })

  useEffect(() => {
    document.title = `MDM CRM - ${t('tasksList')}`
  }, [t, tasksData])

  const handleClickOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    openTaskModalHandler()
  };

  const filterTasks = (filteredTasksByCalendar: GetTaskTypes[]) => filteredTasksByCalendar.map((task) => {
    const isAgent = authUser?.user?.user_type === 'AGENT';
    if (isAgent) {
      const departmentCalendarAssignees = task.calendar_type === 'DEPARTMENT' && authUser?.user?.departments?.some((dept) => dept.id === task.department?.id);
      const filteredAssigneesByAuthUser = departmentCalendarAssignees
        ? task.assignee
        : task.assignee.filter((assignee) => assignee.agent?.id === authUser?.user?.agent_id || task.assignee.some((a) => a.agent?.id === authUser?.user?.agent_id));
      const filteredDepartment = (task.calendar_type === 'DEPARTMENT' && departmentCalendarAssignees)
        ? authUser?.user?.departments?.find((dept) => dept.id === task.department?.id) || undefined
        : undefined;

      return {
        ...task,
        assignee: filteredAssigneesByAuthUser,
        department: filteredDepartment
      };
    }
    return task;
  }).filter((task) => {
    const hasAssignees = task.assignee?.length > 0;
    const hasNonEmptyDepartment = task.department && (Array.isArray(task.department) ? task.department.length > 0 : task.department);
    const isMeetingRoomReservation = task.calendar_type === 'MEETING_ROOM';

    return hasAssignees || hasNonEmptyDepartment || isMeetingRoomReservation;
  });

  // filter tasks by query
  const selectHandler = (index: number, last: number, e: Event) => {
    e.stopPropagation();
    setSelected(index);
    if (index === 0) {
      setQueryParams('')
    } else if (index === 1) {
      // expired today tasks
      setQueryParams(`start_time__gte=${todayDate}&end_time__lte=${moment(todayDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss')}`);
    } else if (index === 2) {
      // expired tasks
      setQueryParams(`start_time__lte=${todayDate}`)
    } else if (index === 3) {
      // scheduled tasks
      setQueryParams(`start_time__gte=${todayDate}`);
    }
  };

  useEffect(() => {
    const filteredAgentByCalendarTasks = filterTasks(tasksData || []);
    setTasks(filteredAgentByCalendarTasks);
  }, [tasksData]);

  return (
    <section>
      <TaskModal />
      <RenderIf isTrue={!!isExactPath}>
        <Title title="tasksList" add="createTask" onClickOpenModalHandler={handleClickOpenModal} />
        <RenderIf isTrue={isTasksLoading}>
          {' '}
          <TableSkeleton />
        </RenderIf>
        <RenderIf isTrue={!isTasksLoading}>
          <Tabs selectedIndex={selected} onSelect={selectHandler} className="relative">
            <TabList className="flex absolute top-[-3.15rem] md:top-[-3.5rem] lg:top-[-3.75rem] xl:top-[-3.75rem] z-0 w-full">
              <Tab
                className={`px-4 pt-[1.375rem] cursor-pointer pb-[0.75rem] sm:px-8 custom-screen:px-14 xl:px-16 text-xxs md:text-sm lg:text-regular outline-none whitespace-nowrap flex items-center ${
                  selected === 0 ? 'bg-white text-mdm-blue font-bold rounded-t-2xl -mt-1' : 'bg-[#ebf5f9] p text-lines rounded-tl-2xl z-[-1] mr-[-0.94rem]'
                }`}
              >
                <span className="hidden sm:inline">{t('allTasks')}</span>
                <span className="lg:hidden">{t('all')}</span>
              </Tab>
              <Tab
                className={`px-4 pt-[1.375rem] pb-[0.75rem] sm:px-8 custom-screen:px-14 xl:px-16 outline-none flex items-center justify-center text-xxs whitespace-nowrap md:text-sm cursor-pointer lg:text-regular   ${
                  selected === 1 ? 'bg-white text-mdm-blue font-bold rounded-t-2xl -mt-1' : 'bg-[#ebf5f9] text-lines z-[-1] ml-[-15px] mr-[-0.94rem]'
                }`}
              >
                <span className="hidden sm:inline">{t('expiredTodayTasks')}</span>
                <span className="md:hidden">{t('expiredTodayTasksShort')}</span>
              </Tab>
              <Tab
                className={`px-3 pt-[1.375rem] pb-[0.75rem] sm:px-8 custom-screen:px-14 xl:px-16 outline-none flex cursor-pointer items-center text-xxs md:text-sm lg:text-regular ${
                  selected === 2 ? 'bg-white text-mdm-blue font-bold rounded-t-2xl -mt-1' : 'rounded-tr-2xl bg-[#ebf5f9] text-lines z-[-1] ml-[-1.25rem] md:ml-[-2.5rem]'
                }`}
              >
                {t('expiredTasks')}
              </Tab>
              <Tab
                className={`px-3 pt-[1.375rem] pb-[0.75rem] sm:px-6 custom-screen:px-12 cursor-pointer xl:px-14 outline-none flex items-center text-xxs md:text-sm lg:text-regular ${
                  selected === 3 ? 'bg-white text-mdm-blue font-bold rounded-t-2xl -mt-1 -ml-3' : 'rounded-tr-2xl bg-[#ebf5f9] text-lines z-[-1] ml-[-1.25rem] md:ml-[-2.5rem]'
                }`}
              >
                {t('scheduledTasks')}
              </Tab>
            </TabList>
            <TabPanel>
              <TaskListTable tasks={tasks} />
            </TabPanel>
            <TabPanel>
              <TaskListTable tasks={tasks} />
            </TabPanel>
            <TabPanel>
              <TaskListTable tasks={tasks} />
            </TabPanel>
            <TabPanel>
              <TaskListTable tasks={tasks} />
            </TabPanel>
          </Tabs>
        </RenderIf>
      </RenderIf>
    </section>
  )
}

export default TasksList
