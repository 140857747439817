/* eslint-disable react/no-children-prop */
import { useEffect, useState } from 'react'
import user_guide from 'src/assets/docs/GR - Médecins du Monde Greece Customer Relationship Management (CRM) User Guide.md'
import Markdown from 'react-markdown'

export default function UserGuideContent() {
  const [userGuide, setUserGuide] = useState('')

  useEffect(() => {
    fetch(user_guide).then((res) => res.text()).then((text) => setUserGuide(text))
  })

  return (
    <div className="text-sm md:text-regular">
      <Markdown children={userGuide} />
    </div>
  )
}
