import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { FaUser } from 'react-icons/fa'
import routes from 'src/constants/routes'

interface Props {
  path: string
}

function AdminUsersNav({ path }: Props) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const activePath = pathname.split('/')[2]
  const isActiveLink = path.includes(activePath) || pathname === path

  return (
    <div className={`hidden custom-screen-2:flex px-4 py-1 transition-all duration-200 cursor-pointer text-regular hover:text-mdm-sky-blue ${isActiveLink ? 'font-bold text-mdm-sky-blue' : 'text-white text-regular'}`}>
      <Link to={routes.admin.users} className="flex items-center gap-2">
        <FaUser className="flex-shrink-0" />
        {t('users')}
      </Link>
      <div className={`${isActiveLink ? 'active-link-border -bottom-[4rem] custom-screen-2:hidden absolute -left-7 -right-7 h-[3.75rem] transition-transform duration-200 scale-100' : 'scale-0'}`} />
    </div>
  )
}

export default AdminUsersNav
