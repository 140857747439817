import {
  useLocation, useNavigate, Outlet
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useMemo } from 'react'
import routes from 'src/constants/routes'
import RenderIf from 'src/components/UI/RenderIf'
import TableSkeleton from 'src/components/Skeletons/TableSkeleton'
import Title from 'src/components/UI/Title'
import {
  Tab, Tabs, TabList, TabPanel
} from 'react-tabs'
import { WarehouseTypes } from 'src/interfaces/warehouses'
import 'react-tabs/style/react-tabs.css';
import useGetData from 'src/hooks/useGetData'
import apiService from 'src/services/api/apiService'
import WarehousesTable from './components/WarehousesTable'

function Warehouses() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isExactPath = pathname === routes.admin.warehouses || pathname === routes.agent.warehouses;
  const [selected, setSelected] = useState(0);
  const [filteredWarehouses, setFilteredWarehouses] = useState<WarehouseTypes[]>([]);

  // get warehouses and translate
  const { data: warehousesData, isLoading: isWarehousesLoading } = useGetData<WarehouseTypes[]>({
    queryKey: 'warehouses',
    queryFn: apiService.getWarehouses
  })

  const warehouses = useMemo(() => {
    if (warehousesData) {
      return warehousesData.map((warehouse) => ({
        ...warehouse,
        location: t(warehouse.location),
        kind: t(warehouse.kind.toLowerCase())
      }));
    }
    return [];
  }, [warehousesData, t]);

  useEffect(() => {
    document.title = `MDM CRM - ${t('warehouse')}`;
  }, [t]);

  useEffect(() => {
    if (selected === 0) {
      setFilteredWarehouses(warehouses);
    } else if (selected === 1) {
      const athensWarehouses = warehouses?.filter((warehouse: WarehouseTypes) => warehouse.location === t('athens'));
      setFilteredWarehouses(athensWarehouses);
    } else if (selected === 2) {
      const ukraineWarehouses = warehouses?.filter((warehouse: WarehouseTypes) => warehouse.location === t('ukraine'));
      setFilteredWarehouses(ukraineWarehouses);
    }
  }, [selected, warehouses]);

  const selectHandler = (index: number, last: number, e: Event) => {
    e.stopPropagation();
    setSelected(index);
  };

  return (
    <section>
      <RenderIf isTrue={isExactPath}>
        <>
          <Title
            title="warehouse"
            add="createRegistration"
            onClickHandler={() => navigate(routes.shared.createWarehouse)}
          />
          <RenderIf isTrue={isWarehousesLoading}>
            <TableSkeleton />
          </RenderIf>
        </>
        <RenderIf isTrue={!isWarehousesLoading}>
          <Tabs selectedIndex={selected} onSelect={selectHandler} className="relative">
            <TabList className="flex absolute top-[-3.47rem] md:top-[-3.8rem] z-0 w-full mx-auto flex-nowrap">
              <Tab
                className={`px-8 pt-[1.375rem] pb-[0.75rem] md:px-10 cursor-pointer text-sm md:text-regular outline-none flex items-end ${
                  selected === 0 ? 'bg-white text-mdm-blue font-bold rounded-t-2xl -mt-1' : 'bg-[#ebf5f9] p text-lines rounded-tl-2xl z-[-1] mr-[-0.94rem]'
                }`}
              >
                <span className="hidden sm:inline">{t('allLocations')}</span>
                <span className="sm:hidden">{t('all')}</span>
              </Tab>
              <Tab
                className={`px-9 pt-[1.375rem] cursor-pointer pb-[0.75rem] md:px-20 outline-none flex items-end text-sm md:text-regular  ${
                  selected === 1 ? 'bg-white text-mdm-blue font-bold rounded-t-2xl -mt-1' : 'bg-[#ebf5f9] text-lines z-[-1] ml-[-15px] mr-[-0.94rem]'
                }`}
              >
                {t('Athens')}
              </Tab>
              <Tab
                className={`px-8 pt-[1.375rem] cursor-pointer pb-[0.75rem] md:px-20  outline-none flex items-end text-sm md:text-regular  ${
                  selected === 2 ? 'bg-white text-mdm-blue font-bold rounded-t-2xl -mt-1' : 'rounded-tr-2xl bg-[#ebf5f9] text-lines z-[-1] ml-[-1.25rem] md:ml-[-2.5rem]'
                }`}
              >
                {t('Ukraine')}
              </Tab>
            </TabList>
            <TabPanel>
              <WarehousesTable warehouses={filteredWarehouses} />
            </TabPanel>
            <TabPanel>
              <RenderIf isTrue={isExactPath}>
                <RenderIf isTrue={!isWarehousesLoading}>
                  <WarehousesTable warehouses={filteredWarehouses} />
                </RenderIf>
              </RenderIf>
            </TabPanel>
            <TabPanel>
              <RenderIf isTrue={isExactPath}>
                <RenderIf isTrue={!isWarehousesLoading}>
                  <WarehousesTable warehouses={filteredWarehouses} />
                </RenderIf>
              </RenderIf>
            </TabPanel>
          </Tabs>
        </RenderIf>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Outlet context={warehouses} />
      </RenderIf>
    </section>
  );
}

export default Warehouses;
