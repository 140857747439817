import { IoMdClose, IoMdRemove } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from 'src/constants/routes';
import RenderIf from './RenderIf';

interface BadgeProps {
  text: string;
  textSize?: string;
  paddingX?:string;
  paddingY?: string;
  cursor?: 'cursor-pointer' | 'cursor-auto';
  textColor?: string;
  tagId?: string | number;
  deleteTag?: (variables: { id: string | number }) => void;
  deleteSelectedTag?: (id: string | number) => void;
  selectedTagId?: string | number;
  isSelected?: boolean;
}

function Badge({
  text,
  textColor = 'text-mdm-navy-blue',
  tagId,
  cursor = 'cursor-pointer',
  paddingX = 'px-3',
  paddingY = 'py-1',
  textSize = 'text-[0.75rem]',
  isSelected = false,
  deleteTag,
  deleteSelectedTag,
  selectedTagId
}: BadgeProps) {
  const { pathname } = useLocation()
  const isExactPath = pathname.includes(routes.shared.createContact)
|| pathname.includes(routes.shared.updateContact)
  const { t } = useTranslation();

  const handleDeleteTagClick = (event: React.MouseEvent<SVGElement>) => {
    event.stopPropagation();
    if (deleteTag && tagId) {
      deleteTag({ id: tagId });
    }
  }

  const handleDeleteSelectedTagClick = () => {
    if (deleteSelectedTag && selectedTagId) {
      deleteSelectedTag(selectedTagId);
    }
  }

  return (
    <span className={`flex ${cursor} min-w-max items-center mb-1 justify-center gap-2 mr-2 ${paddingX} ${paddingY} w-auto font-sans rounded-full  ${isSelected ? 'bg-mdm-sky-blue/30 cursor-not-allowed' : 'bg-mdm-sky-blue text-white cursor-pointer'}`}>
      <p className={`${textColor} ${textSize} font-semibold`}>{text}</p>
      <RenderIf isTrue={isExactPath}>
        {deleteTag && tagId && (
          <IoMdClose
            size={18}
            className="transition-colors duration-200 text-paragraph hover:text-[#BD1118] cursor-pointer"
            onClick={handleDeleteTagClick}
            title={t('deleteTag')}
          />
        )}
        {deleteSelectedTag && selectedTagId && (
          <IoMdRemove
            size={18}
            className="transition-colors duration-200 cursor-pointer text-paragraph hover:text-mdm-navy-blue"
            onClick={handleDeleteSelectedTagClick}
            title={t('removeTagFromChosenTags')}
          />
        )}
      </RenderIf>
    </span>
  );
}

export default Badge
