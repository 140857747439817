import { useTranslation } from 'react-i18next'

interface Props {
  title: string
}

export default function SubTitle({ title }: Props) {
  const { t } = useTranslation()
  return (
    <div className="py-4"><h2 className="font-bold text-mdm-navy-blue text-[1.38rem]">{t(title)}</h2></div>
  )
}
