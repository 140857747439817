import { UserRoleTypes } from 'src/interfaces/user'
import AdminNav from './UserRoleNav/AdminNav/AdminNav'
import RenderIf from '../UI/RenderIf'
import AgentNav from './UserRoleNav/AgentNav/AgentNav'

export default function Navigation({ userRole }: UserRoleTypes) {
  return (
    <nav className="hidden h-full custom-screen-2:flex lg:gap-16">
      <RenderIf isTrue={userRole === 'ADMINISTRATOR'}>
        <AdminNav />
      </RenderIf>
      <RenderIf isTrue={userRole === 'AGENT'}>
        <AgentNav />
      </RenderIf>
    </nav>
  )
}
