/* eslint-disable react/no-unstable-nested-components */
import { useTranslation } from 'react-i18next'
import { useMemo, useEffect, useState } from 'react'
import UserMethods from 'src/utils/userMethods'
import { useNavigate } from 'react-router-dom'
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import TableActionBtns from 'src/components/Table/TableActionBtns'
import Table from 'src/components/Table/Table'
import { NewsletterTypes } from 'src/interfaces/newsletters'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import routes from 'src/constants/routes'
import { useMailingList } from 'src/context/MailingListCtx/MailingListProvider'
import { useNewsletters } from 'src/context/NewsletterCtx/NewslettersProvider'
import newsletter_draft from 'src/assets/images/newsletter_draft.svg'
import newsletter_send from 'src/assets/images/newsletter_send.svg'
import formattedDate from 'src/utils/formattedDate'
import sortByDate from 'src/utils/sortByDate'
import capitalize from 'src/utils/capitalize'

interface Props {
  newsletters: NewsletterTypes[]
}

function NewslettersTable({ newsletters = [] }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const authUser = UserMethods.getUser()
  const { openConfirmationModalHandler } = useModalContext()
  const { onResetNewsletterHandler } = useNewsletters()
  const { onEditNewsletterHandler, onResetMailingListHandler } = useMailingList()
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const { mutate: sendNewsletter } = useMutateData({
    key: 'newsletters',
    mutationFn: apiService.sendNewsletter,
    successMessage: 'newsletterHasBeenSend'
  })

  interface OriginalTypes {
    cell: { row: { original: NewsletterTypes } }
  }

  const handleCheckboxClick = (rowId: string) => {
    setSelectedRows((prevOptions) => {
      if (prevOptions.includes(rowId)) {
        return prevOptions.filter((option) => option !== rowId);
      }
      return [...prevOptions, rowId];
    });
  };

  // Reset Newsletter state on mount
  useEffect(() => {
    onResetNewsletterHandler()
    onResetMailingListHandler()
  }, [onResetNewsletterHandler, onResetMailingListHandler])

  const columns = useMemo(() => [
    {
      accessor: 'selected',
      Cell: ({ row }: { row: any }) => (
        <input
          type="checkbox"
          value="checkbox"
          className="custom-checkbox"
          onClick={(e) => {
            e.stopPropagation();
            handleCheckboxClick(row.original.id);
          }}
        />
      )
    },
    {
      Header: t('campaignName'),
      accessor: 'name',
      Cell: ({ value }:{ value:string }) => <p>{value || '-'}</p>
    },
    {
      Header: t('emailSubject'),
      accessor: 'email_subject',
      Cell: ({ value }:{ value:string }) => <p title={value} className="truncate max-w-[180px]">{value}</p>
    },
    {
      Header: t('emailList'),
      accessor: 'mailing_lists[0].name',
      Cell: ({ value }:{ value:string }) => <p>{value || '-'}</p>
    },
    {
      Header: t('creationDate'),
      accessor: (row: { created_date : string }) => `${formattedDate(row.created_date)}`,
      Cell: ({ row }: any) => <p>{formattedDate(row.original.created_date)}</p>,
      sortType: (a: any, b: any) => sortByDate(a.original.created_date, b.original.created_date)
    },
    {
      Header: t('state'),
      accessor: 'status',
      Cell: ({ value }:{ value:string }) => (
        <div className="flex gap-3">
          {value === 'DRAFT' ? <img src={newsletter_draft} alt="" /> : <img src={newsletter_send} alt="" />}
          <p className={`font-sans ${value === 'DRAFT' ? 'text-[#E9511C]' : 'text-#00AF9C'}`}>{capitalize(value)}</p>
        </div>
      )
    },
    {
      Header: t('actions'),
      Cell: ({
        cell: {
          row: { original }
        }
      }:OriginalTypes) => (
        <TableActionBtns
          onClickHandlerSend={() => sendNewsletter(original.id)}
          onClickHandlerEdit={() => {
            onEditNewsletterHandler(original)
            navigate(`/${authUser?.user.user_type.toLowerCase()}/newsletters/${routes.shared.createNewsletter}/`)
          }}
          onClickHandlerDelete={() => openConfirmationModalHandler('deleteNewsletterMessage', {
            id: original.id,
            userRole: null
          })}

        />
      )
    }
  ], [t, openConfirmationModalHandler, navigate, onEditNewsletterHandler, sendNewsletter]);

  return (
    <Table
      columns={columns}
      data={newsletters}
      storageKey="newslettersTable"
      rowsPerPage={5}
      selectedRows={selectedRows}
    />
  )
}

export default NewslettersTable
