/* eslint-disable max-len */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker from 'src/components/Calender/DatePicker'
import Title from 'src/components/UI/Title'
import NotesPanel from 'src/components/UI/NotesPanel'
import LatestContactsRegistrationPanel from 'src/components/UI/LatestContactsRegistationPanel'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import userMethods from 'src/utils/userMethods'
import TaskModal from 'src/components/Modals/TaskModal'
import { GetTaskTypes } from 'src/interfaces/tasks'
import RenderIf from 'src/components/UI/RenderIf'
import DashboardTableSkeleton from 'src/components/Skeletons/DashboardTableSkeleton'
import TaskListTable from '../Common/pages/Tasks/components/TaskListTable'

function AgentDashboard() {
  const { t } = useTranslation()
  const { tasks: tasksData, isTasksLoading } = useDataProvider()
  const [tasks, setTasks] = useState<GetTaskTypes[]>([])
  const authUser = userMethods.getUser()

  useEffect(() => {
    document.title = `MDM CRM - ${t('homePage')}`
  }, [t])

  const filterTasks = (filteredTasksByCalendar: GetTaskTypes[]) => filteredTasksByCalendar.map((task) => {
    const isAgent = authUser?.user?.user_type === 'AGENT';
    if (isAgent) {
      const departmentCalendarAssignees = task.calendar_type === 'DEPARTMENT' && authUser?.user?.departments?.some((dept) => dept.id === task.department?.id);
      const filteredAssigneesByAuthUser = departmentCalendarAssignees
        ? task.assignee
        : task.assignee.filter((assignee) => assignee.agent?.id === authUser?.user?.agent_id || task.assignee.some((a) => a.agent?.id === authUser?.user?.agent_id));
      const filteredDepartment = (task.calendar_type === 'DEPARTMENT' && departmentCalendarAssignees)
        ? authUser?.user?.departments?.find((dept) => dept.id === task.department?.id) || undefined
        : undefined;

      return {
        ...task,
        assignee: filteredAssigneesByAuthUser,
        department: filteredDepartment
      };
    }
    return task;
  }).filter((task) => {
    const hasAssignees = task.assignee?.length > 0;
    const hasNonEmptyDepartment = task.department && (Array.isArray(task.department) ? task.department.length > 0 : task.department);
    const isMeetingRoomReservation = task.calendar_type === 'MEETING_ROOM';

    return hasAssignees || hasNonEmptyDepartment || isMeetingRoomReservation;
  });

  useEffect(() => {
    const filteredAgentByCalendarTasks = filterTasks(tasksData || []);
    setTasks(filteredAgentByCalendarTasks);
  }, [tasksData]);

  return (
    <section>
      <Title title={t('homePage')} add="" />
      <div className="grid grid-cols-1 place-items-center lg:place-items-start lg:grid-cols-[375px_minmax(500px,1fr)] mb-7 gap-9">
        <TaskModal />
        <DatePicker
          tasks={tasks}
        />
        <RenderIf isTrue={isTasksLoading}>
          <DashboardTableSkeleton />
        </RenderIf>
        <RenderIf isTrue={!isTasksLoading}>
          <TaskListTable tasks={tasks} isDashboardTable />
        </RenderIf>
      </div>
      <div className="flex flex-col gap-5 lg:flex-row lg:gap-9">
        <LatestContactsRegistrationPanel />
        <NotesPanel />
      </div>
    </section>
  )
}

export default AgentDashboard
