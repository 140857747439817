import { useEffect } from 'react'
import { useOutletContext, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageSection from 'src/components/UI/PageSection'
import PageTitle from 'src/components/UI/PageTitle'
import DetailsInfoBtns from 'src/components/UI/DetailsInfoBtns'
import routes from 'src/constants/routes'
import UserMethods from 'src/utils/userMethods'
import { UserInfoTypes } from 'src/interfaces/user'
import RenderIf from 'src/components/UI/RenderIf'
import UserInfoSkeleton from 'src/components/Skeletons/UserInfoSkeleton'
import UserDetails from '../components/UserDetails'

function UserInfo() {
  const navigate = useNavigate()
  const params = useParams()
  const users = useOutletContext<UserInfoTypes[]>()
  const parsedId = params.id ? parseInt(params.id, 10) : undefined;
  const user = users?.find((prd) => prd.id === parsedId);
  const authUser = UserMethods.getUser()
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `MDM CRM - ${t('user')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text="user" id={user?.id} />
      <RenderIf isTrue={user !== undefined}>
        <UserDetails user={user as UserInfoTypes} />
        <DetailsInfoBtns
          onClickHandler={() => {
            navigate(`/${authUser?.user.user_type.toLowerCase()}/users/${routes.shared.userUpdate}/${user?.user_type.toLowerCase()}-${user?.agent_id || user?.admin_id}`);
          }}
          btnText="edit"
        />
      </RenderIf>
      <RenderIf isTrue={user === undefined}>
        <UserInfoSkeleton />
      </RenderIf>
    </PageSection>
  )
}

export default UserInfo
