/* eslint-disable max-len */
import { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi';
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider';
import userMethods from 'src/utils/userMethods';
import RenderIf from '../UI/RenderIf';
import TaskPanelFilterSkeleton from '../Skeletons/TaskPanelFilterSkeleton';

interface Props {
  handleCheckboxClick: (value: string, departmentId?: number) => void;
}

function TaskFilterPanel({ handleCheckboxClick } : Props) {
  const [isExpanded, setExpanded] = useState<boolean>(true)
  const { t } = useTranslation()
  const authUser = userMethods.getUser()
  const { departments, isDepartmentsLoading } = useDataProvider()
  const [isAdminDepartmentsCheckboxesChecked, setIsAdminDepartmentsCheckboxesChecked] = useState<{ [id: string]: boolean }>({});
  const [isAgentDepartmentsCheckboxesChecked, setIsAgentDepartmentsCheckboxesChecked] = useState<{ [id: string]: boolean }>({});
  const [isMeetingRoomCalendarCheckboxChecked, setIsMeetingRoomCalendarCheckboxChecked] = useState(true);
  const [isDepartmentCalendarCheckboxChecked, setIsDepartmentCalendarCheckboxChecked] = useState(false);
  const [isPersonalCalendarCheckboxChecked, setIsPersonalCalendarCheckboxChecked] = useState(true)

  const toggleClickHandler = () => {
    setExpanded(!isExpanded);
  };

  // makes departments checkboxes checked by default for admin
  useEffect(() => {
    if (departments) {
      const initialCheckboxes: { [id: string]: boolean } = {};
      departments.forEach((department) => {
        initialCheckboxes[department.id] = true;
      });
      setIsAdminDepartmentsCheckboxesChecked(initialCheckboxes);
    }
  }, [departments]);

  // makes departments checkboxes checked by default for agent with multiply departments
  useEffect(() => {
    if (authUser?.user.departments) {
      // creates a flag to check whether to update the state
      let shouldUpdateState = false;

      const initialCheckboxes: { [id: string]: boolean } = {};
      authUser.user.departments.forEach((department) => {
        if (!isAgentDepartmentsCheckboxesChecked[department.id]) {
          initialCheckboxes[department.id] = true;
          shouldUpdateState = true;
        }
      });

      if (shouldUpdateState) {
        setIsAgentDepartmentsCheckboxesChecked((prevState) => ({
          ...prevState,
          ...initialCheckboxes
        }));
      }

      if (authUser.user.departments.length === 1) {
        setIsDepartmentCalendarCheckboxChecked(true);
      }
    }
  }, []);

  return (
    <div className="md:w-[375px] w-full">
      <RenderIf isTrue={!!(isDepartmentsLoading)}>
        <TaskPanelFilterSkeleton />
      </RenderIf>
      <RenderIf isTrue={!(isDepartmentsLoading)}>
        <div className="flex flex-col p-6 bg-white gap-7 shadow-faint rounded-regular">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div className="flex justify-between cursor-pointer" onClick={toggleClickHandler}>
            <h2 className="text-[1.38rem] font-bold">{t('calendars')}</h2>
            <div className="flex items-center justify-center px-2 duration-150 border text-lines hover:text-paragraph hover:border-paragraph border-lines rounded-small translate-all">
              {isExpanded ? <HiOutlineChevronUp className="w-4 h-4" aria-hidden="true" title={t('collapse')} /> : <HiOutlineChevronDown className="w-4 h-4" aria-hidden="true" title={t('expand')} />}
            </div>
          </div>
          {isExpanded && (
          <div className="flex flex-col gap-4 text-paragraph text-regular">
            <label htmlFor="personal" className="inline-flex items-center mb-2">
              <input
                id="personal"
                type="checkbox"
                name="options"
                value="personal"
                checked={isPersonalCalendarCheckboxChecked}
                className="custom-checkbox-calender-filter"
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  setIsPersonalCalendarCheckboxChecked(target.checked);
                  handleCheckboxClick('PERSONAL');
                }}
              />
              <span className="ml-2">{t('personal')}</span>
            </label>
            <RenderIf isTrue={!!(authUser?.user.user_type === 'ADMINISTRATOR')}>
              {departments?.map((department) => (
                <Fragment key={department.id}>
                  <label htmlFor={`department_${department.title}`} className="inline-flex items-center mb-2" key={department.id}>
                    <input
                      id={`department_${department.title}`}
                      type="checkbox"
                      name={`department_${department.title}`}
                      value={department.id}
                      className="custom-checkbox-calender-filter"
                      checked={isAdminDepartmentsCheckboxesChecked[department.id] || false}
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        const updatedCheckboxes = { ...isAdminDepartmentsCheckboxesChecked };
                        updatedCheckboxes[department.id] = target.checked;
                        setIsAdminDepartmentsCheckboxesChecked(updatedCheckboxes);
                        Object.keys(isAdminDepartmentsCheckboxesChecked)
                          .filter((departmentId) => isAdminDepartmentsCheckboxesChecked[departmentId])
                          .join(',');
                        handleCheckboxClick('DEPARTMENT', department.id);
                      }}
                    />
                    <span className="ml-2">
                      {t('department')}
                      {' '}
                      {t(department.title?.toLowerCase())}
                    </span>
                  </label>
                </Fragment>

              ))}
            </RenderIf>
            <RenderIf isTrue={!!(authUser?.user.user_type === 'AGENT')}>
              {authUser?.user?.departments?.map((department) => (
                <Fragment key={department.id}>
                  <label htmlFor={`department_${department.title}`} className="inline-flex items-center mb-2">
                    <input
                      id={`department_${department.title}`}
                      key={department.id}
                      type="checkbox"
                      name={`department_${department.title}`}
                      checked={authUser?.user.departments && authUser?.user.departments.length > 1
                        ? (isAgentDepartmentsCheckboxesChecked[department.id] || false) : isDepartmentCalendarCheckboxChecked}
                      value={department.id}
                      className="custom-checkbox-calender-filter"
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        // agent with one department
                        if (authUser?.user.departments && authUser?.user.departments.length === 1) {
                          setIsDepartmentCalendarCheckboxChecked(target.checked);
                          handleCheckboxClick('DEPARTMENT', department.id);
                        } else if (authUser?.user.departments && authUser?.user.departments.length > 1) {
                          // agent with multiply departments
                          const updatedCheckboxes = { ...isAgentDepartmentsCheckboxesChecked };
                          updatedCheckboxes[department.id] = target.checked;
                          setIsAgentDepartmentsCheckboxesChecked(updatedCheckboxes);
                          handleCheckboxClick('DEPARTMENT', department.id);
                        }
                      }}
                    />
                    <span className="ml-2">
                      {t('department')}
                      {' '}
                      {t(department.title?.toLowerCase())}
                    </span>
                  </label>
                </Fragment>
              ))}
            </RenderIf>
            <label htmlFor="meeting_form" className="inline-flex items-center mb-2">
              <input
                id="meeting_form"
                type="checkbox"
                name="meeting_room"
                value="meeting_room"
                className="custom-checkbox-calender-filter"
                checked={isMeetingRoomCalendarCheckboxChecked}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  setIsMeetingRoomCalendarCheckboxChecked(target.checked);
                  handleCheckboxClick('MEETING_ROOM');
                }}
              />
              <span className="ml-2">{t('meetingRoom')}</span>
            </label>
          </div>
          )}

        </div>
        {' '}

      </RenderIf>
    </div>
  );
}

export default TaskFilterPanel;
