/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactNode } from 'react'
import { ColumnInstance } from 'react-table'
import { useTranslation } from 'react-i18next'
import useToggleMenu from 'src/hooks/useToggleMenu'
import { StorageKeyTypes } from 'src/interfaces/table'
import Dropdown from '../Dropdown/Dropdown'
import TableColumnsConfigurationButton from './TableColumnsConfigurationButton'

interface Props {
  allColumns: ColumnInstance<object>[],
  storageKey: StorageKeyTypes
  children?: ReactNode
  isDashboardTable?: boolean
}

function TableColumnsConfigurationDropdown({
  allColumns, isDashboardTable, storageKey, children
}:Props) {
  const { t } = useTranslation()
  const { ref, isOpen, setIsOpen } = useToggleMenu()
  const handleDropdownMenu = () => setIsOpen((prevState) => !prevState)

  const storeSelectedColumnsHandler = (columnId: string) => {
    const addedColumns = JSON.parse(localStorage.getItem(storageKey)!)
    if (addedColumns) {
      if (addedColumns.includes(columnId)) {
        localStorage
          .setItem(
            storageKey,
            JSON.stringify(addedColumns.filter((column: string) => column !== columnId))
          )
      } else {
        localStorage.setItem(storageKey, JSON.stringify([...addedColumns, columnId]))
      }
    } else {
      localStorage.setItem(storageKey, JSON.stringify([columnId]))
    }
  }

  return (
    <div ref={ref} className="relative flex items-center h-full py-2">
      <TableColumnsConfigurationButton
        onClickHandler={handleDropdownMenu}
        isDashboardTable={isDashboardTable}
      />
      <Dropdown isOpen={isOpen}>
        {children}
        {allColumns.slice(storageKey === 'contactsTable' ? 2 : 1).map((col) => (
          <React.Fragment key={col.id}>
            {col.id !== 'selected' && !(col.Header === t('actions') || col.Header === t('editDelete') || col.Header === ' ') && (
            <div
              className="flex items-center gap-4 px-4 py-2"
              onClick={() => storeSelectedColumnsHandler(col.id)}
            >
              <input
                type="checkbox"
                {...col.getToggleHiddenProps()}
                className="custom-checkbox"
              />
              <p className="text-left whitespace-nowrap text-mdm-navy-blue">
                {col.Header?.toString()}
              </p>
            </div>
            )}
          </React.Fragment>
        ))}
      </Dropdown>
    </div>
  )
}

export default TableColumnsConfigurationDropdown
