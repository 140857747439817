import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { DonationTypes, SelectedDonationsTypes } from 'src/interfaces/donations';
import exception from 'src/utils/exception';
import { ServerError } from 'src/interfaces/api';
import { DonationsServiceInterface } from './donationService.interface';
import axiosInstance from '../axiosInstance';

class DonationsService implements DonationsServiceInterface {
  public async getAllDonations(): Promise<DonationTypes[] | null> {
    try {
      const response = await axiosInstance.get('/donations/donation/all/');
      const mergedDonations: DonationTypes[] = [];

      Object.values(response.data.data).forEach((donationsArray: unknown) => {
        if (Array.isArray(donationsArray)) {
          mergedDonations.push(...donationsArray);
        }
      });
      mergedDonations.sort((a, b) => new Date(b.registration_date).getTime()
      - new Date(a.registration_date).getTime());
      return mergedDonations
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>);
      }
      return null;
    }
  }

  public async createDonation(body: any, donationType: string): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.post(`donations/${donationType}-donation/`, body)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async getGoodsDonation(valueId: any): Promise<DonationTypes | null> {
    try {
      const response = await axiosInstance.get(`/donations/goods-donation/${valueId}`)
      return response.data.data as DonationTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateGoodsDonation(values: any): Promise<AxiosResponse<any, any> | null> {
    try {
      const formData = new FormData();
      Object.entries(values).forEach((entry: any) => {
        const [key, value] = entry;
        formData.append(key, value as any);
      });
      const response = await axiosInstance.patch(`/donations/goods-donation/${values.id}/`, formData);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>);
      }
    }
    return null;
  }

  public async getFinancialDonation(valueId: any): Promise<DonationTypes | null> {
    try {
      const response = await axiosInstance.get(`/donations/financial-donation/${valueId}`)
      return response.data.data as DonationTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async updateFinancialDonation(values: any): Promise<AxiosResponse<any, any> | null> {
    try {
      const formData = new FormData();
      Object.entries(values).forEach((entry: any) => {
        const [key, value] = entry;
        formData.append(key, value as any);
      });
      const response = await axiosInstance.patch(`/donations/financial-donation/${values.id}/`, formData);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>);
      }
    }
    return null;
  }

  // eslint-disable-next-line max-len
  public async updateDonationStatus(id: string | number, endPoint: string, isDonationActive: any): Promise<AxiosResponse | null> {
    try {
      const formData = new FormData();
      formData.append('is_active', isDonationActive.data);
      const response = await axiosInstance.patch(`/donations/${endPoint}/${id}/`, formData);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>);
      }
    }
    return null;
  }

  public async exportAndDownloadDonations(selectedDonations: SelectedDonationsTypes):
  Promise<AxiosResponse> {
    const headers = { 'Content-Type': 'application/json' };

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/donations/donation/export/',
      responseType: 'arraybuffer',
      headers
    };

    if (selectedDonations.financial_ids.length > 0 && selectedDonations.goods_ids.length === 0) {
      config.data = {
        financial_ids: selectedDonations.financial_ids
      };
    } else if (selectedDonations.financial_ids.length === 0
    && selectedDonations.goods_ids.length > 0) {
      config.data = {
        goods_ids: selectedDonations.goods_ids
      };
    } else if (selectedDonations.financial_ids.length > 0
     && selectedDonations.goods_ids.length > 0) {
      config.data = selectedDonations;
    }

    try {
      const response = await axiosInstance(config);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
      throw error;
    }
  }
}

export default DonationsService
