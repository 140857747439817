/* eslint-disable react/no-unstable-nested-components */
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UserInfoTypes, UserRoleTypes } from 'src/interfaces/user'
import Table from 'src/components/Table/Table'
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import { v4 as uuidv4 } from 'uuid'
import TableActionBtns from 'src/components/Table/TableActionBtns'
import routes from 'src/constants/routes'
import userMethods from 'src/utils/userMethods'

interface Props {
  users: UserInfoTypes[]
}

interface OriginalTypes {
  cell: { row: { original: { fullName: string, agent_id: number; admin_id: number; user_type: UserRoleTypes['userRole'] } } }
}

interface UserFullName {
  first_name: string;
  last_name: string;
}

function UsersTable({ users = [] }: Props) {
  const { t } = useTranslation()
  const { openConfirmationModalHandler } = useModalContext()
  const navigate = useNavigate()
  const authUser = userMethods.getUser()
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const handleCheckboxClick = (rowId: string) => {
    setSelectedRows((prevOptions) => {
      if (prevOptions.includes(rowId)) {
        return prevOptions.filter((option) => option !== rowId);
      }
      return [...prevOptions, rowId];
    });
  };

  const columns = useMemo(() => [
    {
      accessor: 'selected',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ row }: { row: any }) => (
        <label htmlFor={`checkbox-${row.original.id}`}>
          <input
            type="checkbox"
            value="checkbox"
            className="custom-checkbox"
            id={`checkbox-${row.original.id}`}
            onClick={(e) => {
              e.stopPropagation();
              handleCheckboxClick(row.original.id);
            }}
          />
        </label>
      )
    },
    {
      Header: 'ID',
      accessor: (row: { id: string }) => `#${row.id.toString()}`,
      Cell: ({ value }: { value: string }) => (
        <p title={value}>
          {value}
        </p>
      )
    },
    {
      Header: t('fullName'),
      accessor: (row: UserFullName) => `${row.first_name} ${row.last_name}`,
      Cell: ({ row }: any) => (
        <p>{`${row.original.first_name} ${row.original.last_name}`}</p>
      )
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }: { value: string }) => <p>{value}</p>
    },
    {
      Header: t('telephone'),
      accessor: 'telephone',
      Cell: ({ value }: { value: string }) => (
        <div>
          {!value ? (
            <p>-</p>
          ) : (
            <p>{value}</p>
          )}
        </div>
      )
    },
    {
      Header: t('department'),
      // eslint-disable-next-line max-len
      accessor: (row: { departments: { title: string }[] }) => row.departments?.map((department) => department.title),
      Cell: ({ value }: { value: string [] }) => (
        <div>
          {!value || value.length === 0 ? (
            <p>-</p>
          ) : (
            <p className="flex gap-1 flex-nowrap">
              {Array.isArray(value) && value.map((val: string, index: number) => (
                <span key={uuidv4()}>
                  {val}
                  {index !== value.length - 1 && ','}
                </span>
              ))}
            </p>
          )}
        </div>
      )
    },
    {
      Header: t('userType'),
      accessor: 'user_type',
      Cell: ({ value }: { value: string }) => <p>{t(value.toLowerCase())}</p>
    },
    {
      Header: t('deleteEditUser'),
      Cell: ({
        cell: {
          row: { original }
        }
      }: OriginalTypes) => (
        <TableActionBtns
          onClickHandlerEdit={(e) => {
            e.stopPropagation();
            if (original.agent_id) {
              navigate(`/${authUser?.user?.user_type.toLowerCase()}/users/${routes.shared.userUpdate}/${original.user_type.toLocaleLowerCase()}-${original.agent_id}`);
            } else {
              navigate(`/${authUser?.user?.user_type.toLowerCase()}/users/${routes.shared.userUpdate}/${original.user_type.toLocaleLowerCase()}-${original.admin_id}`);
            }
          }}
          onClickHandlerDelete={(e) => {
            e.stopPropagation();
            if (original.agent_id) {
              openConfirmationModalHandler('deleteUserMessage', {
                id: original.agent_id,
                userRole: original.user_type
              });
            } else {
              openConfirmationModalHandler('deleteUserMessage', {
                id: original.admin_id,
                userRole: original.user_type
              })
            }
          }}
        />
      )
    }
  ], [t, navigate, openConfirmationModalHandler]);

  return (
    <Table
      columns={columns}
      data={users}
      storageKey="usersTable"
      rowsPerPage={7}
      selectedRows={selectedRows}
    />
  )
}

export default UsersTable
