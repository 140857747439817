import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './App'
import i18n from './i18n/i18n'
import './index.css'
import DataProvider from './context/DataProviderCtx/DataProvider'
import ModalsProvider from './context/ModalsCtx/ModalsProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <DataProvider>
          <ModalsProvider>
            <App />
          </ModalsProvider>
        </DataProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
)

i18n.on('languageChanged', () => i18n.language)
