import { useTranslation } from 'react-i18next'
import { BsSearch } from 'react-icons/bs'
import { ChangeEvent, useEffect } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import { BiLoaderAlt } from 'react-icons/bi'

interface Props {
  onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void
}

function SearchFromApi({ onChangeHandler }: Props) {
  const { t } = useTranslation()
  const {
    isContactsLoading, searchTextContact
  } = useDataProvider()

  useEffect(() => {}, [searchTextContact])

  return (
    <div className="bg-white min-w-[200px] md:max-w-[60%] lg:max-w-[50%] xl:max-w-[40%] w-full flex items-center border border-solid border-lines rounded-small px-4 focus:border-transparent focus:ring-0 ">
      <DebounceInput
        autoComplete="off"
        type="text"
        minLength={3}
        placeholder={t('search')}
        debounceTimeout={1500}
        value={searchTextContact!}
        onChange={onChangeHandler}
        className="w-full py-3 border-0 focus:border-transparent focus:ring-0 placeholder:text-paragraph"
      />
      {
        isContactsLoading
          ? (
            <BiLoaderAlt size={18} className="text-lines animate-spin" />
          )

          : <BsSearch size={18} className="text-lines" />
      }
    </div>
  )
}

export default SearchFromApi
