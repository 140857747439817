import { Route, Routes } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { ReactQueryDevtools } from 'react-query/devtools'
import 'react-toastify/dist/ReactToastify.css'
import 'simplebar-react/dist/simplebar.min.css'
import routes from 'src/constants/routes'
import Layout from 'src/containers/Layout'
import RenderIf from 'src/components/UI/RenderIf'
import Login from 'src/pages/Login/Login'
import ForgotPassword from 'src/pages/ForgotPassword/ForgotPassword'
import ActivateAccount from 'src/pages/ActivateAccount/ActivateAccount'
import ResetPassword from 'src/pages/ResetPassword/ResetPassword'
import Contacts from 'src/pages/Common/pages/Contacts/Contacts'
import Donations from 'src/pages/Common/pages/Donations/Donations'
import Tasks from 'src/pages/Common/pages/Tasks/Tasks'
import Newsletters from 'src/pages/Common/pages/Newsletters/Newsletters'
import Settings from 'src/pages/Common/pages/Settings/Settings'
import ContactInfo from 'src/pages/Common/pages/Contacts/pages/ContactInfo'
import CreateContact from 'src/pages/Common/pages/Contacts/pages/CreateContact'
import UpdateContact from 'src/pages/Common/pages/Contacts/pages/UpdateContact'
import CreateNewsletter from 'src/pages/Common/pages/Newsletters/pages/CreateNewsletter'
import CampaignDetails from 'src/pages/Common/pages/Newsletters/pages/CampaignDetails'
import DesignEmail from 'src/pages/Common/pages/Newsletters/pages/DesignEmail'
import CampaignPreview from 'src/pages/Common/pages/Newsletters/pages/CampaignPreview'
import CreateDonation from 'src/pages/Common/pages/Donations/pages/CreateDonation'
import PublicRoutes from 'src/components/Routes/PublicRoutes'
import ProtectedRoutes from 'src/components/Routes/ProtectedRoutes'
import Warehouses from 'src/pages/Common/pages/Warehouses/Warehouses'
import CreateWarehouse from 'src/pages/Common/pages/Warehouses/pages/CreateWarehouse'
import WarehouseInfo from 'src/pages/Common/pages/Warehouses/pages/WarehouseInfo'
import UpdateWarehouse from 'src/pages/Common/pages/Warehouses/pages/UpdateWarehouse'
import TasksList from './pages/Common/pages/Tasks/pages/TasksList'
import AdminDashboard from './pages/Admin/AdminDashboard'
import AgentDashboard from './pages/Agent/AgentDashboard'
import GoodsDonationInfo from './pages/Common/pages/Donations/pages/GoodsDonationInfo'
import FinancialDonationInfo from './pages/Common/pages/Donations/pages/FinancialDonationInfo'
import UpdateFinancialDonation from './pages/Common/pages/Donations/pages/UpdateFinancialDonation'
import UpdateGoodsDonation from './pages/Common/pages/Donations/pages/UpdateGoodsDonation'
import Users from './pages/Admin/Users/Users'
import UserInfo from './pages/Admin/Users/pages/UserInfo'
import UpdateUser from './pages/Admin/Users/pages/UpdateUser'
import CreateUser from './pages/Admin/Users/pages/CreateUser'
import UserGuide from './pages/Common/pages/UserGuide/UserGuide'

function App() {
  return (
    <>
      <Routes>
        <Route path={routes.activateAccount} element={<ActivateAccount />} />
        <Route path={routes.resetPassword} element={<ResetPassword />} />
        <Route element={<PublicRoutes />}>
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.forgotPassword} element={<ForgotPassword />} />
        </Route>

        {/* ADMINISTRATOR ROUTES */}
        <Route element={<ProtectedRoutes userRole="ADMINISTRATOR" />}>
          <Route element={<Layout userRole="ADMINISTRATOR" />}>
            <Route path={routes.admin.home} element={<AdminDashboard />} />
            <Route path={routes.admin.tasks} element={<Tasks />} />
            <Route path={routes.admin.tasksList} element={<TasksList />} />
            <Route path={routes.admin.contacts} element={<Contacts />}>
              <Route path={`${routes.shared.contactInfo}/:id`} element={<ContactInfo />} />
              <Route path={routes.shared.createContact} element={<CreateContact />} />
              <Route path={`${routes.shared.updateContact}/:id`} element={<UpdateContact />} />
            </Route>
            <Route path={routes.admin.donations} element={<Donations />}>
              <Route path={`${routes.shared.goodsDonationInfo}/:id`} element={<GoodsDonationInfo />} />
              <Route path={`${routes.shared.financialDonationInfo}/:id`} element={<FinancialDonationInfo />} />
              <Route path={routes.shared.createDonation} element={<CreateDonation />} />
              <Route path={`${routes.shared.updateGoodsDonation}/:id`} element={<UpdateGoodsDonation />} />
              <Route path={`${routes.shared.updateFinancialDonation}/:id`} element={<UpdateFinancialDonation />} />
            </Route>
            <Route path={routes.admin.warehouses} element={<Warehouses />}>
              <Route path={routes.shared.createWarehouse} element={<CreateWarehouse />} />
              <Route path={`${routes.shared.warehouseInfo}/:id`} element={<WarehouseInfo />} />
              <Route path={`${routes.shared.updateWarehouse}/:id`} element={<UpdateWarehouse />} />
            </Route>
            <Route path={routes.admin.settings} element={<Settings />} />
            <Route path={routes.admin.userGuide} element={<UserGuide />} />
            <Route path={routes.admin.users} element={<Users />}>
              <Route path={`${routes.shared.createUser}`} element={<CreateUser />} />
              <Route path={`${routes.shared.userInfo}/:id`} element={<UserInfo />} />
              <Route path={`${routes.shared.userUpdate}/:id`} element={<UpdateUser />} />
            </Route>
            <Route path={routes.admin.newsletters} element={<Newsletters />}>
              <Route
                path={routes.shared.createNewsletter}
                element={<CreateNewsletter />}
              >
                <Route index element={<CampaignDetails />} />
                <Route path={routes.admin.designEmail} element={<DesignEmail />} />
                <Route path={routes.admin.campaignPreview} element={<CampaignPreview />} />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* AGENT ROUTES */}
        <Route element={<ProtectedRoutes userRole="AGENT" />}>
          <Route element={<Layout userRole="AGENT" />}>
            <Route path={routes.agent.home} element={<AgentDashboard />} />
            <Route path={routes.agent.tasks} element={<Tasks />} />
            <Route path={routes.agent.tasksList} element={<TasksList />} />
            <Route path={routes.agent.contacts} element={<Contacts />}>
              <Route path={`${routes.shared.contactInfo}/:id`} element={<ContactInfo />} />
              <Route path={routes.shared.createContact} element={<CreateContact />} />
              <Route path={`${routes.shared.updateContact}/:id`} element={<UpdateContact />} />
            </Route>
            <Route path={routes.agent.donations} element={<Donations />}>
              <Route path={`${routes.shared.goodsDonationInfo}/:id`} element={<GoodsDonationInfo />} />
              <Route path={`${routes.shared.financialDonationInfo}/:id`} element={<FinancialDonationInfo />} />
              <Route path={routes.shared.createDonation} element={<CreateDonation />} />
              <Route path={`${routes.shared.updateGoodsDonation}/:id`} element={<UpdateGoodsDonation />} />
              <Route path={`${routes.shared.updateFinancialDonation}/:id`} element={<UpdateFinancialDonation />} />
            </Route>
            <Route path={routes.agent.warehouses} element={<Warehouses />}>
              <Route path={routes.shared.createWarehouse} element={<CreateWarehouse />} />
              <Route path={`${routes.shared.warehouseInfo}/:id`} element={<WarehouseInfo />} />
              <Route path={`${routes.shared.updateWarehouse}/:id`} element={<UpdateWarehouse />} />
            </Route>
            <Route path={routes.agent.settings} element={<Settings />} />
            <Route path={routes.agent.userGuide} element={<UserGuide />} />
            <Route path={routes.agent.newsletters} element={<Newsletters />}>
              <Route
                path={routes.shared.createNewsletter}
                element={<CreateNewsletter />}
              >
                <Route index element={<CampaignDetails />} />
                <Route path={routes.agent.designEmail} element={<DesignEmail />} />
                <Route path={routes.agent.campaignPreview} element={<CampaignPreview />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>

      {/* React toastify configuration - Ignore */}
      <ToastContainer
        position="top-center"
        autoClose={2500}
        pauseOnFocusLoss={false}
        transition={Slide}
      />
      <RenderIf isTrue={process.env.NODE_ENV === 'development'}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </RenderIf>
    </>
  )
}

export default App
