import { FieldInputProps } from 'formik'
import { useTranslation } from 'react-i18next'
import InputErrorMessage from '../../../../../components/UI/InputErrorMsg'
import RenderIf from '../../../../../components/UI/RenderIf'

interface PropTypes {
  inputId: string
  name: string
  getFieldProps: FieldInputProps<any>
  errors: boolean
  errorName: string | undefined
  type?: string
  width?: 'lg:w-1/3' | 'lg:w-1/2' | 'lg:w-full' | 'md:w-full' | 'w-full'
  marginX?: 'mx-4' | 'mx-0',
  marginY?: 'my-4' | 'my-0',
  [key: string]: any
}

function FormInputNewsletter({
  inputId,
  name,
  getFieldProps,
  errors,
  errorName,
  type = 'text',
  width = 'lg:w-1/3',
  marginX = 'mx-4',
  marginY = 'my-4',
  ...restProps
}: PropTypes) {
  const { t } = useTranslation()

  return (
    <div className={`relative text-base md:text-lg w-full sm:w-full flex flex-col last:mb-0 ${width} ${marginY}`}>
      <label
        htmlFor={inputId}
        className={`${marginX} mb-2 leading-6 text-paragraph whitespace-nowrap`}
      >
        {t(name)}
      </label>
      <input
        type={type}
        id={inputId}
        {...getFieldProps}
        {...restProps}
        className={`mx-0 md:${marginX} text-black border py-3.5 px-4 rounded-small h-12 ${errors ? 'border-red-700 focus:border-red-700 focus:ring-0' : 'border-lines focus:border-mdm-blue focus:ring-0'}`}
      />
      <RenderIf isTrue={errors}>
        <div className={`${marginX} absolute w-full`}>
          <InputErrorMessage text={errorName!} />
        </div>
      </RenderIf>
    </div>
  )
}

export default FormInputNewsletter
