// navigation between event calendar and tasks list
import { Link, useLocation } from 'react-router-dom'
import routes from 'src/constants/routes'
import { BsList } from 'react-icons/bs'
import { FiCalendar } from 'react-icons/fi'
import UserMethods from 'src/utils/userMethods'
import { useTranslation } from 'react-i18next'

function TasksNav() {
  const { pathname } = useLocation()
  const authUser = UserMethods.getUser()
  const [t] = useTranslation()
  const links = [{
    id: 1,
    path: authUser?.user.user_type === 'ADMINISTRATOR' ? routes.admin.tasks : routes.agent.tasks,
    icon: <FiCalendar size="23" title={t('calendar')} />,
    borderRadius: 'rounded-l-small',
    label: 'View Calendar'
  },
  {
    id: 2,
    path: authUser?.user.user_type === 'ADMINISTRATOR' ? routes.admin.tasksList : routes.agent.tasksList,
    icon: <BsList size="23" title={t('list')} />,
    borderRadius: 'rounded-r-small',
    label: 'View Tasks List'
  }]

  return (
    <div className="flex items-center">
      {links.map((link) => (
        <Link
          key={link.id}
          to={link.path}
          aria-label={link.label}
          className={`p-[1.15rem] md:p-[1.16rem] text-mdm-blue ${link.borderRadius} ${
            pathname === link.path ? 'bg-mdm-sky-blue rounded-small z-10 -ml-1' : 'bg-white z-0 -ml-1'
          }`}
        >
          {link.icon}
        </Link>
      ))}
    </div>
  )
}

export default TasksNav
