/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { useLocation } from 'react-router-dom'
import routes from 'src/constants/routes'
import FormLayout from 'src/components/Form/FormLayout'
import FormInput from 'src/components/Form/FormInput'
import FormButtons from 'src/components/Form/FormButtons'
import validationSchema from 'src/utils/validationSchema'
import FormTextArea from 'src/components/Form/FormTextArea'
import FormSelect from 'src/components/Form/FormSelect'
import RenderIf from 'src/components/UI/RenderIf'

interface Option {
  value: string
  label: string
}

interface Props {
  onSubmit: (values: any) => void
  isLoading: boolean
  donation?: any
  handleSelectClick?: (event: React.MouseEvent<HTMLSelectElement>) => void
  selectedDonationType: 'goods' | 'financial' | ''
  options?: Option[],
}

function DonationCreationForm({
  onSubmit, isLoading, donation, handleSelectClick, selectedDonationType, options
}: Props) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isExactPath = pathname.includes(routes.shared.createDonation)

  const initialGoodsValues: any = {
    id: donation ? donation.id : '',
    donor_name: donation ? donation.donor_name : '',
    type: donation ? donation.type : '',
    kind: donation ? donation.kind : '',
    subcategory: donation ? donation.subcategory : '',
    quantity: donation ? donation.quantity : null,
    notes: donation ? donation.notes : '',
    isActive: donation ? donation.status : ''
  }

  const initialFinancialValues: any = {
    id: donation ? donation.id : '',
    donor_name: donation ? donation.donor_name : '',
    type: donation ? donation.type : '',
    amount: donation ? donation.amount : '',
    invoice_number: donation ? donation.invoice_number : '',
    deposit_account: donation ? donation.deposit_account : '',
    invoice_issue_date: donation ? donation.invoice_issue_date : '',
    date_of_deposit: donation ? donation.date_of_deposit : '',
    bank: donation ? donation.bank : '',
    notes: donation ? donation.notes : '',
    isActive: donation ? donation.status : ''
  }

  const goodsValidationSchema = validationSchema.goodsDonationCreationForm
  const financialDonationValidationSchema = validationSchema.financialDonationCreationForm
  const selectedValidationSchema = selectedDonationType === 'goods' ? goodsValidationSchema : financialDonationValidationSchema

  const formik = useFormik({
    initialValues: initialGoodsValues,
    validationSchema: selectedValidationSchema,
    onSubmit
  });

  const { setValues, values: { donor_name } } = formik

  useEffect(() => {
    if (selectedDonationType === 'goods') {
      setValues({ ...initialGoodsValues, donor_name, type: 'goods' })
    }
    if (selectedDonationType === 'financial') {
      setValues({ ...initialFinancialValues, donor_name, type: 'financial' })
    }
  }, [selectedDonationType, setValues])

  const goodsDonationOptions = [
    { value: ' ', label: t('selectOneOption') },
    { value: 'MEDICINE', label: t('medicines') },
    { value: 'CONSUMABLE', label: t('consumables') },
    { value: 'MEDICAL_EQUIPMENT', label: t('medicalEquipment') },
    { value: 'IT_EQUIPMENT', label: t('ITequipment') },
    { value: 'PRODUCT', label: t('products') }
  ];

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <FormLayout title={t('donationDetails')} isExpandedForm marginTop="mt-6">
          <div className="flex flex-col w-full xl:flex-row">
            <div className="flex flex-col items-center justify-center w-full">
              <div className="grid w-full grid-cols-1 lg:grid-cols-3">
                <FormInput
                  inputId="donor_name"
                  name="donorName"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('donor_name')}
                  errors={!!(formik.touched.donor_name && formik.errors.donor_name)}
                  errorName={formik.errors.donor_name as string}
                />
                <FormSelect
                  name="type"
                  inputId="type"
                  getFieldProps={formik.getFieldProps('type')}
                  errors={!!(formik.touched.type && formik.errors.type)}
                  errorName={formik.errors.type as string}
                  width="md:w-full"
                  handleSelectClick={handleSelectClick}
                  disabled={isExactPath ? false : formik.getFieldProps('type').value}
                >
                  {options?.map((option) => (
                    <option key={option.label} value={option?.value}>
                      {t(option.label)}
                    </option>
                  ))}
                </FormSelect>
              </div>
              <RenderIf isTrue={!!(selectedDonationType === 'goods')}>
                <div className="grid w-full grid-cols-1 md:grid-cols-3">
                  <FormSelect
                    inputId="kind"
                    name="kind"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('kind')}
                    errors={!!(formik.touched.kind && formik.errors.kind)}
                    errorName={formik.errors.kind as string}
                  >
                    {goodsDonationOptions?.map((option) => (
                      <option key={option.label} value={option?.value}>
                        {option.label}
                      </option>
                    ))}
                  </FormSelect>
                  <FormInput
                    inputId="subcategory"
                    name="subcategory"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('subcategory')}
                    errors={!!(formik.touched.subcategory && formik.errors.subcategory)}
                    errorName={formik.errors.subcategory as string}
                  />
                  <FormInput
                    inputId="quantity"
                    name="quantity"
                    type="number"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('quantity')}
                    errors={!!(formik.touched.quantity && formik.errors.quantity)}
                    errorName={formik.errors.quantity as string}
                  />
                  <div className="col-span-1 md:col-span-3">
                    <FormTextArea
                      inputId="notes"
                      name="observations"
                      width="md:w-full"
                      getFieldProps={formik.getFieldProps('notes')}
                      errors={!!(formik.touched.notes && formik.errors.notes)}
                      errorName={formik.errors.notes as string}
                    />
                  </div>
                </div>
              </RenderIf>
              <RenderIf isTrue={!!(selectedDonationType === 'financial')}>
                <div className="grid w-full grid-cols-1 md-3 md:mt-0 md:grid-cols-2 lg:grid-cols-3">
                  <FormInput
                    inputId="amount"
                    type="number"
                    name="amount"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('amount')}
                    errors={!!(formik.touched.amount && formik.errors.amount)}
                    errorName={formik.errors.amount as string}
                  />
                  <FormInput
                    inputId="invoice_number"
                    name="invoiceNumber"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('invoice_number')}
                    errors={!!(formik.touched.invoice_number && formik.errors.invoice_number)}
                    errorName={formik.errors.invoice_number as string}
                  />
                  <FormInput
                    inputId="invoice_issue_date"
                    type="date"
                    name="invoiceIssueDate"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('invoice_issue_date')}
                    // eslint-disable-next-line max-len
                    errors={!!(formik.touched.invoice_issue_date && formik.errors.invoice_issue_date)}
                    errorName={formik.errors.invoice_issue_date as string}
                  />

                  <FormInput
                    inputId="deposit_account"
                    name="depositAccount"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('deposit_account')}
                    errors={!!(formik.touched.deposit_account && formik.errors.deposit_account)}
                    errorName={formik.errors.deposit_account as string}
                  />
                  <FormInput
                    type="date"
                    inputId="date_of_deposit"
                    name="dateOfDeposit"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('date_of_deposit')}
                    errors={!!(formik.touched.date_of_deposit && formik.errors.date_of_deposit)}
                    errorName={formik.errors.date_of_deposit as string}
                  />
                  <FormInput
                    inputId="bank"
                    name="bank"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('bank')}
                    errors={!!(formik.touched.bank && formik.errors.bank)}
                    errorName={formik.errors.bank as string}
                  />
                  <div className="col-span-1 md:col-span-2 lg:col-span-3">
                    <FormTextArea
                      inputId="notes"
                      name="observations"
                      width="md:w-full"
                      getFieldProps={formik.getFieldProps('notes')}
                      errors={!!(formik.touched.notes && formik.errors.notes)}
                      errorName={formik.errors.notes as string}
                    />
                  </div>
                </div>
              </RenderIf>
            </div>
          </div>
        </FormLayout>
        <FormButtons isLoading={isLoading} btnText={donation ? t('editDonation') : t('registration')} spinnerWidth={donation ? 'w-[10.8rem]' : ''} px="px-8" />
      </form>
    </FormikProvider>

  )
}

export default DonationCreationForm
