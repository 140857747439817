import { createContext } from 'react'
import { MailingListCtxTypes } from './mailingListCtx.interface'

const mailingListCtx = createContext<MailingListCtxTypes>({
  mailingList: [],
  getter: {
    mailingListName: null,
    selectedMailingListInput: null,
    isEditMailingList: false,
    mailingListSelectedContacts: [],
    specificMailingListContacts: []
  },
  showMailingListModal: false,
  showSpecificContactsListModal: false,
  showSelectedContactsModal: false,
  showSelectedSpecificContactsModal: false,
  showMailingListModalHandler: () => {},
  closeMailingListModalHandler: () => {},
  showSelectedContactsModalHandler: () => {},
  showSpecificContactsListModalHandler: () => {},
  closeSelectedContactsModalHandler: () => {},
  closeSpecificContactsListModalHandler: () => {},
  onSelectContactHandler: () => {},
  onSpecificSelectContactHandler: () => {},
  onRemoveContactHandler: () => {},
  onRemoveSpecificContactHandler: () => {},
  onClearMailingListSelectedContactsHandler: () => {},
  onClearSpecificContactsHandler: () => {},
  showSelectedSpecificContactsModalHandler: () => {},
  closeSelectedSpecificContactsModalHandler: () => {},
  onResetMailingListHandler: () => {},
  onEditNewsletterHandler: () => {},
  setter: {
    setMailingListName: () => {},
    setSelectedMailingListInput: () => {}
  }
})

export default mailingListCtx
