import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';
import { useFormik, FormikProvider } from 'formik';
import FormLayout from 'src/components/Form/FormLayout';
import FormInput from 'src/components/Form/FormInput';
import FormButtons from 'src/components/Form/FormButtons';
import validationSchema from 'src/utils/validationSchema';
import FormPhoneInput from 'src/components/Form/FormPhoneInput';
import FormMultiSelect from 'src/components/Form/FormMultiSelect/FormMultiSelect';
import FormSelect from 'src/components/Form/FormSelect';
import RenderIf from 'src/components/UI/RenderIf';
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider';

interface Props {
  onSubmit: (values: any) => void;
  isLoading: boolean;
  user?: any;
}

function UserCreationForm(
  { onSubmit, isLoading, user }: Props
) {
  const { t } = useTranslation();
  const [selectedUserType, setSelectedUserType] = useState<'' | 'AGENT' | 'ADMINISTRATOR'>('');
  const { departments } = useDataProvider()

  const modifiedDepartments = useMemo(() => departments?.map((dep: any) => ({
    label: t(dep.title?.toLowerCase()),
    value: dep.title
  })), [departments, t])

  const initialValues = {
    username: user ? user.user.username : '',
    first_name: user ? user.user.first_name : '',
    last_name: user ? user.user.last_name : '',
    email: user ? user.user.email : '',
    telephone: user ? user.user.telephone : '',
    position: user ? user.user.position : '',
    departments: user ? user.user.departments?.map((dep: any) => ({
      label: t(dep.title?.toLowerCase()),
      value: dep.title
    })) : [],
    user_type: user ? user.user.user_type : '',
    password: '12345'
  };

  const userTypeOptions = [
    { value: ' ', label: t('selectOneOption') },
    { value: 'AGENT', label: t('agent') },
    { value: 'ADMINISTRATOR', label: t('administrator') }
  ];

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.userCreationForm,
    onSubmit
  });

  const handleSelectClick = (event: React.MouseEvent<HTMLSelectElement>) => {
    const selectOption = event.currentTarget.value as '' | 'AGENT' | 'ADMINISTRATOR';
    setSelectedUserType(selectOption);
  };

  const handleChange = (value: any) => {
    const uniqueValues = Array.from(new Set(value));
    formik.setFieldValue('departments', uniqueValues);
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <FormLayout title={t('basicInfo')} isExpandedForm marginTop="mt-6">
          <div className="grid w-full grid-cols-1 md:grid-col-2 lg:grid-cols-3">
            <FormInput
              inputId="username"
              name="Username"
              width="md:w-full"
              getFieldProps={formik.getFieldProps('username')}
              errors={!!(formik.touched.username && formik.errors.username)}
              errorName={formik.errors.username as string}
            />
            <FormInput
              inputId="first_name"
              name="firstName"
              width="md:w-full"
              getFieldProps={formik.getFieldProps('first_name')}
              errors={!!(formik.touched.first_name && formik.errors.first_name)}
              errorName={formik.errors.first_name as string}
            />
            <FormInput
              inputId="last_name"
              name="lastName"
              width="md:w-full"
              getFieldProps={formik.getFieldProps('last_name')}
              errors={!!(formik.touched.last_name && formik.errors.last_name)}
              errorName={formik.errors.last_name as string}
            />
            <FormInput
              inputId="Email"
              name="Email"
              width="md:w-full"
              type="email"
              inputMode="email"
              getFieldProps={formik.getFieldProps('email')}
              errors={!!(formik.touched.email && formik.errors.email)}
              errorName={formik.errors.email as string}
            />
            <FormPhoneInput
              inputId="telephone"
              name="telephone"
              width="md:w-full"
              getFieldProps={formik.getFieldProps('telephone')}
              errors={!!(formik.touched.telephone && formik.errors.telephone)}
              errorName={formik.errors.telephone as string}
              marginY="my-2"
            />
            <FormSelect
              disabled={!!(user && (user.user.user_type === 'AGENT' || user.user.user_type === 'ADMINISTRATOR'))}
              name="userType"
              inputId="user_type"
              getFieldProps={formik.getFieldProps('user_type')}
              errors={!!(formik.touched.user_type && formik.errors.user_type)}
              errorName={formik.errors.user_type as string}
              width="md:w-full"
              handleSelectClick={handleSelectClick}
            >
              {userTypeOptions?.map((option) => (
                <option key={option.label} value={option?.value}>
                  {t(option.label)}
                </option>
              ))}
            </FormSelect>
            <RenderIf isTrue={!!(user && user.user.user_type === 'AGENT') || !!(selectedUserType === 'AGENT')}>
              <FormMultiSelect
                inputId="departments"
                name="departments"
                errors={!!(formik.touched.departments && formik.errors.departments)}
                errorName={formik.errors.departments as string}
                options={modifiedDepartments}
                value={formik.values.departments}
                onChange={handleChange}
              />
              <FormInput
                inputId="position"
                name="position"
                width="md:w-full"
                getFieldProps={formik.getFieldProps('position')}
                errors={!!(formik.touched.position && formik.errors.position)}
                errorName={formik.errors.position as string}
              />
            </RenderIf>
          </div>
        </FormLayout>
        <FormButtons
          isLoading={isLoading}
          btnText={user ? t('save') : t('addNewUser')}
          px="px-11"
          spinnerWidth={user ? '' : 'w-[9.3rem]'}
        />
      </form>
    </FormikProvider>
  );
}

export default UserCreationForm
