import RenderIf from 'src/components/UI/RenderIf'
import CampaignHeaderPreviewValue from './CampaignHeaderPreviewValue'

interface Props {
  campaignName: string | null;
  emailSubject: string | null;
}

function CampaignHeaderPreviewValues({ campaignName, emailSubject }:Props) {
  return (
    <div className="flex flex-col justify-between w-full gap-4 pt-5 sm:flex-row sm:flex-wrap sm:items-center">
      <RenderIf isTrue={!!campaignName}>
        <CampaignHeaderPreviewValue title="campaignName" text={campaignName ?? ''} />
      </RenderIf>
      <RenderIf isTrue={!!emailSubject}>
        <CampaignHeaderPreviewValue title="emailSubject" text={emailSubject ?? ''} />
      </RenderIf>
      <CampaignHeaderPreviewValue title="emailList" text="test list" />
    </div>
  )
}

export default CampaignHeaderPreviewValues
