import { ChildrenTypes } from '../../interfaces/children'

interface Props extends ChildrenTypes {
  isTrue: boolean
}

function RenderIf({ isTrue, children }: Props): JSX.Element | null {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isTrue ? <>{children}</> : null
}

export default RenderIf
