import { useTranslation } from 'react-i18next'
import {
  useNavigate, useParams
} from 'react-router-dom'
import { useEffect } from 'react'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import PageTitle from 'src/components/UI/PageTitle'
import PageSection from 'src/components/UI/PageSection'
import useGetData from 'src/hooks/useGetData'
import RenderIf from 'src/components/UI/RenderIf'
import UserSkeleton from 'src/components/Skeletons/UserSkeleton'
import { UpdateUserTypes } from 'src/interfaces/user'
import UserCreationForm from '../components/UserCreationForm'

function UpdateUser() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams();
  const { id } = params;
  const [stringPart, numericPart] = id?.split('-') || [];

  const { data: user, isLoading: isUserLoading } = useGetData<any>({
    queryKey: `user-${numericPart}`,
    queryFn: () => {
      if (numericPart) {
        return apiService.getUser(Number(numericPart), stringPart);
      }
      return null;
    }
  })

  const { isLoading, mutate: updateUserMutation } = useMutateData(
    {
      key: ['users', `user-${numericPart}`],
      mutationFn: (userData: UpdateUserTypes) => apiService.updateUser(userData),
      successMessage: 'userHasBeenUpdated'
    }
  )

  const submitFormHandler = (values: any) => {
    const userData: UpdateUserTypes = {
      id: Number(numericPart),
      values
    };

    updateUserMutation(userData, {
      onSuccess: () => navigate(-1)
    });
  };

  useEffect(() => {
    document.title = `MDM CRM - ${t('updateUser')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('updateUser')} />
      <RenderIf isTrue={isUserLoading}>
        <UserSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isUserLoading}>
        <UserCreationForm
          onSubmit={submitFormHandler}
          user={user}
          isLoading={isLoading}
        />
      </RenderIf>
    </PageSection>

  )
}
export default UpdateUser
