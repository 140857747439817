import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PageTitle from 'src/components/UI/PageTitle';
import useMutateData from 'src/hooks/useMutateData';
import apiService from 'src/services/api/apiService';
import PageSection from 'src/components/UI/PageSection';
import { startOfToday, format } from 'date-fns';
import WarehouseCreationForm from '../components/WarehouseCreationForm';

function CreateWarehouse() {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const { mutate: createWarehouseMutation, isLoading } = useMutateData({
    key: 'warehouses',
    mutationFn: apiService.createWarehouse,
    successMessage: 'warehouseHasBeenCreated'
  })

  const onSubmit = (values: any) => {
    createWarehouseMutation(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `MDM CRM - ${t('newWarehouseEntry')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text="newWarehouseEntry" subtext="dateOfRegistrationLong" date={format(startOfToday(), 'dd/MM/yyyy')} />
      <WarehouseCreationForm isLoading={isLoading} onSubmit={onSubmit} />
    </PageSection>
  )
}
export default CreateWarehouse
