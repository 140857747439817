import { Navigate, Outlet } from 'react-router-dom'
import userMethods from 'src/utils/userMethods'
import tokenMethods from 'src/utils/tokenMethods'
import navigateTo from 'src/utils/navigateTo'

function PublicRoutes() {
  const token = tokenMethods.getAccessToken()
  const user = userMethods.getUser()

  return (
    <>
      {!token && <Outlet />}
      {token && user && <Navigate to={navigateTo(user?.user.user_type)} />}
    </>
  )
}

export default PublicRoutes
