import { createContext } from 'react'
import { NewslettersCtxTypes } from './newslettersCtx.interface'

const newslettersCtx = createContext<NewslettersCtxTypes>({
  content: null,
  emailGreeting: null,
  searchValue: '',
  emailImage: undefined,
  emailImageUrl: null,
  isEditNewsletter: false,
  existingFiles: [],
  getter: {
    campaignName: '',
    emailSubject: '',
    emailTemplate: '',
    selectedNewsletterId: 0,
    newsletterFiles: []
  },
  setter: {
    setCampaignName: () => {},
    setEmailSubject: () => {},
    setContent: () => {},
    setEmailTemplate: () => {},
    setEmailGreeting: () => {},
    setNewsletterFiles: () => {},
    setEmailImage: () => {},
    setEmailImageUrl: () => {},
    setIsEditNewsletter: () => {},
    setExistingFiles: () => {},
    setSelectedNewsletterId: () => {}
  },
  onResetNewsletterHandler: () => {}
})

export default newslettersCtx
