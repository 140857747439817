import routes from 'src/constants/routes'
import NavLink from '../../NavLink'

function AdminNav() {
  return (
    <>
      <NavLink text="homePage" path={routes.admin.home} />
      <NavLink text="contacts" path={routes.admin.contacts} />
      <NavLink text="tasks" path={routes.admin.tasks} />
      <NavLink text="donations" path={routes.admin.donations} />
      <NavLink text="warehouse" path={routes.admin.warehouses} />
      <NavLink text="newsletters" path={routes.admin.newsletters} />
    </>
  )
}

export default AdminNav
