import {
  useMemo, useContext, useState, useCallback
} from 'react'
import { ChildrenTypes } from 'src/interfaces/children'
import useGetData from 'src/hooks/useGetData'
import { MailingListGetterDataTypes, MailingListTypes, NewsletterTypes } from 'src/interfaces/newsletters'
import apiService from 'src/services/api/apiService'
import mailingListCtx from './mailingListCtx'
import { MailingListCtxTypes } from './mailingListCtx.interface'
import { useNewsletters } from '../NewsletterCtx/NewslettersProvider'

export const useMailingList = ():MailingListCtxTypes => useContext(mailingListCtx)

function MailingListProvider({ children }:ChildrenTypes) {
  const newslettersCtx = useNewsletters()

  const [mailingListName, setMailingListName] = useState<MailingListGetterDataTypes['mailingListName']>(null)
  const [selectedMailingListInput, setSelectedMailingListInput] = useState<MailingListGetterDataTypes['selectedMailingListInput']>(null)
  const [mailingListSelectedContacts, setMailingListSelectedContacts] = useState<MailingListGetterDataTypes['mailingListSelectedContacts']>([])
  const [specificMailingListContacts, setSpecificMailingListContacts] = useState<MailingListGetterDataTypes['specificMailingListContacts']>([])
  const [isEditMailingList, setIsEditMailingList] = useState<MailingListGetterDataTypes['isEditMailingList']>(false)

  const [showMailingListModal, setShowMailingListModal] = useState<MailingListCtxTypes['showMailingListModal']>(false)
  const [showSpecificContactsListModal, setShowSpecificContactsListModal] = useState<MailingListCtxTypes['showSpecificContactsListModal']>(false)
  const [showSelectedContactsModal, setShowSelectedContactsModal] = useState<MailingListCtxTypes['showSelectedContactsModal']>(false)
  const [showSelectedSpecificContactsModal, setShowSelectedSpecificContactsModal] = useState<MailingListCtxTypes['showSelectedSpecificContactsModal']>(false)

  const setter = useMemo(() => ({
    setMailingListName,
    setSelectedMailingListInput
  }), [])

  const getter: MailingListGetterDataTypes = useMemo(() => ({
    mailingListName,
    mailingListSelectedContacts,
    specificMailingListContacts,
    selectedMailingListInput,
    isEditMailingList
  }), [
    mailingListName,
    mailingListSelectedContacts,
    specificMailingListContacts,
    selectedMailingListInput,
    isEditMailingList
  ])

  const { data: mailingList } = useGetData<MailingListTypes[]>(
    {
      queryKey: 'mailingList',
      queryFn: apiService.getMailingList
    }
  )

  // on edit newsletter from table - prefill newsletter state with data
  const onEditNewsletterHandler = useCallback((selectedNewsletter: NewsletterTypes) => {
    const domParser = new DOMParser()

    // Set newsletter state for edit functionality
    newslettersCtx.setter.setIsEditNewsletter(true)
    newslettersCtx.setter.setSelectedNewsletterId(selectedNewsletter.id)
    newslettersCtx.setter.setCampaignName(selectedNewsletter.name)
    newslettersCtx.setter.setEmailSubject(selectedNewsletter.email_subject)
    // Get only the content div from email body
    newslettersCtx.setter.setContent(domParser.parseFromString(selectedNewsletter.email_body, 'text/html')
      .getElementById('content')?.innerHTML || '')

    // Get only the email greeting div from email body
    newslettersCtx.setter.setEmailGreeting(domParser.parseFromString(selectedNewsletter.email_body, 'text/html')
      .getElementById('emailGreeting')?.innerHTML || 'Με εκτίμηση, \nΗ ομάδα της')

    // Set the mailing list if it exists
    if (selectedNewsletter.mailing_lists.length > 0) {
      setSelectedMailingListInput(selectedNewsletter.mailing_lists[0].id.toString())
    }

    // Set specific contacts if they exist
    if (selectedNewsletter.specific_contacts?.length > 0) {
      setSpecificMailingListContacts(selectedNewsletter.specific_contacts)
    }

    // Set existing files if they exist
    if (selectedNewsletter.files.length > 0) {
      newslettersCtx.setter.setExistingFiles(selectedNewsletter.files)
    }

    // Set cover image if it exists
    const imageUrl = domParser.parseFromString(selectedNewsletter.email_body, 'text/html')
      .getElementById('coverImage')?.getAttribute('src')
    const imageId = domParser.parseFromString(selectedNewsletter.email_body, 'text/html')
      .getElementById('coverImage')?.getAttribute('data-id')

    if (imageUrl && imageId) {
      newslettersCtx.setter.setEmailImageUrl({
        id: +imageId,
        file: imageUrl,
        campaign: null
      })
    }
  }, [newslettersCtx.setter])

  // ---------------------------- Mailing List Modal ----------------------------

  const showMailingListModalHandler = useCallback((selectedList?:MailingListTypes) => {
    setShowMailingListModal(true)
    if (selectedList) {
      setMailingListName(selectedList.name)
      setMailingListSelectedContacts(selectedList.contacts)
      setIsEditMailingList(true)
    }
  }, [])

  const closeMailingListModalHandler = useCallback(() => {
    setShowMailingListModal(false)
    setMailingListName(null)
    setMailingListSelectedContacts([])
    setIsEditMailingList(false)
  }, [])

  // ---------------------------- Specific Contacts List Modal ----------------------------

  const showSpecificContactsListModalHandler = useCallback(() => {
    setShowSpecificContactsListModal(true)
  }, [])

  const closeSpecificContactsListModalHandler = useCallback(() => {
    setShowSpecificContactsListModal(false)
  }, [])

  // ---------------------------- Mailing List Selected Contacts Modal ----------------------------
  const showSelectedContactsModalHandler = useCallback(() => {
    setShowSelectedContactsModal(true)
  }, [])

  const closeSelectedContactsModalHandler = useCallback(() => {
    setShowSelectedContactsModal(false)
  }, [])

  const onSelectContactHandler = useCallback((contact:any) => {
    setMailingListSelectedContacts((prevContacts) => {
      const existingContact = prevContacts
        .some((prevContact) => prevContact.id === contact.id)
      if (existingContact) {
        return prevContacts
      }
      return [...prevContacts, contact]
    })
  }, [])

  const onRemoveContactHandler = useCallback((contact:any) => {
    setMailingListSelectedContacts((prevContacts) => prevContacts
      .filter((prevContact) => prevContact.id !== contact.id))
  }, [])

  const onClearMailingListSelectedContactsHandler = useCallback(() => {
    setMailingListSelectedContacts([])
  }, [])

  // ---------------------------- Specific Contacts ----------------------------

  const showSelectedSpecificContactsModalHandler = useCallback(() => {
    setShowSelectedSpecificContactsModal(true)
  }, [])

  const closeSelectedSpecificContactsModalHandler = useCallback(() => {
    setShowSelectedSpecificContactsModal(false)
  }, [])

  const onSpecificSelectContactHandler = useCallback((contact:any) => {
    setSpecificMailingListContacts((prevContacts) => {
      const existingClient = prevContacts
        .some((prevContact) => prevContact.id === contact.id)
      if (existingClient) {
        return prevContacts
      }
      return [...prevContacts, contact]
    })
  }, [])

  const onRemoveSpecificContactHandler = useCallback((contact:any) => {
    setSpecificMailingListContacts((prevContacts) => prevContacts
      .filter((prevContact) => prevContact.id !== contact.id))
  }, [])

  const onClearSpecificContactsHandler = useCallback(() => {
    setSpecificMailingListContacts([])
  }, [])

  const onResetMailingListHandler = useCallback(() => {
    setMailingListName(null)
    setMailingListSelectedContacts([])
    setSpecificMailingListContacts([])
    setIsEditMailingList(false)
    setSelectedMailingListInput(null)
  }, [])

  const ctx = useMemo(() => ({
    mailingList,
    showMailingListModal,
    showSelectedContactsModal,
    showSelectedSpecificContactsModal,
    showSpecificContactsListModal,
    showMailingListModalHandler,
    closeMailingListModalHandler,
    showSpecificContactsListModalHandler,
    showSelectedContactsModalHandler,
    closeSelectedContactsModalHandler,
    closeSpecificContactsListModalHandler,
    onClearMailingListSelectedContactsHandler,
    onClearSpecificContactsHandler,
    onSelectContactHandler,
    onSpecificSelectContactHandler,
    onRemoveContactHandler,
    onRemoveSpecificContactHandler,
    showSelectedSpecificContactsModalHandler,
    closeSelectedSpecificContactsModalHandler,
    onResetMailingListHandler,
    onEditNewsletterHandler,
    getter,
    setter
  }), [
    mailingList,
    showMailingListModal,
    showSelectedContactsModal,
    showSelectedSpecificContactsModal,
    showSpecificContactsListModal,
    showMailingListModalHandler,
    closeMailingListModalHandler,
    showSelectedContactsModalHandler,
    closeSelectedContactsModalHandler,
    onClearMailingListSelectedContactsHandler,
    onSelectContactHandler,
    onSpecificSelectContactHandler,
    onClearSpecificContactsHandler,
    onRemoveContactHandler,
    onRemoveSpecificContactHandler,
    showSpecificContactsListModalHandler,
    closeSpecificContactsListModalHandler,
    showSelectedSpecificContactsModalHandler,
    closeSelectedSpecificContactsModalHandler,
    onResetMailingListHandler,
    onEditNewsletterHandler,
    getter,
    setter
  ]);

  return (
    <mailingListCtx.Provider value={ctx}>
      {children}
    </mailingListCtx.Provider>
  )
}

export default MailingListProvider
