import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
  children: ReactNode
}

export default function UserGuideSection(props: Props) {
  const { title, children } = props
  const { t } = useTranslation()

  return (
    <div className="p-5 bg-white md:p-10 shadow-faint rounded-regular">
      <h2 className="text-mdm-navy-blue text-regular md:text-[22px] font-bold">
        {t(title)}
      </h2>
      {children}
    </div>
  )
}
