import { useTranslation } from 'react-i18next'

interface Props {
  text: string
}

function InputErrorMessage({ text }: Props) {
  const [t] = useTranslation()

  return (
    <p className="text-red-700 mt-1 text-[12px] top-[5rem] absolute left-0 leading-3 md:whitespace-nowrap">
      {t(text)}
    </p>
  )
}

export default InputErrorMessage
