import { useEffect } from 'react'
import { useOutletContext, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageSection from 'src/components/UI/PageSection'
import PageTitle from 'src/components/UI/PageTitle'
import DetailsInfoBtns from 'src/components/UI/DetailsInfoBtns'
import routes from 'src/constants/routes'
import UserMethods from 'src/utils/userMethods'
import { DonationTypes } from 'src/interfaces/donations'
import formattedDate from 'src/utils/formattedDate'
import RenderIf from 'src/components/UI/RenderIf'
import GoodsDonationSkeleton from 'src/components/Skeletons/GoodsDonationInfoSkeleton'
import DonationDetails from '../components/DonationDetails'

function GoodsDonationInfo() {
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const donations = useOutletContext<DonationTypes[]>()
  const { t } = useTranslation()
  const donation = donations?.find((d) => d.type === t('goods') && d.id.toString() === params.id)
  const authUser = UserMethods.getUser()

  useEffect(() => {
    document.title = `MDM CRM - ${t('donation')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text="donation" id={donation?.id} subtext={donation ? 'dateOfRegistrationLong' : ''} date={donation ? formattedDate(donation?.registration_date) : ''} />
      <RenderIf isTrue={!donation}>
        <GoodsDonationSkeleton />
      </RenderIf>
      <RenderIf isTrue={!!donation}>
        <DonationDetails donation={donation} />
        <DetailsInfoBtns onClickHandler={() => { navigate(`/${authUser?.user.user_type.toLowerCase()}/donations/${routes.shared.updateGoodsDonation}/${donation?.id}`) }} btnText="editDonation" px="px-8" />
      </RenderIf>
    </PageSection>
  )
}

export default GoodsDonationInfo
