import ReactQuill, { Quill } from 'react-quill'
import {
  useEffect
} from 'react'
import 'react-quill/dist/quill.snow.css'
import { formats, modules } from 'src/helpers/editorToolbarOptions'
import useToggleMenu from 'src/hooks/useToggleMenu'
import FormUploadMedia from 'src/components/Form/FormUploadMedia'
import { NewslettersCtxTypes } from 'src/context/NewsletterCtx/newslettersCtx.interface'
import FormTitle from '../../FormTitle'

Quill.register(Quill.import('attributors/style/direction'), true);
Quill.register(Quill.import('attributors/style/align'), true);

interface Props {
  formik: any
  newslettersCtx:NewslettersCtxTypes
}

function DesignEmailStep({
  formik,
  newslettersCtx
}:Props) {
  const { setIsOpen } = useToggleMenu()

  useEffect(() => {
    if (newslettersCtx.searchValue.trim().length > 2) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [newslettersCtx.searchValue, setIsOpen])

  const onRemoveMediaHandler = (name: string) => {
    // Remove file from formik state
    const filteredMedia = [...formik.values.files].filter((file) => file.name !== name)
    // Create new file list
    const list = new DataTransfer()
    filteredMedia.forEach((file) => list.items.add(file))
    // Set new file list
    formik.setFieldValue('files', list.files)
  }

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col w-full h-full">
      <FormTitle text="designYourEmail" />
      <ReactQuill
        theme="snow"
        value={formik.values.content}
        onChange={(value) => formik.setFieldValue('content', value)}
        formats={formats}
        modules={modules}
      />
      <FormTitle text="emailGreeting" />
      <textarea rows={2} className="w-full p-2 border rounded-lg outline-none border-lines" {...formik.getFieldProps('emailGreeting')} />

      <FormTitle text="attachingFiles" />
      <FormUploadMedia
        setMedia={formik.setFieldValue}
        media={formik.values.files}
        onRemoveMediaHandler={onRemoveMediaHandler}
        acceptedFiles="*"
      />
    </form>
  )
}

export default DesignEmailStep
