import { useTranslation } from 'react-i18next'

interface Props {
  title: string;
  text: string;
}

function CampaignHeaderPreviewValue({ title, text }:Props) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-3 ">
      <p className="text-paragraph text-regular">{t(title)}</p>
      <p className="font-medium text-black font-sans text-[1.38rem]">{text}</p>
    </div>
  )
}

export default CampaignHeaderPreviewValue
