import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import logo from 'src/assets/images/mdm-logo-blue.png'
import LoginForm from './components/LoginForm/LoginForm'

function Login() {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `MDM CRM - ${t('login')}`
  }, [t])

  return (
    <div className="flex flex-col justify-center w-full min-h-full p-5">
      <img src={logo} className="mx-auto w-[8rem] h-[8rem] md:w-[12.3rem] md:h-[12.3rem]" alt="MdM Greece Logo" />
      <div className="mx-auto bg-white mt-14 max-w-[580px] rounded-regular shadow-faint w-full mb-5">
        <h1 className="pt-8 md:pt-12 font-bold text-center text-[1.875rem] md:text-2xl text-mdm-blue">{`${t('Welcome')}!`}</h1>
        <p className="mt-3 text-center md:mb-10 text-regular text-paragraph">
          {t('loginToContinue')}
        </p>
        <LoginForm />
      </div>
    </div>
  )
}

export default Login
