import applyMixins from 'src/mixins/applyMixins'
import NotesService from 'src/services/notes/NotesService'
import AuthService from 'src/services/auth/authService'
import NewslettersService from '../newsletters/newslettersService'
import ContactsService from '../contacts/ContactsService'
import DonationsService from '../donations/DonationsService'
import WarehousesService from '../warehouses/WarehousesService'
import ProgramService from '../programs/ProgramsService'
import UsersService from '../users/UsersService'
import TasksService from '../tasks/TasksService'
import DepartmentsService from '../departments/DepartmentsService'
import MediaService from '../media/MediaService'

class ApiService { }

interface ApiService extends
  AuthService,
  NewslettersService,
  NotesService,
  ContactsService,
  DonationsService,
  WarehousesService,
  ProgramService,
  UsersService,
  TasksService,
  DepartmentsService,
  MediaService
{ }

applyMixins(
  ApiService,
  [
    AuthService,
    NewslettersService,
    NotesService,
    ContactsService,
    DonationsService,
    WarehousesService,
    ProgramService,
    UsersService,
    TasksService,
    DepartmentsService,
    MediaService
  ]
)

export default new ApiService()
