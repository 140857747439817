/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useTable, useGlobalFilter } from 'react-table'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import { tableRowNavigation } from 'src/helpers/tableRowNavigation'
import { useTranslation } from 'react-i18next'
import underscoreToUpperCase from 'src/utils/underscoreToUpperCase'

interface TableProps {
  columns: any
  data: any
}

function SubTable({ columns, data }: TableProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const modifiedData = useMemo(
    () => data.map((contact: any) => ('is_active' in contact
      ? {
        ...contact,
        is_active: contact.is_active ? t('active') : t('notActive')
      }
      : { ...contact })),
    [data, t]
  )

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } = useTable(
    {
      columns,
      data: modifiedData
    },
    useGlobalFilter
  )

  return (
    <table
      {...getTableProps()}
      className="w-full group mx-2 md:mx-0 border-collapse min-w-[1100px] print:min-w-[400px] print:max-w-[1100px] text-paragraph text-regular"
    >
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th
                {...column.getHeaderProps()}
                className="text-mdm-navy-blue text-regular font-[500] py-2 whitespace-nowrap px-2 md:px-3 text-left group-hover:bg-mdm-sky-blue/50 transition-colors duration-150"
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
        {...getTableBodyProps()}
        className="transition-colors duration-150 cursor-pointer group-hover:bg-mdm-sky-blue/50"
      >
        {rows.map((row: any) => {
          prepareRow(row)
          return (
            <tr key={row.id} {...row.getRowProps()}>
              {row.cells.map((cell: any) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <td
                  key={row.original.id}
                  {...cell.getCellProps()}
                  className="text-regular font-sans text-left align-top text-paragraph h-[50px]  px-2 md:px-3"
                  onClick={() => {
                    navigate(
                      tableRowNavigation({
                        key: 'contactsTable',
                        rowData: cell.row.original
                      })
                    )
                  }}
                >
                  {cell.value ? t(underscoreToUpperCase(cell.value)) : '-'}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default SubTable
