import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import apiService from 'src/services/api/apiService'
import useMutateData from 'src/hooks/useMutateData'
import { ResetPasswordTypes } from 'src/interfaces/resetPassword'
import logo from 'src/assets/images/mdm-logo-blue.png'
import ForgotPasswordForm from './components/ForgotPasswordForm'

function ForgotPassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = `MDM CRM - ${t('resetPassword')}`
  }, [t])

  const { mutate: resetPassword, isLoading } = useMutateData({
    key: 'email',
    mutationFn: apiService.forgotPassword,
    successMessage: 'emailWillBeSent'
  })

  const onSubmit = (values: ResetPasswordTypes) => {
    resetPassword(values, {
      onSuccess: () => navigate(-1)
    })
  }

  return (
    <div className="flex flex-col justify-center w-full min-h-full p-5">
      <img src={logo} className="mx-auto w-[8rem] h-[8rem] md:w-[12.3rem] md:h-[12.3rem]" alt="MdM Greece Logo" />
      <div className="mx-auto bg-white mt-12 max-w-[580px] rounded-regular shadow-faint w-full">
        <h1 className="w-[85%] mx-auto md:w-3/4 pt-8 md:pt-12 font-bold text-center text-[1.6rem] md:text-[1.875rem] text-mdm-blue">{t('Forgot password?')}</h1>
        <p className="w-[85%] mx-auto md:w-3/4 mt-8 md:mb-10 text-sm text-center md:mx-16 md:text-regular text-paragraph">
          {t('pleaseTypeEmailToResetPassword')}
        </p>
        <ForgotPasswordForm onSubmit={onSubmit} isLoading={isLoading} />
      </div>
    </div>
  )
}

export default ForgotPassword
