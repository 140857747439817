import { useState, useEffect } from 'react';
import { FieldInputProps, useFormikContext } from 'formik';
import { HiOutlinePlus } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import apiService from 'src/services/api/apiService';
import useMutateData from 'src/hooks/useMutateData';
import { TagsTypes } from 'src/interfaces/contacts';
import useGetData from 'src/hooks/useGetData';
import Badge from '../UI/Badge';
import InputErrorMessage from '../UI/InputErrorMsg';

interface Props {
  inputId: string;
  name: string;
  getFieldProps: FieldInputProps<any>;
  errors: boolean;
  errorName: string | undefined;
  type?: string;
  width?: 'lg:w-1/3' | 'lg:w-1/2' | 'lg:w-full' | 'md:w-full' | 'w-full';
  marginX?: 'mx-4' | 'mx-2' | 'mx-0';
  marginY?: 'my-4' | 'my-0';
}

function FormTagInput({
  inputId,
  name,
  getFieldProps,
  errors,
  type = 'text',
  marginX = 'mx-4',
  marginY = 'my-4'
}: Props) {
  const [createdTags, setCreatedTags] = useState<string[]>([]);
  const [tagError, setTagError] = useState<string>('');
  const { t } = useTranslation();
  const [selectedTags, setSelectedTags] = useState<TagsTypes[]>(getFieldProps.value);
  const formikContext = useFormikContext();
  const { setFieldValue } = formikContext;

  const { mutate: createTag } = useMutateData({
    key: 'tags',
    mutationFn: apiService.createTag,
    successMessage: 'tagHasBeenCreated'
  })

  // get tags for tasks
  const { data: tagsGlobal } = useGetData<TagsTypes[]>({
    queryKey: 'tags',
    queryFn: apiService.getAllTags
  })

  const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tagValue = event.target.value;
    setCreatedTags([tagValue]);
    setTagError('');
  };

  const createTagHandler = () => {
    if (createdTags.length === 0) {
      setTagError('tagNameRequired');
    } else {
      createdTags.forEach((tag) => {
        createTag(tag);
      });
      setCreatedTags([]);
      setTagError('');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      createTagHandler();
    }
  };

  const { mutate: deleteTagMutate } = useMutateData({
    key: 'tags',
    mutationFn: apiService.deleteTag,
    successMessage: 'tagSuccessfullyDeleted'
  });

  const handleTagSelection = (tag: TagsTypes) => {
    const isTagSelected = selectedTags.some((selectedTag) => selectedTag.id === tag.id);
    if (!isTagSelected) {
      setSelectedTags([...selectedTags, tag]);
    }
  }

  const deleteSelectedTag = (tagId: string | number) => {
    const updatedTags = selectedTags.filter((tag) => tag.id !== tagId);
    setSelectedTags(updatedTags);
  }

  useEffect(() => {
    // const tagsNotInGlobal = selectedTags.filter(
    //   (selectedTag) => !tagsGlobal?.some((globalTag) => globalTag.id === selectedTag.id)
    // );

    // if (tagsNotInGlobal.length > 0) {
    //   setSelectedTags((prevSelectedTags) => prevSelectedTags
    //     .filter((tag) => !tagsNotInGlobal.includes(tag)));
    // }
    setFieldValue(getFieldProps.name, selectedTags.map((tag) => tag.id));
  }, [selectedTags, tagsGlobal, getFieldProps.name, setFieldValue]);

  return (
    <div className={`relative text-sm md:text-regular w-full sm:w-full flex flex-col ${marginY}`}>
      <label
        htmlFor={inputId}
        className={`md:${marginX} mb-2 text-paragraph whitespace-nowrap ${
          name === 'Email' ? 'font-sans' : ''
        }`}
      >
        {t(name)}
      </label>
      <div className="flex flex-wrap gap-1 md:mx-4 mb-6 max-h-[150px] overflow-y-auto max-w-full">
        {tagsGlobal?.map((tag) => (
          <div
            role="presentation"
            key={tag.id}
            onClick={() => handleTagSelection(tag)}
          >
            <Badge
              text={tag.title}
              deleteTag={deleteTagMutate}
              tagId={tag.id}
              isSelected={selectedTags.some((selectedTag) => selectedTag.id === tag.id)}
            />
          </div>
        ))}
      </div>
      <div>
        <label htmlFor="tagCreation" className="md:mx-4 text-paragraph whitespace-nowrap">{t('createTag')}</label>
        <div className="relative grid justify-between mt-2 w-full md:mx-4 grid-cols-[75%_30%] md:grid-cols-[40%_60%] lg:grid-cols-4">
          <input
            type={type}
            id="tagCreation"
            className={`text-black border py-3.5 px-4 rounded-small h-12 ${
              errors || tagError ? 'border-red-700 focus:border-red-700 focus:ring-0' : 'border-lines focus:border-mdm-blue focus:ring-0'
            }`}
            value={createdTags}
            onChange={handleTagChange}
            onKeyDown={handleKeyPress}
          />
          <button
            type="button"
            className="w-12 flex items-center justify-center h-12 ml-4 text-mdm-blue bg-fields font-bold rounded-[8px] hover:text-mdm-navy-blue transition-all duration-200"
            onClick={createTagHandler}
            title={t('addTag')}
          >
            <HiOutlinePlus size={20} />
          </button>
          <div className="absolute w-full -mt-8 md:-mt-9">
            <InputErrorMessage text={t(tagError)} />
          </div>
        </div>
      </div>
      <input
        {...getFieldProps}
        value={getFieldProps.value || ''}
        id={inputId}
        type={type}
        className="hidden"
      />
      <div className="flex flex-col mt-6">
        {selectedTags.length > 0 ? (
          <>
            <p className="mb-2 md:mx-4 text-paragraph whitespace-nowrap">{t('chosenTags')}</p>
            <div className="flex flex-wrap gap-1 md:mx-4 max-h-[150px] overflow-y-auto">
              {selectedTags.map((tag) => (
                <div
                  role="presentation"
                  key={tag.id}
                >
                  <Badge
                    text={tag.title}
                    cursor="cursor-auto"
                    deleteSelectedTag={deleteSelectedTag}
                    selectedTagId={tag.id}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <p className="mb-2 md:mx-4 text-paragraph whitespace-nowrap">{t('noTagsSelected')}</p>
        )}
      </div>
    </div>
  );
}

export default FormTagInput;
