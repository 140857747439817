/* eslint-disable max-len */
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import routes from 'src/constants/routes'
import RenderIf from 'src/components/UI/RenderIf'
import Title from 'src/components/UI/Title'
import CalenderTasks from 'src/components/Calender/CalenderTasks'
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import TaskModal from 'src/components/Modals/TaskModal'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'

function Tasks() {
  const [t] = useTranslation()
  const { openTaskModalHandler } = useModalContext()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.admin.tasks || pathname === routes.agent.tasks
  const { tasks } = useDataProvider()

  const handleClickOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    openTaskModalHandler()
  }

  useEffect(() => {
    document.title = `MDM CRM - ${t('tasks')}`
  }, [t, tasks])

  return (
    <RenderIf isTrue={isExactPath}>
      <TaskModal />
      <section>
        <Title title="" add="createTask" onClickOpenModalHandler={handleClickOpenModal} />
        <CalenderTasks />
      </section>
    </RenderIf>
  )
}

export default Tasks
