import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { LoginTypes } from 'src/interfaces/login'
import apiService from 'src/services/api/apiService'
import { UserTypes } from 'src/interfaces/user'
import InputErrorMessage from 'src/components/UI/InputErrorMsg'
import RenderIf from 'src/components/UI/RenderIf'
import Spinner from 'src/components/UI/Spinner'
import routes from 'src/constants/routes'
import useMutateData from 'src/hooks/useMutateData'
import navigateTo from 'src/utils/navigateTo'
import validationSchema from 'src/utils/validationSchema'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'

function LoginForm() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)
  const [isCaptchaValid, setIsCaptchaValid] = useState<boolean>(false)
  const navigate = useNavigate()
  const initialValues: LoginTypes = {
    username: '',
    password: ''
  }

  const { setIsEnabled } = useDataProvider()

  const { mutate: sendCaptchaToken } = useMutateData({
    key: '',
    mutationFn: apiService.sendCaptchaToken
  })

  useEffect(
    () => {
      if (captchaToken) {
        sendCaptchaToken(captchaToken, {
          onSuccess: () => {
            setIsCaptchaValid(true)
          }
        })
      }
    },
    [sendCaptchaToken, captchaToken]
  )
  const onCaptchaError = () => {
    setCaptchaToken(null)
    setIsCaptchaValid(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.login,
    onSubmit: (values) => {
      if (!captchaToken && !isCaptchaValid) {
        toast.error<string>(t('captchaRequired'))
        return
      }

      setIsLoading(true)
      apiService
        .login(values)
        .then((res) => {
          const user: UserTypes = res?.data.data
          setIsEnabled(true)
          navigate(navigateTo(user.user.user_type))
        })
        .catch((error) => {
          toast.error<string>(t(error.message))
        })
        .finally(() => setIsLoading(false))
    }
  })

  const inputDivClasses = 'relative flex flex-col w-[85%] md:w-3/4 mx-auto mt-4'

  return (
    <form onSubmit={formik.handleSubmit} className="relative flex flex-col">
      <div className={inputDivClasses}>
        <label htmlFor="username" className="mb-2 text-paragraph">
          {t('Username')}
        </label>
        <input
          className={`border py-3.5 px-4 rounded-small h-12 
          ${formik.errors.username && formik.touched.username
            ? 'border-red-700 focus:border-red-700 focus:ring-0'
            : 'border-lines focus:border-mdm-blue focus:ring-0'
          }`}
          id="username"
          type="text"
          autoComplete="on"
          {...formik.getFieldProps('username')}
        />
        <RenderIf isTrue={!!(formik.touched.username && formik.errors.username)}>
          <InputErrorMessage text={formik.errors.username!} />
        </RenderIf>
      </div>
      <div className={inputDivClasses}>
        <label htmlFor="password" className="mb-2 text-paragraph">
          {t('Password')}
        </label>
        <input
          className={`border py-3.5 px-4 rounded-small h-12 ${formik.errors.password && formik.touched.password ? 'border-red-700 focus:border-red-700 focus:ring-0' : 'border-lines focus:border-mdm-blue focus:ring-0'
          }`}
          id="password"
          type="password"
          autoComplete="on"
          {...formik.getFieldProps('password')}
        />
        <RenderIf isTrue={!!(formik.touched.password && formik.errors.password)}>
          <InputErrorMessage text={formik.errors.password!} />
        </RenderIf>
      </div>
      <div className="w-[85%] md:w-3/4 mx-auto mb-4 text-xs xs:flex xs:justify-between">
        <div className="mt-3 text-right ml-auto text-[#0065BD80] hover:text-mdm-blue transition-colors duration-200">
          <Link to={routes.forgotPassword}>{t('Forgot password?')}</Link>
        </div>
      </div>
      <div className="flex justify-center">
        <HCaptcha
          onExpire={onCaptchaError}
          onError={onCaptchaError}
          onClose={onCaptchaError}
          onVerify={(token) => setCaptchaToken(token)}
          sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY_PRODUCTION!}
        />
      </div>
      <button
        disabled={!isCaptchaValid}
        className="md:w-3/4 font-[500] w-[85%] p-4 mx-auto mt-4 text-center text-white duration-200 md:p-5 mb-8 md:mb-14 disabled:bg-mdm-navy-blue/80 disabled:cursor-not-allowed text-regular bg-mdm-blue xs:mt-6 rounded-small hover:bg-mdm-navy-blue transition-color"
        type="submit"
      >
        {isLoading ? <Spinner /> : t('Login')}
      </button>
    </form>
  )
}

export default LoginForm
