import axios, { AxiosError, AxiosResponse } from 'axios'
import exception from 'src/utils/exception'
import { ServerError } from 'src/interfaces/api'
import axiosInstance from '../axiosInstance'
import { MediaServiceInterface } from './mediaService.interface'

class MediaService implements MediaServiceInterface {
  public async deleteMedia(id:string): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.delete(`warehouses/warehouse-file/${id}/`)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }
}

export default MediaService;
