import { useTranslation } from 'react-i18next'
import email from 'src/assets/images/email_preview_template.png'

function EmailTemplatePreviewImage() {
  const { t } = useTranslation()
  return (
    <div className="h-[calc(100%-40px)] pb-6 w-full ">
      <p className="-mt-3 mb-6 text-[1.38rem] font-medium text-mdm-navy-blue">{t('emailSample')}</p>
      <img src={email} alt="email preview" className="w-full h-full" loading="lazy" />
    </div>
  )
}

export default EmailTemplatePreviewImage
