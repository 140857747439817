import { BsSearch } from 'react-icons/bs';
import { useAsyncDebounce } from 'react-table'
import { useTranslation } from 'react-i18next'

interface Props {
  setFilter: (value: string) => void
}

function Search({ setFilter }: Props) {
  const { t } = useTranslation()

  const handleChange = useAsyncDebounce((val) => setFilter(val || ''), 500);

  return (
    <div className="bg-white min-w-[200px] md:max-w-[60%] lg:max-w-[50%] xl:max-w-[40%] w-full flex items-center border border-solid border-lines  rounded-small px-4 ">
      <input
        type="text"
        placeholder={t('search')}
        onChange={(event) => {
          handleChange(event.target.value)
        }}
        inputMode="search"
        className="w-full py-3 border-0 focus:border-transparent focus:ring-0 placeholder:text-paragraph"
      />
      <BsSearch size={18} className="text-lines" />
    </div>
  )
}

export default Search
