import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import FormButtons from 'src/components/Form/FormButtons'
import { NoteCreationTypes } from 'src/interfaces/notes'
import UserMethods from 'src/utils/userMethods'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import validationSchema from 'src/utils/validationSchema'
import RenderIf from 'src/components/UI/RenderIf'
import InputErrorMessage from 'src/components/UI/InputErrorMsg'
import { FaRegTrashAlt, FaRegCopy, FaCopy } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import useCopyToClipboard from 'src/hooks/useCopyClipboard'

function NoteModal() {
  const authUser = UserMethods.getUser();
  const { selectedNote } = useModalContext();
  const { t } = useTranslation();
  const { showNoteModal, closeNoteModalHandler } = useModalContext();
  const [isCopied, handleCopy] = useCopyToClipboard(2000);

  const { mutate, isLoading } = useMutateData({
    key: 'notes',
    mutationFn: selectedNote ? apiService.editNote : apiService.createNote,
    successMessage: selectedNote
      ? 'noteHasBeenUpdated'
      : 'noteHasBeenCreated'
  });

  const initialValues: NoteCreationTypes = {
    id: '',
    user: authUser?.user.id ?? '',
    title: '',
    description: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.noteCreationForm,
    onSubmit: (values: NoteCreationTypes) => {
      mutate(values, {
        onSuccess: () => {
          closeNoteModalHandler();
          formik.resetForm();
        }
      });
    }
  });

  const { setValues } = formik;
  useEffect(() => {
    if (selectedNote) {
      setValues({
        id: selectedNote.id ?? '',
        user: authUser?.user.id ?? '',
        title: selectedNote?.title ?? '',
        description: selectedNote?.description ?? ''
      });
    } else {
      setValues({
        id: '',
        user: authUser?.user.id ?? '',
        title: '',
        description: ''
      });
    }
  }, [selectedNote, authUser?.user.id, setValues]);

  const { mutate: deleteNoteMutate } = useMutateData({
    key: 'notes',
    mutationFn: apiService.deleteNote,
    successMessage: 'noteSuccessfullyDeleted'
  });

  const handleDeleteNote = () => {
    deleteNoteMutate({
      id: selectedNote?.id ?? 0
    }, {
      onSuccess: () => {
        closeNoteModalHandler();
        formik.resetForm();
      }
    });
  };

  const handleCloseNoteModal = () => {
    closeNoteModalHandler();
    formik.resetForm();
  };

  const overlays = document.getElementById('overlays') as HTMLElement;

  const backdropClasses = showNoteModal ? 'opacity-100 visible' : 'opacity-0 invisible';
  const modalClasses = showNoteModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0';
  const labelClasses = 'text-paragraph text-small md:text-regular tracking-normal';
  const inputClasses = 'border rounded-small py-3.5 px-4';
  const wrapperClasses = 'flex flex-col gap-4 relative mb-3';

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={handleCloseNoteModal} />
      <div className={`absolute bg-white top-8 md:top-1/2 left-1/2 -translate-x-1/2 md:-translate-y-1/2 z-50 max-w-[90%] md:max-w-[648px] w-full origin-center transition-all duration-300 ${modalClasses} rounded-regular`}>
        <div className="flex items-center justify-between px-5 py-6 text-white rounded-t-2xl md:px-9 bg-mdm-blue">
          <h1 className="font-bold text-[1.75rem] md:text-2xl">{t(selectedNote ? 'note' : 'newNoteModal')}</h1>
          {selectedNote ? (
            <button type="button" className="flex items-center gap-4 md:gap-8">
              {isCopied ? (
                <FaCopy size={33} />
              ) : (
                <FaRegCopy
                  size={33}
                  onClick={() => handleCopy(formik.values)}
                />
              )}
              <FaRegTrashAlt
                size={33}
                onClick={handleDeleteNote}
              />
            </button>
          ) : (
            <button type="button">
              <IoMdClose size={35} onClick={handleCloseNoteModal} />
            </button>
          )}
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-1 p-4 md:gap-4 md:p-9 ">
          <div className={`${wrapperClasses}`}>
            <label className={`${labelClasses}`} htmlFor="title">{t('title')}</label>
            <input
              id="title"
              {...formik.getFieldProps('title')}
              className={`h-10 outline-none ${inputClasses} ${formik.errors.title && formik.touched.title ? 'border-red-700 focus:border-red-700 focus:ring-0 ' : 'border-lines focus:border-mdm-blue focus:ring-0'}`}
            />
            <RenderIf
              isTrue={!!(formik.errors.title && formik.touched.title)}
            >
              <InputErrorMessage text={formik.errors.title!} />
            </RenderIf>
          </div>
          <div className={`${wrapperClasses}`}>
            <label
              className={`${labelClasses}`}
              htmlFor="description"
            >
              {t('shortDescription')}

            </label>
            <textarea
              id="description"
              {...formik.getFieldProps('description')}
              className={`border h-[150px] resize-none ${inputClasses} ${formik.errors.description && formik.touched.description ? 'border-red-700 focus:border-red-700 focus:ring-0 ' : 'border-lines focus:border-mdm-blue focus:ring-0'}`}
            />
            <RenderIf
              isTrue={!!(formik.errors.description && formik.touched.description)}
            >
              <p className="text-red-700 text-[0.75rem] mt-1 top-[11.5rem] leading-normal md:top-[11.8rem] absolute left-0">
                {t(formik.errors.description!)}
              </p>
            </RenderIf>
          </div>
          <div className="hidden md:block w-full h-[1px] bg-lines/50" />
          <div className="mt-3">
            <FormButtons isLoading={isLoading} btnText={selectedNote ? 'saveChanges' : 'addNote'} onCancelHandler={closeNoteModalHandler} px="px-7" spinnerWidth={selectedNote ? 'w-[11.2rem]' : 'w-[10.9rem]'} />
          </div>
        </form>
      </div>
    </>,
    overlays
  );
}

export default NoteModal;
