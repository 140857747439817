import { useTranslation } from 'react-i18next'
import { HiOutlinePlus } from 'react-icons/hi'
import { FormikErrors } from 'formik'
import { MdDelete } from 'react-icons/md'
import RenderIf from '../UI/RenderIf'
import ExistingFiles from '../../pages/Common/pages/Newsletters/components/ExistingFiles'
import { useNewsletters } from '../../context/NewsletterCtx/NewslettersProvider'

interface Props {
  media: File[]
  setMedia: (field: string, value: any, shouldValidate?:
  (boolean | undefined)) => (Promise<FormikErrors<any>> | Promise<void>)
  onRemoveMediaHandler: (name: string) => void
  text?: string
  marginLeft?: 'ml-4' | 'ml-0'
  acceptedFiles: string
}

function FormUploadMedia({
  acceptedFiles, onRemoveMediaHandler, setMedia, media = [], marginLeft = 'ml-0', text
}:Props) {
  const { t } = useTranslation();
  const { existingFiles, isEditNewsletter } = useNewsletters()
  return (
    <div className={`${marginLeft} mb-4`}>
      <RenderIf isTrue={!!text}>
        <p className="mb-5 text-paragraph">{t(text!)}</p>
      </RenderIf>
      <RenderIf isTrue={existingFiles.length > 0 && isEditNewsletter}>
        <ExistingFiles />
      </RenderIf>
      <RenderIf isTrue={media.length > 0}>
        <div className="flex-col fex">
          {Array.from(media, (file, idx) => (
            <div key={idx} className="flex items-center gap-3 mb-3">
              <p className="text-[#100899] font-sans font-medium">{file.name}</p>
              <MdDelete className="cursor-pointer transition-colors duration-200 text-mdm-navy-blue hover:text-[#BD1118]" size={21} onClick={() => onRemoveMediaHandler(file.name)} />
            </div>
          ))}
        </div>
      </RenderIf>

      <label htmlFor="complaintMedia" className="flex cursor-pointer justify-center items-center text-mdm-navy-blue rounded-lg bg-fields w-[46px] h-[46px] hover:opacity-80 transition-opacity duration-200" title={t('addFile')}>
        <HiOutlinePlus />
        <input
          id="complaintMedia"
          type="file"
          multiple
          accept={acceptedFiles}
          className="hidden"
          onClick={(event) => {
            // Reset file list
            const target = event.target as HTMLInputElement
            target.value = ''
          }}
          onChange={(event) => {
            setMedia('files', event.currentTarget.files)
          }}
        />
      </label>
    </div>
  )
}

export default FormUploadMedia
