/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import * as yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import { useLocation } from 'react-router-dom'
import { Dispatch, SetStateAction, useState, useEffect, useMemo } from 'react'
import FormLayout from 'src/components/Form/FormLayout'
import FormInput from 'src/components/Form/FormInput'
import FormButtons from 'src/components/Form/FormButtons'
import FormSelect from 'src/components/Form/FormSelect'
import FormTextArea from 'src/components/Form/FormTextArea'
import {
  statusesOfSupport,
  sizesOfSupport,
  typesOfSupport,
  typesOfSponsor,
  institutionalPositions,
  media,
  supportTypes,
  banks,
  titles,
  statuses
} from 'src/data/contactForm'
import validationSchema from 'src/utils/validationSchema'
import RenderIf from 'src/components/UI/RenderIf'
import userMethods from 'src/utils/userMethods'
import FormTagInput from 'src/components/Form/FormTagInput'
import FormPhoneInput from 'src/components/Form/FormPhoneInput'
import routes from 'src/constants/routes'
import {
  CommunicationDepartmentContact,
  FinanceDepartmentContact,
  ProgramDepartmentContact,
  VolunteerDepartmentContact
} from 'src/interfaces/contacts'
import { DepartmentOption } from 'src/interfaces/department'

interface Props {
  onSubmit: (values: any) => void
  isLoading: boolean
  contact?: any
  setSelectedContactType?: Dispatch<SetStateAction<DepartmentOption | ''>>
  selectedContactType?: string
  handleSelectClick?: (event: React.MouseEvent<HTMLSelectElement>) => void
}

function formattedDate(dateString: string | undefined): string {
  if (!dateString) return ''
  const parsedDate = moment(dateString, 'YYYYMMDD')
  return parsedDate.format('YYYY-MM-DD')
}

function ContactCreationForm({
  onSubmit,
  isLoading,
  contact,
  selectedContactType,
  handleSelectClick,
  setSelectedContactType
}: Props) {
  const authUser = userMethods.getUser()
  const { t } = useTranslation()
  const [expandedForms, setExpandedForms] = useState(new Array(4).fill(true))
  const { pathname } = useLocation()
  const isExactPath = pathname.includes(routes.shared.updateContact)

  const initialDepartments = useMemo(() => {
    if (authUser?.user.user_type === 'ADMINISTRATOR') {
      return [
        {
          id: 1,
          title: 'Volunteers'
        },
        {
          id: 2,
          title: 'Programs'
        },
        {
          id: 3,
          title: 'Finance'
        },
        {
          id: 4,
          title: 'Communication'
        },
        {
          id: 5,
          title: 'Charity'
        }
      ]
    }
    if (authUser?.user.user_type === 'AGENT') {
      const agentDepartments =
        authUser.user.departments.map((department) => department) || []
      return [...agentDepartments]
    }
    return []
  }, [authUser?.user.user_type, authUser?.user.departments])

  const initialValues: any = {
    id: contact ? contact.id : '',
    title: contact ? contact.title : '',
    first_name: contact ? contact.first_name : '',
    last_name: contact ? contact.last_name : '',
    email: contact ? contact.email : '',
    home_phone: contact ? contact.home_phone : '',
    mobile_phone: contact ? contact.mobile_phone : '',
    carrier: contact ? contact.carrier : '',
    program: contact ? contact.program : '',
    department: contact ? contact.department.id : '',
    position: contact ? contact.position : '',
    country: contact ? contact.country : '',
    work_address: contact ? contact.work_address : '',
    tags: contact ? contact.tags : [],
    comments: contact ? contact.comments : ''
  }

  const financialDepContactValues: FinanceDepartmentContact = {
    type_of_support: contact ? contact.type_of_support : '',
    agency_name: contact ? contact.agency_name : '',
    amount: contact ? contact.amount : '',
    invoice_issue_date: contact
      ? formattedDate(contact.invoice_issue_date)
      : '',
    deposit_bank: contact ? contact.deposit_bank : '',
    deposit_account: contact ? contact.deposit_account : '',
    date_of_deposit: contact ? formattedDate(contact.date_of_deposit) : '',
    notes: contact ? contact.notes : ''
  }

  const communicationDepContactValues: CommunicationDepartmentContact = {
    website: contact ? contact.website : '',
    show: contact ? contact.show : '',
    media: contact ? (contact.media === '-' ? '' : contact.media) : ''
  }

  const volunteerDepContactValues: VolunteerDepartmentContact = {
    date_of_birth: contact ? formattedDate(contact.date_of_birth) : '',
    studies: contact ? contact.studies : '',
    job: contact ? contact.job : '',
    city: contact ? contact.city : '',
    volunteer_attributes: contact ? contact.volunteer_attributes : '',
    notes: contact ? contact.notes : '',
    is_active: contact ? (contact?.is_active ? 'active' : 'notActive') : true
  }

  const programDepContactValues: ProgramDepartmentContact = {
    institutional_position: contact ? contact.institutional_position : '',
    type_of_sponsor: contact ? contact.type_of_sponsor : '',
    type_of_support: contact ? contact.type_of_support : '',
    size_of_grant: contact ? contact.size_of_grant : '',
    current_status: contact ? contact.current_status : '',
    implementation_period: contact
      ? formattedDate(contact.implementation_period)
      : '',
    start_of_cooperation: contact
      ? formattedDate(contact.start_of_cooperation)
      : '',
    end_of_cooperation: contact
      ? formattedDate(contact.end_of_cooperation)
      : '',
    budget: contact ? contact.budget : ''
  }

  const updatedInitialValues = {
    ...initialValues,
    ...(selectedContactType === 'volunteers' ||
    contact?.department?.title?.toLowerCase() === 'volunteers'
      ? volunteerDepContactValues
      : {}),
    ...(selectedContactType === 'finance' ||
    contact?.department?.title?.toLowerCase() === 'finance'
      ? financialDepContactValues
      : {}),
    ...(selectedContactType === 'communication' ||
    contact?.department?.title?.toLowerCase() === 'communication'
      ? communicationDepContactValues
      : {}),
    ...(selectedContactType === 'programs' ||
    contact?.department?.title?.toLowerCase() === 'programs'
      ? programDepContactValues
      : {})
  }

  const getValidationSchema = () => {
    let validationSchemas: yup.ObjectSchema<any>[] = [
      validationSchema.createContactMainForm
    ]

    if (
      (selectedContactType &&
        selectedContactType?.toLowerCase() === 'volunteers') ||
      contact?.department?.title?.toLowerCase() === 'volunteers'
    ) {
      validationSchemas = [
        ...validationSchemas,
        validationSchema.createVolunteerContactFrom
      ]
    }
    if (
      (selectedContactType &&
        selectedContactType?.toLowerCase() === 'finance') ||
      contact?.department?.title?.toLowerCase() === 'finance'
    ) {
      validationSchemas = [
        ...validationSchemas,
        validationSchema.createFinancialContactFrom
      ]
    }
    if (
      (selectedContactType &&
        selectedContactType?.toLowerCase() === 'communication') ||
      contact?.department?.title?.toLowerCase() === 'communication'
    ) {
      validationSchemas = [
        ...validationSchemas,
        validationSchema.createCommunicationContactFrom
      ]
    }
    if (
      (selectedContactType &&
        selectedContactType?.toLowerCase() === 'programs') ||
      contact?.department?.title?.toLowerCase() === 'programs'
    ) {
      validationSchemas = [
        ...validationSchemas,
        validationSchema.createProgramContactFrom
      ]
    }
    return yup
      .object()
      .shape(
        Object.assign({}, ...validationSchemas.map((schema) => schema.fields))
      )
  }

  const formik = useFormik({
    initialValues: updatedInitialValues,
    validationSchema: getValidationSchema(),
    onSubmit
  })

  const {
    setValues,
    values: {
      title: formikTitle,
      email,
      first_name,
      last_name,
      home_phone,
      mobile_phone,
      carrier,
      program,
      position,
      country,
      work_address,
      tags
    }
  } = formik

  useEffect(() => {
    if (selectedContactType === 'volunteers') {
      setValues({
        values: {
          ...volunteerDepContactValues,
          department: 1,
          title: formikTitle,
          email,
          first_name,
          last_name,
          home_phone,
          mobile_phone,
          carrier,
          program,
          position,
          country,
          work_address,
          tags
        }
      })
    }
    if (selectedContactType === 'finance') {
      setValues({
        ...financialDepContactValues,
        department: 3,
        email,
        title: formikTitle,
        first_name,
        last_name,
        home_phone,
        mobile_phone,
        carrier,
        program,
        position,
        country,
        work_address,
        tags
      })
    }
    if (selectedContactType === 'communication') {
      setValues({
        ...communicationDepContactValues,
        department: 4,
        email,
        title: formikTitle,
        first_name,
        last_name,
        home_phone,
        mobile_phone,
        carrier,
        program,
        position,
        country,
        work_address,
        tags
      })
    }
    if (selectedContactType === 'programs') {
      setValues({
        ...programDepContactValues,
        department: 2,
        email,
        title: formikTitle,
        first_name,
        last_name,
        home_phone,
        mobile_phone,
        carrier,
        program,
        position,
        country,
        work_address,
        tags
      })
    }
  }, [selectedContactType, setValues, formik.resetForm])

  const toggleForm = (index: number) => {
    const updatedExpandedForms = [...expandedForms]
    updatedExpandedForms[index] = !updatedExpandedForms[index]
    setExpandedForms(updatedExpandedForms)

    if (setSelectedContactType) {
      setSelectedContactType(
        index === 0
          ? 'volunteers'
          : index === 1
          ? 'finance'
          : index === 2
          ? 'communication'
          : index === 3
          ? 'programs'
          : ''
      )
    }
  }

  useEffect(() => {
    if (initialDepartments && initialDepartments.length === 1) {
      const department = initialDepartments[0].title.toLowerCase()
      if (department !== undefined && setSelectedContactType) {
        setSelectedContactType(department as '' | DepartmentOption)
      }
    }
  }, [initialDepartments, setSelectedContactType])

  useEffect(() => {
    if (contact) {
      formik.setFieldValue('department', contact.department?.id)
    }
  }, [])

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <FormLayout
          title={t('basicDetails')}
          isExpandedForm
          py="md:py-10"
          marginTop="mt-0"
        >
          <div className="flex flex-col w-full xl:flex-row">
            <div className="flex flex-col w-full">
              <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                <div className="grid grid-cols-[32%_65%] md:grid-cols-[32%_61%] items-center gap-3">
                  <FormSelect
                    name="title"
                    inputId="title"
                    getFieldProps={formik.getFieldProps('title')}
                    errors={!!(formik.touched.title && formik.errors.title)}
                    errorName={formik.errors.title as string}
                    width="md:w-full"
                  >
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <option id="title" disabled value="" />
                    {titles?.map((title) => (
                      <option key={title.label} value={title?.value}>
                        {title.label}
                      </option>
                    ))}
                  </FormSelect>
                  <FormInput
                    inputId="degree"
                    marginX="mx-0"
                    marginR="mr-4"
                    name="addressToPerson"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('degree')}
                    errors={!!(formik.touched.degree && formik.errors.degree)}
                    errorName={formik.errors.degree as string}
                  />
                </div>
                <FormInput
                  inputId="first_name"
                  name="firstName"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('first_name')}
                  errors={
                    !!(formik.touched.first_name && formik.errors.first_name)
                  }
                  errorName={formik.errors.first_name as string}
                />
                <FormInput
                  inputId="last_name"
                  name="lastName"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('last_name')}
                  errors={
                    !!(formik.touched.last_name && formik.errors.last_name)
                  }
                  errorName={formik.errors.last_name as string}
                />
                <FormInput
                  inputId="email"
                  name="email"
                  type="email"
                  inputMode="email"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('email')}
                  errors={!!(formik.touched.email && formik.errors.email)}
                  errorName={formik.errors.email as string}
                />
                <FormPhoneInput
                  inputId="home_phone"
                  name="homePhone"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('home_phone')}
                  errors={
                    !!(formik.touched.home_phone && formik.errors.home_phone)
                  }
                  errorName={formik.errors.home_phone as string}
                />
                <FormPhoneInput
                  inputId="mobile_phone"
                  name="mobilePhone"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('mobile_phone')}
                  errors={
                    !!(
                      formik.touched.mobile_phone && formik.errors.mobile_phone
                    )
                  }
                  errorName={formik.errors.mobile_phone as string}
                />
                <FormInput
                  inputId="carrier"
                  name="carrier"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('carrier')}
                  errors={!!(formik.touched.carrier && formik.errors.carrier)}
                  errorName={formik.errors.carrier as string}
                />
                <FormInput
                  inputId="program"
                  name="program"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('program')}
                  errors={!!(formik.touched.program && formik.errors.program)}
                  errorName={formik.errors.program as string}
                />
                <FormInput
                  inputId="position"
                  name="position"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('position')}
                  errors={!!(formik.touched.position && formik.errors.position)}
                  errorName={formik.errors.position as string}
                />
                <FormInput
                  inputId="country"
                  name="country"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('country')}
                  errors={!!(formik.touched.country && formik.errors.country)}
                  errorName={formik.errors.country as string}
                />
                <FormInput
                  inputId="work_address"
                  name="workAddress"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('work_address')}
                  errors={
                    !!(
                      formik.touched.work_address && formik.errors.work_address
                    )
                  }
                  errorName={formik.errors.work_address as string}
                />
                <RenderIf
                  isTrue={!!(authUser?.user.user_type === 'ADMINISTRATOR')}
                >
                  <FormSelect
                    inputId="department"
                    name="department"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('department')}
                    errors={
                      !!(formik.touched.department && formik.errors.department)
                    }
                    errorName={formik.errors.department as string}
                    handleSelectClick={handleSelectClick}
                    disabled={
                      !isExactPath
                        ? false
                        : formik.getFieldProps('department').value
                    }
                  >
                    <RenderIf isTrue={!!contact}>
                      <option
                        key={contact?.department?.id}
                        value={contact?.department?.id}
                      >
                        {t(contact?.department?.title?.toLowerCase())}
                      </option>
                    </RenderIf>
                    <RenderIf isTrue={!contact}>
                      <option value="" label={t('selectOneOption')} />
                      {initialDepartments?.map(({ id, title }) => (
                        <option key={id} value={id}>
                          {t(title?.toLowerCase())}
                        </option>
                      ))}
                    </RenderIf>
                  </FormSelect>
                </RenderIf>
                <div className="col-span-1 md:col-span-2 lg:col-span-3">
                  <FormTextArea
                    inputId="comments"
                    name="notes"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('comments')}
                    errors={
                      !!(formik.touched.comments && formik.errors.comments)
                    }
                    errorName={formik.errors.comments as string}
                  />
                </div>
                <RenderIf isTrue={!!(authUser?.user.user_type === 'AGENT')}>
                  <FormSelect
                    inputId="department"
                    name="department"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('department')}
                    errors={
                      !!(formik.touched.department && formik.errors.department)
                    }
                    errorName={formik.errors.department as string}
                    handleSelectClick={handleSelectClick}
                    disabled={
                      (initialDepartments && initialDepartments.length === 1) ||
                      (isExactPath && formik.getFieldProps('department').value)
                    }
                  >
                    <RenderIf isTrue={!!contact}>
                      <option
                        key={contact?.department?.id}
                        value={contact?.department?.id}
                      >
                        {t(contact?.department?.title?.toLowerCase())}
                      </option>
                    </RenderIf>
                    <RenderIf isTrue={!contact}>
                      {initialDepartments && initialDepartments.length > 1 && (
                        <option value="" label={t('selectOneOption')} />
                      )}
                      {initialDepartments.map(({ id, title }) => (
                        <option key={id} value={id}>
                          {t(title.toLowerCase())}
                        </option>
                      ))}
                    </RenderIf>
                  </FormSelect>
                </RenderIf>
              </div>
              <div className="w-full grid-cols-1">
                <FormTagInput
                  inputId="tags"
                  name="chooseTag"
                  getFieldProps={formik.getFieldProps('tags')}
                  errors={!!(formik.touched.tags && formik.errors.tags)}
                  errorName={formik.errors.tags as string}
                />
              </div>
            </div>
          </div>
        </FormLayout>
        <RenderIf isTrue={formik.values.department === 'Volunteers'}>
          <FormLayout
            title={t('detailsDeptVolunteers')}
            cursor="cursor-pointer"
            bgColor="bg-fields"
            shadow="none"
            py="md:py-5"
            toggleForm={() => toggleForm(0)}
            isExpandedForm={expandedForms[0]}
          >
            <div className="flex flex-col w-full xl:flex-row">
              <div className="flex flex-col w-full">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  <FormInput
                    type="date"
                    inputId="date_of_birth"
                    name="dateOfBirth"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('date_of_birth')}
                    errors={
                      !!(
                        formik.touched.date_of_birth &&
                        formik.errors.date_of_birth
                      )
                    }
                    errorName={formik.errors.date_of_birth as string}
                  />
                  <FormInput
                    inputId="studies"
                    name="studies"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('studies')}
                    errors={!!(formik.touched.studies && formik.errors.studies)}
                    errorName={formik.errors.studies as string}
                  />
                  <FormInput
                    inputId="job"
                    name="job"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('job')}
                    errors={!!(formik.touched.job && formik.errors.job)}
                    errorName={formik.errors.job as string}
                  />
                  <FormInput
                    inputId="city"
                    name="city"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('city')}
                    errors={!!(formik.touched.city && formik.errors.city)}
                    errorName={formik.errors.city as string}
                  />
                  <FormInput
                    inputId="volunteer_attributes"
                    name="volunteersAttributes"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('volunteer_attributes')}
                    // eslint-disable-next-line max-len
                    errors={
                      !!(
                        formik.touched.volunteer_attributes &&
                        formik.errors.volunteer_attributes
                      )
                    }
                    errorName={formik.errors.volunteer_attributes as string}
                  />
                  <RenderIf isTrue={contact}>
                    <FormSelect
                      inputId="is_active"
                      name="status"
                      width="md:w-full"
                      getFieldProps={formik.getFieldProps('is_active')}
                      errors={
                        !!(formik.touched.is_active && formik.errors.is_active)
                      }
                      errorName={formik.errors.is_active as string}
                      handleSelectClick={handleSelectClick}
                    >
                      {statuses?.map((status) => (
                        <option key={status.label} value={status?.value}>
                          {t(status.label)}
                        </option>
                      ))}
                    </FormSelect>
                  </RenderIf>
                </div>
                <FormTextArea
                  inputId="notes"
                  name="ratingComments"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('notes')}
                  errors={!!(formik.touched.notes && formik.errors.notes)}
                  errorName={formik.errors.notes as string}
                />
              </div>
            </div>
          </FormLayout>
        </RenderIf>
        <RenderIf isTrue={formik.values.department === 'Finance'}>
          <FormLayout
            title={t('detailsDeptFinancial')}
            cursor="cursor-pointer"
            bgColor="bg-fields"
            shadow="none"
            py="md:py-5"
            toggleForm={() => toggleForm(1)}
            isExpandedForm={expandedForms[1]}
          >
            <div className="flex flex-col w-full xl:flex-row">
              <div className="flex flex-col w-full">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  <FormSelect
                    inputId="type_of_support"
                    name="typeOfSupport"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('type_of_support')}
                    errors={
                      !!(
                        formik.touched.type_of_support &&
                        formik.errors.type_of_support
                      )
                    }
                    errorName={formik.errors.type_of_support as string}
                  >
                    {supportTypes?.map((title) => (
                      <option key={title.label} value={title?.value}>
                        {t(title.label)}
                      </option>
                    ))}
                  </FormSelect>
                  <FormInput
                    inputId="agency_name"
                    name="agencyName"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('agency_name')}
                    errors={
                      !!(
                        formik.touched.agency_name && formik.errors.agency_name
                      )
                    }
                    errorName={formik.errors.agency_name as string}
                  />
                  <FormInput
                    type="number"
                    inputId="amount"
                    name="amount"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('amount')}
                    errors={!!(formik.touched.amount && formik.errors.amount)}
                    errorName={formik.errors.amount as string}
                  />
                  <FormInput
                    type="date"
                    inputId="invoice_issue_date"
                    name="invoiceIssueDate"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('invoice_issue_date')}
                    // eslint-disable-next-line max-len
                    errors={
                      !!(
                        formik.touched.invoice_issue_date &&
                        formik.errors.invoice_issue_date
                      )
                    }
                    errorName={formik.errors.invoice_issue_date as string}
                  />
                  <FormSelect
                    inputId="deposit_bank"
                    name="depositBank"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('deposit_bank')}
                    errors={
                      !!(
                        formik.touched.deposit_bank &&
                        formik.errors.deposit_bank
                      )
                    }
                    errorName={formik.errors.deposit_bank as string}
                  >
                    {banks?.map((title) => (
                      <option key={title.label} value={title?.value}>
                        {t(title.label)}
                      </option>
                    ))}
                  </FormSelect>
                  <FormInput
                    inputId="deposit_account"
                    name="depositAccount"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('deposit_account')}
                    errors={
                      !!(
                        formik.touched.deposit_account &&
                        formik.errors.deposit_account
                      )
                    }
                    errorName={formik.errors.deposit_account as string}
                  />
                  <FormInput
                    type="date"
                    inputId="date_of_deposit"
                    name="dateOfDeposit"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('date_of_deposit')}
                    errors={
                      !!(
                        formik.touched.date_of_deposit &&
                        formik.errors.date_of_deposit
                      )
                    }
                    errorName={formik.errors.date_of_deposit as string}
                  />
                </div>
                <FormTextArea
                  inputId="notes"
                  name="observations"
                  width="md:w-full"
                  getFieldProps={formik.getFieldProps('notes')}
                  errors={!!(formik.touched.notes && formik.errors.notes)}
                  errorName={formik.errors.notes as string}
                />
              </div>
            </div>
          </FormLayout>
        </RenderIf>
        <RenderIf isTrue={formik.values.department === 'Communication'}>
          <FormLayout
            title={t('detailsDeptCommunication')}
            cursor="cursor-pointer"
            bgColor="bg-fields"
            shadow="none"
            py="md:py-5"
            toggleForm={() => toggleForm(2)}
            isExpandedForm={expandedForms[2]}
          >
            <div className="flex flex-col w-full xl:flex-row">
              <div className="flex flex-col w-full">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  <FormInput
                    inputId="website"
                    name="Website"
                    width="md:w-full"
                    inputMode="url"
                    getFieldProps={formik.getFieldProps('website')}
                    errors={!!(formik.touched.website && formik.errors.website)}
                    errorName={formik.errors.website as string}
                  />
                  <FormInput
                    inputId="show"
                    name="show"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('show')}
                    errors={!!(formik.touched.show && formik.errors.show)}
                    errorName={formik.errors.show as string}
                  />
                  <FormSelect
                    inputId="media"
                    name="mediaCommunication"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('media')}
                    errors={!!(formik.touched.media && formik.errors.media)}
                    errorName={formik.errors.media as string}
                  >
                    {media?.map((title) => (
                      <option key={title.label} value={title?.value}>
                        {t(title.label)}
                      </option>
                    ))}
                  </FormSelect>
                </div>
              </div>
            </div>
          </FormLayout>
        </RenderIf>
        <RenderIf isTrue={formik.values.department === 'Charity'}>
          <FormLayout
            title={t('detailsDeptCharity')}
            bgColor="bg-fields"
            shadow="none"
            py="md:py-5"
            isExpandedForm={false}
          >
            {''}
          </FormLayout>
        </RenderIf>
        <RenderIf isTrue={formik.values.department === 'Programs'}>
          <FormLayout
            title={t('detailsDeptProgram')}
            cursor="cursor-pointer"
            bgColor="bg-fields"
            shadow="none"
            py="md:py-5"
            toggleForm={() => toggleForm(3)}
            isExpandedForm={expandedForms[3]}
          >
            <div className="flex flex-col w-full xl:flex-row">
              <div className="flex flex-col w-full">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  <FormSelect
                    inputId="institutional_position"
                    name="institutionalPosition"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps(
                      'institutional_position'
                    )}
                    errors={
                      !!(
                        formik.touched.institutional_position &&
                        formik.errors.institutional_position
                      )
                    }
                    errorName={formik.errors.institutional_position as string}
                  >
                    {institutionalPositions?.map((title) => (
                      <option key={title.label} value={title?.value}>
                        {t(title.label)}
                      </option>
                    ))}
                  </FormSelect>
                  <FormSelect
                    inputId="type_of_sponsor"
                    name="typeOfSponsor"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('type_of_sponsor')}
                    errors={
                      !!(
                        formik.touched.type_of_sponsor &&
                        formik.errors.type_of_sponsor
                      )
                    }
                    errorName={formik.errors.type_of_sponsor as string}
                  >
                    {typesOfSponsor?.map((title) => (
                      <option key={title.label} value={title?.value}>
                        {t(title.label)}
                      </option>
                    ))}
                  </FormSelect>
                  <FormSelect
                    inputId="type_of_support"
                    name="typeOfSupport"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('type_of_support')}
                    errors={
                      !!(
                        formik.touched.type_of_support &&
                        formik.errors.type_of_support
                      )
                    }
                    errorName={formik.errors.type_of_support as string}
                  >
                    {typesOfSupport?.map((title) => (
                      <option key={title.label} value={title?.value}>
                        {t(title.label)}
                      </option>
                    ))}
                  </FormSelect>
                  <FormSelect
                    inputId="size_of_grant"
                    name="sizeOfGrant"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('size_of_grant')}
                    errors={
                      !!(
                        formik.touched.size_of_grant &&
                        formik.errors.size_of_grant
                      )
                    }
                    errorName={formik.errors.size_of_grant as string}
                  >
                    {sizesOfSupport?.map((title) => (
                      <option key={title.label} value={title?.value}>
                        {t(title.label)}
                      </option>
                    ))}
                  </FormSelect>
                  <FormSelect
                    inputId="current_status"
                    name="currentStatus"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('current_status')}
                    // eslint-disable-next-line max-len
                    errors={
                      !!(
                        formik.touched.current_status &&
                        formik.errors.current_status
                      )
                    }
                    errorName={formik.errors.current_status as string}
                  >
                    {statusesOfSupport?.map((title) => (
                      <option key={title.label} value={title?.value}>
                        {t(title.label)}
                      </option>
                    ))}
                  </FormSelect>
                  <FormInput
                    type="date"
                    inputId="implementation_period"
                    name="implementationPeriod"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps(
                      'implementation_period'
                    )}
                    // eslint-disable-next-line max-len
                    errors={
                      !!(
                        formik.touched.implementation_period &&
                        formik.errors.implementation_period
                      )
                    }
                    errorName={formik.errors.implementation_period as string}
                  />
                  <FormInput
                    type="date"
                    inputId="start_of_cooperation"
                    name="startOfCooperation"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('start_of_cooperation')}
                    // eslint-disable-next-line max-len
                    errors={
                      !!(
                        formik.touched.start_of_cooperation &&
                        formik.errors.start_of_cooperation
                      )
                    }
                    errorName={formik.errors.start_of_cooperation as string}
                  />
                  <FormInput
                    type="date"
                    inputId="end_of_cooperation"
                    name="endOfCooperation"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('end_of_cooperation')}
                    // eslint-disable-next-line max-len
                    errors={
                      !!(
                        formik.touched.end_of_cooperation &&
                        formik.errors.end_of_cooperation
                      )
                    }
                    errorName={formik.errors.end_of_cooperation as string}
                  />
                  <FormInput
                    type="number"
                    inputId="budget"
                    name="budget"
                    width="md:w-full"
                    getFieldProps={formik.getFieldProps('budget')}
                    // eslint-disable-next-line max-len
                    errors={!!(formik.touched.budget && formik.errors.budget)}
                    errorName={formik.errors.budget as string}
                  />
                </div>
              </div>
            </div>
          </FormLayout>
        </RenderIf>
        <FormButtons
          isLoading={isLoading}
          btnText={contact ? t('save') : t('addContact')}
          spinnerWidth={contact ? '' : 'w-[9.6rem]'}
          px="px-10"
        />
      </form>
    </FormikProvider>
  )
}

export default ContactCreationForm
