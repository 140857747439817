import logo from 'src/assets/images/mdm-logo-white.png'
import { Link } from 'react-router-dom'
import routes from 'src/constants/routes'
import userMethods from 'src/utils/userMethods'

function Logo() {
  const authUser = userMethods.getUser()

  return (
    <Link to={authUser?.user.user_type === 'ADMINISTRATOR' ? routes.admin.home : routes.agent.home} className="flex-shrink-0">
      <img src={logo} alt="MdM Greece Logo" className="w-12 h-12 mr-5 md:h-auto" />
    </Link>

  )
}

export default Logo
