import { useTranslation } from 'react-i18next'
import { BiExport, BiLoader } from 'react-icons/bi'
import { CSVLink } from 'react-csv'
import apiService from 'src/services/api/apiService'
import { useMutation } from 'react-query'
import moment from 'moment'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import { SelectedDonationsTypes } from 'src/interfaces/donations'
import RenderIf from '../UI/RenderIf'

interface Props {
  tableDataForExport?: any
  isContactsTable?: boolean
  isDonationsTable?: boolean
  isDashboardTable?: boolean
  selectedRowsForExport?: Array<string>
  selectedDonationsForExport?: SelectedDonationsTypes
}

function TableExportButton({
  tableDataForExport,
  isContactsTable,
  isDonationsTable,
  selectedRowsForExport,
  selectedDonationsForExport,
  isDashboardTable
}: Props) {
  const { t } = useTranslation()
  const { selectedContactsForExport } = useDataProvider()

  const getSelectedDonationsCount = (donations: SelectedDonationsTypes) => {
    const financialCount = donations?.financial_ids.length || 0
    const goodsCount = donations?.goods_ids.length || 0
    return financialCount + goodsCount
  }

  const totalSelectedDonationCount = getSelectedDonationsCount(
    selectedDonationsForExport!
  )

  const { mutate: exportTable, isLoading } = useMutation({
    mutationFn: () => {
      if (isContactsTable) {
        return apiService.exportAndDownloadContacts(selectedContactsForExport)
      }
      if (isDonationsTable) {
        return apiService.exportAndDownloadDonations(
          selectedDonationsForExport!
        )
      }
      return Promise.reject(new Error('Invalid table type'))
    },
    onSuccess: (response) => {
      if (response?.data instanceof ArrayBuffer) {
        const uint8Array = new Uint8Array(response.data)
        const decodedString = new TextDecoder('utf-8').decode(uint8Array)

        const blob = new Blob([`\uFEFF${decodedString}`], {
          type: 'text/csv; charset=utf-8'
        })

        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${moment(new Date()).format('YYYY-MM-DD')}.csv`

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  })

  return (
    <button
      disabled={
        !tableDataForExport || tableDataForExport.length === 0 || isLoading
      }
      type="button"
      className="px-4 py-3 text-sm bg-white border border-solid py-small border-lines rounded-small text-paragraph disabled:text-paragraph/70 disabled:border-lines/70 disabled:cursor-not-allowed"
      title={t('export')}
      onClick={() =>
        isContactsTable || isDonationsTable ? exportTable() : null
      }
    >
      <RenderIf
        isTrue={
          (!tableDataForExport || tableDataForExport.length === 0) &&
          isContactsTable!
        }
      >
        <div className="flex items-center gap-2 opacity-50 cursor-not-allowed">
          <BiExport size={20} className="mb-[3px] flex-shrink-0" />
          <span className="hidden lg:block">{t('export')}</span>
        </div>
      </RenderIf>
      <RenderIf
        isTrue={!!tableDataForExport && !isContactsTable && !isDonationsTable}
      >
        <CSVLink
          data={tableDataForExport}
          filename={`${moment(new Date()).format('YYYY-MM-DD')}.csv`}
          target="_blank"
          className="flex items-center gap-2"
        >
          <BiExport size={20} className="mb-[3px] flex-shrink-0" />
          <span
            className={`${
              isDashboardTable ? 'custom-screen-2:block' : 'lg:block'
            } hidden`}
          >
            {selectedRowsForExport && selectedRowsForExport.length === 0
              ? t('export')
              : `${t('export')} (${selectedRowsForExport?.length})`}
          </span>
        </CSVLink>
      </RenderIf>
      <RenderIf isTrue={isContactsTable! && tableDataForExport.length !== 0}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a target="_blank" className="flex items-center gap-2" rel="noreferrer">
          {isLoading ? (
            <BiLoader
              size={20}
              className="mb-[3px] animate-spin flex-shrink-0"
            />
          ) : (
            <BiExport size={20} className="mb-[3px] flex-shrink-0" />
          )}
          <span className="hidden lg:block">
            {isContactsTable && selectedContactsForExport.length === 0
              ? t('export')
              : `${t('export')} (${selectedContactsForExport.length})`}
          </span>
        </a>
      </RenderIf>
      <RenderIf isTrue={isDonationsTable!}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a target="_blank" className="flex items-center gap-2" rel="noreferrer">
          {isLoading ? (
            <BiLoader
              size={20}
              className="mb-[3px] animate-spin flex-shrink-0"
            />
          ) : (
            <BiExport size={20} className="mb-[3px] flex-shrink-0" />
          )}
          <span className="hidden lg:block">
            {isDonationsTable && totalSelectedDonationCount === 0
              ? t('export')
              : `${t('export')} (${totalSelectedDonationCount})`}
          </span>
        </a>
      </RenderIf>
    </button>
  )
}

export default TableExportButton
