/* eslint-disable no-nested-ternary */
import ReactDOM from 'react-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FocusLock from 'react-focus-lock'
import { MutationFunction } from 'react-query'
import { AxiosResponse } from 'axios'
import { BsExclamationCircle } from 'react-icons/bs'
import { useModalContext } from '../../context/ModalsCtx/ModalsProvider'
import useMutateData from '../../hooks/useMutateData'

interface Props {
  cacheKey: string | string[]
  toastSuccessMessage: string
  mutationFn:MutationFunction<AxiosResponse | null, any >,
}

function ConfirmationModal(
  {
    cacheKey,
    toastSuccessMessage,
    mutationFn
  }:Props
) {
  const { t } = useTranslation()
  const {
    modalText, resourceId, closeConfirmationModalHandler, showConfirmationModal,
    contactId, closeContactConfirmationModalHandler
  } = useModalContext()
  const { mutate } = useMutateData({
    key: cacheKey,
    mutationFn,
    successMessage: toastSuccessMessage
  })

  const mutateParams = toastSuccessMessage === t('userHasBeenDeleted')
    ? { id: resourceId?.id!, userRole: resourceId?.userRole! }
    : contactId?.id
      ? { id: contactId?.id, contactDepartmentType: contactId?.contactDepartmentType! }
      : { id: resourceId?.id, userRole: null }

  const backdropClasses = showConfirmationModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = showConfirmationModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
  const overlays = document.getElementById('overlays') as HTMLElement

  useEffect(() => () => {
    closeConfirmationModalHandler()
    closeContactConfirmationModalHandler()
  }, [closeConfirmationModalHandler, closeContactConfirmationModalHandler])

  return ReactDOM.createPortal(

    <FocusLock
      returnFocus
      autoFocus={false}
      disabled={!showConfirmationModal}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={closeConfirmationModalHandler} />

      <div
        className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 min-w-[200px] max-w-[470px] w-full origin-center transition-all duration-300 ${modalClasses} `}
      >
        <div className="relative p-4">
          <div className="relative bg-white rounded-regular shadow-faint">
            <button
              onClick={closeConfirmationModalHandler}
              type="button"
              className="absolute top-3 right-2.5 text-paragraph bg-transparent hover:bg-fields hover:text-mdm-blue rounded-regular text-sm p-1.5 ml-auto transition-colors duration-200 "
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                />
              </svg>
            </button>
            <div className="p-6 text-center">
              <div className="flex justify-center">
                <BsExclamationCircle className="text-red-600" size={60} />
              </div>
              <h3 className="my-6 font-sans text-small text-paragraph">
                {t(modalText ?? '')}
              </h3>
              <div className="flex flex-col-reverse justify-center gap-5 md:flex-row ">
                <button
                  onClick={closeConfirmationModalHandler}
                  type="button"
                  className="w-full py-3 text-sm transition-colors duration-200 bg-white border-2 text-mdm-blue rounded-small border-mdm-blue hover:text-white hover:bg-mdm-blue"
                >
                  {t('noCancel')}
                </button>
                <button
                  onClick={() => mutate(mutateParams, {
                    onSuccess: closeConfirmationModalHandler
                  })}
                  type="button"
                  className="w-full text-white bg-red-600 hover:bg-[#BD1118] rounded-small text-sm py-3 border-2 border-red-600 hover:border-[#BD1118] text-center transition-colors duration-200"
                >
                  {t('yesIamSure')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusLock>,
    overlays
  )
}

export default ConfirmationModal
