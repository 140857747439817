import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import FormLayout from 'src/components/Form/FormLayout'
import DetailsInfoBtns from 'src/components/UI/DetailsInfoBtns'
import { useNewsletters } from 'src/context/NewsletterCtx/NewslettersProvider'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import routes from 'src/constants/routes'
import { useMailingList } from 'src/context/MailingListCtx/MailingListProvider'
import EmailAttachmentsPreview from '../components/EmailAttachmentsPreview'
import CampaignHeaderPreviewValues from '../components/CampaignForms/CampaignHeaderSteps/CampaignHeaderPreviewValues'
import EmailPreview from '../components/EmailPreview/EmailPreview'
import CampaignHeaderStep from '../components/CampaignForms/CampaignHeaderSteps/CampaignHeaderStep'
import CampaignHeaderSteps from '../components/CampaignForms/CampaignHeaderSteps/CampaignHeaderSteps'

function CampaignPreview() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const newslettersCtx = useNewsletters()
  const mailingListCtx = useMailingList()
  const newslettersPath = routes.admin.newsletters

  const { mutate: mutateNewsletter, isLoading: isNewsletterLoading } = useMutateData({
    key: 'newsletters',
    mutationFn: newslettersCtx.isEditNewsletter
      ? apiService.updateNewsletter
      : apiService.createNewsletter,
    successMessage: newslettersCtx.isEditNewsletter ? 'newsletterHasBeenUpdated' : 'newsletterHasBeenCreated'
  })

  useEffect(() => {
    document.title = `MDM CRM - ${t('campaignPreview')}`
  }, [t])

  // Load template
  useEffect(() => {
    newslettersCtx.setter.setEmailTemplate(document.getElementById('emailTemplate')?.innerHTML ?? '')
  }, [newslettersCtx.setter])

  if (!newslettersCtx.getter.campaignName && !newslettersCtx.getter.emailSubject) {
    return <Navigate to={newslettersPath} />
  }

  if (!(location.state as { isDesignEmailCompleted: boolean })?.isDesignEmailCompleted) {
    return <Navigate to={newslettersPath} />
  }

  const submitFilesOnUpdate = async () => {
    if (newslettersCtx.getter.newsletterFiles
      && newslettersCtx.getter.newsletterFiles?.length > 0
      && newslettersCtx.isEditNewsletter) {
      await Promise.all(
        Array.from(newslettersCtx.getter.newsletterFiles, (file) => apiService
          .createFile(file, newslettersCtx.getter.selectedNewsletterId!))
      )
      await queryClient.invalidateQueries('newsletters')
    }
  }

  return (

    <>
      <FormLayout marginBottom="mb-6" isExpandedForm px="md:px-10" py="md:py-5">
        <div className="flex flex-col w-full mb-5">
          <CampaignHeaderSteps>
            <CampaignHeaderStep status="completed" text="campaignDetails" stepNumber={1} />
            <CampaignHeaderStep status="completed" text="emailDesign" stepNumber={2} />
            <CampaignHeaderStep status="inProgress" text="preview" stepNumber={3} />
          </CampaignHeaderSteps>
          <CampaignHeaderPreviewValues
            campaignName={newslettersCtx.getter.campaignName}
            emailSubject={newslettersCtx.getter.emailSubject}
          />
        </div>
      </FormLayout>
      <EmailPreview
        content={newslettersCtx.content ?? ''}
        emailGreeting={newslettersCtx.emailGreeting ?? ''}
      />
      <EmailAttachmentsPreview newslettersCtx={newslettersCtx} />
      <DetailsInfoBtns
        btnText="send"
        onClickHandlerDraft={() => mutateNewsletter({
          newsletter: newslettersCtx.getter,
          mailingList: mailingListCtx.getter,
          isEditNewsletter: newslettersCtx.isEditNewsletter
        }, {
          onSuccess: async () => {
            await submitFilesOnUpdate()
            navigate(newslettersPath)
            newslettersCtx.onResetNewsletterHandler()
            mailingListCtx.onResetMailingListHandler()
          }
        })}
        onClickHandler={() => mutateNewsletter({
          newsletter: newslettersCtx.getter,
          mailingList: mailingListCtx.getter,
          isEditNewsletter: newslettersCtx.isEditNewsletter
        }, {
          onSuccess: async (response) => {
            await submitFilesOnUpdate()

            apiService.sendNewsletter(response?.data.data.id).then(() => {
              navigate(newslettersPath)
              newslettersCtx.onResetNewsletterHandler()
              mailingListCtx.onResetMailingListHandler()
              queryClient.invalidateQueries('newsletters')
            })
          }
        })}
        isPrimaryBtnLoading={isNewsletterLoading}
        isDraftBtnLoading={isNewsletterLoading}
      />
    </>
  )
}

export default CampaignPreview
