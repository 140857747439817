function SkeletonBlock() {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex-grow w-1/2 h-5 bg-gray-200 animate-pulse rounded-small" />
      <div className="flex-grow w-full h-8 bg-gray-200 animate-pulse rounded-small" />
    </div>
  );
}

export default SkeletonBlock
