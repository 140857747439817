import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import apiService from 'src/services/api/apiService';
import useMutateData from 'src/hooks/useMutateData';
import { AxiosResponse } from 'axios';

type SecondaryToggleButtonProps = {
  donationType?: string;
  isActive?: boolean;
  isDonationLoading?: boolean;
};

function SecondaryToggleButton({ donationType, isActive, isDonationLoading }:
SecondaryToggleButtonProps) {
  const { t } = useTranslation();
  const [isDonationActive, setIsDonationActive] = useState(isActive);
  const params = useParams();
  const endPoint = donationType === t('goods') ? 'goods-donation' : 'financial-donation';

  // eslint-disable-next-line max-len
  const { mutate: updateDonationStatusMutation } = useMutateData<AxiosResponse<any, any> | null>({
    key: ['donations', `donation-${params.id}`],
    mutationFn: async (donationStatus) => {
      if (params.id) {
        // eslint-disable-next-line max-len
        await apiService.updateDonationStatus(params.id, endPoint, donationStatus);
      }
      return null;
    }
  });

  const toggleDonationStatus = () => {
    const updatedDonationStatus = !isDonationActive;
    setIsDonationActive(updatedDonationStatus);
    updateDonationStatusMutation({ data: updatedDonationStatus } as AxiosResponse<any, any> | null);
  };

  return (
    <button
      type="button"
      className="relative mb-3 bg-white md:mx-5 w-[140px] h-[40px] rounded-full ml-5 mt-2"
      onClick={toggleDonationStatus}
      disabled={isDonationLoading}
    >
      <div
        className={`${
          isDonationActive ? 'right-[40px] text-[#00AF9C]' : 'right-[10px] text-[#BD1118]'
        } text-regular absolute top-[2px] transition-all duration-300 py-[0.3rem] px-[0.5rem]`}
      >
        {isDonationActive ? t('ACTIVE') : t('INACTIVE')}
      </div>
      <div
        className={`${
          isDonationActive ? 'right-[5px] bg-[#00AF9C]' : 'left-[5px] bg-[#BD1118]'
        } w-[30px] h-[30px] rounded-full absolute top-[5px] transition-all duration-300`}
      />
    </button>
  );
}

export default SecondaryToggleButton;
