import { WarehouseTypes } from 'src/interfaces/warehouses'
import { useTranslation } from 'react-i18next'
import FormLayout from 'src/components/Form/FormLayout'
import Detail from 'src/components/UI/Detail'
import formattedDate from 'src/utils/formattedDate'
import RenderIf from 'src/components/UI/RenderIf'
import { v4 as uuidv4 } from 'uuid'
import { GrAttachment } from 'react-icons/gr';

interface Props {
  warehouse?: WarehouseTypes
}
function WarehouseDetails({ warehouse }: Props) {
  const { t } = useTranslation()
  const print = 'print:grid-cols-3'

  return (
    <section>
      <FormLayout title={t('registrationDetails')} isExpandedForm>
        <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 w-full md:px-4 mt-4 ${print}`}>
          <Detail label={t('supplier')} text={warehouse?.supplier || ''} />
          <Detail label={t('kind')} text={t(warehouse?.kind.toLowerCase() || '')} />
          <Detail label={t('subcategory')} text={warehouse?.subcategory || ''} />
          <Detail label={t('location')} text={t(warehouse?.location || '')} />
          <Detail
            label={t('program')}
            text={warehouse?.program_entry || ''}
          />
          <Detail label={t('importDate')} text={formattedDate(warehouse?.import_date) || ''} />
          <Detail label={t('importQuantity')} text={warehouse?.import_quantity.toString() || ''} />
          <Detail label={t('exportDate')} text={formattedDate(warehouse?.export_date) || ''} />
          <Detail label={t('exportQuantity')} text={warehouse?.export_quantity.toString() || ''} />
          <Detail label={t('dispositionTo')} text={warehouse?.disposition_to || ''} />
          <Detail label={t('registrationDate')} text={formattedDate(warehouse?.registration_date) || ''} />
          <Detail label={t('registrationQuantity')} text={warehouse?.registration_quantity.toString() || ''} />
        </div>
      </FormLayout>
      <RenderIf isTrue={!!warehouse?.media?.length && warehouse.media?.length > 0}>
        <div className="mt-10 mb-12">
          <h2 className="font-bold text-mdm-navy-blue text-[1.375rem] mb-4">{t('files')}</h2>
          <ul className="flex flex-col gap-3">
            {warehouse?.media?.map((m) => (
              <li key={uuidv4()}>
                {' '}
                <a
                  href={m.file.replace('http', 'https')}
                  target="blank"
                  className="flex items-center gap-1 "
                >
                  <GrAttachment size={17} className="text-[#223367] mr-[6px]" />
                  <span className="font-medium font-sans text-[#100899] hover:underline transition-all duration-200 active:text-purple">{m.file.split('/').at(-1)}</span>
                  {' '}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </RenderIf>
    </section>
  )
}

export default WarehouseDetails
