import { createContext } from 'react'
import { ModalContextTypes } from './modalsCtx.interface'

const modalsCtx = createContext<ModalContextTypes>({
  showConfirmationModal: false,
  modalText: null,
  resourceId: null,
  isOpen: false,
  setIsOpen: () => {},
  showNoteModal: false,
  selectedNote: undefined,
  showTaskModal: false,
  selectedTask: undefined,
  openNoteModalHandler: () => null,
  closeNoteModalHandler: () => {},
  openTaskModalHandler: () => null,
  closeTaskModalHandler: () => {},
  closeConfirmationModalHandler: () => null,
  openConfirmationModalHandler: () => null,
  contactId: null,
  closeContactConfirmationModalHandler: () => null,
  openContactConfirmationModalHandler: () => null
})

export default modalsCtx
