import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isEqual,
  isSameMonth,
  isToday,
  parse,
  startOfWeek,
  startOfToday,
  parseISO,
  isSameDay
} from 'date-fns'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import routes from 'src/constants/routes'

interface Props {
  handleDateChange?: (date: Date) => void | undefined
  selectedDay?: any
  tasks: any
}

function DatePicker({ handleDateChange, selectedDay, tasks } : Props) {
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.admin.tasks || pathname === routes.agent.tasks
  const colStartClasses = [
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
    'col-start-7'
  ]

  function classNames(...classes: (string | boolean)[]): string {
    return classes.filter((c) => Boolean(c)).join(' ');
  }

  const [t] = useTranslation()
  const todayDate = startOfToday()
  const [currentMonth, setCurrentMonth] = useState(format(todayDate, 'MMMM-yyyy'))

  // get current month dynamically
  const firstDayCurrentMonth = parse((currentMonth), 'MMMM-yyyy', new Date())

  // create dynamic dates for calendar
  const days = eachDayOfInterval({
    start: startOfWeek(firstDayCurrentMonth),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth))
  })

  function previousMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMMM-yyyy'))
  }

  function nextMonth() {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMMM-yyyy'))
  }

  const formattedDate = format(firstDayCurrentMonth, 'MMMM yyyy');
  const parts = formattedDate.split(' ')
  const firstWord = parts[0];
  const secondPart = parts.slice(1).join(' ')

  return (
    <div className="w-full bg-white border-regular rounded-regular min-w-[335px] max-w-[375px]">
      <div className="px-2 pt-4 pb-3 md:pt-6 md:pb-5 md:px-6 shadow-faint rounded-regular">
        <div className="flex items-center mx-3 mx:ml-0">
          <h2 className="flex-auto font-bold text-mdm-navy-blue text-[1.38rem]">
            {t(firstWord)}
            {' '}
            {secondPart}
          </h2>
          <button
            type="button"
            onClick={previousMonth}
            className="-my-1.5 flex flex-none items-center border border-lines justify-center p-1.5 text-lines hover:text-paragraph hover:border-paragraph rounded-small translate-all duration-150"
          >
            <span className="sr-only">Previous month</span>
            <HiOutlineChevronLeft className="w-4 h-4" aria-hidden="true" title={t('previous')} />
          </button>
          <button
            onClick={nextMonth}
            type="button"
            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 border border-lines rounded-small text-lines hover:text-paragraph hover:border-paragraph translate-all duration-150"
          >
            <span className="sr-only">Next month</span>
            <HiOutlineChevronRight className="w-4 h-4" aria-hidden="true" title={t('next')} />
          </button>
        </div>
        <div className="grid font-sans grid-cols-7 text-[0.6rem] font-bold text-center uppercase mt-7 mb-4 text-mdm-navy-blue/50">
          <div>Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div>Sat</div>
        </div>
        <div className="grid grid-cols-7 mt-2 font-sans text-xs">
          {days.map((day, dayIdx) => (
            <div
              key={day.toString()}
              className={classNames(dayIdx === 0 && colStartClasses[getDay(day)], 'py-1.5 px-1 ')}
            >
              <button
                type="button"
                onClick={() => day && handleDateChange && handleDateChange(day)}
                className={classNames(
                  isEqual(day, selectedDay) && 'text-white',
                  !isEqual(day, selectedDay) && isToday(day) && 'text-white bg-mdm-sky-blue',
                  !isEqual(day, selectedDay) && !isToday(day) && isSameMonth(day, firstDayCurrentMonth) && 'text-mdm-navy-blue',
                  !isEqual(day, selectedDay) && !isToday(day) && !isSameMonth(day, firstDayCurrentMonth) && 'text-lines',
                  isEqual(day, selectedDay) && isToday(day) && 'bg-mdm-blue',
                  isEqual(day, selectedDay) && !isToday(day) && 'bg-mdm-blue',
                  !isEqual(day, selectedDay) && 'hover:bg-mdm-white translate-all duration-150',
                  (isEqual(day, selectedDay) || isToday(day)) && 'font-bold bg-mdm-blue text-white hover:bg-mdm-blue translate-all duration-150',
                  `mx-auto flex h-10 w-10 items-center justify-center rounded-small text-regular ${isExactPath ? 'cursor-pointer ' : 'cursor-auto'}`
                )}
              >
                <time dateTime={format(day, 'yyyy-MM-dd')}>
                  {format(day, 'd')}
                </time>
              </button>
              <div className="flex flex-wrap justify-center w-10 h-1 gap-1 mx-auto mt-1">
                {tasks
                  ?.filter((task: any) => isSameDay(parseISO(task.start_time), day))
                  .map((task: any) => (
                    <div
                      key={task.id}
                      className={`w-1 h-1 rounded-full ${
                        // eslint-disable-next-line no-nested-ternary
                        task.status.toLowerCase() === 'in_progress'
                          ? 'bg-mdm-blue'
                          : task.status.toLowerCase() === 'completed'
                            ? 'bg-[#00AF9C]'
                            : 'bg-[#BD1118]'
                      }`}
                    />
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DatePicker
