import ReactDOM from 'react-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdClose } from 'react-icons/io'
import RenderIf from 'src/components/UI/RenderIf'
import NoDataMessage from 'src/components/UI/NoDataMessage'
import { ContactTypes } from 'src/interfaces/contacts'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'

interface Props {
  selectedContacts: ContactTypes[]
  showModal: boolean
  closeModal: () => void
  onRemoveContact: (contact: ContactTypes) => void
  onClearSelectedContacts: () => void
}

function SelectedContactsModal({
  selectedContacts = [],
  showModal,
  closeModal,
  onRemoveContact,
  onClearSelectedContacts
}
:Props) {
  const { t } = useTranslation()
  const backdropClasses = showModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = showModal ? ' translate-x-[0]' : ' translate-x-[100%]'
  const overlays = document.getElementById('overlays') as HTMLElement
  const { setSearchTextContact } = useDataProvider()

  useEffect(() => () => { setSearchTextContact('') }, [setSearchTextContact])

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div className={`fixed inset-0 z-[90] bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={closeModal} />
      <div className={`bg-white flex flex-col fixed bottom-0 top-0 right-0 z-[100] max-w-[600px] w-full origin-center transition-all duration-300 ${modalClasses} sm:rounded-l-2xl`}>
        <div className="flex items-center justify-between bg-mdm-blue text-white sm:rounded-tl-2xl px-5 md:px-[35px] py-[25px]">
          <p className="text-[1.8rem] md:text-2xl font-bold">{t('selectedContacts')}</p>
          <IoMdClose size={33} onClick={closeModal} className="cursor-pointer" />
        </div>
        <div className="flex flex-col h-[calc(100%-130px)] xs:h-[calc(100%-90px)] p-5">
          <RenderIf isTrue={selectedContacts.length === 0}>
            <div className="flex items-center justify-center flex-1">
              <NoDataMessage text="noContactsSelected" />
            </div>
          </RenderIf>
          <RenderIf isTrue={selectedContacts.length > 0}>
            <div className="flex flex-col h-full mb-4 overflow-y-auto">
              <button
                onClick={onClearSelectedContacts}
                className="mb-2 text-sm text-left transition-colors duration-200 text-paragraph hover:text-mdm-blue w-max"
                type="button"
              >
                {t('removeAll')}
              </button>
              <ul className="mt-3">
                {selectedContacts.map((selectedContact) => (
                  <li key={selectedContact.email}>
                    <div className="flex justify-between mb-3 border-b text-paragraph font-regular">
                      <div className="flex gap-1 ">
                        <p>{selectedContact.first_name}</p>
                        <p>{selectedContact.last_name}</p>
                        <p className="font-[500] ml-3 font-sans text-[#100899]">{selectedContact.email}</p>
                      </div>
                      <button type="button" aria-label="delete">
                        <RiDeleteBin6Line
                          onClick={() => onRemoveContact(selectedContact)}
                          size={22}
                          className="pr-1 transition-colors duration-200 hover:text-red-500"
                        />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </RenderIf>
          <span className="w-full h-[1px] bg-lines my-4 mt-auto" />
          <button
            className="rounded-[5px] w-full px-[30px] py-4 text-regular hover:bg-mdm-navy-blue transition-colors duration-200 bg-mdm-blue text-white"
            type="button"
            onClick={closeModal}
          >
            {t('close')}
          </button>
        </div>
      </div>
    </>,
    overlays
  )
}

export default SelectedContactsModal
