import userMethods from 'src/utils/userMethods'
import { v4 as uuidv4 } from 'uuid';
import RenderIf from '../UI/RenderIf'

function TaskPanelFilterSkeleton() {
  const authUser = userMethods.getUser()

  return (
    <div className="w-full px-6 pt-8 pb-10 bg-gray-100 rounded-regular">
      <div className="h-6 bg-gray-200 mb-12 w-[50%] animate-pulse rounded-small" />
      <div className="flex flex-col items-stretch justify-between mt-5 gap-7">
        <RenderIf isTrue={authUser?.user.user_type === 'ADMINISTRATOR'}>
          {Array(7)
            .fill(null)
            .map(() => (
              <div className="flex items-center gap-3" key={uuidv4()}>
                <div className="w-6 h-5 rounded-[6px] animate-pulse bg-gray-200" />
                <div className="w-full h-5 bg-gray-200 animate-pulse rounded-small flex-grow-2" />
              </div>
            ))}
        </RenderIf>
        <RenderIf isTrue={authUser?.user.user_type === 'AGENT'}>
          {Array(2 + (authUser?.user?.departments?.length || 0))
            .fill(null)
            .map(() => (
              <div className="flex items-center gap-3" key={uuidv4()}>
                <div className="w-5 h-5 rounded-[6px] animate-pulse bg-gray-200" />
                <div className="w-full h-5 bg-gray-200 animate-pulse rounded-small flex-grow-2" />
              </div>
            ))}
        </RenderIf>
      </div>
    </div>
  )
}

export default TaskPanelFilterSkeleton
