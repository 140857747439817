import { useTranslation } from 'react-i18next'
import { FiColumns } from 'react-icons/fi';

interface Props {
  onClickHandler: () => void
  isDashboardTable?: boolean
}

function TableColumnsConfigurationButton({ onClickHandler, isDashboardTable }:Props) {
  const { t } = useTranslation()
  return (
    <button onClick={onClickHandler} type="button" className="flex items-center gap-2 px-4 py-3 text-sm bg-white border border-solid py-small border-lines rounded-small text-paragraph" title={t('columns')}>
      <FiColumns size={21} className="flex-shrink-0" />
      <span className={`${isDashboardTable ? 'custom-screen-2:block' : 'lg:block'} hidden`}>{t('columns')}</span>
    </button>
  )
}

export default TableColumnsConfigurationButton
