/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Field, useFormikContext, FieldProps } from 'formik'
import routes from 'src/constants/routes'

interface FormValues {
  [key: string]: boolean | string
}

interface PropTypes {
  inputId: string
  name: string
  handleAllDayCheckboxChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  disabled?: boolean
}

function FormCheckbox({
  inputId,
  name,
  handleAllDayCheckboxChange,
  disabled
}: PropTypes) {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const { pathname } = useLocation()
  const isExactPath =
    pathname === routes.admin.tasks ||
    pathname === routes.agent.tasks ||
    pathname === routes.admin.tasksList ||
    pathname === routes.agent.tasksList

  const toggleCheckbox = () => {
    setFieldValue(inputId, !values[inputId])
  }

  return (
    <div
      className={`flex items-center mt-2 lg:mt-0 ${
        inputId === 'status' ? 'gap-3' : 'gap-1'
      }`}
    >
      <Field type="checkbox" name={inputId}>
        {({ field }: FieldProps<boolean>) => (
          <>
            <input
              type="checkbox"
              disabled={disabled}
              checked={field.value}
              id={inputId}
              // eslint-disable-next-line max-len, no-nested-ternary
              className={`${
                inputId === 'status'
                  ? 'custom-checkbox'
                  : inputId === 'is_all_day'
                  ? 'custom-checkbox-all-day'
                  : 'custom-checkbox'
              }`}
              onChange={
                !isExactPath ? toggleCheckbox : handleAllDayCheckboxChange
              }
            />
            <label
              htmlFor={inputId}
              className={`${
                inputId === 'status' ? 'text-white' : 'ml-2 text-paragraph'
              }`}
            >
              {t(name)}
            </label>
          </>
        )}
      </Field>
    </div>
  )
}

export default FormCheckbox
