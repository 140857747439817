import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import Title from 'src/components/UI/Title'
import useGetData from 'src/hooks/useGetData'
import { UserInfoTypes } from 'src/interfaces/user'
import apiService from 'src/services/api/apiService'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'
import routes from 'src/constants/routes'
import RenderIf from 'src/components/UI/RenderIf'
import TableSkeleton from 'src/components/Skeletons/TableSkeleton'
import userMethods from 'src/utils/userMethods'
import UsersTable from './components/UsersTable'

function Users() {
  const user = userMethods.getUser()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.admin.users
  const [t] = useTranslation()
  const { data: usersData, isLoading } = useGetData<UserInfoTypes[]>({
    queryKey: 'users',
    queryFn: apiService.getUsers,
    disabled: user?.user?.user_type === 'AGENT'
  })

  const users = useMemo(() => {
    if (usersData) {
      return usersData.map((u) => ({
        ...u,
        departments: u.departments?.map((department) => ({
          id: department.id,
          title: t(department.title.toLowerCase())
        }))
      }));
    }

    return [];
  }, [usersData, t])

  useEffect(() => {
    document.title = `MDM CRM - ${t('users')}`
  }, [t])

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <section className="h-[calc(100%-96px)]">
          <Title title="users" add="addUser" onClickHandler={() => navigate(routes.shared.createUser)} />
          <RenderIf isTrue={isLoading}>
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isLoading}>
            <UsersTable users={users} />
          </RenderIf>
          <ConfirmationModal cacheKey={['users']} toastSuccessMessage={t('userHasBeenDeleted')} mutationFn={apiService.deleteUser} />
        </section>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Outlet context={users} />
      </RenderIf>
    </>

  )
}

export default Users
