import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageSection from 'src/components/UI/PageSection'
import PageTitle from 'src/components/UI/PageTitle'
import DetailsInfoBtns from 'src/components/UI/DetailsInfoBtns'
import routes from 'src/constants/routes'
import UserMethods from 'src/utils/userMethods'
import formattedDate from 'src/utils/formattedDate'
import RenderIf from 'src/components/UI/RenderIf'
import ContactSkeleton from 'src/components/Skeletons/ContactSkeleton'
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import useGetData from 'src/hooks/useGetData'
import apiService from 'src/services/api/apiService'
import ContactDetails from '../components/ContactDetails'

function ContactInfo() {
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()

  const authUser = UserMethods.getUser()
  const { resetFilters } = useDataProvider()
  const { t } = useTranslation()

  const { id } = params
  const [stringPart, numericPart] = id?.split('-') || []

  const { data: contact, isLoading: isContactLoading } = useGetData<any>({
    queryKey: `contact-${numericPart}`,
    queryFn: () => apiService.getContact(numericPart, stringPart),
    disabled: !numericPart || !stringPart
  })

  useEffect(() => {
    document.title = `MDM CRM - ${t('contact')}`
  }, [t, contact])

  useEffect(
    () => () => {
      resetFilters()
    },
    [resetFilters]
  )

  return (
    <PageSection>
      <PageTitle
        text="contactInfo"
        subtext="lastEditLong"
        date={formattedDate(contact?.last_updated_date)}
        tags={contact?.tags}
      />
      <RenderIf isTrue={!isContactLoading}>
        <ContactDetails contact={contact} />
        <DetailsInfoBtns
          onClickHandler={() => {
            navigate(
              `/${authUser?.user.user_type.toLowerCase()}/contacts/${
                routes.shared.updateContact
              }/${contact?.contact_type?.toLowerCase()}-${contact?.id}`
            )
          }}
          btnText="updateContact"
          px="px-8"
        />
      </RenderIf>
      <RenderIf isTrue={!!isContactLoading}>
        <ContactSkeleton />
      </RenderIf>
    </PageSection>
  )
}

export default ContactInfo
