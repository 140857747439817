/* eslint-disable max-len */
import { IoSettingsOutline } from 'react-icons/io5'
import { MdLogout } from 'react-icons/md'
import { TbFileInfo } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import routes from 'src/constants/routes'
import UserMethods from 'src/utils/userMethods'
import useToggleMenu from 'src/hooks/useToggleMenu'
import useLogout from 'src/hooks/useLogout'
import UserIcon from 'src/components/UI/UserIcon'
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'

function OptionsDropdown() {
  const navigate = useNavigate()
  const { ref, isOpen, setIsOpen } = useToggleMenu()
  const { setIsOpen: setModalIsOpen } = useModalContext()
  const { logout } = useLogout()
  const authUser = UserMethods.getUser()
  const handleDropdownMenu = () => setIsOpen((prevState) => !prevState)

  return (
    <div ref={ref} className="relative flex items-center h-full">
      <button type="button" onClick={handleDropdownMenu}>
        <UserIcon isOpen={isOpen} />
      </button>
      <Dropdown isOpen={isOpen}>
        <p className="px-4 py-3 font-bold text-regular text-mdm-navy-blue">
          {`${authUser?.user.first_name} ${authUser?.user.last_name}`}
        </p>
        <DropdownItem
          icon={<IoSettingsOutline size={24} />}
          onClickHandler={() => {
            navigate(
              authUser?.user.user_type === 'AGENT'
                ? routes.agent.settings
                : routes.admin.settings
            )
            setModalIsOpen(false)
          }}
          text="settings"
        />
        <DropdownItem
          icon={<TbFileInfo size={24} />}
          text="userGuide"
          onClickHandler={() => {
            navigate(
              authUser?.user.user_type === 'AGENT'
                ? routes.agent.userGuide
                : routes.admin.userGuide
            )
            setModalIsOpen(false)
          }}
        />
        <DropdownItem
          icon={<MdLogout size={24} />}
          onClickHandler={logout}
          text="logout"
        />
      </Dropdown>
    </div>
  )
}

export default OptionsDropdown
