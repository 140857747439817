/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import {
  Dispatch, SetStateAction, useMemo
} from 'react'
import { FiEdit } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Table from 'src/components/Table/Table'
import Badge from 'src/components/UI/Badge'
import { ContactDepartmentType, ContactTypes } from 'src/interfaces/contacts'
import { parseISO, format } from 'date-fns'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useModalContext } from 'src/context/ModalsCtx/ModalsProvider'
import TableActionBtns from 'src/components/Table/TableActionBtns'
import routes from 'src/constants/routes'
import userMethods from 'src/utils/userMethods'
import { v4 as uuidv4 } from 'uuid';
import { useDataProvider } from 'src/context/DataProviderCtx/DataProvider'
import sortingContactTableHeaders from '../helpers/sortingContactTableHeaders'

interface Props {
  contacts : ContactTypes[]
  isHidden?: boolean
  dataPageParam?: number,
  setDataPageParam?: Dispatch<SetStateAction<number>>,
}

interface OriginalTypes {
  cell: { row: { original: { agent_id: number, admin_id: number, user_type: string, id: number; contact_type: ContactDepartmentType['contactDepartmentType'] } } }
}

interface Person {
  title: string;
  first_name: string;
  last_name: string;
  position: string;
}
interface ContactDetails {
  email: string;
  home_phone: string;
  mobile_phone: string;
}

interface RegistrationDetails {
  created_by: {
    first_name: string;
    last_name: string;
  },
  registration_date: string;
  last_updated_date: string;
}

function ContactsTable({
  contacts = [],
  isHidden,
  dataPageParam,
  setDataPageParam
}:Props) {
  const { t } = useTranslation()
  const { openContactConfirmationModalHandler } = useModalContext()
  const navigate = useNavigate()
  const authUser = userMethods.getUser()
  const { setSelectedContactsForExport } = useDataProvider()

  const handleCheckboxClick = (rowId: string) => {
    setSelectedContactsForExport((prevOptions) => {
      if (prevOptions.includes(rowId)) {
        return prevOptions.filter((option) => option !== rowId);
      }
      return [...prevOptions, rowId];
    });
  };
  const {
    contactsPaginated, searchTextContact, initialDepartments,
    sortTextContact,
    onSortingOrderContactHandler,
    onSortTextContactHandler,
    sortingOrderContact
  } = useDataProvider()

  const departments = [
    { id: 5, title: 'charity' },
    { id: 4, title: 'communication' },
    { id: 3, title: 'finance' },
    { id: 2, title: 'programs' },
    { id: 1, title: 'volunteers' }
  ];

  const contactsDepartmentsFilter = initialDepartments.map((dep) => {
    const departmentDetails = departments.find((d) => d.id === dep);
    return {
      value: departmentDetails?.id,
      label: departmentDetails?.title
    };
  });

  const formattedDate = (dateString: string) => format(parseISO(dateString), 'dd/MM/yyyy');

  const columns = useMemo(() => [
    {
      Header: t('edit'),
      Cell: ({
        cell: {
          row: { original }
        }
      }: OriginalTypes) => (
        <FiEdit
          title={t('edit')}
          size={21}
          aria-label="Edit"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/${authUser?.user?.user_type.toLowerCase()}/contacts/${routes.shared.updateContact}/${original.contact_type.toLocaleLowerCase()}-${original.id}`);
          }}
          className="mt-1 transition-colors duration-200 cursor-pointer hover:text-mdm-blue"
        />
      )
    },
    {
      accessor: 'selected',
      Cell: ({ row }: { row: any }) => (
        <input
          type="checkbox"
          className="custom-checkbox"
          onClick={(e) => {
            e.stopPropagation()
            handleCheckboxClick(row.original.id);
          }}
        />
      )
    },
    {
      accessor: 'expanded',
      Cell: ({ row }: any) => {
        const isCharityContact = row.cells.map((cell: any) => cell.value === t('charity'));
        const trueIndex = isCharityContact.findIndex((item: any) => item === true);
        if (trueIndex !== -1) {
          return null
        }
        return (
          <div>
            {row.isExpanded ? (
              <HiChevronUp className="w-6 h-6 mt-2 text-mdm-blue" />
            ) : (
              <HiChevronDown className="w-6 h-6 mt-2 text-mdm-blue" />
            )}
          </div>
        );
      }
    },
    {
      Header: t('person'),
      accessor: (row: Person) => `${row.first_name} ${row.last_name} ${row.position}`,
      Cell: ({ row }: { row: any }) => (
        <span className="flex flex-col items-start justify-start">
          <p className="text-regular whitespace-nowrap">
            {`${row.original.first_name} ${
              row.original.last_name
            }`}
          </p>
          <p className="text-sm">{row.original.position}</p>
        </span>
      )
    },
    {
      Header: t('contactDetails'),
      accessor: (row: ContactDetails) => `${row.email} ${row.home_phone} ${row.mobile_phone}`,
      Cell: ({ row }: any) => (
        <span className="flex flex-col gap-1">
          <p className="text-[#100899] font-sans">{row.original.email}</p>
          <div className="flex items-center gap-1 text-lines">
            <h3 className="text-xs">
              {t('phoneShort')}
              :
            </h3>
            <h4 className="text-sm">
              {' '}
              {row.original.home_phone}
            </h4>
          </div>
          <div className="flex items-center gap-1 text-lines">
            <h3 className="text-xs">
              {t('mobilePhone')}
              :
            </h3>
            <h4 className="text-sm">
              {row.original.mobile_phone}
            </h4>
          </div>
        </span>
      )
    },
    {
      Header: t('carrier'),
      accessor: 'carrier',
      Cell: ({ value }:{ value:string }) => <p title={value}>{value}</p>
    },
    {
      Header: t('tags'),
      accessor: (row: { tags: { id: number; title: string }[] }) => row
        .tags.map((tag) => tag.title),
      Cell: ({ value }: { value: string[] }) => {
        if (Array.isArray(value) && value.length > 0) {
          return (
            <div className="flex flex-wrap gap-1 w-full max-h-[90px] overflow-y-auto">
              {value.map((val: string) => (
                <Badge text={val} key={uuidv4()} cursor="cursor-auto" />
              ))}
            </div>
          );
        }
        return <p className="text-center md:text-left">-</p>;
      }
    },
    {
      Header: t('program'),
      accessor: 'program',
      Cell: ({ value }:{ value:string }) => (
        <p title={value}>
          {value || <span className="text-center md:text-left">-</span>}
        </p>
      )
    },
    {
      Header: t('department'),
      accessor: 'department.title',
      Cell: ({ value }:{ value:string }) => <p className="text-center md:text-left" title={value}>{value}</p>
    },

    {
      Header: t('registration'),
      accessor: (row: RegistrationDetails) => `${row.created_by?.first_name} ${row.created_by?.last_name} ${formattedDate(row.registration_date)} ${formattedDate(row.last_updated_date)}`,
      Cell: ({ row }: any) => (
        <span className="flex flex-col gap-1">
          <h2>{`${row.original.created_by?.first_name || '-'} ${row.original.created_by?.last_name || '-'} `}</h2>
          <span className="flex items-center gap-1 font-sans text-lines flex-nowrap">
            <h3 className="text-sm whitespace-nowrap">{`${t('dateOfRegistration')}:`}</h3>
            <h4 className="text-xs mt-[3px]">{`${formattedDate(row.original.registration_date)}`}</h4>
          </span>
          <span className="flex items-center gap-1 font-sans text-lines flex-nowrap">
            <h3 className="text-sm whitespace-nowrap">{`${t('lastEdit')}:`}</h3>
            <h4 className="text-xs mt-[3px]">{formattedDate(row.original.last_updated_date)}</h4>
          </span>
        </span>
      )
    },

    {
      Header: t('delete'),
      Cell: ({
        cell: {
          row: { original }
        }
      }: OriginalTypes) => (
        <TableActionBtns
          onClickHandlerDelete={(e) => {
            e.stopPropagation();
            openContactConfirmationModalHandler('deleteContactMessage', {
              id: original.id,
              contactDepartmentType: original.contact_type
            });
          }}
        />
      )
    }
  ], [t]);

  return (
    <Table
      columns={columns}
      data={contacts}
      searchText={searchTextContact}
      storageKey="contactsTable"
      rowsPerPage={100}
      apiPagination={contactsPaginated}
      isHidden={isHidden}
      dataPageParam={dataPageParam}
      setDataPageParam={setDataPageParam}
      onSortingOrderHandler={onSortingOrderContactHandler}
      onSortTextHandler={onSortTextContactHandler}
      sortText={sortTextContact}
      sortingOrder={sortingOrderContact}
      sortingHeadersNames={sortingContactTableHeaders}
      contactsDepartmentsFilter={contactsDepartmentsFilter}
    />
  )
}

export default ContactsTable
