function LastContactsSkeleton() {
  return (
    <div className="w-full p-5 bg-gray-100 rounded-regular">
      <div className="flex flex-col gap-4">
        <div className="flex-grow w-full h-16 bg-gray-200 animate-pulse rounded-small" />
        <div className="flex-grow w-full h-16 bg-gray-200 animate-pulse rounded-small" />
        <div className="flex-grow w-full h-16 bg-gray-200 animate-pulse rounded-small" />
      </div>
    </div>
  );
}

export default LastContactsSkeleton
