import { UserInfoTypes } from 'src/interfaces/user';
import { useTranslation } from 'react-i18next';
import FormLayout from 'src/components/Form/FormLayout';
import Detail from 'src/components/UI/Detail';
import formattedDate from 'src/utils/formattedDate';

interface Props {
  user: UserInfoTypes;
}

function UserDetails({ user }: Props) {
  const { t } = useTranslation();
  const print = 'print:grid-cols-3';

  const username = user?.username || ''
  const fullName = `${user?.first_name || ''} ${user?.last_name || ''}`;
  const email = user?.email || '';
  const telephone = user?.telephone ? `(${user.telephone.slice(1, 3)})${user.telephone.slice(3)}` : '-';
  const position = user?.position || '-';
  const userType = t(user?.user_type.toLowerCase() || '');
  const dateOfRegistration = formattedDate(user?.created_date || '');
  const departmentTitle = user?.departments?.map((department) => ((t(department.title)))).join(', ') || '-';

  return (
    <section>
      <FormLayout title={t('userDetails')} isExpandedForm>
        <div className={`grid grid-cols md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 w-full md:px-4 mt-4 ${print}`}>
          <Detail label={t('Username')} text={username} />
          <Detail label={t('fullName')} text={fullName} />
          <Detail label={t('telephone')} text={telephone} />
          <Detail label={t('dateOfRegistration')} text={dateOfRegistration} />
          <Detail label={t('department')} text={departmentTitle} />
          <Detail label={t('position')} text={position} />
          <Detail label={t('userType')} text={userType} />
          <Detail label="Email" text={email} />
        </div>
      </FormLayout>
    </section>
  );
}

export default UserDetails;
