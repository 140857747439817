import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import useMutateData from 'src/hooks/useMutateData'
import apiService from 'src/services/api/apiService'
import PageTitle from 'src/components/UI/PageTitle'
import PageSection from 'src/components/UI/PageSection'
import useGetData from 'src/hooks/useGetData'
import RenderIf from 'src/components/UI/RenderIf'
import WarehouseSkeleton from 'src/components/Skeletons/WarehouseSkeleton'
import { WarehouseTypes } from 'src/interfaces/warehouses'
import WarehouseCreationForm from '../components/WarehouseCreationForm'

function UpdateWarehouse() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const { data: warehouse, isLoading: isWarehouseLoading } = useGetData<WarehouseTypes>({
    queryKey: `warehouse-${params.id}`,
    queryFn: () => apiService.getWarehouse(params.id),
    disabled: !params.id
  })

  const { isLoading, mutate: updateWarehouseMutation } = useMutateData(
    {
      key: ['warehouses', `warehouse-${params.id}`],
      mutationFn: apiService.updateWarehouse,
      successMessage: 'warehouseHasBeenUpdated'
    }
  )

  const submitFormHandler = (values: any) => {
    updateWarehouseMutation(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `MDM CRM - ${t('updateWarehouse')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('updateWarehouse')} />
      <RenderIf isTrue={isWarehouseLoading}>
        <WarehouseSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isWarehouseLoading}>
        <WarehouseCreationForm
          onSubmit={submitFormHandler}
          warehouse={warehouse}
          isLoading={isLoading}
        />
      </RenderIf>
    </PageSection>
  )
}

export default UpdateWarehouse
