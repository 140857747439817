import { parseISO, format } from 'date-fns'

export default function formattedDate(dateString: string | undefined) {
  if (!dateString) return ''
  if (dateString === '-') return '-'

  const dateOnly = dateString.split('T')[0]
  const date = parseISO(dateOnly)
  return format(date, 'dd/MM/yyyy')
}
