import { useTranslation } from 'react-i18next'

interface Props {
  text: string
}

function FormTitle({ text }:Props) {
  const { t } = useTranslation()
  return (
    <h3 className="md:mb-4 mt-2 mb-2 md:mt-8 text-[1.38rem] font-bold text-mdm-navy-blue">{t(text)}</h3>
  )
}

export default FormTitle
