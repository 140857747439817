import { ReactNode } from 'react'
import medicine from 'src/assets/images/medicine.svg'
import products from 'src/assets/images/products.svg'
import consumables from 'src/assets/images/consumables.svg'
import medicalEq from 'src/assets/images/medical_equipment.svg'
import itEq from 'src/assets/images/it_equipment.svg'
import { useTranslation } from 'react-i18next'
import RenderIf from './RenderIf'

interface Props {
  label: string
  text: string
  children?: ReactNode
  ellipsis?: 'line-clamp-3 text-ellipsis' | 'line-clamp-none'
}
function Detail({
  label, text, children, ellipsis = 'line-clamp-none'
}:Props) {
  const { t } = useTranslation()
  let image = '';

  switch (text) {
    case t('medicine'):
      image = medicine;
      break;
    case t('consumable') || t('consumables'):
      image = consumables;
      break;
    case t('medicalEquipment'):
      image = medicalEq;
      break;
    case t('it_equipment'):
      image = itEq;
      break;
    case t('product'):
      image = products;
      break;
    default:
      image = '';
      break;
  }

  let statusTextColor;
  switch (text) {
    case t('active'):
      statusTextColor = 'text-[#00AF9C]';
      break;
    case t('notActive'):
      statusTextColor = 'text-[#BD1118]';
      break;
    default:
      statusTextColor = 'text-black';
      break;
  }

  return (
    <div className="flex flex-col md:gap-2">
      <p className={` text-paragraph text-xs md:text-regular line-clamp-2 text-ellipsis ${label === 'Email' ? 'font-sans' : ''}`}>{t(label)}</p>
      <div className="flex items-center gap-3">
        <RenderIf isTrue={!!(label === 'kind')}>
          <img src={image} alt={text} className="w-5 h-5 mb-[2px]" />
        </RenderIf>
        <RenderIf isTrue={!!(label === t('status'))}>
          <div className={`w-5 h-5 ${text === t('active') ? 'bg-[#00AF9C] drop-shadow-[0_0_6px_#00AF9C]' : 'bg-[#BD1118] drop-shadow-[0_0_6px_#BD1118]'} rounded-full drop-shadow-sm`} />
        </RenderIf>
        <p title={text} className={`${statusTextColor} font-sans font-semibold text-sm md:text-md flex items-center gap-1 ${ellipsis}`}>
          {children}
          {text}
        </p>
      </div>
    </div>
  )
}

export default Detail
