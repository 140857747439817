import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import apiService from 'src/services/api/apiService'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'
import FormLayout from 'src/components/Form/FormLayout'
import DetailsInfoBtns from 'src/components/UI/DetailsInfoBtns'
import routes from 'src/constants/routes'
import validationSchema from 'src/utils/validationSchema'
import { useMailingList } from 'src/context/MailingListCtx/MailingListProvider'
import userMethods from 'src/utils/userMethods'
import MailingListModal from '../components/MailingList/MailingListModal'
import CampaignDetailsStep from '../components/CampaignForms/CampaignFormSteps/CampaignDetailsStep'
import CampaignHeaderStep from '../components/CampaignForms/CampaignHeaderSteps/CampaignHeaderStep'
import CampaignHeaderSteps from '../components/CampaignForms/CampaignHeaderSteps/CampaignHeaderSteps'
import SelectedClientsModal from '../components/MailingList/SelectedContactsModal'
import SpecificClientsListModal from '../components/MailingList/SpecificContactsListModal'
import EmailTemplatePreviewImage from '../components/EmailTemplatePreviewImage'
import { useNewsletters } from '../../../../../context/NewsletterCtx/NewslettersProvider'

function CampaignDetails() {
  const navigate = useNavigate()
  const newslettersCtx = useNewsletters()
  const mailingListCtx = useMailingList()
  const authUser = userMethods.getUser()
  const nextStepPath = authUser?.user.user_type === 'ADMINISTRATOR' ? routes.admin.designEmail : routes.agent.designEmail

  const formik = useFormik({
    initialValues: {
      campaignName: newslettersCtx.getter.campaignName ?? '',
      emailSubject: newslettersCtx.getter.emailSubject ?? '',
      mailingList: mailingListCtx.getter.selectedMailingListInput ?? '',
      chooseSomeReceivers: ''
    },
    validationSchema: () => validationSchema
      .campaignDetailsForm(mailingListCtx.getter.specificMailingListContacts),
    onSubmit: (values) => {
      newslettersCtx.setter.setCampaignName(values.campaignName)
      newslettersCtx.setter.setEmailSubject(values.emailSubject)
      mailingListCtx.setter.setSelectedMailingListInput(values.mailingList)
      navigate(nextStepPath, {
        state: {
          isCampaignDetailsCompleted: true
        }
      })
    }
  })

  const [t] = useTranslation()
  useEffect(() => {
    document.title = `MDM CRM - ${t('campaignDetails')}`
  }, [t])
  return (
    <>
      <ConfirmationModal cacheKey="mailingList" toastSuccessMessage="mailingHasBeenDeleted" mutationFn={apiService.deleteMailingList} />
      <div className="flex flex-col-reverse gap-5 md:gap-10 3xl:grid 3xl:grid-cols-2">
        <div className="flex flex-col gap-6">
          <FormLayout marginBottom="mb-0" isExpandedForm py="md:py-5" px="md:px-10">
            <CampaignHeaderSteps>
              <CampaignHeaderStep status="inProgress" text="campaignDetails" stepNumber={1} />
              <CampaignHeaderStep status="inActive" text="emailDesign" stepNumber={2} />
              <CampaignHeaderStep status="inActive" text="preview" stepNumber={3} />
            </CampaignHeaderSteps>
            <CampaignDetailsStep formik={formik} />
          </FormLayout>
          <DetailsInfoBtns
            type="submit"
            btnText="nextStep"
            onClickHandler={
            () => formik.submitForm()
              }
          />
        </div>
        <FormLayout marginBottom="mb-0" px="md:px-8" py="md:py-5" isExpandedForm>
          <EmailTemplatePreviewImage />
        </FormLayout>
      </div>
      {/* MODALS */}
      <MailingListModal mailingListId={formik.values.mailingList} />
      <SpecificClientsListModal />

      <SelectedClientsModal
        closeModal={mailingListCtx.closeSelectedContactsModalHandler}
        showModal={mailingListCtx.showSelectedContactsModal}
        selectedContacts={mailingListCtx.getter.mailingListSelectedContacts}
        onRemoveContact={mailingListCtx.onRemoveContactHandler}
        onClearSelectedContacts={mailingListCtx.onClearMailingListSelectedContactsHandler}
      />
      <SelectedClientsModal
        closeModal={mailingListCtx.closeSelectedSpecificContactsModalHandler}
        showModal={mailingListCtx.showSelectedSpecificContactsModal}
        selectedContacts={mailingListCtx.getter.specificMailingListContacts}
        onRemoveContact={mailingListCtx.onRemoveSpecificContactHandler}
        onClearSelectedContacts={mailingListCtx.onClearSpecificContactsHandler}
      />
    </>

  )
}

export default CampaignDetails
