import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PageTitle from 'src/components/UI/PageTitle';
import useMutateData from 'src/hooks/useMutateData';
import apiService from 'src/services/api/apiService';
import PageSection from 'src/components/UI/PageSection'
import { startOfToday, format } from 'date-fns'
import DonationCreationForm from '../components/DonationCreationForm';

function CreateDonation() {
  const [selectedDonationType, setSelectedDonationType] = useState<'' | 'goods' | 'financial'>('');
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { mutate: createDonationMutation, isLoading } = useMutateData({
    key: 'donations',
    mutationFn: (body: any) => apiService.createDonation(body, selectedDonationType),
    successMessage: 'donationHasBeenCreated'
  })

  const onSubmit = (values: any) => {
    createDonationMutation(values, {
      onSuccess: () => navigate(-1)
    })
  }

  const handleSelectClick = (event: React.MouseEvent<HTMLSelectElement>) => {
    const selectOption = event.currentTarget.value as '' | 'goods' | 'financial';
    setSelectedDonationType(selectOption);
  };

  useEffect(() => {
    document.title = `MDM CRM - ${t('newDonation')}`
  }, [t])

  const donationOptions = [
    { value: ' ', label: 'selectOneOption' },
    { value: 'goods', label: 'goods' },
    { value: 'financial', label: 'financialDonation' }
  ];

  return (
    <PageSection>
      <PageTitle text="newDonation" subtext="dateOfRegistrationLong" date={format(startOfToday(), 'dd/MM/yyyy')} />
      <DonationCreationForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        handleSelectClick={handleSelectClick}
        selectedDonationType={selectedDonationType}
        options={donationOptions}
      />
    </PageSection>
  )
}

export default CreateDonation
