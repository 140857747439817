import { ContactTypes } from 'src/interfaces/contacts'
import { DonationTypes } from 'src/interfaces/donations'
import { WarehouseTypes } from 'src/interfaces/warehouses'
import { UserTypes } from 'src/interfaces/user'
import { StorageKeyTypes } from '../interfaces/table'
import routes from '../constants/routes'

interface TableRowNavigationProps {
  key: StorageKeyTypes
  rowData: object
}

// eslint-disable-next-line import/prefer-default-export
export function tableRowNavigation({
  key,
  rowData
}: TableRowNavigationProps): string {
  // eslint-disable-next-line no-constant-condition
  if (key === 'contactsTable') {
    const data = rowData as ContactTypes
    const { id } = data
    const contactType = data.contact_type.toLowerCase()
    return `${routes.shared.contactInfo}/${contactType}-${id}`
  }
  if (key === 'donationsTable') {
    const data = rowData as DonationTypes
    let route = ''
    if (data.type === 'Δωρεά σε Εξοπλισμό') {
      route = `${routes.shared.goodsDonationInfo}/${data.id}`
    } else if (data.type === 'Οικονομικών') {
      route = `${routes.shared.financialDonationInfo}/${data.id}`
    }
    return route
  }
  if (key === 'warehousesTable') {
    const data = rowData as WarehouseTypes
    return `${routes.shared.warehouseInfo}/${data.id}`
  }
  if (key === 'usersTable') {
    const data = rowData as UserTypes
    return `${routes.shared.userInfo}/${data.id}`
  }
  return ''
}
